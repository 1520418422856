import React, {Component} from 'react';
import Dialog, {DialogContent} from 'react-native-popup-dialog';
import ProgressBar from 'react-native-progress/Bar';
import MonthSelectorCalendar from 'react-native-month-selector';

import {Content, Image, ListView, Progress, TitleBar} from 'react-native-1app';
import Icon from 'react-native-1app/lib/Icon';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Alert,
  Platform,
} from 'react-native';
import Banner from './Banner';
import * as Divida from '../../worker/divida';
import styleGlobal from '../../styleGlobal';
import TodoSwipeList from '../../components/TodoSwipeList';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import {Util} from '../../infra';
import * as Actions from '../../redux/actions';
//import DateTime from 'react-native-customize-selected-date'
import DateTime from '../../components/DateTime';
import moment from 'moment-timezone';
import CellListaMain from './CellListaMain';

export default class Calendario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faturas: [],
      flag_tipo: '',
      flag_status: 'N',
      coluna: 'nome_pessoa',
      order: 'asc',
      load: false,
      dif: 0,
      pra_mim: 0,
      por_mim: 0,
      pos: true,
      data_ini: this.getProps().data
        ? moment(this.getProps().data).startOf('day')
        : moment().startOf('day'),
      data_fim: this.getProps().data
        ? moment(this.getProps().data).startOf('day')
        : moment().startOf('day'),
      time: this.getProps().data
        ? moment(this.getProps().data).format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      datedesc: this.getProps().data
        ? moment(this.getProps().data).format('MMMM YYYY')
        : moment().format('MMMM YYYY'),
      timepicker: this.getProps().data
        ? moment(this.getProps().data)
        : moment(),
      tipo_data: 'data_vencimento',
      desc_datas: 'Todos',
      nome_pessoa: '',
      id_usuario_divida: 0,
      mostrarvalheader: true,
      qtdnot: 0,
      add: false,
      aceitasqtd: this.props.screenProps.store.getState().aceitasqtd,
      calendario: [],
      modalFiltroVisible: false,
      tema: this.props.screenProps.store.getState().tema,
    };
  }

  getProps() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  quitarDivida(item) {
    Alert.alert(
      'Aviso',
      'Tem certeza que deseja quitar esta dívida? Os valores não poderão ser modificados após essa operação',
      [
        {text: 'Não', onPress: () => {}, style: 'cancel'},
        {
          text: 'Sim',
          onPress: () => {
            this.setState({load: true});
            Divida.quitar(item, (data, error) => {
              this.setState({load: false});
              if (error) {
                showMessage({
                  message: 'Erro ao quitar',
                  description: error.msg,
                  type: 'warning',
                  icon: {icon: 'warning', position: 'left'},
                  duration: 2000,
                });
              } else if (data.id) {
                showMessage({
                  message: 'Divida quitada!',
                  type: 'success',
                  duration: 2000,
                });
              }
              this.preparaFitros({});
              this.loadCalendar({});
            });
          },
        },
      ],
      {cancelable: false},
    );
  }

  preparaFitros(param, callback) {
    if (param.flag_tipo == undefined || param.flag_tipo == null) {
      param.flag_tipo = this.state.flag_tipo;
    }
    if (param.flag_status == undefined || param.flag_status == null) {
      param.flag_status = this.state.flag_status;
    }
    if (param.coluna == undefined || param.coluna == null) {
      param.coluna = this.state.coluna;
    }
    if (param.order == undefined || param.order == null) {
      param.order = this.state.order;
    }
    if (param.data_ini == undefined || param.data_ini == null) {
      param.data_ini = moment(this.state.data_ini).format('YYYY-MM-DD');
    }
    if (param.data_fim == undefined || param.data_fim == null) {
      param.data_fim = moment(this.state.data_fim).format('YYYY-MM-DD');
    }
    if (param.tipo_data == undefined || param.tipo_data == null) {
      param.tipo_data = this.state.tipo_data;
    }

    if (param.nome_pessoa == undefined || param.nome_pessoa == null) {
      param.nome_pessoa = this.state.nome_pessoa;
    }

    if (
      param.id_usuario_divida == undefined ||
      param.id_usuario_divida == null
    ) {
      param.id_usuario_divida = this.state.id_usuario_divida;
    }

    if (
      param.id_contato_proprio_divida == undefined ||
      param.id_contato_proprio_divida == null
    ) {
      param.id_contato_proprio_divida = this.state.id_contato_proprio_divida;
    }

    this.carregarDividas(param, (data, error) => {
      this.setState(
        {
          flag_tipo: param.flag_tipo,
          flag_status: param.flag_status,
          coluna: param.coluna,
          data_ini: param.data_ini,
          data_fim: param.data_fim,
          tipo_data: param.tipo_data,
          order: param.order,
          nome_pessoa: param.nome_pessoa,
          id_usuario_divida: param.id_usuario_divida,
          id_contato_proprio_divida: param.id_contato_proprio_divida,
        },
        () => {
          if (callback) return callback(data, error);
        },
      );
    });
  }

  carregarDividas(obj, callback) {
    this.setState({load: true});
    if (!obj) {
      obj = {};
    }

    Divida.listarHome(obj, (data, error) => {
      try {
        data.lista = this.preparaAds(data.lista);
      } catch (error) {
        console.log(error);
        data.lista = [];
      }

      this.setState(
        {
          faturas: data.lista,
          load: false,
          pra_mim: data.pra_mim,
          por_mim: data.por_mim,
          dif: data.dif,
          pos: data.pos,
        },
        () => {
          if (
            this.state.id_contato_proprio_divida ||
            this.state.id_usuario_divida
          ) {
            this.calculaTotalDaPessoa(obj);
          }
        },
      );
      if (callback) callback();
    });
  }

  calculaTotalDaPessoa(param) {
    var obj = JSON.parse(JSON.stringify(param));
    obj.data_fim = undefined;
    obj.data_ini = undefined;
    Divida.listarHome(obj, (data, error) => {
      var array = data.lista ? data.lista : [];
      let total = 0;
      for (let a = 0; a < array.length; a++) {
        let element = array[a];
        if (element.id) {
          if (element.flag_tipo == '1') {
            total = total + element.val_montante;
          } else if (element.flag_tipo == '2') {
            total = total - element.val_montante;
          }
        }
      }
      this.setState({
        total_filtro_pessoa: total < 0 ? total * -1 : total,
        cor_filtro_pessoa: total < 0 ? 'red' : 'rgba(48,129,219,1)',
      });
    });
  }

  returnTextTopLeft() {
    if (this.state.flag_status == 'P') {
      return 'R$ 0,00';
    }
    if (this.state.flag_tipo == '') {
      return this.state.dif;
    } else if (this.state.flag_tipo == '1') {
      return this.state.pra_mim;
    } else if (this.state.flag_tipo == '2') {
      return this.state.por_mim;
    }
  }

  preparaAds(array) {
    let pos = 0;
    let interval = 8;
    var id = 1;
    if (array && array.length > 0) {
      while (pos < array.length) {
        array.splice(pos, 0, {add: true, id: id++});
        pos += interval;
      }
      if (!array[array.length - 1].add) {
        array.push({add: true, id: id++});
      }
    }
    return array;
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (
        store.user != this.state.user ||
        store.desc_datas != this.state.desc_datas ||
        store.aceitasqtd != this.state.aceitasqtd
      )
        this.setState({
          user: store.user,
          desc_datas: store.desc_datas,
          nome_pessoa: store.nome_pessoa,
          aceitasqtd: store.aceitasqtd,
          id_usuario_divida: store.id_usuario_divida,
          id_contato_proprio_divida: store.id_contato_proprio_divida,
        });
      if (store.tema != this.state.tema) this.setState({tema: store.tema});
      if (
        store.nome_pessoa != this.state.nome_pessoa ||
        store.id_usuario_divida != this.state.id_usuario_divida ||
        store.id_contato_proprio_divida != this.state.id_contato_proprio_divida
      ) {
        return this.preparaFitros(
          {
            nome_pessoa: store.nome_pessoa,
            id_usuario_divida: store.id_usuario_divida,
            id_contato_proprio_divida: store.id_contato_proprio_divida,
          },
          () => {
            this.loadCalendar({});
          },
        );
      }
      // if (store.index == 1) {
      //   this.preparaFitros({});
      //   this.loadCalendar({});
      // }
      if (store.fazer_pesquisa2 != this.state.fazer_pesquisa2) {
        if (store.fazer_pesquisa2 == true) {
          this.preparaFitros(
            {
              nome_pessoa: store.nome_pessoa,
              id_usuario_divida: store.id_usuario_divida,
              id_contato_proprio_divida: store.id_contato_proprio_divida,
            },
            () => {
              this.loadCalendar({});
            },
          );
          Actions.setFazerPesquisa2(false);
        }
      }
    });
    // this.loadCalendar({
    //   time: this.getProps().data
    //     ? moment(this.getProps().data).format('YYYY-MM-DD')
    //     : moment().format('YYYY-MM-DD'),
    // });
    // this.preparaFitros({});
    this.subs = [
      // this.props.navigation.addListener('didFocus', payload => {
      //   this.preparaFitros({}, () => {
      //     this.loadCalendar({});
      //   });
      // }),
    ];

    this.props.navigation.addListener('didFocus', () => {
      this.preparaFitros({});
      this.loadCalendar({});
    });

    this.preparaFitros({});
    this.loadCalendar({});
  }

  loadCalendar(obj, callback) {
    this.setState({load: true});
    var flag_tipo = obj.flag_tipo;
    if (!flag_tipo) {
      flag_tipo = this.state.flag_tipo;
    }
    var time = obj.time;
    if (!time) {
      time = this.state.time;
    }

    let arraydata = [
      moment(time, 'YYYY-MM')
        .subtract(1, 'month')
        .format('YYYY-MM-DD 00:00:00'),
      moment(time, 'YYYY-MM')
        .add(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD 23:59:59.999'),
    ];

    Divida.calendario(
      {
        vemarray: true,
        data_calendario: arraydata,
        flag_tipo: flag_tipo,
        nome_pessoa: this.state.nome_pessoa,
        id_usuario_divida: this.state.id_usuario_divida,
      },
      (data, error) => {
        if (error) {
          Alert.alert(
            'Erro',
            error.msg,
            [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
            {cancelable: false},
          );
        }

        Divida.organizarObjporDataCalendario(data, (data, error) => {
          this.setState(
            {
              calendario: data,
              load: false,
              time: time,
              timepicker: moment(time, 'YYYY-MM'),
              flag_tipo: flag_tipo ? flag_tipo : '',
            },
            () => {
              if (callback) callback();
            },
          );
        });
      },
    );
  }

  abrirPessoas() {
    this.props.navigation.navigate('ListaPessoasDivida', {
      activity: this,
      oldState: this.state,
      tela: 'calendário',
    });
  }

  cleanFiltro() {
    Actions.setFiltroPessoa('', 0, 0);
    this.state.id_contato_proprio_divida = undefined;
    this.state.id_usuario_divida = undefined; //({ id_usuario_divida: undefined, nome_pessoa: undefined })
    this.state.nome_pessoa = undefined;
    this.preparaFitros(
      {
        flag_tipo: '',
        nome_pessoa: '',
        id_usuario_divida: '',
        id_contato_proprio_divida: '',
      },
      () => {
        this.loadCalendar({});
      },
    );
  }

  abrirNots() {
    this.props.navigation.navigate('Notificacao', {
      activity: this,
      oldState: this.state,
    });
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();

    if (this.subs) this.subs.forEach(sub => sub.remove());
  }

  carregaLista(date, notCurrentMonth) {
    this.state.data_ini = moment(date, 'YYYY-MM-DD').startOf('day');
    this.state.data_fim = moment(date, 'YYYY-MM-DD').startOf('day');
    this.state.time = !notCurrentMonth
      ? this.state.time
      : moment(date, 'YYYY-MM-DD').startOf('day');
    this.preparaFitros({});
  }

  filtroTipo() {
    this.setState({modalFiltroVisible: true});
  }

  openrelatorio(item) {
    this.props.navigation.navigate('Relatorio', {
      activity: this,
      oldState: this.state,
      id_divida: item.id,
      onGoBack: state => {
        // this.setState({data_ini:state.data_ini, data_fim:state.data_fim ,  tipo_data:state.tipo_data});
        // this.preparaFitros({ data_ini: state.data_ini, data_fim: state.data_fim, tipo_data: state.tipo_data });
      },
    });
  }

  returnTextDesc() {
    if (this.state.flag_tipo == '1') {
      return (
        'Dívidas para receber ' +
        moment(this.state.data_ini).format('DD/MM/YYYY')
      );
    } else if (this.state.flag_tipo == '2') {
      return (
        'Dívidas para pagar ' + moment(this.state.data_ini).format('DD/MM/YYYY')
      );
    } else {
      return (
        'Dívidas do dia ' + moment(this.state.data_ini).format('DD/MM/YYYY')
      );
    }
  }

  returnPrev() {
    return (
      <Text style={[{color: Actions.getTema('scrollalfa')}]}>{'Anterior'}</Text>
    );
  }

  returnNext() {
    return (
      <Text style={[{color: Actions.getTema('scrollalfa')}]}>{'Próximo'}</Text>
    );
  }

  onChangeMonthCallback(date) {
    this.state.datedesc = moment(date, 'YYYY-MM').format('MMMM YYYY');
    this.state.time = moment(date, 'YYYY-MM').format('YYYY-MM-DD');
    this.state.data_ini = moment(date).startOf('day');
    this.state.data_fim = moment(date).startOf('day');
    this.loadCalendar({});
    this.preparaFitros({});
  }

  setMonth(date) {
    (this.state.data_ini = moment(date).startOf('day')),
      (this.state.data_fim = moment(date).startOf('day')),
      (this.state.time = moment(date).format('YYYY-MM-DD')),
      (this.state.datedesc = moment(date).format('MMMM YYYY')),
      (this.state.timepicker = moment(date)),
      (this.state.modalFiltroVisible2 = false),
      this.loadCalendar({}, (data, error) => {
        this.preparaFitros({});
      });
  }

  render() {
    return (
      <Content style={styles.content} keyboard={true}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={[styles.button2]}
            onPress={() => {
              this.props.activity.menuLeft.openDrawer();
            }}>
            <Icon
              style={[styles.icon2, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'dots-vertical'}
            />

            {this.state.aceitasqtd > 0 && (
              <View style={styles.viewnot1}>
                <View style={styles.viewnot10} />
              </View>
            )}
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.props.navigation.navigate('Calendarios', {
                setMonth: data => this.setMonth(data),
              });
            }}>
            <Text style={[styles.text2TOP]}>
              {moment(this.state.time, 'YYYY-MM').format('MMMM YYYY')}
            </Text>
          </TouchableOpacity>

          {/* <View style={styles.view} /> */}
          <TouchableOpacity
            style={styles.button2}
            onPress={() => {
              this.cleanFiltro();
            }}>
            <Icon
              style={[styles.icon2, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'close-circle'}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.button2}
            onPress={() => {
              this.filtroTipo();
            }}>
            <Icon
              style={[styles.icon4, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'filter'}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button2}
            onPress={() => {
              this.abrirPessoas();
            }}>
            <Icon
              style={[styles.icon4, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'account-search'}
            />
          </TouchableOpacity>
        </TitleBar>
        <TodoSwipeList
          style={[styles.listview, Actions.getTema('scroll')]}
          ListHeaderComponent={() => {
            return (
              <View style={{alignSelf: 'stretch'}}>
                {/* {!this.state.load ? ( */}
                <DateTime
                  screenProps={this.props.screenProps}
                  date={this.state.time}
                  selectedDate={moment(this.state.data_ini).format(
                    'YYYY-MM-DD',
                  )}
                  ref={v => (this.DateTime = v)}
                  calendario={this.state.calendario}
                  changeDate={(date, notCurrentMonth) =>
                    this.carregaLista(date, notCurrentMonth)
                  }
                  changeMonthCallback={date => this.onChangeMonthCallback(date)}
                  format="YYYY-MM-DD"
                  customWeekdays={[
                    'DOM',
                    'SEG',
                    'TER',
                    'QUA',
                    'QUI',
                    'SEX',
                    'SÁB',
                  ]}
                  renderChildDay={day => this.renderChildDay(day)}
                />
                {/* ) : null} */}
                <View style={styles.viewprogress}>
                  {this.state.load ? (
                    <Progress style={styleGlobal.progress} />
                  ) : null}
                </View>
                <View style={styles.view61}>
                  <View style={styles.view7} />
                  <Text style={styles.text7}>{this.returnTextDesc()}</Text>
                  <View style={styles.view8} />
                </View>

                {this.state.nome_pessoa ? (
                  <View style={[styles.viewnomepess]}>
                    <TouchableOpacity
                      style={[
                        styles.buttonfiltroremove,
                        {...Util.elevation()},
                        Actions.getTema('titlebar'),
                      ]}
                      onPress={() => {
                        this.cleanFiltro();
                      }}>
                      <View style={[styles.view9999]}>
                        <Text
                          style={[
                            styles.text888,
                            Actions.getTema('labelfields'),
                          ]}>
                          {this.state.nome_pessoa}
                        </Text>
                      </View>
                      <View style={[styles.view99991]}>
                        <Text
                          style={[
                            styles.text8881,
                            {color: this.state.cor_filtro_pessoa},
                          ]}>
                          {Util.parseMoney(this.state.total_filtro_pessoa)}
                        </Text>
                      </View>
                      <Icon
                        style={[styles.icon4, Actions.getTema('iconstitlebar')]}
                        fromFontFamily={'Material Design Icons'}
                        name={'close-circle'}
                      />
                    </TouchableOpacity>
                  </View>
                ) : null}

                {this.state.faturas.length == 0 ? (
                  <Banner screenProps={this.props.screenProps} />
                ) : null}

                {this.state.faturas.length == 0 ? (
                  <View style={styles.view6}>
                    <Text style={styles.nenhumreg}>
                      {'Nenhum registro para exibir'}
                    </Text>
                  </View>
                ) : null}
              </View>
            );
          }}
          renderItem={({item, index}) => {
            return (
              <CellListaMain
                item={item}
                screenProps={this.props.screenProps}
                activity={this}
                navigation={this.props.navigation}
                rowID={index}
                tela={'calendario'}
              />
            );
          }}
          data={this.state.faturas}
          keyExtractor={(item, index) => index}
          rightSubView={(item, index) => {
            let pode = false;
            if (item.flag_status != 'P') {
              pode = true;
            } else {
              pode = false;
            }

            if (item.flag_pode_editar == 'C' && item.flag_espelho == 'S') {
              pode = false;
            }

            if (item.add == true) {
              pode = false;
            }

            if (pode)
              return (
                <View style={styles.view9988}>
                  <TouchableOpacity
                    style={styles.button2quitar}
                    onPress={() => {
                      this.openrelatorio(item);
                    }}>
                    <Icon
                      style={styles.icon6quitar}
                      fromFontFamily={'Material Design Icons'}
                      name={'clipboard-text'}
                    />
                    <Text style={styles.text33}>{'Relatório'}</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.button2quitar}
                    onPress={() => {
                      this.quitarDivida(item);
                    }}>
                    <Icon
                      style={styles.icon6quitar}
                      fromFontFamily={'Material Icons'}
                      name={'attach_money'}
                    />
                    <Text style={styles.text33}>{'Quitar'}</Text>
                  </TouchableOpacity>
                </View>
              );
            else
              return (
                <View style={styles.view9988}>
                  <TouchableOpacity
                    style={styles.button2quitar}
                    onPress={() => {
                      this.openrelatorio(item);
                    }}>
                    <Icon
                      style={styles.icon6quitar}
                      fromFontFamily={'Material Design Icons'}
                      name={'clipboard-text'}
                    />
                    <Text style={styles.text33}>{'Relatório'}</Text>
                  </TouchableOpacity>
                </View>
              );
          }}
          leftSubView={(item, index) => {
            return <View />;
          }}
        />

        <Dialog
          visible={this.state.modalFiltroVisible}
          onTouchOutside={() => {
            this.setState({modalFiltroVisible: false});
          }}>
          <DialogContent>
            <View
              style={{
                padding: 10,
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}>
              <Text>Exibir Dívidas</Text>
              <TouchableOpacity
                style={styles.button424}
                onPress={() => {
                  this.state.modalFiltroVisible = false;
                  this.preparaFitros({flag_tipo: ''}, () => {
                    this.loadCalendar({flag_tipo: ''});
                  });
                }}>
                <Text style={styles.text3124}>{'Tudo'}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button424}
                onPress={() => {
                  this.state.modalFiltroVisible = false;
                  this.preparaFitros({flag_tipo: '1'}, () => {
                    this.loadCalendar({flag_tipo: '1'});
                  });
                }}>
                <Text style={styles.text3124}>{'Receber'}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button424}
                onPress={() => {
                  this.state.modalFiltroVisible = false;
                  this.preparaFitros({flag_tipo: '2'}, () => {
                    this.loadCalendar({flag_tipo: '2'});
                  });
                }}>
                <Text style={styles.text3124}>{'Pagar'}</Text>
              </TouchableOpacity>
            </View>
          </DialogContent>
        </Dialog>
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(249,249,249,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  text3124: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  button424: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    backgroundColor: 'rgba(44,126,218,1)',
    borderRadius: 7,
    marginTop: 15,
    marginLeft: 5,
  },
  button1: {
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
  },
  text33: {
    textAlign: 'center',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  text2TOP: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
    fontSize: 20,
  },
  view: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view9988: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    
    width: 175,
  },
  button2: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  button222: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  icon6quitar: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  button2quitar: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 50,
    flexDirection: 'column',
    width: 70,
    marginLeft: 2.5,
    marginRight: 2.5,
  },

  icon4: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  view99991: {
    alignSelf: 'stretch',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'row',
    marginRight: 10,
    width: 100,
  },
  text8881: {
    textAlign: 'right',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 15,
  },
  viewprogress: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  view61: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: 35,
  },
  view7: {
    alignSelf: 'center',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderColor: 'rgba(177,177,184,1)',
    backgroundColor: 'rgba(177,177,184,1)',
    height: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  text7: {
    textAlign: 'center',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'center',
    fontWeight: 'normal',
  },
  view8: {
    alignSelf: 'center',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'rgba(177,177,184,1)',
    height: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: 75,
  },
  viewnomepess: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: 40,
  },
  nenhumreg: {
    textAlign: 'center',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'center',
    fontWeight: 'normal',
    flex: 1,
  },
  listview: {
    alignSelf: 'stretch',
    flex: 1,
  },
  buttonfiltroremove: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 8,
    margin: 5,
  },
  view9999: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  text888: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 15,
  },
  viewnot1: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    padding: 3,
  },
  viewnot10: {
    alignSelf: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    minWidth: 12,
    minHeight: 12,
    backgroundColor: 'rgba(222,34,33,1)',
    borderRadius: 6,
    marginLeft: 30,
  },
});
