import * as types from './actionTypes';
import {Query, Cloud} from '../infra';
import {AsyncStorage, NetInfo, Platform} from 'react-native';
import * as Login from '../worker/login';
import * as Dados from '../worker/dados';
import * as SobreW from '../worker/sobre';
import * as Chat from '../worker/chat';
import * as Notificacao from '../worker/notificacao';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import {PermissionsAndroid} from 'react-native';
import Contacts from 'react-native-contacts';
import moment from 'moment-timezone';
export let net = true;
let globalStore = null;
export function setStore(store) {
  globalStore = store;
}

export function getActualTema() {
  return globalStore.getState().tema;
}

export function getTema(x) {
  let tema = globalStore.getState().tema;
  let obj = {};
  var corbranco = 'rgba(255,255,255,1)';
  var corazul = 'rgba(48,129,219,1)';
  var corpreto = 'rgba(0,0,0,1)';
  var cinzaescuro = 'rgba(177,177,184,1)';
  if (tema == 'white') {
    if (x == 1) {
    } else if (x == 'titlebar') {
      obj.backgroundColor = corbranco;
    } else if (x == 'iconstitlebar') {
      obj.color = corazul;
    } else if (x == 'scroll') {
      obj.backgroundColor = 'rgba(249,249,249,1)';
    } else if (x == 'textcell') {
      obj.color = corpreto;
    } else if (x == 'textcell2') {
      obj.color = corpreto;
    } else if (x == 'textcell3') {
      obj.color = cinzaescuro;
    } else if (x == 'footer') {
      obj.backgroundColor = corbranco;
    } else if (x == 'footericones_ativo') {
      return corazul;
    } else if (x == 'footericones_desativo') {
      return '#DEDEDE';
    } else if (x == 'labelfields') {
      obj.color = corpreto;
    } else if (x == 'icon_checkbox') {
      obj.backgroundColor = corpreto;
    } else if (x == 'datepicker') {
      obj.borderColor = corpreto;
    } else if (x == 'translabel') {
      obj.color = corpreto;
    } else if (x == 'addtrans_btn') {
      return '#3BD97B';
    } else if (x == 'addtrans_icone') {
      return corbranco;
    } else if (x == 'lbl_trans') {
      obj.color = 'rgba(172,172,172,1)';
    } else if (x == 'lbl_tituloconfig') {
      obj.color = corpreto;
    } else if (x == 'lbl_menu') {
      obj.color = '#2c2d33';
    } else if (x == 'swtichtint') {
      return corbranco;
    } else if (x == 'swtichtint2') {
      return corazul;
    } else if (x == 'notification') {
      obj.backgroundColor = 'rgba(222,34,33,1)';
    } else if (x == 'pesquisar') {
      obj.backgroundColor = 'rgba(81,82,83,0.13)';
      obj.borderRadious = 25;
    } else if (x == 'scrollalfa') {
      return corpreto;
    } else if (x == 'bolinhapin') {
      return cinzaescuro;
    } else if (x == 'bolinhapinbt') {
      obj.borderColor = corazul;
      obj.color = corazul;
    } else if (x == 'cellcalendar') {
      obj.backgroundColor = corbranco;
    } else if (x == 'calendardias') {
      obj.color = corbranco;
    } else if (x == 'cellcalendarnotcurrent') {
      return '#DEDEDE';
    } else if (x == 'cellcalendarnotcurrentday') {
      return cinzaescuro;
    } else if (x == 'rellabelfields') {
      obj.color = corazul;
    } else if (x == 'linhasrelatorio') {
      return 'rgba(48,129,219,0.2)';
    } else if (x == 'buttionborderconfig3') {
      obj.borderColor = cinzaescuro;
    }
  } else if (tema == 'preto') {
    if (x == 1) {
    } else if (x == 'titlebar') {
      obj.backgroundColor = '#303030';
    } else if (x == 'iconstitlebar') {
      obj.color = corbranco;
    } else if (x == 'scroll') {
      obj.backgroundColor = '#414141';
    } else if (x == 'textcell') {
      obj.color = '#AAAAAA';
    } else if (x == 'textcell2') {
      obj.color = corbranco;
    } else if (x == 'textcell3') {
      obj.color = corbranco;
    } else if (x == 'footer') {
      obj.backgroundColor = '#414141';
      // obj.shadowOffset = { width: 0, height: 1, };
      // obj.shadowColor = 'black';
      // obj.shadowOpacity = 1.0;
      obj.borderColor = 'black';
      obj.borderTopWidth = 0.5;
      // obj.borderBottomWidth = 0;
      // obj.borderLeftWidth = 0;
      // obj.borderRightWidth = 0;
      // obj.shadowRadius = 2;
      // obj.elevation = 5/1;
    } else if (x == 'footericones_ativo') {
      return corbranco;
    } else if (x == 'footericones_desativo') {
      return '#909090';
    } else if (x == 'labelfields') {
      obj.color = corbranco;
    } else if (x == 'rellabelfields') {
      obj.color = corbranco;
    } else if (x == 'icon_checkbox') {
      obj.backgroundColor = corbranco;
    } else if (x == 'datepicker') {
      obj.borderColor = corbranco;
    } else if (x == 'translabel') {
      obj.color = cinzaescuro;
    } else if (x == 'addtrans_btn') {
      // return corbranco;
      return '#3BD97B';
    } else if (x == 'addtrans_icone') {
      // obj.color = "#303030"
      return corbranco;
    } else if (x == 'lbl_trans') {
      obj.color = corbranco;
    } else if (x == 'lbl_tituloconfig') {
      obj.color = cinzaescuro;
    } else if (x == 'lbl_menu') {
      obj.color = corbranco;
    } else if (x == 'buttonpageview_ativo') {
      obj.borderColor = '#303030';
      // obj.borderBottomColor = corbranco;
      obj.borderBottomColor = 'rgba(48,129,219,1)';
      obj.backgroundColor = null;
    } else if (x == 'buttonpageview_desativo') {
      obj.backgroundColor = null;
    } else if (x == 'buttonpageview_ativolbl') {
      obj.color = corbranco;
    } else if (x == 'linhadivida') {
      obj.backgroundColor = '#AAAAAA';
    } else if (x == 'swtichtint') {
      return corazul;
    } else if (x == 'swtichtint2') {
      return corbranco;
    } else if (x == 'chatmsgs') {
      obj.backgroundColor = '#414141';
      obj.borderColor = '#414141';
      obj.borderBottomColor = '#AAAAAA';
      obj.borderStyle = 'solid';
      obj.borderWidth = 1;
    } else if (x == 'notification') {
      obj.backgroundColor = corazul;
    } else if (x == 'lastmsg') {
      obj.color = corbranco;
    } else if (x == 'pesquisar') {
      obj.backgroundColor = '#cccccc';
      obj.borderRadious = 25;
    } else if (x == 'scrollalfa') {
      return corbranco;
    } else if (x == 'bolinhapin') {
      return corbranco;
    } else if (x == 'bolinhapinbt') {
      obj.borderColor = cinzaescuro;
      obj.color = cinzaescuro;
    } else if (x == 'cellcalendar') {
      obj.backgroundColor = '#303030';
    } else if (x == 'calendardias') {
      obj.color = corpreto;
    } else if (x == 'cellcalendarnotcurrent') {
      // return "#929292";
      return '#414141';
    } else if (x == 'cellcalendarnotcurrentday') {
      return '#C2C2C2';
    } else if (x == 'linhasrelatorio') {
      return 'rgba(255,255,255,0.3)';
    } else if (x == 'buttionborderconfig3') {
      obj.borderColor = corbranco;
    }
  }

  return obj;
}

export function requestContatosPermission(callback) {
  if (Contacts && Platform.OS != 'web')
    Contacts.checkPermission((err, permission) => {
      if (permission == 'authorized') {
        if (callback) callback();
      } else {
        if (Platform.OS == 'ios') {
          Contacts.requestPermission((err, permission) => {
            if (callback) callback();
          });
        } else {
          PermissionsAndroid.request(
            PermissionsAndroid.PERMISSIONS.READ_CONTACTS,
            {
              title: 'Contatos',
              message: 'Casher gostaria de acessar seus contatos.',
              buttonNegative: 'Cancelar',
              buttonPositive: 'OK',
            },
          ).then(() => {
            requestContatosPermission(callback);
          });
        }
      }
    });
}

export function contatosPermission(callback) {
  if (Contacts && Platform.OS != 'web')
    Contacts.checkPermission((err, permission) => {
      if (permission == 'authorized') {
        if (callback) return callback(true, err);
      } else {
        if (callback) return callback(false, err);
      }
    });
}

export function loginPadrao({email, senha}, dispatch, callback) {
  // console.log(dispatch,callback);
  Login.loginEmail({email, senha}, (user, erro) => {
    if (user && user.id) {
      logarUser(user, dispatch);
      callback(user, erro);
    } else {
      callback(user, erro);
    }
  });
}
export function logarUser(user, dispatch) {
  logar(user);
  dispatch({
    type: 'LOGIN',
    user,
  });
}

export function setTrocaPagdash(x) {
  globalStore.dispatch({
    type: 'TROCA_PAG',
    tabdash: x,
  });
}

export function setNotsMsg() {
  Chat.getMsgsQtds((data, error) => {
    globalStore.dispatch({
      type: 'SET_QTDMSGS',
      qtdmsgs: data.qtd,
    });
  });
}

export function setCor(x) {
  globalStore.dispatch({
    type: 'TEMA',
    tema: x,
  });
}

export function setAjuda(x) {
  globalStore.dispatch({
    type: 'AJUDA',
    ajuda: x,
  });
}

export function setChatAtivo(x) {
  globalStore.dispatch({
    type: 'SET_CHATATIVO',
    chatativo: x,
  });
}

export function setFiltroPessoa(
  nome,
  id_usuario_divida,
  id_contato_proprio_divida,
) {
  globalStore.dispatch({
    type: 'SET_NOMEPESSOA',
    nome_pessoa: nome,
    id_usuario_divida: id_usuario_divida,
    id_contato_proprio_divida: id_contato_proprio_divida,
  });
}

export function setFazerPesquisa(x) {
  globalStore.dispatch({
    type: 'FAZERPESQ',
    fazer_pesquisa: x,
  });
}
export function setShowPreInfos(data) {
  globalStore.dispatch({
    type: 'SHOWPREINFOS',
    showpreinfos: data.show,
    preinfos_id_usuario: data.id_usuario,
    preinfos_boolcontatoproprio: data.boolcontatoproprio,
  });
}

export function setFazerPesquisa2(x) {
  globalStore.dispatch({
    type: 'FAZERPESQ2',
    fazer_pesquisa2: x,
  });
}

export function setTelaInicial(x) {
  globalStore.dispatch({
    type: 'TELAINI',
    telaini: x,
  });
}

export function setMostrarValHeader(x) {
  globalStore.dispatch({
    type: 'MOSTRARVALHEADER',
    mostrarvalheader: x,
  });
}

export function setListamsgTela(flag) {
  globalStore.dispatch({
    type: 'SET_LISTAMSGTELA',
    listamsgativa: flag,
  });
}

export function setAceitaQtd(qtd) {
  globalStore.dispatch({
    type: 'SET_ACEITASQTD',
    aceitasqtd: qtd,
  });
}

export function cleanPessoaFiltro(dispatch) {
  dispatch({type: 'SET_NOMEPESSOA', nome_pessoa: '', id_usuario_divida: 0});
}

export function getSobre(dispatch) {
  SobreW.getSobre((dados = {}, error) => {
    if (error)
      return showMessage(
        'Erro na busca de dados verifique sua conexão com internet',
      );
    dispatch({
      type: 'SET_SOBRE',
      dados,
    });
    setTimeout(() => {
      saveRedux();
    }, 500);
  });
}
function logar(user) {
  Cloud.setTokenUser(user.token);
  setTimeout(() => {
    saveRedux();
  }, 10);
}

export function historicoBusca(texto) {
  if (texto && texto.length > 2) {
    let list = globalStore.getState().listBuscaAnterios;
    let newL = [];
    newL.push(texto);
    for (let i = 0; i < list.length; i++) {
      if (i < 7 && list[i] != texto) {
        newL.push(list[i]);
      }
    }
    // list.push(dados.pesquisa);
    globalStore.getState().listBuscaAnterios = [...newL];
  }
}
export function logout() {
  globalStore.dispatch({
    type: 'LOGOUT',
  });
  setTimeout(() => {
    saveRedux();
  }, 10);
}

export function testeToken() {
  if (!Cloud.getTokenUser()) {
    logout();
  }
}

export function loadRedux() {
  AsyncStorage.getItem('redux', (err, result) => {
    if (result)
      try {
        var state = JSON.parse(result);
        state.user = state.user ? state.user : null;
        if (state.user && state.user.token)
          Cloud.setTokenUser(state.user.token);
        globalStore.dispatch({
          type: 'INIT',
          ...state,
        });
      } catch (e) {}
  });
}
export function saveRedux() {
  try {
    AsyncStorage.setItem('redux', JSON.stringify(globalStore.getState()));
  } catch (e) {
    console.log('errorsaveredux', e);
  }
}

export function setRecemAtivou(recemativou) {
  globalStore.dispatch({
    type: 'SET_RECEMATIVOU',
    recemativou: recemativou,
  });
}
export function saveLastExit() {
  globalStore.dispatch({
    type: 'SET_LASTEXIT',
    lastexit: moment(),
  });
}

export function setPin(pin) {
  globalStore.dispatch({
    type: 'SET_PIN',
    pin: pin,
    tempopin: 0,
  });
}

export function setTempoPin(tempo) {
  globalStore.dispatch({
    type: 'SET_TEMPOPIN',
    tempopin: tempo,
  });
}

export function setQtdNot() {
  Notificacao.getNotreaded((data, error) => {
    if (data)
      globalStore.dispatch({
        type: 'SET_QTDNOT',
        qtdnot: data.num,
      });
  });
}
