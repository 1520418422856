import React, {Component} from 'react';
import IconeCarrinho from './IconeCarrinho';
import * as Actions from '../../redux/actions';
import Share from 'react-native-share';
import {
  Content,
  Icon,
  Image,
  ListView,
  Progress,
  TitleBar,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  // Share,
  ImageBackground,
  Platform,
  Dimensions,
  Clipboard,
} from 'react-native';
import Alert from 'react-native-1app/lib/Alert';
import {Util} from '../../infra';
import moment from 'moment-timezone';
import {
  ViewPager,
  PagerTabIndicator,
  IndicatorViewPager,
  PagerTitleIndicator,
  PagerDotIndicator,
} from 'rn-viewpager';
import styleGlobal from '../../styleGlobal';
import Header from './HeaderRelatorio';
import * as Divida from '../../worker/divida';
import * as TransacoesWorker from '../../worker/transacao';
import ViewShot from 'react-native-view-shot';

import {OpenModal, CloseModal} from 'react-1app/lib/PgModal';

export default class Relatorios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      loadGrafico: true,
      currentPosition: 0,
      viewpager: {},
      divida: {},
      uri: '',
      link_g:
        'https://play.google.com/store/apps/details?id=com.casher.app&hl=pt_BR',
      link_a: 'https://apps.apple.com/br/app/id1483978455',
    };
  }
  backPageButton() {
    this.props.navigation.goBack();
  }
  componentDidMount() {
    if (this.propsNav().id_divida) {
      this.abrir(this.propsNav().id_divida);
    }
  }

  abrir(id) {
    this.setState({load: true});
    Divida.listar({id: id}, (data, error) => {
      if (data && data[0]) {
        this.setState({divida: data[0]}, () => {
          TransacoesWorker.listar({id_divida: id}, (data, error) => {
            this.state.divida.pago = data.pago_r;
            this.state.divida.total = data.total_r;
            this.setState(
              {divida: this.state.divida, load: false},
              (data, error) => {
                console.log(this.state.divida);
              },
            );
          });
        });
      }
    });
  }
  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }
  componentWillUnmount() {}

  listarGraficos() {
    // this.setState({ loadGrafico: true })
    // Divida.listarGraficos((data, error) => {
    //   this.setState({ lista: data ? data : [], loadGrafico: false })
    // })
  }

  capturescreen() {
    if (Platform.OS === 'web') {
      const html2canvas = require('html2canvas');

      OpenModal(({}) => {
        return (
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => {
              CloseModal();
            }}
            style={{
              alignSelf: 'stretch',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'rgba(0,0,0,0.7)',
            }}>
            <div id={'teste'} />
          </TouchableOpacity>
        );
      });

      html2canvas(document.querySelector('#relatorio')).then(canvas => {
        document.getElementById('teste').appendChild(canvas);
      });

      return;
    }

    this.refs.viewShot.capture().then(uri => {
      Util.parseImgContatctsToBase64(uri, base64 => {
        base64 = 'data:image/png;base64,' + base64;
        let shareOptions = {
          message: '', // Note that according to the documentation at least one of "message" or "url" fields is required
          url: base64,
        };

        Share.open(shareOptions)
          .then(re => {
            console.log(re);
          })
          .catch(error => {
            console.log(error);
          });
      });
    });
  }

  shareText() {
    var msg = 'Prezado(a) ' + this.state.divida.nome_pessoa;
    msg = msg + '\n';
    msg = msg + '\n';
    msg =
      msg +
      'Este é um relatório do valor devido ' +
      (this.state.divida.flag_tipo == '2' ? 'a' : 'por') +
      ' você';
    msg = msg + '\n';
    msg = msg + '\n';
    msg =
      msg +
      'Descrição: ' +
      (this.state.divida.descricao
        ? this.state.divida.descricao
        : 'Não informada');
    msg = msg + '\n';
    msg = msg + '\n';
    msg =
      msg +
      'Montante: ' +
      Util.maskMoneyKotheService(
        this.state.divida.pago + this.state.divida.total,
      );
    msg = msg + '\n';
    msg = msg + 'Pago: ' + Util.maskMoneyKotheService(this.state.divida.pago);
    msg = msg + '\n';
    msg =
      msg + 'Pendente: ' + Util.maskMoneyKotheService(this.state.divida.total);
    msg = msg + '\n';
    msg = msg + '\n';
    msg =
      msg +
      'Data do débito: ' +
      moment(this.state.divida.data_debito).format('DD/MM/YYYY');
    msg = msg + '\n';
    msg =
      msg +
      'Data do vencimento: ' +
      moment(this.state.divida.data_vencimento).format('DD/MM/YYYY');
    msg = msg + '\n';
    msg = msg + '\n';
    msg = msg + 'Baixe o app na Play Store ou App Store =)';
    msg = msg + '\n';
    msg = msg + '\n';
    msg = msg + this.state.link_g;
    msg = msg + '\n';
    msg = msg + '\n';
    msg = msg + this.state.link_a;
    msg = msg + '\n';
    msg = msg + '\n';
    msg = msg + 'Enviado por Casher $2';

    if (Platform.OS == 'web') {
      Clipboard.setString(msg);
      return Alert.alert('Copiado para a área de transferência!');
    }

    Share.open({
      message: msg,
    })
      .then(re => {
        console.log(re);
      })
      .catch(error => {
        console.log(error);
      });
  }

  getLinha() {
    var obj = {};
    obj.borderBottomColor = Actions.getTema('linhasrelatorio');
    obj.borderTopColor = Actions.getTema('linhasrelatorio');
    return obj;
  }
  getLinha3() {
    var obj = {};
    obj.borderBottomColor = Actions.getTema('linhasrelatorio');
    return obj;
  }

  returnText() {
    return (
      <View
        style={{
          alignSelf: 'stretch',
          alignItems: 'center',
        }}>
        <View>
          <View style={{height: 20}} />
          <View style={[styles.linha, this.getLinha()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {'Prezado(a) ' + this.state.divida.nome_pessoa}
            </Text>
          </View>

          <View style={styles.linha2} />
          <View style={[styles.linha, this.getLinha()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {'Este é um relatório do valor devido ' +
                (this.state.divida.flag_tipo == '2' ? 'a' : 'por') +
                ' você'}
            </Text>
          </View>

          <View style={styles.linha2} />
          <View style={[styles.linha, this.getLinha()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {'Descrição: ' +
                (this.state.divida.descricao
                  ? this.state.divida.descricao
                  : 'Não informada')}
            </Text>
          </View>

          <View style={styles.linha2} />
          <View style={[styles.linha, this.getLinha()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {'Montante: ' +
                Util.maskMoneyKotheService(
                  this.state.divida.pago + this.state.divida.total,
                )}
            </Text>
          </View>
          <View style={[styles.linha3, this.getLinha3()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {'Pago: ' + Util.maskMoneyKotheService(this.state.divida.pago)}
            </Text>
          </View>
          <View style={[styles.linha3, this.getLinha3()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {'Pendente: ' +
                Util.maskMoneyKotheService(this.state.divida.total)}
            </Text>
          </View>

          <View style={styles.linha2} />
          <View style={[styles.linha, this.getLinha()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {'Data do débito: ' +
                moment(this.state.divida.data_debito).format('DD/MM/YYYY')}
            </Text>
          </View>
          <View style={[styles.linha3, this.getLinha3()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {'Data do vencimento: ' +
                moment(this.state.divida.data_vencimento).format('DD/MM/YYYY')}
            </Text>
          </View>

          <View style={styles.linha2} />
          <View style={[styles.linha, this.getLinha()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {'Baixe o app na Play Store ou App Store =)'}
            </Text>
          </View>

          <View style={styles.linha2} />
          <View style={[styles.linha, this.getLinha()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {this.state.link_g}
            </Text>
          </View>
          <View style={styles.linha2} />
          <View style={[styles.linha, this.getLinha()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {this.state.link_a}
            </Text>
          </View>

          <View style={styles.linha2} />
          <View style={[styles.linha, this.getLinha()]}>
            <Text style={[styles.textlinha, Actions.getTema('rellabelfields')]}>
              {'Enviado por Casher $2'}
            </Text>
          </View>
        </View>
      </View>
    );
  }

  returnImg() {
    return (
      <View
        style={{
          alignSelf: 'stretch',
          justifyContent: 'space-between',
          flex: 1,
          paddingHorizontal: 20,
          paddingVertical: 60,
        }}>
        <View style={styles.linha_1}>
          <Text style={[styles.textlinha_1, Actions.getTema('rellabelfields')]}>
            {'Prezado(a) ' + this.state.divida.nome_pessoa}
          </Text>
        </View>

        <View style={styles.linha_1}>
          <Text style={[styles.textlinha_1, Actions.getTema('rellabelfields')]}>
            {'Este é um relatório do valor devido ' +
              (this.state.divida.flag_tipo == '2' ? 'a' : 'por') +
              ' você'}
          </Text>
        </View>

        <View style={styles.linha_1}>
          <Text style={[styles.textlinha_1, Actions.getTema('rellabelfields')]}>
            {'Descrição: ' +
              (this.state.divida.descricao
                ? this.state.divida.descricao
                : 'Não informada')}
          </Text>
        </View>

        <View style={styles.linha_1}>
          <Text style={[styles.textlinha_1, Actions.getTema('rellabelfields')]}>
            {'Montante: ' +
              Util.maskMoneyKotheService(
                this.state.divida.pago + this.state.divida.total,
              )}
          </Text>
        </View>

        <View>
          <View style={styles.linha3_1}>
            <Text
              style={[styles.textlinha_1, Actions.getTema('rellabelfields')]}>
              {'Pago: ' + Util.maskMoneyKotheService(this.state.divida.pago)}
            </Text>
          </View>
          <View style={styles.linha3_1}>
            <Text
              style={[styles.textlinha_1, Actions.getTema('rellabelfields')]}>
              {'Pendente: ' +
                Util.maskMoneyKotheService(this.state.divida.total)}
            </Text>
          </View>
        </View>

        <View>
          <View style={styles.linha_1}>
            <Text
              style={[styles.textlinha_1, Actions.getTema('rellabelfields')]}>
              {'Data do débito: ' +
                moment(this.state.divida.data_debito).format('DD/MM/YYYY')}
            </Text>
          </View>
          <View style={styles.linha3_1}>
            <Text
              style={[styles.textlinha_1, Actions.getTema('rellabelfields')]}>
              {'Data do vencimento: ' +
                moment(this.state.divida.data_vencimento).format('DD/MM/YYYY')}
            </Text>
          </View>
        </View>

        <View style={styles.linha_1}>
          <Text style={[styles.textlinha_1, Actions.getTema('rellabelfields')]}>
            {'Baixe o app na Play Store ou App Store =)'}
          </Text>
        </View>

        <View style={styles.linha_1}>
          <Text style={[styles.textlinha_1, Actions.getTema('rellabelfields')]}>
            {'Enviado por Casher $2'}
          </Text>
        </View>
      </View>
    );
  }

  render() {
    return (
      <Content style={styles.content} keyboard={true}>
        <TitleBar
          style={[
            styleGlobal.titlebar,
            Actions.getTema('titlebar'),
            {flexDirection: 'column', height: 100},
          ]}>
          <View style={styles.viewhead99}>
            <View style={styles.view778}>
              <TouchableOpacity
                style={styles.button3}
                onPress={() => {
                  this.backPageButton();
                }}>
                <Icon
                  style={[styles.icon, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'arrow-left'}
                />
              </TouchableOpacity>
              <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
                {'Relatórios'}
              </Text>
            </View>
          </View>
          <View style={styles.viewhead99}>
            {/* {this.viewpager ? */}
            <Header
              screenProps={this.props.screenProps}
              activity={this}
              navigation={this.props.navigation}
              currentPosition={this.state.currentPosition}
              viewpager={this.viewpager}
            />
            {/* : null} */}
          </View>
        </TitleBar>
        <View style={styles.view2}>
          <ViewPager
            horizontalScroll={true}
            style={[styles.fragment2, {flexGrow: 1}]}
            ref={viewpager => {
              this.viewpager = viewpager;
            }}
            onPageSelected={page => {
              this.setState({currentPosition: page.position});
            }}>
            {/* page Todos */}
            <ScrollView style={[styles.scroll, Actions.getTema('scroll')]}>
              {Actions.getActualTema() == 'white' && (
                <ImageBackground
                  resizeMode={'contain'} // or cover
                  style={{flex: 1, width: '100%'}}
                  source={require('../../../img/rel1_3.png')}>
                  {this.returnText()}
                </ImageBackground>
              )}

              {Actions.getActualTema() == 'preto' && (
                <ImageBackground
                  resizeMode={'contain'} // or cover
                  style={{flex: 1, width: '100%'}}
                  source={require('../../../img/rel1_2.png')}>
                  {this.returnText()}
                </ImageBackground>
              )}
            </ScrollView>

            {/* page relacionados */}
            <ScrollView
              style={[styles.scroll, Actions.getTema('scroll')]}
              contentContainerStyle={{alignItems: 'center'}}>
              {/* //https://github.com/react-native-community/react-native-share/issues/160 */}

              {Actions.getActualTema() == 'white' && (
                <ViewShot
                  ref="viewShot"
                  options={{format: 'jpg', quality: 0.9}}>
                  {Platform.OS === 'web' ? (
                    <div id={'relatorio'}>
                      <ImageBackground
                        resizeMode={'contain'} // or cover
                        style={styles.imgBack}
                        source={require('../../../img/papel_picotado2.png')}>
                        {this.returnImg()}
                      </ImageBackground>
                    </div>
                  ) : (
                    <ImageBackground
                      resizeMode={'contain'} // or cover
                      style={styles.imgBack}
                      source={require('../../../img/papel_picotado2.png')}>
                      {this.returnImg()}
                    </ImageBackground>
                  )}
                </ViewShot>
              )}

              {Actions.getActualTema() == 'preto' && (
                <ViewShot
                  ref="viewShot"
                  options={{format: 'jpg', quality: 0.9}}>
                  {Platform.OS === 'web' ? (
                    <div id={'relatorio'}>
                      <ImageBackground
                        resizeMode={'contain'} // or cover
                        style={styles.imgBack}
                        source={require('../../../img/papel_picotado_escuro2.png')}>
                        {this.returnImg()}
                      </ImageBackground>
                    </div>
                  ) : (
                    <ImageBackground
                      resizeMode={'contain'} // or cover
                      style={styles.imgBack}
                      source={require('../../../img/papel_picotado_escuro2.png')}>
                      {this.returnImg()}
                    </ImageBackground>
                  )}
                </ViewShot>
              )}
            </ScrollView>
          </ViewPager>
        </View>

        <IconeCarrinho
          style={styles.fragment}
          screenProps={this.props.screenProps}
          navigation={this.props.navigation}
          onClick={() => {
            if (this.state.currentPosition == 0) {
              this.shareText();
            } else {
              this.capturescreen();
            }
          }}
          activity={this}
        />
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(249,249,249,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  imgBack: {
    width:
      Dimensions.get('window').width > 500
        ? 500
        : Dimensions.get('window').width,
    height:
      (Dimensions.get('window').width > 500
        ? 500
        : Dimensions.get('window').width) /
      (3 / 4),
  },
  fragment2: {
    alignSelf: 'stretch',
    flex: 1,
  },
  linha: {
    borderBottomWidth: 1,
    borderTopWidth: 1,
    flexDirection: 'row',
  },
  linha3: {
    borderBottomWidth: 1,
    flexDirection: 'row',
  },
  linha2: {
    height: 35,
  },

  linha_1: {
    // borderBottomColor: "rgba(255,255,255,0.3)",
    // borderTopColor: "rgba(255,255,255,0.3)",
    // borderBottomWidth: 1,
    // borderTopWidth: 1,
    flexDirection: 'row',
  },
  linha3_1: {
    // borderBottomColor: "rgba(255,255,255,0.3)",
    // borderBottomWidth: 1,
    flexDirection: 'row',
  },
  linha2_1: {
    height: 35,
  },

  view2: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'rgba(238,238,238,1)',
    flex: 1,
  },

  viewlegendaslabel: {
    flexDirection: 'row',
    margin: 5,
    alignItems: 'center',
  },
  viewlegendaslabelsqare: {
    height: 10,
    width: 10,
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    width: 50,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginLeft: 5,
  },
  text2: {
    color: 'rgba(255,255,255,1)',
    fontWeight: 'normal',
    marginLeft: 10,
  },
  textlinha: {
    color: 'rgba(255,255,255,1)',
    fontWeight: 'normal',
    marginLeft: 40,
    marginTop: 5,
    marginBottom: 5,
    marginRight: 90,
  },
  textlinha_1: {
    color: 'rgba(48,129,219,1)',
    fontWeight: 'normal',
    marginLeft: 20,
    marginTop: 5,
    marginBottom: 5,
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(249,249,249,1)',
  },
  view778: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    
  },
  viewhead99: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
  },
});
