import {StyleSheet} from 'react-native-1app';
export default StyleSheet.create({
  titlebar_logo: {
    width: 150,
    height: 40,
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  azul: {
    color: 'rgba(48,129,219,1)',
  },
  titlebar: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    alignSelf: 'stretch',
    height: 50,
    backgroundColor: 'rgba(255,255,255,1)',
  },
  titlebar_voltar: {
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: 'rgba(44,126,218,1)',
    width: 50,
    height: 50,
  },
  titlebar_voltar_icone: {
    color: 'rgba(48,129,219,1)',
  },
  titlebar_textoprincipal: {
    alignSelf: 'auto',
    textAlign: 'left',
    fontWeight: 'normal',
    color: 'rgba(48,129,219,1)',
    flex: 1,
  },
  botao_desabilitado: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    flexDirection: 'column',
    flex: 1,
    borderRadius: 10,
    marginLeft: 15,
    marginRight: 15,
    borderColor: 'rgba(177,177,184,1)',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  botao_text_desabilitado: {
    textAlign: 'center',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  botao_abilitado2: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 40,
    flexDirection: 'column',
    backgroundColor: 'rgba(48,129,219,1)',
    borderRadius: 10,
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
  },
  botao_abilitado: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 68,
    width: 68,
    flexDirection: 'column',
    backgroundColor: 'rgba(48,129,219,1)',
    borderRadius: 34,
    // "flex": 0,
    // "marginLeft": 5,
    padding: 2,
    // "marginRight": 5
  },
  botao_text_abilitado: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    fontWeight: 'normal',
    fontSize: 11,
  },
  buttonfooter: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    backgroundColor: 'rgba(44,126,218,1)',
    borderRadius: 7,
    marginTop: 15,
  },
  progress: {
    width: 35,
    height: 35,
  },
  viewfantasma: {
    height: 50,
  },
  viewpins: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    marginTop: 10,
    marginBottom: 10,
    maxWidth: 190,
  },
  buttonpin: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderStyle: 'solid',
    borderRadius: 50,
    width: 50,
    borderColor: 'rgba(48,129,219,1)',
    borderWidth: 1,
    marginLeft: 12,
    marginRight: 12,
  },
  title_bar_text: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },
  icon_back: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  viewprogress: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
  },
});
