import React, {Component} from 'react';
import {
  View,
  Vibration,
  Platform,
  AppState,
  Dimensions,
  Alert,
} from 'react-native';
import {Cloud} from '../infra';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import {setNotsMsg, setQtdNot} from '../redux/actions';
import firebase from 'react-native-firebase';
import * as Usuario from '../worker/usuario';
import type {Notification, NotificationOpen} from 'react-native-firebase';
var navigation = null;
var globalStore = null;
var subscrible = null;
var dispatch = null;

export function init(callback, store, nav, disp) {
  navigation = nav;
  globalStore = store;
  dispatch = disp;
  firebase
    .messaging()
    .hasPermission()
    .then(enabled => {
      if (enabled) {
        continuar(callback, store);
      } else {
        firebase
          .messaging()
          .requestPermission()
          .then(() => {
            continuar(callback, store);
          })
          .catch(error => {
            console.log(error);
          });
      }
    });

  firebase
    .notifications()
    .onNotificationOpened((notificationOpen: NotificationOpen) => {
      const notification: Notification = notificationOpen.notification;
      // if (Platform.OS === "ios") {
      notificacaoExterna(notification._data);
      // }
    });
  firebase
    .notifications()
    .getInitialNotification()
    .then((notificationOpen: NotificationOpen) => {
      if (notificationOpen) {
        const notification: Notification = notificationOpen.notification;
        notificacaoExterna(notification._data);
      }
    });
}
function continuar(callback, store) {
  getToken(callback);
  configReceberNotificacoes(data => {
    tratarNotificacao(data, store);
  });
}
export function tratarNotificacao(data, store) {
  if (!store.store.getState().chatativo) {
    showMessage({
      message: data.title,
      description: data.body,
      type: 'info',
      backgroundColor: '#001E60',
      onPress: () => {
        notificacaoExterna(data);
      },
      icon: {icon: 'notifications_active', position: 'left'},
      duration: 8000,
      actions: [{label: 'OK'}],
    });
  }

  if (store.store.getState().listamsgativa) {
    store.store.getState().listamsgativa();
  } else {
    setNotsMsg();
  }
  setQtdNot();

  Vibration.vibrate();
}

export function notificacaoExterna(data = {}) {
  let props = {};
  if (data.id_divida) {
    data.page = 'NovaDivida';
    props = {
      id_divida: data.id_divida,
    };

    data &&
      data.page &&
      navigation.navigate(data.page, {
        activity: {},
        oldState: {},
        ...props,
      });
  } else if (data.id_usuario_contato) {
    console.log('saywat');
    Usuario.getUsuarioInfos({id: data.id_usuario_contato}, (user, error) => {
      console.log(user, error);
      data.page = 'Conversa';
      props = {
        id_divida: data.id_divida,
        item: user,
      };
      navigation.navigate('Conversa', {
        activity: {},
        oldState: {},
        id_divida: data.id_divida,
        item: {id_usuario_contato: data.id_usuario_contato, ...user},
      });
    });
  }
  // onGoBack: () => {
  //   setNotsMsg();
  //   this.props.activity.getConversas();
  // }
}

export function configReceberNotificacoes(callback) {
  try {
    firebase.notifications().setBadge(0);
    AppState.addEventListener('change', state => {
      firebase.notifications().setBadge(0);
    });
  } catch (e) {
    // alert(JSON.stringify(e));
    console.log(e);
  }
  try {
    if (subscrible) return;
    subscrible = firebase.notifications().onNotification(notification => {
      Vibration.vibrate();
      var data = parseNotificacao(notification);
      if (callback) callback(data);
    });
  } catch (e) {
    alert(JSON.stringify(e));
    console.log(e);
  }
}
function parseNotificacao(notif) {
  console.log(notif);
  if (Platform.OS === 'ios') {
    return {...notif._data, body: notif._body};
  } else {
    return {...notif._data, body: notif._body};
  }
  var alert = {};
  if (notif.data) {
    alert = notif.data;
  } else if (notif.aps && notif.aps.data) {
    alert = notif.aps.data;
  }
  return alert;
}

export function getToken(callback) {
  firebase
    .messaging()
    .getToken()
    .then(token => {
      if (token) {
        if (callback) callback(token, Platform.OS);
      }
    });
}
