import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {Content, Icon, Progress, TitleBar} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Dimensions,
} from 'react-native';

import {Calendar, LocaleConfig} from 'react-native-calendars';
import styleGlobal from '../../styleGlobal';
import moment from 'moment';
import CalendarioPequeno from './CalendarioPequeno';
import FlatGrid from 'react-native-super-grid';
import {Util} from '../../infra';
import * as WorkerDivida from '../../worker/divida';

export default class Calendarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaDividas: [],
      load: false,
    };
  }

  componentDidMount() {
    this.getDividas({data: this.props.data});
  }

  getDividas(params) {
    if (!params.data) {
      params.data = moment()
        .set(12, 'hours')
        .format('YYYY-MM-DD HH:MM:SS');
    }

    this.setState({load: true});

    WorkerDivida.listarDatasStatus(params, (res = [], error) => {
      this.setState({load: false, listaDividas: this.mapDividas(res)});
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.load !== nextState.load) return true;
    if (this.props.data !== nextProps.data) {
      this.getDividas({
        data: moment(nextProps.data)
          .set(12, 'hours')
          .format('YYYY-MM-DD HH:MM:SS'),
      });
      return true;
    }
  }

  mapDividas(res) {
    let lista = [];
    res.map(divida_res => {
      let flag = '';
      let flag_1 = '';
      let flag_2 = '';

      let dividas_dia = res.filter(divida =>
        moment(divida.data_vencimento).isSame(
          moment(divida_res.data_vencimento),
          'day',
        ),
      );

      if (dividas_dia && dividas_dia[0]) {
        flag_1 = dividas_dia.find(d => d.flag_tipo === '1');
        flag_2 = dividas_dia.find(d => d.flag_tipo === '2');

        if (flag_1 && flag_2) {
          flag = 3;
        } else if (flag_1) {
          flag = 1;
        } else if (flag_2) flag = 2;
      }

      lista.push({
        [moment(divida_res.data_vencimento).format('YYYY-MM-DD')]: flag,
      });
    });

    return lista;
  }

  getRowMax() {
    let width = Dimensions.get('window').width;
    if (width < 500) return 2;
    if (width < 800) return 4;
    return 6;
  }

  render() {
    if (this.state.load)
      return (
        <View
          style={{
            alignSelf: 'stretch',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Progress style={{width: 80, height: 80, color: 'rgb(66,126,218)'}} />
        </View>
      );
    return (
      <View
        style={[{alignSelf: 'stretch', flex: 1}, Actions.getTema('scroll')]}>
        <FlatGrid
          items={this.props.lista}
          rowMax={this.getRowMax()}
          itemDimension={150}
          renderItem={({item, index}) => {
            return (
              <Cell
                screenProps={this.props.screenProps}
                load={this.state.load}
                item={item}
                listaDividas={this.state.listaDividas}
                onMonth={data => {
                  this.props.onMonth(data);
                }}
              />
            );
          }}
          style={[
            {
              alignSelf: 'stretch',
              flex: 1,
            },
            Actions.getTema('scroll'),
          ]}
        />
      </View>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <View
        style={{
          borderRadius: 5,
          margin: 5,
          flex: 1,
          backgroundColor:
            this.props.screenProps.store.getState().tema == 'preto'
              ? 'rgba(48,48,48,1)'
              : 'white',
          ...Util.elevation(
            1,
            this.props.screenProps.store.getState().tema == 'preto'
              ? 'white'
              : 'rgba(48,48,48,1)',
          ),
          overflow: 'hidden',
        }}>
        <CalendarioPequeno
          screenProps={this.props.screenProps}
          data={this.props.item}
          onMonth={data => {
            this.props.onMonth(data);
          }}
          listaDividas={this.props.listaDividas}
          load={this.props.load}
        />
      </View>
    );
  }
}

var styles = StyleSheet.create({
  viewDia: {
    width: 25,
    height: 25,
    borderRadius: 12.5,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  progress: {width: 50, height: 50, color: styleGlobal.azul.color},
  viewSetas: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 30,
  },
  viewhead99: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    width: 50,
  },
  view778: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  progress: {width: 50, height: 50, color: styleGlobal.azul.color},
  viewAzul: {
    height: 30,
    width: 30,
    backgroundColor: 'rgb(66,126,218)',
    position: 'absolute',
  },
  viewVermelha: {
    height: 30,
    width: 30,
    backgroundColor: 'rgb(247,49,66)',
    position: 'absolute',
  },
});
