export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const INIT = 'INIT';
export const LISTABUSCA = 'LISTABUSCA';
export const SET_CARTOES = 'SET_CARTOES';
export const SET_CARTAO = 'SET_CARTAO';
export const SET_USER = 'SET_USER';
export const LIMPAR_PEDIDO = 'LIMPAR_PEDIDO';
export const SET_SOBRE = 'SET_SOBRE';
export const SET_FILTROS = 'SET_FILTROS';
export const SET_LASTEXIT = 'SET_LASTEXIT';
export const SET_PIN = 'SET_PIN';
export const SET_TEMPOPIN = 'SET_TEMPOPIN';
export const SET_RECEMATIVOU = 'SET_RECEMATIVOU';
export const SET_QTDMSGS = 'SET_QTDMSGS';
export const SET_NOMEPESSOA = 'SET_NOMEPESSOA';
export const SET_CHATATIVO = 'SET_CHATATIVO';
export const SET_ACEITASQTD = 'SET_ACEITASQTD';
export const TEMA = 'TEMA';
export const SET_QTDNOT = 'SET_QTDNOT';
export const TROCA_PAG = 'TROCA_PAG';
