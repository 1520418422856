import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {Content, Icon, TitleBar, Progress} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
} from 'react-native';
import Alert from 'react-native-1app/lib/Alert';
import * as actions from '../../redux/actions';
import styleGlobal from '../../styleGlobal';
import * as Usuario from '../../worker/usuario';
export default class CriarPin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pin1: '',
      pin1ok: false,
      pin2: '',
      tempopin: this.props.screenProps.store.getState().tempopin,
      load: false,
    };
  }

  bolinhaPin(bol) {
    var style = {
      alignSelf: 'stretch',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      borderRadius: 50,
      borderColor: 'rgba(137,137,137,1)',
      borderWidth: 1,
      width: 20,
      height: 20,
      marginLeft: 5,
      marginRight: 5,
      borderStyle: 'solid',
    };
    if (!this.state.pin1ok) {
      if (bol == 1 && this.state.pin1.length >= 1) {
        style.backgroundColor = Actions.getTema('bolinhapin');
      } else if (bol == 2 && this.state.pin1.length >= 2) {
        style.backgroundColor = Actions.getTema('bolinhapin');
      } else if (bol == 3 && this.state.pin1.length >= 3) {
        style.backgroundColor = Actions.getTema('bolinhapin');
      } else if (bol == 4 && this.state.pin1.length >= 4) {
        style.backgroundColor = Actions.getTema('bolinhapin');
      }
    } else {
      if (bol == 1 && this.state.pin2.length >= 1) {
        style.backgroundColor = Actions.getTema('bolinhapin');
      } else if (bol == 2 && this.state.pin2.length >= 2) {
        style.backgroundColor = Actions.getTema('bolinhapin');
      } else if (bol == 3 && this.state.pin2.length >= 3) {
        style.backgroundColor = Actions.getTema('bolinhapin');
      } else if (bol == 4 && this.state.pin2.length >= 4) {
        style.backgroundColor = Actions.getTema('bolinhapin');
      }
    }
    return style;
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  addPinNumber(num) {
    let pin = this.state.pin1ok ? this.state.pin2 : this.state.pin1;
    if (num == undefined) {
      pin = pin.slice(0, -1);
    } else {
      if (pin.length >= 4) {
        return;
      } else {
        pin = pin + (num + '');
      }
    }
    if (this.state.pin1ok) {
      this.state.pin2 = pin;
    } else {
      this.state.pin1 = pin;
    }

    if (this.state.pin1.length == 4 && this.state.pin1ok == false) {
      this.continuar();
    } else if (this.state.pin2.length == 4 && this.state.pin1ok == true) {
      this.continuar();
    } else {
      if (this.state.pin1ok) {
        this.setState({pin2: pin});
      } else {
        this.setState({pin1: pin});
      }
    }
  }

  continuar() {
    if (this.state.pin1.length >= 4 && this.state.pin1ok == false) {
      this.setState({pin1ok: true});
    } else {
      if (this.state.pin2.length >= 4 && this.state.pin2 != this.state.pin1) {
        Alert.alert(
          'Aviso',
          'PINS estão diferentes. Cancele e tente novamente',
          [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
          {cancelable: false},
        );
      } else {
        actions.setRecemAtivou(false);
        if (!this.state.tempopin) actions.setTempoPin(1800);
        actions.setPin(this.state.pin1);

        Alert.alert(
          'Atenção',
          'Seu PIN foi criado. Se você perder o seu PIN, seus dados ficarão inacessíveis! Gostaria de enviar uma cópia por e-mail para evitar a perda de dados?',
          [
            {
              text: 'Cancelar',
              onPress: () => {
                this.backPageButton();
              },
              style: 'cancel',
            },
            {
              text: 'Ok',
              onPress: () => {
                this.mandarEmail();
              },
              style: 'cancel',
            },
          ],
          {cancelable: false},
        );
      }
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.tempopin != this.state.tempopin)
        this.setState({tempopin: store.tempopin});
      if (store.pin != this.state.pin) this.setState({pin: store.pin});
    });
  }

  mandarEmail() {
    this.setState({load: true});
    Usuario.enviarPin({pin: this.state.pin1}, (data, error) => {
      var msg = 'E-mail enviado';
      if (error && error.msg) {
        msg = error.msg;
      }
      Alert.alert(
        'Atenção',
        msg,
        [
          {
            text: 'Ok',
            onPress: () => {
              this.backPageButton();
            },
            style: 'cancel',
          },
        ],
        {cancelable: false},
      );
      this.setState({load: false});
    });
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
            <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
              {this.state.pin1ok ? 'Confirmar PIN' : 'Criar PIN'}
            </Text>
          </TouchableOpacity>
          <View style={styles.view} />
          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
        </TitleBar>
        <ScrollView style={[styles.scroll, Actions.getTema('scroll')]}>
          <View style={styles.view31}>
            <Icon
              style={[styles.icon4, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'lock-outline'}
            />
            <Text style={[styles.text3, Actions.getTema('textcell3')]}>
              {this.state.pin1ok
                ? 'Confirmar PIN de 4 dígitos'
                : 'Criar PIN de 4 dígitos'}
            </Text>
            <View style={styles.view311}>
              <View style={this.bolinhaPin(1)} />
              <View style={this.bolinhaPin(2)} />
              <View style={this.bolinhaPin(3)} />
              <View style={this.bolinhaPin(4)} />
            </View>
          </View>
          <View style={styles.view41} />
          <View style={styleGlobal.viewpins}>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(1);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'1'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(2);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'2'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(3);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'3'}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styleGlobal.viewpins}>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(4);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'4'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(5);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'5'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(6);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'6'}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styleGlobal.viewpins}>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(7);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'7'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(8);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'8'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(9);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'9'}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styleGlobal.viewpins}>
            <TouchableOpacity style={styles.button163} disabled={true} />
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(0);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'0'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button162}
              onPress={() => {
                this.addPinNumber();
              }}>
              <Icon
                style={[styleGlobal.icon41, Actions.getTema('bolinhapinbt')]}
                fromFontFamily={'Material Design Icons'}
                name={'backspace'}
              />
            </TouchableOpacity>
          </View>
          <View style={styles.view411} />
          <Text style={[styles.text15, Actions.getTema('textcell3')]}>
            {
              'Você podera também desbloquear por digital caso seu dispositivo já tenha uma cadastrada'
            }
          </Text>
          {/* <View style={styles.view71}>
            <TouchableOpacity
              style={styles.button15}
              onPress={() => {
                this.backPageButton();
              }}
            >
              <Text style={styles.text13}>{"Cancelar"}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button16}
              onPress={() => {
                this.continuar();
              }}
            >
              <Text style={styles.text14}>{"Continuar"}</Text>
            </TouchableOpacity>
          </View> */}
        </ScrollView>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },
  view: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(252,252,252,1)',
    flex: 1,
    padding: 20,
  },
  view31: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 5,
    paddingTop: 10,
  },
  icon4: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  text3: {
    textAlign: 'center',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'stretch',
    fontWeight: 'bold',
    marginTop: 10,
  },
  view311: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 5,
    paddingTop: 10,
  },
  view41: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
    height: 1,
    backgroundColor: 'rgba(177,177,184,1)',
  },
  button163: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    marginRight: 10,
    width: 50,
    borderRadius: 50,
  },
  button162: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderRadius: 50,
    width: 50,
    borderColor: null,
  },
  view411: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
    height: 1,
    backgroundColor: 'rgba(177,177,184,1)',
  },
  text15: {
    textAlign: 'center',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
});
