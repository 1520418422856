import React, {Component} from 'react';

import {
  Content,
  Icon,
  Image,
  ListView,
  TitleBar,
  Progress,
  TextInput,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  FlatList,
  RefreshControl,
} from 'react-native';
import Alert from 'react-native-1app/lib/Alert';
import * as Actions from '../../redux/actions';
import styleGlobal from '../../styleGlobal';
import {Util} from '../../infra';
import * as Chat from '../../worker/chat';
import * as Contatos from '../../worker/contatos';
import TodoSwipeList from '../../components/TodoSwipeList';
import {setNotsMsg, setListamsgTela} from '../../redux/actions';
import {
  ViewPager,
  PagerTabIndicator,
  IndicatorViewPager,
  PagerTitleIndicator,
  PagerDotIndicator,
} from 'rn-viewpager';
import ListaContatos from '../user/ListaContatos';
import HeaderContatos from './HeaderContatos';
export default class Lista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listacontatos: [],
      listacontatos_completa: [],
      listamigos: [],
      listamigos_completa: [],
      load: false,
      todos: [],
      user: this.props.screenProps.store.getState().user,
      currentPosition: 0,
      pesquisar: false,
      ajuda: this.props.screenProps.store.getState().ajuda,
    };
  }

  pesquisar_contatos(nome, tela) {
    var alunos = [];

    if (tela == '0') {
      alunos = [
        ...this.state.listamigos_completa,
        ...this.state.listacontatos_completa,
      ];
    } else if (tela == '1') {
      alunos = this.state.listamigos_completa;
    } else {
      alunos = this.state.listacontatos_completa;
    }

    if (!nome) {
      return this.setState(
        {
          listamigos: this.state.listamigos_completa,
          listacontatos: this.state.listacontatos_completa,
        },
        (data, error) => {
          this.arrumalista(
            this.state.listamigos_completa,
            this.state.listacontatos_completa,
          );
        },
      );
    }
    nome = Util.cleanString(nome);
    let name = nome.toUpperCase();
    var list = [];
    for (let i = 0; i < alunos.length; i++) {
      let aluno = alunos[i];
      var nomeAluno = Util.cleanString(aluno.nome);
      var pes = nomeAluno.toUpperCase();
      if (Util.contemString(pes, name)) {
        list.push(aluno);
      }
    }
    console.log(list);

    if (tela == '0') {
      this.setState({todos: list});
    } else if (tela == '1') {
      this.setState({listamigos: list});
    } else {
      this.setState({listacontatos: list});
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user) this.setState({user: store.user});
      if (store.ajuda != this.state.ajuda) this.setState({ajuda: store.ajuda});
    });

    this.getAmigos();
    this.getContatos();
    this.getAmigosEContatos();
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  removerContatoFunc(item) {
    this.setState({load: true});
    Contatos.remover(item, (data, error) => {
      if (error) {
        Alert.alert(
          'Erro',
          error.msg,
          [{text: 'Não', onPress: () => {}, style: 'cancel'}],
          {cancelable: false},
        );
      }

      this.setState({load: false});
      this.getContatos();
    });
  }

  removerContato(item) {
    Alert.alert(
      'Aviso',
      'Tem certeza que deseja remover este contato?',
      [
        {text: 'Não', onPress: () => {}, style: 'cancel'},
        {
          text: 'Sim',
          onPress: () => {
            this.removerContatoFunc(item);
          },
          style: 'cancel',
        },
      ],
      {cancelable: false},
    );
  }

  editarContato(item) {
    this.props.navigation.navigate('NovoContato', {
      activity: this,
      oldState: this.state,
      id_contato: item.id,
      onGoBack: (page, contato) => {
        this.getContatos();
        this.getAmigos();
        this.getAmigosEContatos();
        // console.log(contato,this.propsNav().tela);
        // if (contato && this.propsNav().tela == "divida") {
        //   this.escolherContato(contato);
        // }
      },
    });
  }
  getAmigosEContatos() {
    this.getAmigos((amigos, error) => {
      this.getContatos((contatos, error) => {
        this.arrumalista(amigos, contatos);
      });
    });
  }

  arrumalista(amigos, contatos) {
    for (let index = 0; index < amigos.length; index++) {
      let alet = amigos[index];
      alet.nome_teste = alet.nome.toUpperCase();
      alet.tela = 'amigos';
    }

    for (let index = 0; index < contatos.length; index++) {
      let alet = contatos[index];
      alet.nome_teste = alet.nome.toUpperCase();
      alet.tela = 'contatos';
    }
    let list = [...amigos, ...contatos];

    list = list.sort((a, b) => (a.nome_teste > b.nome_teste ? 1 : -1));
    console.log(list);
    this.setState({todos: list});
  }

  getAmigos(callback) {
    this.setState({load: true});
    Chat.getContato({}, (data, error) => {
      if (callback) return callback(data, error);
      this.setState({listamigos_completa: data, nome: '', listamigos: data});
      this.setState({load: false});
    });
  }

  getContatos(callback) {
    Contatos.listar({}, (data, error) => {
      if (callback) return callback(data, error);
      this.setState({
        listacontatos: data,
        nome: '',
        listacontatos_completa: data,
      });
    });
  }

  escolherContato(obj) {
    if (!obj.foto) obj.foto = obj.url_foto;

    if (this.props.navigation.state.params.onGoBack)
      this.props.navigation.state.params.onGoBack(obj);
    this.props.navigation.goBack();
  }

  render() {
    return (
      <Content style={styles.container} keyboard={true}>
        <TitleBar
          style={[
            styleGlobal.titlebar,
            Actions.getTema('titlebar'),
            {flexDirection: 'column', height: 100},
          ]}>
          <View style={styles.viewhead99}>
            <View style={styles.view778}>
              <TouchableOpacity
                style={styles.button3}
                onPress={() => {
                  this.props.navigation.goBack();
                }}>
                <Icon
                  style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'arrow-left'}
                />
              </TouchableOpacity>

              {!this.state.pesquisar ? (
                <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
                  {'Contatos'}
                </Text>
              ) : null}
            </View>
            {this.state.pesquisar ? (
              <View
                style={[
                  styles.view777,
                  this.state.pesquisar ? Actions.getTema('pesquisar') : null,
                ]}>
                <TextInput
                  style={styles.textinput}
                  value={this.state.nome}
                  ref={input => {
                    this.pesquisacampo = input;
                  }}
                  onChange={value => {
                    this.state.nome = value;
                    if (this.state.currentPosition == 0) {
                      this.pesquisar_contatos(value, '0');
                    } else if (this.state.currentPosition == 1) {
                      this.pesquisar_contatos(value, '1');
                    } else {
                      this.pesquisar_contatos(value, '2');
                    }
                  }}
                  keyboardType={'default'}
                  placeholder={'Pesquisar'}
                  inputNative={true}
                  selectionColor={'#fff'}
                />
              </View>
            ) : null}
            {!this.state.pesquisar ? (
              <View
                style={[
                  styles.view777,
                  this.state.pesquisar ? Actions.getTema('pesquisar') : null,
                ]}>
                <View style={styles.view779}>
                  <TouchableOpacity
                    style={styles.button3}
                    onPress={() => {
                      this.setState({pesquisar: true}, () => {
                        this.pesquisacampo.focus();
                      });
                    }}>
                    <Icon
                      style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                      fromFontFamily={'Material Design Icons'}
                      name={'magnify'}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            ) : null}

            {this.state.pesquisar ? (
              <TouchableOpacity
                style={styles.button3}
                onPress={() => {
                  this.state.nome = '';
                  this.pesquisar_contatos('', '1');
                  this.pesquisar_contatos('', '2');
                  this.pesquisacampo.focus();
                }}>
                <Icon
                  style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'close-circle'}
                />
              </TouchableOpacity>
            ) : null}

            {/* </View> */}
            {this.state.ajuda && (
              <TouchableOpacity
                style={styles.buttonhelp}
                onPress={() => {
                  Alert.alert(
                    'Ajuda',
                    'Selecione um amigo ou contato, caso não tenha nenhum, adicione-o no ícone',
                    [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                  );
                }}>
                <Icon
                  style={[styles.icon29, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'help-circle'}
                />
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.viewhead99}>
            {this.viewpager ? (
              <HeaderContatos
                screenProps={this.props.screenProps}
                activity={this}
                navigation={this.props.navigation}
                currentPosition={this.state.currentPosition}
                viewpager={this.viewpager}
              />
            ) : null}
          </View>
        </TitleBar>

        <View style={styles.view2}>
          <ViewPager
            horizontalScroll={true}
            style={[styles.fragment2, {flexGrow: 1}]}
            ref={viewpager => {
              this.viewpager = viewpager;
            }}
            onPageSelected={page => {
              this.setState({
                currentPosition: page.position,
                nome: '',
                mensagens: this.state.mensagens_completa,
                listamigos: this.state.listamigos_completa,
              });
            }}>
            {/* page conversa */}

            <View style={styles.content21}>
              <View style={[styles.scroll, Actions.getTema('scroll')]}>
                <FlatList
                  style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                  refreshControl={
                    <RefreshControl
                      refreshing={this.state.load}
                      onRefresh={() => {
                        this.getAmigosEContatos();
                      }}
                    />
                  }
                  renderItem={({item, index}) => {
                    return (
                      <Celllistview
                        item={item}
                        screenProps={this.props.screenProps}
                        activity={this}
                        navigation={this.props.navigation}
                        rowID={index}
                        tela={'todos'}
                      />
                    );
                  }}
                  ListEmptyComponent={() => {
                    return (
                      <Text
                        style={{
                          textAlign: 'center',
                          color: 'gray',
                          margin: 10,
                        }}>
                        {'Nenhum contato encontrado.'}
                      </Text>
                    );
                  }}
                  data={Array.isArray(this.state.todos) ? this.state.todos : []}
                  keyExtractor={(item, index) => index}
                />
              </View>
            </View>

            <View style={styles.content21}>
              <View style={[styles.scroll, Actions.getTema('scroll')]}>
                <FlatList
                  style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                  refreshControl={
                    <RefreshControl
                      refreshing={this.state.load}
                      onRefresh={() => {
                        this.getAmigos();
                      }}
                    />
                  }
                  renderItem={({item, index}) => {
                    return (
                      <Celllistview
                        item={item}
                        screenProps={this.props.screenProps}
                        activity={this}
                        navigation={this.props.navigation}
                        rowID={index}
                        tela={'amigos'}
                      />
                    );
                  }}
                  ListEmptyComponent={() => {
                    return (
                      <Text
                        style={{
                          textAlign: 'center',
                          color: 'gray',
                          margin: 10,
                        }}>
                        {'Nenhum amigo encontrado.'}
                      </Text>
                    );
                  }}
                  data={
                    Array.isArray(this.state.listamigos)
                      ? this.state.listamigos
                      : []
                  }
                  keyExtractor={(item, index) => index}
                />
              </View>
            </View>

            <View style={styles.content21}>
              <View style={[styles.scroll, Actions.getTema('scroll')]}>
                <FlatList
                  style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                  refreshControl={
                    <RefreshControl
                      refreshing={this.state.load}
                      onRefresh={() => {
                        this.getContatos();
                      }}
                    />
                  }
                  ListHeaderComponent={() => {
                    return (
                      <View style={{alignSelf: 'stretch'}}>
                        <View style={styles.view506}>
                          <Text style={styleGlobal.botao_text_desabilitado}>
                            {'Para editar um contato mantenha pressionado'}
                          </Text>
                        </View>
                      </View>
                    );
                  }}
                  renderItem={({item, index}) => {
                    return (
                      <Celllistview
                        item={item}
                        screenProps={this.props.screenProps}
                        activity={this}
                        navigation={this.props.navigation}
                        rowID={index}
                        tela={'contatos'}
                        editarContato={contato => this.editarContato(contato)}
                        removerContato={contato => this.removerContato(contato)}
                      />
                    );
                  }}
                  ListEmptyComponent={() => {
                    return (
                      <Text
                        style={{
                          textAlign: 'center',
                          color: 'gray',
                          margin: 10,
                        }}>
                        {'Nenhum contato encontrado.'}
                      </Text>
                    );
                  }}
                  data={
                    Array.isArray(this.state.listacontatos)
                      ? this.state.listacontatos
                      : []
                  }
                  keyExtractor={(item, index) => index}
                />
                {/*
                <ListView
                  style={styles.listview}
                  dataSource={this.state.listacontatos}
                  renderRow={(rowData, sectionID, rowID) => {
                    return (
                      <Celllistview
                        item={rowData}
                        screenProps={this.props.screenProps}
                        activity={this}
                        navigation={this.props.navigation}
                        rowID={rowID}
                        tela={"contatos"}
                      />
                    );
                  }}
                /> */}
              </View>
            </View>
          </ViewPager>
        </View>
      </Content>
    );
  }
}

class Celllistview extends Component {
  constructor(props) {
    super(props);

    this.state = {item: {msgs: {}}};
  }
  componentDidMount() {}

  componentWillUnmount() {}

  getWeight(item) {
    if (
      !item.msgs.readed &&
      item.msgs.id_sender != this.props.activity.state.user.id
    ) {
      return {
        fontWeight: 'bold',
        color: 'rgba(0,0,0,1)',
      };
    } else {
      return {
        fontWeight: 'normal',
        color: 'rgba(177,177,184,1)',
      };
    }
  }

  escolher(contato) {
    if (!contato.tela) contato.tela = this.props.tela;
    this.props.activity.escolherContato(contato);
  }
  render() {
    return (
      <TouchableOpacity
        style={[styles.Cell, Actions.getTema('chatmsgs')]}
        screenProps={this.props.screenProps}
        navigation={this.props.navigation}
        activity={this}
        onLongPress={() => {
          if (this.props.tela == 'contatos')
            Alert.alert('Editar', 'Opções de contato:', [
              {
                text: 'Editar Contato',
                onPress: () => this.props.editarContato(this.props.item),
              },
              {
                text: 'Remover Contato',
                onPress: () => this.props.removerContato(this.props.item),
              },
            ]);
        }}
        onPress={() => {
          this.escolher(this.props.item);
        }}>
        <View style={styles.view9}>
          <Image
            style={styles.image}
            source={{
              uri: this.props.item.foto
                ? this.props.item.foto
                : this.props.item.url_foto,
            }}
            resizeMode={'cover'}
          />
        </View>
        <View style={styles.view131}>
          <View style={styles.view611}>
            <Text style={[styles.text811, Actions.getTema('lbl_tituloconfig')]}>
              {this.props.item.nome}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

var styles = StyleSheet.create({
  view9988: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',

    width: 120,
  },
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  button2quitar: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 50,
    flexDirection: 'column',
    width: 50,
  },
  button3remover: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 50,
    flexDirection: 'column',
    width: 75,
  },
  text33: {
    textAlign: 'center',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  textinput: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'center',
    fontWeight: 'normal',
    marginLeft: 5,
    paddingRight: 5,
  },
  container: {
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: 'rgba(255,255,255,1)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  view777: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',

    borderRadius: 30,
    margin: 5,
  },
  view779: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  view778: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',

    marginRight: 10,
  },

  text2: {
    // alignSelf: "stretch",
    // textAlign: "left",

    color: 'rgba(48,129,219,1)',
    // alignSelf: "auto",
    fontWeight: 'normal',
    // flex: 1,
    marginLeft: 10,
  },
  viewhead99: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
  },
  fragment2: {
    alignSelf: 'stretch',
    flex: 1,
  },
  content21: {
    backgroundColor: null,
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    flex: 1,
  },
  listview: {
    alignSelf: 'stretch',
  },
  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
  },
  view9: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 36,
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 2,
  },
  buttonhelp: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    flexDirection: 'column',
    width: 30,
    paddingTop: 10,
    marginRight: 5,
  },
  icon6quitar: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  view611: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  text811: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
    marginLeft: 10,
  },
  view2: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'rgba(238,238,238,1)',
    flex: 1,
  },
  view506: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 10,
  },
});
