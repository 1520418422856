import React, { Component } from "react";
import * as Actions from "../../redux/actions";
import { Content, Icon } from "react-native-1app";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPosition: this.props.activity.state.currentPosition

    };
  }



  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }
  

  shouldComponentUpdate() {
    if (this.state.currentPosition != this.props.activity.state.currentPosition) {
      this.setState({ currentPosition: this.props.activity.state.currentPosition })
      return true;
    } else {
      return false;
    }
  }
  // t

  componentWillUnmount() { }

  render() {
    return (
      <View style={[styles.content, Actions.getTema("titlebar")]}>
        <TouchableOpacity style={[this.state.currentPosition == 0 ? styles.button : styles.button2, Actions.getTema(this.state.currentPosition == 0 ? "buttonpageview_ativo" : "buttonpageview_desativo")]}
          onPress={() => {
            this.props.viewpager.setPage(0);
          }}
        >
          <Text style={[this.state.currentPosition == 0 ? styles.text : styles.text2,  Actions.getTema(this.state.currentPosition == 0 ? "buttonpageview_ativolbl" : "nothing") ]}>{"Barra"}</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[this.state.currentPosition == 1 ? styles.button : styles.button2, Actions.getTema(this.state.currentPosition == 1 ? "buttonpageview_ativo" : "buttonpageview_desativo")]}
          onPress={() => {
            this.props.viewpager.setPage(1);
          }}>
          <Text style={[this.state.currentPosition == 1 ? styles.text : styles.text2,  Actions.getTema(this.state.currentPosition == 1 ? "buttonpageview_ativolbl" : "nothing") ]}>{"Pizza"}</Text>
        </TouchableOpacity>


      </View >
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  button: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    borderColor: "rgba(255,255,255,1)",
    borderBottomColor: "rgba(48,129,219,1)",
    backgroundColor: "rgba(255,255,255,1)",
    flex: 1,
    borderStyle: "solid",
    borderWidth: 2
  },
  text: {
    textAlign: "center",
    color: "rgba(48,129,219,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  button2: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    borderColor: "rgba(255,255,255,1)",
    backgroundColor: "rgba(255,255,255,1)",
    flex: 1
  },
  text2: {
    textAlign: "center",
    color: "rgba(198,198,198,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
});
