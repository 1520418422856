import React, {Component} from 'react';
import moment from 'moment-timezone';
import {
  Content,
  Icon,
  ListView,
  TitleBar,
  TextInput,
  Progress,
  Image,
} from 'react-native-1app';
import * as Actions from '../../redux/actions';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  FlatList,
} from 'react-native';
import * as Chat from '../../worker/chat';
import styleGlobal from '../../styleGlobal';
import {setQtdNot} from '../../redux/actions';
import * as Notificacao from '../../worker/notificacao';
export default class GifPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNotificacoes: [],
      load: false,
      descpesquisa: '',
      gifs: [],
      page: 0,
    };
    this.timeout = 0;
  }

  componentDidMount() {}

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  buscarGifs(callback) {
    Chat.getGifs(
      {page: this.state.page, pesquisa: this.state.descpesquisa},
      (data, error) => {
        this.setState({gifs: data.data});
        if (callback) return callback(data, error);
      },
    );
  }

  backPageButton() {
    this.props.navigation.goBack();
  }
  doSearch() {
    this.setState({load: true});
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.buscarGifs((data, error) => {
        this.setState({load: false});
      });
    }, 300);
  }

  returnGif(gif) {
    this.props.navigation.state.params.callback(gif);
    this.props.navigation.goBack();
  }

  componentWillUnmount() {}

  createRows(data, columns) {
    const rows = Math.floor(data.length / columns);
    let lastRowElements = data.length - rows * columns;

    while (lastRowElements !== columns) {
      data.push({
        id: `empty-${lastRowElements}`,
        name: `empty-${lastRowElements}`,
        empty: true,
      });
      lastRowElements += 1;
    }

    return data;
  }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
            <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
              {'Escolher Gif'}
            </Text>
          </TouchableOpacity>
          <View style={[styles.view777, Actions.getTema('pesquisar')]}>
            <TextInput
              style={styles.textinput}
              value={this.state.descpesquisa}
              onChange={value => {
                this.doSearch(value);
                this.state.descpesquisa = value;
                this.setState({descpesquisa: value});
              }}
              keyboardType={'default'}
              placeholder={'Pesquisar'}
              inputNative={true}
              selectionColor={'#fff'}
            />
          </View>
        </TitleBar>
        <View
          style={[styles.scroll, Actions.getTema('scroll')]}
          keyboardShouldPersistTaps={true}>
          {this.state.load ? <Progress style={styles.progress} /> : null}
          <FlatList
            data={this.state.gifs}
            ListHeaderComponent={() => {
              if (this.state.gifs.length == 0 && this.state.load == false)
                return (
                  <View style={styles.view5069}>
                    <Text style={styleGlobal.botao_text_desabilitado}>
                      {'Digite sua pesquisa no campo de busca'}
                    </Text>
                  </View>
                );
              else return null;
            }}
            keyExtractor={item => item.id}
            numColumns={3}
            style={[styles.listview, Actions.getTema('scroll')]}
            renderItem={({item}) => {
              return (
                <Cell
                  item={item}
                  screenProps={this.props.screenProps}
                  activity={this}
                  navigation={this.props.navigation}
                />
              );
            }}
          />
          {this.state.gifs.length > 0 && this.state.load == false && (
            <View style={styles.view55}>
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  this.setState({
                    page: this.state.page == 0 ? 0 : this.state.page - 1,
                  }),
                    () => {
                      this.doSearch();
                    };
                }}>
                <Text style={styles.buttontext}>{'Anterior'}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  this.setState({page: this.state.page + 1}, () => {
                    this.doSearch();
                  });
                }}>
                <Text style={styles.buttontext}>{'Próxima'}</Text>
              </TouchableOpacity>
            </View>
          )}
        </View>
      </View>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  abrir() {}

  render() {
    return (
      <TouchableOpacity
        style={[styles.Cell, Actions.getTema('scroll')]}
        onPress={() => {
          this.props.activity.returnGif(this.props.item);
        }}>
        <Image
          style={styles.image}
          source={{uri: this.props.item.images.original.url}}
          resizeMode={'cover'}
        />
      </TouchableOpacity>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  buttontext: {
    color: 'white',
  },
  button: {
    flex: 1,
    alignSelf: 'stretch',
    justifyContent: 'center',
    backgroundColor: 'rgba(48,129,219,1)',
    alignItems: 'center',
    borderRadius: 10,
    height: 40,
    padding: 5,
    margin: 10,
  },
  view777: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',

    borderRadius: 30,
    margin: 5,
  },
  textinput: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'center',
    fontWeight: 'normal',
    marginLeft: 5,
    paddingRight: 5,
  },
  image: {
    width: 100,
    height: 100,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view5069: {
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 10,
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    // flex: 1
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },
  scroll: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
  },
  view50: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  view55: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  listview: {
    alignSelf: 'stretch',
    paddingTop: 25,
    // backgroundColor: "rgba(249,249,249,s1)"
  },
  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(249,249,249,1)',
  },
});
