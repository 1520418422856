import React, {Component, PureComponent} from 'react';
import {Image} from 'react-native-1app';
import {Dimensions, View, Text, StyleSheet} from 'react-native';
import moment from 'moment';
moment.locale('pt-br');

class Pagina extends Component {
  constructor(props) {
    super(props);
    this.state = {dia: props.dia, agenda: props.agenda};
    this.state.hoje = moment().format('YYYY-MM-DD');
    this.centrarDia(this.state.dia);
  }
  componentDidMount() {
    // alert(this.state.agenda.filtro);
    // console.log(this.state.agenda);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.dia) {
      nextState.dia = nextProps.dia;
    }
    return true;
  }

  getWeek(dia) {
    var mJsDate = moment(dia);
    return Math.ceil(mJsDate.date() / 7);
  }

  centrarDia(foco) {
    var data = moment(foco, 'YYYY/MM/DD');
    data.set(1, 'day');
    // this.state.min = data.get("date");
    this.state.dias = [];
    for (var i = 0; i < 30; i++) {
      data.add(1, 'day');
      var dia = data.get('day');
      // console.log(dia);
      this.state.dias.push({
        dia: data.get('date'),
        data: data.format('YYYY-MM-DD'),
        text: data.locale('pt-br').format('ddd'),
      });
    }
    return this.state.dias;
  }

  cell(dia) {
    var w = Dimensions.get('window').width / 14;
    var atual = moment(this.state.dia, 'YYYY/MM/DD');
    // console.log(atual,dia,);°°
    if (dia.mes != atual.get('month')) {
      return (
        <View
          Key={dia.data + '_dia'}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        />
      );
    }
    return (
      <View
        Key={dia.data + '_dia'}
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flex: 1,
          alignSelf: 'stretch',
        }}>
        {this.props.renderCustomDay ? (
          this.props.renderCustomDay(dia.data)
        ) : (
          <View
            style={[styles.button_1, {backgroundColor: 'rgba(200,200,200,0)'}]}
            underlayColor="rgba(77,77,77,1)"
            onPress={() => {
              this.montarDia(dia);
            }}>
            <View style={styles.View_8}>
              <Text style={styles.label_8}>{dia.dia}</Text>
            </View>
          </View>
        )}
      </View>
    );
  }

  getSemana(data) {
    var dias = [];

    for (var i = 0; i < 7; i++) {
      var dia = data.get('date');
      // if(dia>= this.ultimoDia){
      //   this.montarDias = false;
      // }
      // console.log(dia);
      var foco = moment(data);
      // foco.add(1, 'day');
      // console.log(foco,data,dia);
      dias.push(
        this.cell({
          mes: foco.get('month'),
          dia: foco.get('date'),
          data: foco.format('YYYY-MM-DD'),
          text: foco.locale('pt-br').format('ddd'),
        }),
      );
      //  console.log(data.toJSON(),i);
      data.add(1, 'day');
    }
    return (
      <View Key={data.get('date') + '_semana'} style={styles.view_4_10}>
        {dias}
      </View>
    );
  }

  getTotalSemanas() {
    var data = moment(this.state.dia, 'YYYY-MM-DD');
    data.set('date', 1);
    var dia_da_semana = data.day();
    var somar = 0;
    if (dia_da_semana > 0) {
      somar = dia_da_semana;
    }
    data.endOf('month');
    this.ultimoDia = data.get('date');
    return Math.ceil((data.date() + somar) / 7);
  }

  dias() {
    this.montarDias = true;
    var data = moment(this.state.dia + ' 12:10', 'YYYY-MM-DD HH:mm');
    // console.log(this.state.dia);
    data.set('date', 1);
    var dia_da_semana = data.day();
    // console.log(data,dia_da_semana,data.toJSON());
    if (dia_da_semana > 0) {
      data.add(dia_da_semana * -1, 'day');
    }
    // console.log(data,dia_da_semana,data.toJSON());
    var cells = [];
    //  console.log(this.getTotalSemanas());
    for (var i = 0; i < this.getTotalSemanas() + 1; i++) {
      //ß  console.log(i);
      cells.push(this.getSemana(data));
    }
    return cells;
  }

  montarDia(dia) {
    this.setState({dia: dia.data});
    this.props.pg.mostrarDia(dia);
  }

  render() {
    return (
      <View style={styles.cabecalho}>
        <Text style={{color: 'rgb(66,126,218)'}}>
          {moment(this.props.dia).format('MMMM')}
        </Text>
        <View
          style={[
            {
              alignSelf: 'stretch',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              flex: 1,
            },
          ]}>
          <View style={[styles.view_2]}>
            <Text
              style={[
                styles.label_2,
                {
                  color:
                    this.props.screenProps.store.getState().tema == 'preto'
                      ? 'rgb(230,230,230)'
                      : 'rgb(100,100,100)',
                },
              ]}>
              {'D'}
            </Text>
            <Text
              style={[
                styles.label_2,
                {
                  color:
                    this.props.screenProps.store.getState().tema == 'preto'
                      ? 'rgb(230,230,230)'
                      : 'rgb(100,100,100)',
                },
              ]}>
              {'S'}
            </Text>
            <Text
              style={[
                styles.label_2,
                {
                  color:
                    this.props.screenProps.store.getState().tema == 'preto'
                      ? 'rgb(230,230,230)'
                      : 'rgb(100,100,100)',
                },
              ]}>
              {'T'}
            </Text>
            <Text
              style={[
                styles.label_2,
                {
                  color:
                    this.props.screenProps.store.getState().tema == 'preto'
                      ? 'rgb(230,230,230)'
                      : 'rgb(100,100,100)',
                },
              ]}>
              {'Q'}
            </Text>
            <Text
              style={[
                styles.label_2,
                {
                  color:
                    this.props.screenProps.store.getState().tema == 'preto'
                      ? 'rgb(230,230,230)'
                      : 'rgb(100,100,100)',
                },
              ]}>
              {'Q'}
            </Text>
            <Text
              style={[
                styles.label_2,
                {
                  color:
                    this.props.screenProps.store.getState().tema == 'preto'
                      ? 'rgb(230,230,230)'
                      : 'rgb(100,100,100)',
                },
              ]}>
              {'S'}
            </Text>
            <Text
              style={[
                styles.label_2,
                {
                  color:
                    this.props.screenProps.store.getState().tema == 'preto'
                      ? 'rgb(230,230,230)'
                      : 'rgb(100,100,100)',
                },
              ]}>
              {'S'}
            </Text>
          </View>
          {this.dias()}
          {this.dias().length === 6 ? <View style={{height: 24}} /> : null}
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  cabecalho: {
    // "backgroundColor": "rgba(0,0,255,1)",
    backfaceVisibility: 'visible',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },

  view_2: {
    backfaceVisibility: 'visible',
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  label_2: {
    textAlign: 'center',
    flex: 1,
    backfaceVisibility: 'visible',
    alignSelf: 'stretch',
  },
  label_1: {
    textAlign: 'center',
    fontSize: 12,
    backfaceVisibility: 'visible',
    color: 'rgba(84,84,84,1)',
    marginLeft: 0,
    marginTop: 0,
  },
  label_3: {
    textAlign: 'center',
    fontSize: 12,
    backfaceVisibility: 'visible',
    color: 'rgba(84,84,84,1)',
    marginLeft: 0,
    marginTop: 0,
  },
  label_4: {
    textAlign: 'center',
    fontSize: 12,
    backfaceVisibility: 'visible',
    color: 'rgba(84,84,84,1)',
    marginLeft: 0,
    marginTop: 0,
  },
  label_5: {
    textAlign: 'center',
    fontSize: 12,
    backfaceVisibility: 'visible',
    color: 'rgba(84,84,84,1)',
    marginLeft: 0,
    marginTop: 0,
  },
  label_6: {
    textAlign: 'center',
    fontSize: 12,
    backfaceVisibility: 'visible',
    color: 'rgba(84,84,84,1)',
    marginLeft: 0,
    marginTop: 0,
  },
  label_7: {
    textAlign: 'center',
    fontSize: 12,
    backfaceVisibility: 'visible',
    color: 'rgba(84,84,84,1)',
    marginLeft: 0,
    marginTop: 0,
  },
  view_4_10: {
    backgroundColor: 'rgba(0,0,0,0)',
    backfaceVisibility: 'visible',
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  button_1: {
    backfaceVisibility: 'visible',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginLeft: 0,
    marginTop: 0,
    alignSelf: 'stretch',
  },
  View_8: {
    backfaceVisibility: 'visible',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    flex: 1,
  },
  view4: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    backgroundColor: 'rgba(153,197,192,1)',
    height: 5,
    zIndex: 100,
  },
  label_8: {
    textAlign: 'center',
    backfaceVisibility: 'visible',
  },
  bandeira: {
    width: 8,
    height: 8,
    flexDirection: 'column',
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
  },
  view_5: {
    backgroundColor: 'rgba(214,51,81,1)',
    backfaceVisibility: 'visible',
    borderRadius: 3,
    width: 6,
    height: 6,
    flexDirection: 'row',
    marginLeft: 0,
    marginTop: 0,
  },
});

module.exports = Pagina;
