import { Cloud, Util } from "../infra";
import md5 from "md5";
import moment from "moment-timezone";

export function salvar(usuario, callback) {
  Cloud.post("usuarios", { ...usuario, senha: md5(usuario.senha), confirmar_senha: md5(usuario.confirmar_senha) }, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function atualizar(usuario, callback) {
  Cloud.put("usuarios", usuario, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function atualizarSenha(usuario, callback) {
  Cloud.put("usuarios/atualizar/senha", usuario, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function recuperarSenha(email, callback) {
  Cloud.put("usuarios/esqueceu/senha/" + email, {}, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function remover(id, callback) {
  Cloud.delete("usuarios/" + id, {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listar(callback) {
  Cloud.get("usuarios", {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function enviarPin(obj, callback) {
  Cloud.post("usuarios/enviarpin", obj, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function getUserLogado(callback) {
  Cloud.get("usuarios/logado", {}, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function salvarTempoAviso(dados, callback) {
  Cloud.put('usuarios/tempoaviso', dados, (res, error) => {
    if (callback) callback(res, error);
  })
}
export function atualizarDiasNot(dados, callback) {
  Cloud.put('usuarios/notsconfig', dados, (res, error) => {
    if (callback) callback(res, error);
  })
}
export function validarUser({ email, nome, senha, telefone, nacimento, genero, cpf, confirmar_senha }) {
  let erro = "";
  // if (!genero) erro = "Adicione sexo";
  if (!senha) erro = "Adicione uma senha";
  // if (nacimento&&moment().diff(moment(nacimento),"years")<18) erro = "Este app é apenas para maiores de 18 anos";
  // if (!nacimento) erro = "Adicione data de nacimento";
  if (!telefone) erro = "Adicione telefone";
  if (!email) erro = "Adicione um e-mail";
  if (!nome) erro = "Adicione um nome";
  if (!confirmar_senha) erro = "Confirme sua senha";
  if (confirmar_senha != senha) erro = "Senhas estão diferentes"
  return erro;
}

export function validarUserMod({ email, nome, senha, telefone, data_nascimento, genero, cpf, confirmar_senha }) {
  let erro = "";
  // if (!genero) erro = "Adicione sexo";
  if (!senha) erro = "Adicione uma senha";
  // if (nascimento&&moment().diff(moment(nacimento),"years")<18) erro = "Este app é apenas para maiores de 18 anos";
  if (!data_nascimento) erro = "Adicione data de nascimento";
  if (!telefone) erro = "Adicione telefone";
  if (!email) erro = "Adicione um e-mail";
  if (!nome) erro = "Adicione um nome";
  return erro;
}


export function atualizarPrimeiraVez(callback) {
  Cloud.put('usuarios/primeiravez', {}, (res, error) => {
    if (callback) callback(res, error);
  })
}



export function enviarContatosTeste(obj, callback) {
  Cloud.post('usuarios/telefones/teste', { telefones: obj }, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function getUsuarioInfos(obj, callback) {
  Cloud.get("usuarios/infospublic", obj, (res, error) => {
    if (callback) callback(res, error);
  })
}


export function salvarObs(obj, callback) {
  Cloud.post('usuarios/anotacoes', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}


export function returnFotoGen() {
  return "https://1app.nyc3.digitaloceanspaces.com/catalogo/3lubZ8KCHWcbvT73ULmY0QfaX2SPNMR2.jpg";
}