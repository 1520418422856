// import React from 'react';
import {Platform} from 'react-native';

import Icon from "react-1app/lib/Icon";
import IconNative from "react-native-1app/lib/Icon";





export default Platform.select({
  web: Icon,
  default: IconNative,
})
