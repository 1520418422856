import {Cloud, Util} from '../infra';
import * as Actions from '../redux/actions';
import moment from 'moment-timezone';
export function insert(divida, callback) {
  Cloud.post('dividas', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function alterar(divida, callback) {
  Cloud.put('dividas', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listar(divida, callback) {
  Cloud.get('dividas', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listarDatasStatus(divida, callback) {
  Cloud.get('dividas/datas/status', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listarDatasRelatorio(divida, callback) {
  Cloud.get('dividas/datas/relatorio', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listarDividasRelatorio(param, callback) {
  Cloud.post('dividas/relatorio', param, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function desarquivar(divida, callback) {
  Cloud.post('dividas/desarquivar', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function getTelaDividasPorPessoa(callback) {
  Cloud.get('dividas/pessoas', {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function cancelar(divida, callback) {
  Cloud.put('dividas/cancelar', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function quitar(obj, callback) {
  Cloud.post('dividas/quitar', obj, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function restaurarDivida(obj, callback) {
  Cloud.post('dividas/recuperardivida', obj, (res, error) => {
    if (callback) callback(res, error);
  });
}

//fiz post pq tava dando erro com as data do moment
export function listarHome(divida, callback) {
  divida.timzone = moment().format('Z');
  Cloud.post('dividas/home', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

//fiz post pq tava dando erro com as data do moment
export function listarArquivada(divida, callback) {
  Cloud.post('dividas/arquivadas', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

//fiz post pq tava dando erro com as data do moment
export function listarExcluidas(divida, callback) {
  Cloud.post('dividas/excluidas', divida, (res, error) => {
    console.log('yooo', res, error);
    if (callback) callback(res, error);
  });
}

export function listarNaoAceitas(divida, callback) {
  Cloud.get('dividas/naoaceitas', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function trocarPermission(divida, callback) {
  Cloud.post('dividas/trocarpermissao', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function dividaExcluir(divida, callback) {
  Cloud.post('dividas/excluir', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function listarRejeitadas(divida, callback) {
  Cloud.get('dividas/rejeitadas', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function listarEspera(divida, callback) {
  Cloud.get('dividas/espera', divida, (res, error) => {
    if (callback) callback(res, error);
  });
}
export function listarGraficos(callback) {
  Cloud.get('dividas/graficos', {}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function relacionar(data, callback) {
  Cloud.post('dividas/addusuario', data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function calendario(data, callback) {
  // data.timezone =
  data.timzone = moment().format('Z');
  Cloud.post('dividas/calendario', data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function testeDividaEdicao(divida, arquivar) {
  if (!divida.id) {
    return true;
  }
  if (!arquivar) {
    if (divida.flag_status == 'C' || divida.flag_status == 'P') {
      return false;
    }
  }
  if (divida.flag_espelho == 'S' && divida.flag_pode_editar == 'C') {
    return false;
  } else {
    return true;
  }
}

export function testeDividaEdicaoColor(divida, arquivar) {
  return {
    color: testeDividaEdicao(divida, arquivar)
      ? Actions.getTema('footericones_ativo')
      : Actions.getTema('footericones_desativo'),
  };
}

export function testeDividaEdicaoBgColor(divida, arquivar) {
  return {
    backgroundColor: testeDividaEdicao(divida, arquivar)
      ? Actions.getTema('footericones_ativo')
      : Actions.getTema('footericones_desativo'),
  };
}

export function aceitar(data, callback) {
  Cloud.post('dividas/aceitar', data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function rejeitar(data, callback) {
  Cloud.post('dividas/rejeitar', data, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function organizarObjporDataCalendario(lista, callback) {
  let objret = {};

  for (let index = 0; index < lista.length; index++) {
    let item = lista[index];
    let key = moment(item.data_vencimento).format('YYYY-MM-DD');
    if (objret[key]) {
      objret[key].push(item);
    } else {
      objret[key] = [];
      objret[key].push(item);
    }
  }

  if (callback) return callback(objret, null);
}
