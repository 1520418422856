import { Cloud, Util } from "../infra";


export function insert(obj, callback) {

  Cloud.post('contatos_proprios', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function alterar(obj, callback) {

  Cloud.put('contatos_proprios', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function remover(obj, callback) {

  Cloud.delete('contatos_proprios', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function listar(obj, callback) {

  Cloud.get('contatos_proprios', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}
