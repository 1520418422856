import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {Content, Icon, Image, Progress, TitleBar} from 'react-native-1app';
import TextInput from 'react-native-1app/lib/TextInput';
import ImageUpload from 'react-native-1app/lib/ImageUpload';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  Alert,
  Linking,
  Platform,
  Dimensions,
} from 'react-native';
import styleGlobal from '../../styleGlobal';
import DatePicker from '../../components/DatePicker';
import {Util} from '../../infra';
import * as Contatos from '../../worker/contatos';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import {requestContatosPermission} from '../../redux/actions';
import moment from 'moment-timezone';
export default class NovaContato extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      contato: {},
      id_contato: this.propsNav().id_contato,
      ajuda: this.props.screenProps.store.getState().ajuda,
    };
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  backPageButton(page, contato) {
    if (this.props.navigation.state.params.onGoBack)
      this.props.navigation.state.params.onGoBack(page, contato);
    this.props.navigation.goBack();
  }

  status(flag) {
    if (flag == 'P') {
      return {color: 'rgba(44,126,218,1)'};
    } else if (flag == 'N') {
      return {color: 'rgba(255, 0, 0,1)'};
    }
  }

  salvar() {
    this.setState({load: true});
    if (!this.state.contato.id) {
      Contatos.insert(this.state.contato, (data, error) => {
        if (error) {
          showMessage({
            message: 'Erro salvar',
            description: error.msg,
            type: 'warning',
            icon: {icon: 'warning', position: 'left'},
            duration: 2000,
          });
          this.setState({load: false});
        } else if (data.id) {
          showMessage({
            message: 'Contato criado!',
            type: 'success',
            duration: 2000,
          });
          this.abrir(data.id);
          if (data.amigos) {
            this.backPageButton(0, data);
          } else {
            this.backPageButton(1, data);
          }
        }
      });
    } else {
      Contatos.alterar(this.state.contato, (data, error) => {
        if (error) {
          showMessage({
            message: 'Erro salvar',
            description: error.msg,
            type: 'warning',
            icon: {icon: 'warning', position: 'left'},
            duration: 2000,
          });
          this.setState({load: false});
        } else if (data.id) {
          showMessage({
            message: 'Contato salvo!',
            type: 'success',
            duration: 2000,
          });
          this.abrir(data.id);
        }
      });
    }
  }

  limparcontato() {
    if (this.state.contato.id) {
      this.abrir(this.state.contato.id);
    } else {
      this.setState({contato: {}});
    }
  }

  delete() {
    if (this.state.contato.id) {
      this.setState({load: true});
      Contatos.remover({id: this.state.contato.id}, (data, error) => {
        if (error) {
          showMessage({
            message: 'Erro ao remover',
            description: error.msg,
            type: 'warning',
            icon: {icon: 'warning', position: 'left'},
            duration: 2000,
          });
          this.setState({load: false});
        } else if (data.id) {
          showMessage({
            message: 'Contato removido!',
            type: 'success',
            duration: 2000,
          });
          this.setState({contato: {}, load: false});
        }
      });
    } else {
      this.backPageButton(1);
    }
  }

  setContato(contato) {
    let obj = {
      email: contato.email,
      url_foto: contato.url_foto,
      nome: contato.nome,
      num_telefone: contato.telefone,
    };
    this.setState({contato: obj});
  }

  deletecancelar() {
    Alert.alert(
      'Aviso',
      'Tem certeza que deseja deletar este contato?',
      [
        {text: 'Não', onPress: () => {}, style: 'cancel'},
        {
          text: 'Sim',
          onPress: () => {
            this.delete();
          },
        },
      ],
      {cancelable: false},
    );
  }

  abrir(id) {
    if (id) {
      this.setState({load: true});
      Contatos.listar({id: id}, (data, error) => {
        this.setState({load: false});
        if (data && data[0]) {
          this.setState({contato: data[0]});
        }
      });
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.ajuda != this.state.ajuda) this.setState({ajuda: store.ajuda});
    });
    if (this.propsNav().id_contato) {
      this.abrir(this.propsNav().id_contato);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  removerContatoFunc(item) {
    this.setState({load: true});
    Contatos.remover(item, (data, error) => {
      if (error) {
        Alert.alert(
          'Erro',
          error.msg,
          [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
          {cancelable: false},
        );
      }

      this.setState({load: false});
      this.backPageButton(1);
    });
  }

  removerContato(item) {
    Alert.alert(
      'Aviso',
      'Tem certeza que deseja remover este contato?',
      [
        {text: 'Não', onPress: () => {}, style: 'cancel'},
        {
          text: 'Sim',
          onPress: () => {
            this.removerContatoFunc(item);
          },
          style: 'cancel',
        },
      ],
      {cancelable: false},
    );
  }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton(0);
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
            <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
              {this.state.contato.id ? 'Editar Contato' : 'Novo Contato'}
            </Text>
          </TouchableOpacity>
          <View style={styles.view} />

          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}

          {this.state.contato.id && (
            <TouchableOpacity
              style={styles.button21}
              onPress={() => {
                this.removerContato(this.state.contato);
              }}>
              <Icon
                style={[styles.icon41, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'delete'}
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity
            style={styles.button21}
            onPress={() => {
              this.limparcontato();
            }}>
            <Icon
              style={[styles.icon41, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'close-circle'}
            />
          </TouchableOpacity>

          {!this.state.load ? (
            <TouchableOpacity
              style={styles.button3}
              onPress={() => {
                this.salvar();
              }}>
              <Icon
                style={[styles.icon3, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Icons'}
                name={'save'}
              />
            </TouchableOpacity>
          ) : null}

          {this.state.ajuda && (
            <TouchableOpacity
              style={styles.buttonhelp}
              onPress={() => {
                Alert.alert(
                  'Ajuda',
                  'Adicione um novo contato para que você possa adicionar uma nova dívida',
                  [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                );
              }}>
              <Icon
                style={[styles.icon39, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'help-circle'}
              />
            </TouchableOpacity>
          )}
        </TitleBar>
        <ScrollView
          style={[styles.scroll, Actions.getTema('scroll')]}
          keyboardShouldPersistTaps={true}>
          <View style={styles.view3}>
            <ImageUpload
              style={
                Platform.OS == 'web'
                  ? {
                      minWidth: 100,
                      minHeight: 100,
                      borderRadius: 50,
                    }
                  : styles.imageupload
              }
              value={this.state.contato.url_foto}
              onChange={value => {
                this.state.contato.url_foto = value;
                this.setState({contato: this.state.contato});
              }}
              path={'casher/contato'}
            />
          </View>
          <View style={styles.view8}>
            <TextInput
              style={[styles.textinput3, Actions.getTema('labelfields')]}
              value={this.state.contato.nome}
              onChange={value => {
                this.state.contato.nome = value;
                this.setState({contato: this.state.contato});
              }}
              keyboardType={'default'}
              label={'Nome (obrigatório)'}
            />
            {Platform.OS !== 'web' && this.state.contato.id == null ? (
              <TouchableOpacity
                style={styles.button10}
                onPress={() => {
                  requestContatosPermission((data, error) => {
                    this.props.navigation.navigate('ListaContatosTelefone', {
                      activity: this,
                      oldState: this.state,
                      tela: 'novocontato',
                      onGoBack: contato => {
                        if (contato.achou) {
                          this.backPageButton(0);
                        } else {
                          this.setContato(contato);
                        }
                      },
                    });
                  });
                }}>
                <Icon
                  style={[styles.icon3, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'account-box-outline'}
                />
              </TouchableOpacity>
            ) : null}
          </View>

          <View style={styles.viewicons}>
            <TextInput
              style={[styles.textinput1, Actions.getTema('labelfields')]}
              value={this.state.contato.num_telefone}
              onChange={value => {
                this.state.contato.num_telefone = Util.maskFormate(
                  value,
                  'phone',
                );
                this.setState({contato: this.state.contato});
              }}
              keyboardType={'numeric'}
              label={'Telefone'}
            />
            <TouchableOpacity
              style={styles.button999}
              onPress={() => {
                Linking.openURL(`tel:${this.state.contato.num_telefone}`);
              }}>
              <Icon
                style={[styles.icon50, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'phone'}
              />
            </TouchableOpacity>
          </View>

          <View style={styles.viewicons}>
            <TextInput
              style={[styles.textinput1, Actions.getTema('labelfields')]}
              value={this.state.contato.email}
              onChange={value => {
                this.state.contato.email = value;
                this.setState({contato: this.state.contato});
              }}
              keyboardType={'email-address'}
              label={'Email'}
            />
            <TouchableOpacity
              style={styles.button999}
              onPress={() => {
                Linking.openURL('mailto:' + this.state.contato.email);
              }}>
              <Icon
                style={[styles.icon50, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'email'}
              />
            </TouchableOpacity>
          </View>

          <DatePicker
            style={[styles.datepicker1, Actions.getTema('labelfields')]}
            value={this.state.contato.data_nascimento}
            onChange={value => {
              try {
                value = moment(value)
                  .set({hour: 0, minute: 0, second: 0, millisecond: 0})
                  .format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
              } catch (error) {}
              this.state.contato.data_nascimento = value;
              this.setState({contato: this.state.contato});
            }}
            disabledClear={true}
            type={'date'}
            datePickerModeAndroid={'calendar'}
            label={'Data de Nascimento'}
          />

          <TextInput
            style={[styles.textinput1, Actions.getTema('labelfields')]}
            value={this.state.contato.obs}
            onChange={value => {
              this.state.contato.obs = value;
              this.setState({contato: this.state.contato});
            }}
            multiline={true}
            numberOfLines={50}
            maxLength={10000}
            keyboardType={'default'}
            label={'Anotações'}
          />

          {this.state.contato.id ? (
            <View style={[styles.view5, {height: 30}]} />
          ) : null}
        </ScrollView>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  buttonhelp: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 40,
  },
  button999: {
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    width: 50,
    // padding: 10,
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    flex: 1,
  },
  viewicons: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
  },
  imageupload: {
    width: 190,
    height: 190,
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 95,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  view3: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },
  view: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  button21: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  icon41: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginBottom: 1,
  },
  button3: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  icon3: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(252,252,252,1)',
    padding: 20,
    paddingTop: 10,
  },
  view8: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  textinput3: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
    flex: 1,
  },
  button10: {
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
    marginLeft: 5,
  },
  textinput1: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
    flex: 1,
  },

  datepicker1: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    marginTop: 5,
    marginBottom: 5,
  },
  view5: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
});
