import React, {Component} from 'react';
import ProgressBar from 'react-native-progress/Bar';
import {
  Content,
  Icon,
  Image,
  ListView,
  Progress,
  TitleBar,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Alert,
} from 'react-native';
import {Util} from '../../infra';
import * as Actions from '../../redux/actions';
import moment from 'moment-timezone';
import Banner from './Banner';
export default class CellListaMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      showmodal: false,
      tema: this.props.screenProps.store.getState().tema,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.tema != this.state.tema) {
        this.setState({tema: store.tema});
      }
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
  getColor(flag) {
    if (flag == '2') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    } else if (flag == '1') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    }
  }

  getColor2(flag) {
    if (flag == '2') {
      return 'rgba(255,0,0,1)';
    } else if (flag == '1') {
      return 'rgba(62,162,67,1)';
    }
  }
  getColorStatus(flag) {
    if (flag == 'N') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    } else if (flag == 'P') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    }
  }

  getColorStatus2(flag, flag_tipo, flag_aceito) {
    if (flag_aceito == 'N') {
      return {
        color: 'rgba(177,177,184,1)',
      };
    }
    if (flag == 'N') {
      if (flag_tipo == '1') {
        return {
          color: 'rgba(62,162,67,1)',
        };
      } else {
        return {
          color: 'rgba(255,0,0,1)',
        };
      }
    } else if (flag == 'P') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    }
  }

  abrir(id) {
    this.props.navigation.navigate('NovaDivida', {
      activity: this,
      oldState: this.state,
      id_divida: id,
      atualizar: () => {
        if (this.props.atualizar) this.props.atualizar();
      },
      // onGoBack: () => { this.props.activity.carregarDividas(); }
    });
  }

  renderBanner() {
    let obj_existe = {
      elevation: 5 / 1,
      shadowColor: '#000000',
      shadowOpacity: 0.5 / 1,
      shadowRadius: 2 / 1,
      shadowOffset: {
        height: 1,
        width: 0,
      },
      flexDirection: 'column',
      alignSelf: 'stretch',
      flex: 1,
      padding: 5,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
      margin: 5,
    };
    let obj_nao_existe = {overflow: 'hidden', height: 0};

    // // if (this.state.add == false) {
    //   return obj_nao_existe;
    // } else {
    return obj_existe;
    // }
  }

  getInfos() {
    let id_usuario = 0;
    var contato = false;
    if (this.props.item.id_contato_proprio_divida) {
      id_usuario = this.props.item.id_contato_proprio_divida;
      contato = true;
    }

    if (this.props.item.id_usuario_divida) {
      id_usuario = this.props.item.id_usuario_divida;
    }

    // alert(JSON.stringify({
    //   id_usuario: id_usuario,
    //   boolcontatoproprio: contato
    // }));

    return {
      id_usuario: id_usuario,
      boolcontatoproprio: contato,
    };
  }

  render() {
    if (this.props.item.add) {
      return <Banner screenProps={this.props.screenProps} />;
    } else
      return (
        <View
          style={[
            styles.Cell,
            {...Util.elevation()},
            Actions.getTema('titlebar'),
          ]}>
          <TouchableOpacity
            style={styles.view9}
            onPress={() => {
              Actions.setShowPreInfos({show: true, ...this.getInfos()});
            }}>
            <Image
              style={styles.image}
              source={{uri: this.props.item.foto}}
              resizeMode={'cover'}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.view131}
            onPress={() => {
              if (this.props.onTouch) {
                this.props.onTouch();
              } else {
                this.abrir(this.props.item.id);
              }
            }}>
            <View style={styles.view133}>
              <Text style={[styles.text8, Actions.getTema('textcell')]}>
                {this.props.item.nome_pessoa}
              </Text>
              <Text
                style={[
                  styles.text9,
                  {...this.getColor(this.props.item.flag_tipo)},
                ]}>
                {this.props.item.val_montante_f}
              </Text>
            </View>

            <View style={styles.view13}>
              <Text style={[styles.text10, Actions.getTema('textcell2')]}>
                {this.props.item.descricao}
              </Text>
            </View>

            <View style={styles.view13}>
              <ProgressBar
                style={styles.progresslinhas}
                color={this.getColor2(this.props.item.flag_tipo)}
                width={null}
                progress={this.props.item.perc}
              />
            </View>

            <View style={styles.view13}>
              <Text style={[styles.text11, Actions.getTema('textcell2')]}>
                {moment(this.props.item.data_vencimento).format('DD/MM/YYYY')}
              </Text>
              <Text
                style={[
                  styles.text91,
                  {
                    ...this.getColorStatus2(
                      this.props.item.flag_status,
                      this.props.item.flag_tipo,
                    ),
                  },
                ]}>
                {this.props.item.descstatus}
              </Text>

              {this.props.item.id_usuario_divida ? (
                <Icon
                  style={[
                    styles.icon,
                    {
                      ...this.getColorStatus2(
                        this.props.item.flag_status,
                        this.props.item.flag_tipo,
                        this.props.item.espelho_aceito,
                      ),
                    },
                  ]}
                  fromFontFamily={'Material Design Icons'}
                  name={'link-variant'}
                />
              ) : null}
            </View>
          </TouchableOpacity>
        </View>
      );
  }
}

var styles = StyleSheet.create({
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginLeft: 5,
  },

  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRadius: 8,
    margin: 5,
  },
  view9: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 25,
  },
  imagelogo: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  text8: {
    textAlign: 'left',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
  },

  text9: {
    textAlign: 'right',
    alignSelf: 'center',
    fontWeight: 'bold',
    
  },
  view13: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 5,
  },
  view133: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 5,
  },
  text10: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    marginLeft: 10,
    flex: 1,
  },
  progresslinhas: {
    borderRadius: 20,
    borderWidth: 0,
    color: 'rgba(62,162,67,1)',
    height: 3,
    backgroundColor: 'rgba(177,177,184,1)',
    flex: 1,
    marginLeft: 10,
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginLeft: 5,
  },
  text11: {
    textAlign: 'left',

    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 10,
    flex: 1,
  },
  text91: {
    textAlign: 'right',
    alignSelf: 'center',
    // fontWeight: "bold",
    
  },
});
