import moment from 'moment'

export default class CommonFn {

  //rem relação aos utcOffset, tava dando varios bug de data, isso meio q resolve, apesar de nao parecer mto certo. dependendo se for usa em outros pais pode bugar. Tem reepnesar logica.
  static calendarArray(date) {
    // console.log("ini", JSON.stringify(date));//é a data q iria no primeiro quadradinho, onde atualmvente tem uma seta
    var dates = []
    let startDate2 = moment(date).date(1).utcOffset(0);
    let teste = startDate2.weekday(0).format('DD');
    startDate2.set({ hour: 0, minute: 0, seconds: 0, millisecond: 0 });
    // console.log("teste", teste);
    // console.log("teste2", JSON.stringify(startDate2));
    let aux = 42;//numero de vezes q é pra adicionar datas
    if (teste == "01") { //se o primeiro quadradinho fosse ser o dia primeiro, é adicionado uma linha extra de data do mes anterior, pq senao a seta ia sumir o dia primeiro. Ex: setembro de 2019
      // console.log("ennnnnnnnnnnnnnntroooooooooooooooooooou", teste);
      aux = 35
      //entao a partir da data do dia 1, ele adiciona datas de dia 1 menos 7 dia, dia 1 menos 6 dia, dia menos 5 dia e etc
      dates.push(moment(date).utcOffset(0).date(1).subtract(7, 'day').format('YYYY-MM-DD'));
      dates.push(moment(date).utcOffset(0).date(1).subtract(6, 'day').format('YYYY-MM-DD'));
      dates.push(moment(date).utcOffset(0).date(1).subtract(5, 'day').format('YYYY-MM-DD'));
      dates.push(moment(date).utcOffset(0).date(1).subtract(4, 'day').format('YYYY-MM-DD'));
      dates.push(moment(date).utcOffset(0).date(1).subtract(3, 'day').format('YYYY-MM-DD'));
      dates.push(moment(date).utcOffset(0).date(1).subtract(2, 'day').format('YYYY-MM-DD'));
      dates.push(moment(date).utcOffset(0).date(1).subtract(1, 'day').format('YYYY-MM-DD'));
    }

    //aqui ele popula o resto do array
    for (let i = 0; i < aux; i += 1) {
      let startDate = moment(date).utcOffset(0).date(1);
      // let dataauxparaprint = JSON.parse(JSON.stringify(startDate));
      let a = startDate.weekday(i).format('YYYY-MM-DD'); //0 seria data do primeiro dia da semana do mes, no caso de outurbro é 29/09, pq a primeira linha de domingo vai de 29,30,1,2,3,4,5(sabado)    //https://momentjs.com/docs/#/get-set/weekday/
      // console.log(dataauxparaprint, JSON.stringify(startDate), a);
      dates.push(a)
    }

    // console.log(dates);//datas retornadas
    return dates
  }

  static ym(date) {
    return moment(date || undefined).format('YYYY-MM')
  }

  static ymd(date) {
    return moment(date || undefined).format('YYYY-MM-DD');
  }
}
