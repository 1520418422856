import Cloud from "../infra/Cloud";




export function atualizar(sobre, callback){
  Cloud.put('sobre', sobre, (res,error) => {
    if(callback) callback(res,error);
  })
}


export function getSobre( callback){
  Cloud.get('sobre', {}, (res,error) => {
    if(callback) callback(res,error);
  })
}
