import React, {Component} from 'react';
import moment from 'moment-timezone';
import * as Actions from '../../redux/actions';
import {
  Content,
  Icon,
  Image,
  ListView,
  Progress,
  TitleBar,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Alert,
  FlatList,
  RefreshControl,
} from 'react-native';
import * as Divida from '../../worker/divida';
import PreInfos from '../user/PreInfos';
import styleGlobal from '../../styleGlobal';
import TodoSwipeList from '../../components/TodoSwipeList';
import {Util} from '../../infra';
export default class ListaArquivadas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faturas: [],
      load: false,
    };
  }

  getColor() {
    if (this.state.flag_tipo == '') {
      if (!this.state.pos) {
        return {
          color: 'rgba(255,0,0,1)',
        };
      } else {
        return {
          color: 'rgba(48,129,219,1)',
        };
      }
    } else if (this.state.flag_tipo == '1') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    } else if (this.state.flag_tipo == '2') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    }
  }

  getArrow() {
    if (this.state.flag_tipo == '') {
      if (this.state.pos) {
        return 'arrow-up';
      } else {
        return 'arrow-down';
      }
    } else if (this.state.flag_tipo == '1') {
      return 'arrow-up';
    } else if (this.state.flag_tipo == '2') {
      return 'arrow-down';
    }
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  listar() {
    this.setState({load: true});
    Divida.listarArquivada({}, (data, error) => {
      if (error) return this.setState({load: false});
      this.setState({
        faturas: data ? data : [],
        load: false,
      });
      if (callback) callback();
    });
  }
  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user) this.setState({user: store.user});
    });

    this.listar();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  render() {
    return (
      <Content style={styles.content} keyboard={true}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
            <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
              {'Arquivados'}
            </Text>
          </TouchableOpacity>
          <View style={styles.view} />
          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
        </TitleBar>
        <FlatList
          style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
          refreshControl={
            <RefreshControl
              refreshing={this.state.load}
              onRefresh={() => {
                this.listar();
              }}
            />
          }
          renderItem={({item, index}) => {
            return (
              <Cell
                item={item}
                screenProps={this.props.screenProps}
                activity={this}
                navigation={this.props.navigation}
                rowID={index}
              />
            );
          }}
          ListEmptyComponent={() => {
            return (
              <Text
                style={{
                  textAlign: 'center',
                  color: 'gray',
                  margin: 10,
                }}>
                {'Nenhum registro para exibir.'}
              </Text>
            );
          }}
          data={Array.isArray(this.state.faturas) ? this.state.faturas : []}
          keyExtractor={(item, index) => index}
        />
      </Content>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  getColor(flag) {
    if (flag == '2') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    } else if (flag == '1') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    }
  }

  getColorStatus(flag) {
    if (flag == 'N') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    } else if (flag == 'P') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    }
  }

  abrir(id) {
    this.props.navigation.navigate('NovaDivida', {
      id_divida: id,
      arquivada: true,
    });
  }

  componentWillUnmount() {}

  getInfos() {
    let id_usuario = 0;
    var contato = false;

    if (this.props.tela == 'aguardando') {
      id_usuario = this.props.item.id_usuario;
    } else {
      if (this.props.item.id_usuario_divida) {
        id_usuario = this.props.item.id_usuario_divida;
      }
    }

    return {
      id_usuario: id_usuario,
      boolcontatoproprio: contato,
    };
  }

  render() {
    return (
      <View
        style={[
          styles.Cell,
          {...Util.elevation()},
          Actions.getTema('titlebar'),
        ]}
        disabled={this.props.tela == 'aguardando'}>
        <TouchableOpacity
          style={styles.view9}
          onPress={() => {
            Actions.setShowPreInfos({show: true, ...this.getInfos()});
          }}>
          <Image
            style={styles.image}
            source={{
              uri:
                this.props.tela == 'aguardando'
                  ? this.props.item.fotodouser
                  : this.props.item.foto,
            }}
            resizeMode={'cover'}
          />
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.view131}
          onPress={() => {
            this.abrir(this.props.item.id);
          }}
          disabled={this.props.tela == 'aguardando'}>
          <View style={styles.view133}>
            <Text style={[styles.text8, Actions.getTema('textcell')]}>
              {this.props.tela == 'aguardando'
                ? this.props.item.nomedouser
                : this.props.item.nome_pessoa}
            </Text>
            <Text
              style={[
                styles.text9,
                {...this.getColor(this.props.item.flag_tipo)},
              ]}>
              {this.props.item.val_montante_f}
            </Text>
          </View>
          <View style={styles.view13}>
            <Text style={[styles.text10, Actions.getTema('textcell2')]}>
              {this.props.item.descricao}
            </Text>
          </View>
          <View style={[styles.view10, Actions.getTema('linhadivida')]} />
          <View style={styles.view15}>
            <Text style={styles.text11}>
              {moment(this.props.item.data_vencimento).format('DD/MM/YYYY')}
            </Text>
            <Text
              style={[
                styles.text91,
                Actions.getTema('textcell'),
                {...this.getColorStatus(this.props.item.flag_status)},
              ]}>
              {this.props.item.descstatus}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(249,249,249,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
    paddingLeft: 10,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginLeft: 5,
  },
  view: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(249,249,249,1)',
  },
  view50: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: 75,
    marginTop: 10,
  },
  nenhumreg: {
    textAlign: 'center',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'center',
    fontWeight: 'normal',
    flex: 1,
  },
  listview: {
    alignSelf: 'stretch',
    flex: 1,
  },
  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 8,
    margin: 5,
  },
  view9: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 25,
  },
  text8: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
  },
  text9: {
    textAlign: 'right',
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  view10: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    height: 3,
    backgroundColor: 'rgba(249,249,249,1)',
    marginTop: 5,
    marginLeft: 10,
    marginBottom: 5,
  },
  view15: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  view13: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 5,
  },
  text10: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    marginLeft: 10,
    flex: 1,
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginLeft: 5,
  },
  view133: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 5,
  },
  text11: {
    textAlign: 'left',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 10,
    flex: 1,
  },
  text91: {
    textAlign: 'right',
    alignSelf: 'center',
    fontWeight: 'bold',
  },
});
