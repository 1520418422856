import React, { Component } from "react";
import styleGlobal from "../../styleGlobal";
import * as SobreW from "../../worker/sobre";
import { showMessage } from "react-native-1app/lib/FlashMessage";
import {
  Content,
  Icon,
  Progress,
  TextInput,
  TitleBar
} from "react-native-1app";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text
} from "react-native";

export default class Sobre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      descricao: "",
      user: this.props.screenProps.store.getState().user
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user) this.setState({ user: store.user });
    });
    this.listar();
  }

  salvar() {
    this.setState({ load: true });
    SobreW.atualizar({ descricao: this.state.descricao }, (data, error) => {
      if (error) {
        showMessage({
          message: "Erro salvar",
          description: error.msg,
          type: "warning",
          icon: { icon: "warning", position: "left" },
          duration: 2000
        });
        this.setState({ load: false });
      } else if (data.id) {
        showMessage({
          message: "Dados salvos!",
          type: "success",
          duration: 2000
        });
      }
      this.setState({ divida: data, load: false });
    });
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  listar() {
    this.setState({ load: true });
    SobreW.getSobre((data, error) => {
      this.setState({ descricao: data.descricao, load: false })
    });
  }

  backPageButton() {

    this.props.navigation.goBack();
  }
  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={styleGlobal.titlebar}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}
          >
            <Icon
              style={styles.icon}
              fromFontFamily={"Material Design Icons"}
              name={"arrow-left"}
            />
            <Text style={styles.text2}>{"Sobre"}</Text>
          </TouchableOpacity>
          <View style={styles.view} />

          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}

          {this.state.user.admin && !this.state.load ?
            <TouchableOpacity style={styles.button6}
              onPress={() => {
                this.salvar();
              }}
            >
              <Icon
                style={styles.icon2}
                fromFontFamily={"Material Icons"}
                name={"save"}
              />
            </TouchableOpacity> : null}
        </TitleBar>
        <ScrollView style={styles.scroll}>
          <View style={styles.view3}>
            <Text style={styles.text31}>{"Sobre"}</Text>
          </View>
          <View style={styles.view32}>
            {this.state.user.admin ?
              <TextInput
                value={this.state.descricao}
                style={styles.textinput}
                onChange={value => {
                  this.state.descricao = value;
                  this.setState({ descricao: this.state.descricao });
                }}
                label={"Descrição"}
                keyboardType={"default"}
                multiline={true}
              /> : <Text style={styles.text10}>{this.state.descricao}</Text>}
          </View>
        </ScrollView>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  button1: {
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "rgba(44,126,218,1)",
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1
  },
  icon: {
    color: "rgba(48,129,219,1)",
    fontSize: 25,
    marginRight: 5
  },
  text2: {
    textAlign: "left",
    color: "rgba(48,129,219,1)",
    alignSelf: "auto",
    fontWeight: "normal"
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  scroll: {
    alignSelf: "stretch",
    flexDirection: "column",
    backgroundColor: "rgba(252,252,252,1)",
    flex: 1,
    padding: 20
  },
  view3: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  text31: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "bold",
    flex: 1
  },
  button6: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    width: 50
  },
  icon2: {
    color: "rgba(150,150,145,1)",
    fontSize: 25
  },
  view32: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: null
  },
  text10: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  textinput: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal"
  }
});
