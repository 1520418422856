import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Dimensions,
  FlatList,
  Text,
  RefreshControl,
  Platform,
} from 'react-native';
import $ from 'jquery';

export default class FlatGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.ativo = true;
    $(window).resize(() => {
      if (this.ativo) this.setState({time: new Date()});
    });
  }
  componentWillUnmount() {
    this.ativo = false;
  }

  getLista() {
    var itemDimension = this.props.itemDimension
      ? this.props.itemDimension
      : 100;
    var total = this.props.rowMax
      ? this.props.rowMax
      : parseInt(Dimensions.get('window').width / 120);
    // var total = 3;
    var lista = this.props.items ? this.props.items : [];
    var nova = [];
    for (let i = 0; i < lista.length; i = i + total) {
      var cell = [];
      //   console.log(Dimensions.get("window").width, "-", i, total);
      for (let ia = 0; ia < total; ia++) {
        var pos = i + ia;
        var item = lista[pos];
        if (item)
          cell.push({
            item,
            pos,
          });
        if (!item) cell.push({});

        // console.log(i + ia, ia, cell);
      }
      nova.push(cell);
    }
    // console.log("nova", nova);
    // return this.state.list;
    return nova;
  }

  renderCell(cell) {
    var lista = cell ? cell : [];
    var views = [];
    for (let i = 0; i < lista.length; i++) {
      let item = lista[i];
      if (item.item) {
        views.push(
          <View key={'key_' + i} style={styles.cell}>
            {this.props.renderItem({item: item.item, index: item.pos})}
          </View>,
        );
      } else {
        views.push(<View key={'key_' + i} style={styles.cell} />);
      }
    }
    return views;
  }
  renderLinha(item, index) {
    return <View style={styles.content_cell}>{this.renderCell(item)}</View>;
  }

  render() {
    return (
      <FlatList
        {...this.props}
        data={this.getLista()}
        renderItem={({item, index}) => {
          return this.renderLinha(item, index);
        }}
      />
    );
  }
}

var styles = StyleSheet.create({
  content_cell: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  cell: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
