import React, {Component} from 'react';
import {
  AreaChart,
  BarChart,
  Grid,
  YAxis,
  XAxis,
  PieChart,
} from 'react-native-svg-charts';
import {Circle, G, Line, Text as Textsvg} from 'react-native-svg';
import * as Actions from '../../redux/actions';
import * as shape from 'd3-shape';
import {
  Content,
  Icon,
  Image,
  ListView,
  Progress,
  TitleBar,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Alert,
} from 'react-native';
import {Util} from '../../infra';
import {
  ViewPager,
  PagerTabIndicator,
  IndicatorViewPager,
  PagerTitleIndicator,
  PagerDotIndicator,
} from 'rn-viewpager';
import styleGlobal from '../../styleGlobal';
import HeaderGraficos from './HeaderGraficos';
import * as Divida from '../../worker/divida';
export default class Graficos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: [],
      loadGrafico: true,
      currentPosition: 0,
      viewpager: {},
    };
  }
  randomColor() {
    return (
      '#' +
      ((Math.random() * 0xffffff) << 0).toString(16) +
      '000000'
    ).slice(0, 7);
  }
  pieData() {
    var data = this.state.lista.map((value, index) => ({
      value: value.valor,
      negativo: value.negativo,
      svg: {
        fill: value.color,
        onPress: () => console.log('press', index),
      },
      key: `pie-${index}`,
    }));

    return data;
  }
  backPageButton() {
    this.props.navigation.goBack();
  }
  componentDidMount() {
    this.listarGraficos();
  }
  componentWillUnmount() {}

  listarGraficos() {
    this.setState({loadGrafico: true});
    Divida.listarGraficos((data, error) => {
      this.setState({lista: data ? data : [], loadGrafico: false});
    });
  }

  render() {
    const fill = 'rgb(134, 65, 244)';
    const Labels = ({slices}) => {
      return slices.map((slice, index) => {
        const {labelCentroid, pieCentroid, data} = slice;
        return (
          <G key={index}>
            <Textsvg
              key={index}
              x={labelCentroid[0]}
              y={labelCentroid[1]}
              fill={data.svg.fill}
              textAnchor={'middle'}
              alignmentBaseline={'middle'}
              fontSize={12}>
              {data.negativo
                ? 'R$ -' + Util.parseReal(data.value)
                : 'R$ ' + Util.parseReal(data.value)}
            </Textsvg>
          </G>
        );
      });
    };
    return (
      <Content style={styles.content} keyboard={true}>
        <TitleBar
          style={[
            styleGlobal.titlebar,
            Actions.getTema('titlebar'),
            {flexDirection: 'column', height: 100},
          ]}>
          <View style={styles.viewhead99}>
            <View style={styles.view778}>
              <TouchableOpacity
                style={styles.button3}
                onPress={() => {
                  this.backPageButton();
                }}>
                <Icon
                  style={[styles.icon, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'arrow-left'}
                />
              </TouchableOpacity>
              <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
                {'Gráficos'}
              </Text>
            </View>
          </View>
          <View style={styles.viewhead99}>
            {/* {this.viewpager ? */}
            <HeaderGraficos
              screenProps={this.props.screenProps}
              activity={this}
              navigation={this.props.navigation}
              currentPosition={this.state.currentPosition}
              viewpager={this.viewpager}
            />
            {/* : null} */}
          </View>
        </TitleBar>
        <View style={styles.view2}>
          <ViewPager
            horizontalScroll={true}
            style={[styles.fragment2, {flexGrow: 1}]}
            ref={viewpager => {
              this.viewpager = viewpager;
            }}
            onPageSelected={page => {
              this.setState({currentPosition: page.position});
            }}>
            {/* page Todos */}
            <View style={styles.content21}>
              <ScrollView style={[styles.scroll, Actions.getTema('scroll')]}>
                {this.state.loadGrafico ? (
                  <Progress style={styleGlobal.progress} />
                ) : null}
                {this.state.lista && this.state.lista.length > 0 && (
                  <View style={styles.view501}>
                    {/* <YAxis
              style={{ height: 250 }}
              data={this.state.lista2}
              contentInset={{ top: 0, bottom: 0 }}
              svg={{
                fill: 'grey',
                fontSize: 10,
              }}
              // yAccessor={(value, index) => (value.item.valor)}
              numberOfTicks={10}
            /> */}

                    <View style={styles.view51}>
                      <BarChart
                        style={{height: 250}}
                        data={this.state.lista}
                        svg={{fill}}
                        yAccessor={(value, index) => value.item.valor}
                        contentInset={{top: 20, bottom: 20}}>
                        {/* <Grid /> */}
                      </BarChart>

                      <XAxis
                        style={{height: 20, marginTop: 10}}
                        data={this.state.lista}
                        formatLabel={(item, index, u) =>
                          this.state.lista[index].nome
                        }
                        contentInset={{left: 75, right: 75}}
                        svg={{fontSize: 12}}
                      />

                      <XAxis
                        style={{height: 20, marginTop: 0}}
                        data={this.state.lista}
                        formatLabel={(item, index, u) =>
                          this.state.lista[index].valor_f
                        }
                        contentInset={{left: 75, right: 75}}
                        svg={{fontSize: 12}}
                      />
                    </View>
                  </View>
                )}
              </ScrollView>
            </View>

            {/* page relacionados */}
            <View style={styles.content21}>
              <ScrollView style={[styles.scroll, Actions.getTema('scroll')]}>
                {this.state.loadGrafico ? (
                  <Progress style={styleGlobal.progress} />
                ) : null}
                {this.state.lista && this.state.lista.length > 0 && (
                  <View style={styles.view20}>
                    <PieChart
                      style={{height: 250}}
                      spacing={0}
                      outerRadius={65}
                      innerRadius={15}
                      labelRadius={115}
                      data={this.pieData()}
                      valueAccessor={({item}) => item.value}>
                      <Labels />
                    </PieChart>

                    <View style={styles.viewlegendas}>
                      <View style={styles.viewlegendaslabel}>
                        <View
                          style={[
                            styles.viewlegendaslabelsqare,
                            {backgroundColor: 'rgba(255,0,0,1)'},
                          ]}
                        />
                        <Text
                          style={[styles.text2, {color: 'rgba(255,0,0,1)'}]}>
                          {'Para Pagar'}
                        </Text>
                      </View>
                      <View style={styles.viewlegendaslabel}>
                        <View
                          style={[
                            styles.viewlegendaslabelsqare,
                            {backgroundColor: 'rgba(62,162,67,1)'},
                          ]}
                        />
                        <Text
                          style={[styles.text2, {color: 'rgba(62,162,67,1)'}]}>
                          {'Para Receber'}
                        </Text>
                      </View>
                      <View style={styles.viewlegendaslabel}>
                        <View
                          style={[
                            styles.viewlegendaslabelsqare,
                            {backgroundColor: 'rgba(48,129,219,1)'},
                          ]}
                        />
                        <Text
                          style={[styles.text2, {color: 'rgba(48,129,219,1)'}]}>
                          {'Total'}
                        </Text>
                      </View>
                    </View>
                  </View>
                )}
              </ScrollView>
            </View>
          </ViewPager>
        </View>
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(249,249,249,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  content21: {
    alignSelf: 'stretch',
    flex: 1,
  },
  fragment2: {
    alignSelf: 'stretch',
    flex: 1,
  },
  view2: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'rgba(238,238,238,1)',
    flex: 1,
  },
  view20: {
    marginTop: 10,
    alignSelf: 'stretch',
    flexDirection: 'column',
    justifyContent: 'center',
    // alignItems: "center",
    height: 500,
    //  backgroundColor: "rgba(238,238,238,1)",
    // flex: 1
  },
  viewlegendas: {
    marginTop: 0,
    alignSelf: 'stretch',
    flexDirection: 'column',
    height: 200,
    marginLeft: 10,
  },

  viewlegendaslabel: {
    flexDirection: 'row',
    margin: 5,
    alignItems: 'center',
  },
  viewlegendaslabelsqare: {
    height: 10,
    width: 10,
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    width: 50,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginLeft: 5,
  },
  text2: {
    // alignSelf: "stretch",
    // textAlign: "left",

    color: 'rgba(48,129,219,1)',
    // alignSelf: "auto",
    fontWeight: 'normal',
    // flex: 1,
    marginLeft: 10,
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(249,249,249,1)',
  },
  view51: {
    alignSelf: 'stretch',
    flex: 1,
  },
  view778: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    
  },
  viewhead99: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
  },
  view501: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'row',
  },
});
