import {StyleSheet, Dimensions, Platform} from 'react-native';
const {width, height} = Dimensions.get('window');
const screenWidth = width < height ? width : height;

export default StyleSheet.create({
  day: {
    // margin: 2,
    // color: 'black',
    fontSize: 11,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    // backgroundColor:"blue",
    flex: 1,
  },
  day2: {
    margin: 2,
    color: '#fff',
    fontSize: 11,
    alignSelf: 'flex-end',
  },
  txtHeaderDate: {
    color: '#fff',
    fontSize: 18,
  },
  weekdays: {
    margin: 10,
    color: 'white',
    width: screenWidth / 7 - 8,
    textAlign: 'center',
  },
  customweekdays: {
    color: 'white',
    width: screenWidth / 14,
    textAlign: 'center',
  },
  customweekdayscontainer: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: screenWidth / 14,
    flex: 1,
  },
  customWarpDay: {
    width: screenWidth / 14,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderColor: 'rgba(48,129,219,1)',
    borderWidth: 0.5,
    height: screenWidth / 14,
  },
  warpDay: {
    width: screenWidth / 7,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    // backgroundColor: 'rgba(48,129,219,1)',
    borderColor: 'rgba(48,129,219,1)',
    borderWidth: 0.5,
    height: 60,
  },
  warpicon: {
    width: screenWidth / 7,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'rgba(48,129,219,1)',
    borderWidth: 0.5,
    minHeight: 50,
  },
  icLockRed: {
    width: 13 / 2,
    height: 9,
    position: 'absolute',
    top: 2,
    left: 1,
  },
  cell: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: Platform.OS == 'web' ? 'space-between' : 'baseline',
    textAlign: 'left',
    flex: 1,
    alignSelf: 'stretch',
    // backgroundColor:"green"
  },
  cell3: {
    // flex: 1,
    marginLeft: 1,
    marginTop: 0.5,
    // paddingRight:0.5,
    // backgroundColor: "green",
    height: 17,
    width: 17,
    justifyContent: 'center',
    borderRadius: 8.5,
    alignItems: 'center',
    textAlign: 'center',
  },
  cell4: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  cell2: {
    flexDirection: 'row',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    // textAlign:"left",
    // flex:1,
    height: 15,
    // backgroundColor:"green"
  },
  container: {
    backgroundColor: 'rgba(48,129,219,1)',
  },
});
