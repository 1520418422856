import React, {Component} from 'react';

import {View, Text} from 'react-native';
import Paper from '@material-ui/core/Paper';
import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
let position = {interpolate: console.log};
// import {TabBar} from 'react-native-tab-view';
import Icon from './icon';
const TabView = props => (
  <View
    style={{
      flex: 1,
      alignSelf: 'stretch',
    }}>
    <View style={{flex: 1, alignSelf: 'stretch'}}>
      {props.renderScene &&
        props.renderScene({
          route: props.navigationState.routes[props.navigationState.index || 0],
        })()}
    </View>

    {props.renderTabBar && props.renderTabBar({...props, position})}
  </View>
);

class TabBar extends React.Component {
  handleChange = (event, value) => {
    this.props.onIndexChange(value);
  };

  render() {
    const {
      renderIcon,
      navigationState,
      activeColor,
      inactiveColor,
      style,
    } = this.props;
    // return(<Text>{"asdhaj"}</Text>)
    // console.log(inactiveColor,activeColor,style,this.props)
    return (
      <View square style={style}>
        <Tabs
          value={navigationState.index}
          onChange={this.handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary">
          {navigationState.routes.map((route, index) => (
            <Tab
              key={index}
              classes={'tabs_app'}
              icon={
                <View style={{paddingTop: 4}}>
                  {renderIcon({
                    route,
                    focused: navigationState.index == index,
                    color:
                      navigationState.index == index
                        ? activeColor
                        : inactiveColor,
                  })}

                  <Text
                    style={{
                      color:
                        navigationState.index == index
                          ? activeColor
                          : inactiveColor,
                      fontWeight: '200',
                      fontSize: 11,
                      marginBottom: 4,
                      textAlign: 'center',
                      marginTop: 5,
                    }}>
                    {route.title}
                  </Text>
                </View>
              }
            />
          ))}
        </Tabs>
      </View>
    );
  }
}

const SceneMap = page => {
  return ({route, swipeEnabled}) => {
    if (swipeEnabled) {
      let list = [];
      for (var i in page) {
        list.push(page[i]);
      }
      return list;
    }

    return page[route.key];
  };
};

export {TabView, SceneMap, TabBar};
