import React,{Component} from 'react';

import {View} from 'react-native';

class ViewPager extends Component {
  state={pg:0}
  setPage=(index)=>{
    this.setState({pg:index});
    this.props.onPageSelected({position:index})
  }
  render(){
    return this.props.children[this.state.pg];
  }
}

export {
  ViewPager
}

export default View
