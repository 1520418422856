import React, { Component } from "react";

import { Content, Image } from "react-native-1app";
import { View, StyleSheet } from "react-native";

export default class Abertura extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // Navigator.cloneState(this);
  }

  componentDidMount() { }

  componentWillUnmount() { }

  render() {
    return (
      <Content style={styles.content}>
        <Image
          style={styles.image}
          source={require("../../img/logo_vazado.png")}
          resizeMode={"contain"}
        />
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: "rgba(78,122,189,1)",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  image: {
    width: 300,
    height: 300,
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginBottom: 100
  }
});
