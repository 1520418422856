import React, {Component} from 'react';
import {Content, Icon, Progress, TitleBar} from 'react-native-1app';
import Image from 'react-native-1app/lib/Image';
import ImageUpload from 'react-native-1app/lib/ImageUpload';
import {
  StyleSheet,
  View,
  Alert,
  TouchableOpacity,
  Text,
  BackHandler,
  ActivityIndicator,
  Dimensions,
  Platform,
} from 'react-native';
import TodoSwipeList from '../../components/TodoSwipeList';
import {SwipeRow} from 'react-native-swipe-list-view';
import styleGlobal from '../../styleGlobal';
import {
  GiftedChat,
  InputToolbar,
  Send,
  Color,
  Message,
  Bubble,
  Composer,
  Time,
  MessageText,
} from 'react-native-gifted-chat';
import * as Chat from '../../worker/chat';
import * as Actions from '../../redux/actions';
import {Util, Cloud} from '../../infra';
import 'moment/locale/pt-br';
import SocketIO from 'socket.io-client';
import CompMensagem from './CompMensagem';
import PreInfos from '../user/PreInfos';
import * as Usuarios from '../../worker/usuario';
import {setNotsMsg, setListamsgTela} from '../../redux/actions';

export default class ChatView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadGravar: true,
      url_image_upload: '',
      escolhendoImage: false,
      tema: this.props.screenProps.store.getState().tema,
      messareply: '',
    };
    this.state.contato = {
      id_usuario_contato: this.getProps().id_usuario_contato,
    };
    this.chatativo = true;
    this.connected = false;
    this.socket = {};
    this.backHandler = {};
    this.id_chat = 0;
    this.state.user = {
      _id: this.props.screenProps.store.getState().user.id,
      name: this.props.screenProps.store.getState().user.nome,
      avatar: this.props.screenProps.store.getState().user.foto,
    };
    this.state.messages = [
      {
        _id: Math.round(Math.random() * 1000000),
        text: 'Atualizando suas conversas',
        createdAt: new Date(),
        system: true,
        sent: true,
      },
    ];
  }

  getInfoContato() {
    Usuarios.getUsuarioInfos(
      {id: this.state.contato.id_usuario_contato},
      (res, error) => {
        if (!error) this.setState({contato: {...this.state.contato, ...res}});
      },
    );
  }

  backPageButton() {
    if (this.props.navigation && this.props.navigation.update)
      this.props.navigation.update();
    this.props.navigation.goBack();
  }

  getInfos() {
    let id_usuario = 0;
    if (this.state.contato.id_usuario_contato) {
      id_usuario = this.state.contato.id_usuario_contato;
    }

    return {
      id_usuario: id_usuario,
      boolcontatoproprio: false,
    };
  }

  onSend(messages) {
    // console.log(JSON.stringify(messages[0]));
    if (this.state.id_chat && this.state.connected) {
      var msg = messages[0];
      if (msg && msg.text) {
        if (msg) {
          msg.sent = true;
          if (this.state.url_image_upload) {
            msg.image = this.state.url_image_upload;
          }

          if (this.state.citacao) {
            msg.citacao = this.state.citacao + '';
          }

          this.setState({
            citacao: false,
            messages: [msg, ...this.state.messages],
            url_image_upload: '',
          });
          this.socket.emit(
            'msg',
            {
              id_user_logado: Cloud.getTokenUser(),
              user: this.state.user,
              message: msg,
              contato: this.state.contato,
              id_chat: this.state.id_chat,
            },
            (error, ret) => {},
          );
        }
      }
    } else {
      this.init(false, (data, error) => {
        if (error) {
          alert(JSON.stringify(error));
        } else {
          this.onSend(messages);
        }
      });
    }
  }

  connectChat(item) {
    var obj = {
      transports: ['websocket'],
      query:
        'id_user_logado=' +
        Cloud.getTokenUser() +
        '&contato=' +
        item.id_usuario_contato,
    };
    //console.log(new Date());
    this.socket = SocketIO(Cloud.getSocket(), obj);
    //console.log(new Date());
    this.socket.on('connect_error', () => {
      Alert.alert('Erro', 'Erro ao conectar no chat', [
        {text: 'OK', onPress: () => {}, style: 'cancel'},
      ]);
      this.setState({connected: false});
      this.backPageButton();
    });
    this.socket.on('connect', () => {
      this.setState({connected: this.socket.connected});
    });
    this.socket.on('disconnect', () => {
      this.setState({connected: false});
    });
    this.socket.emit('setChat', obj);
    this.socket.on('system', data => {
      //   console.log(new Date());
      if (data.connected) {
        this.setState({id_chat: data.id_chat});
        this.carregarConversas();
      } else {
        alert('Erro ao conectar chat');
      }
    });

    this.socket.on('readed_atualizar', data => {
      if (data.user.id != this.state.user._id) {
        var mess = JSON.parse(JSON.stringify(this.state.messages));
        this.setState({
          messages: this.atualizaReaded(mess, data.msgs_updates),
        });
      }
    });
    this.socket.on('newmsg', data => {
      if (data.user._id != this.state.user._id) {
        this.socket.emit('read', {
          id_chat: this.state.id_chat,
          id_user_logado: Cloud.getTokenUser(),
        });
      }
      if (this.testMsgExistente(data)) {
        this.setState({messages: [...this.state.messages]});
      } else {
        this.setState({messages: [data, ...this.state.messages]});
      }
    });

    this.socket.on('errorss', data => {
      Alert.alert('Erro', data.msg, [
        {text: 'OK', onPress: () => {}, style: 'cancel'},
      ]);
    });
  }

  atualizaReaded(msgs, data) {
    // let msgs = this.state.messages;
    for (let a = 0; a < data.length; a++) {
      let id = data[a];
      for (let index = 0; index < msgs.length; index++) {
        let element = msgs[index];
        if (element._id == id) {
          msgs[index].readed = true;
        }
      }
    }
    // console.log("reads", msgs);
    return msgs;
  }

  testMsgExistente(msg) {
    let msgs = this.state.messages;
    for (let index = 0; index < msgs.length; index++) {
      let element = msgs[index];
      if (element._id == msg._id) {
        msgs[index] = msg;
        return true;
      }
    }
    return false;
  }

  getProps() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  carregarConversas() {
    var msgs = [];
    Chat.listarMsgsAntigas({id_chat: this.state.id_chat}, (lista, error) => {
      // console.log(new Date());
      if (
        !error &&
        (lista.length != this.state.messages.length || this.state.loadGravar)
      ) {
        if (lista.length == 0) {
          msgs.push({
            _id: Math.round(Math.random() * 1000000),
            text: 'Nenhuma mensagem encontrada',
            createdAt: new Date(),
            system: true,
          });
        } else {
          for (let index = 0; index < lista.length; index++) {
            let element = lista[index].text;
            msgs.push(element);
          }
        }
        this.socket.emit('read', {
          id_chat: this.state.id_chat,
          id_user_logado: Cloud.getTokenUser(),
        });
        setNotsMsg();
        if (this.getProps().atualizar) this.getProps().atualizar();
        this.setState({messages: msgs, loadGravar: false});
      }
    });
  }

  init(entrada, callback) {
    if (entrada) {
      if (this.state.contato) {
        this.connectChat(this.state.contato);
      }
    }
  }

  componentDidMount() {
    this.getInfoContato();
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', () => {
      this.backPageButton(); // works best when the goBack is async
      return true;
    });

    this.setState({connected: false, id_chat: 0});
    this.subs = [
      this.props.navigation.addListener('willFocus', payload => {
        this.init(true, (data, error) => {});
      }),
      this.props.navigation.addListener('willBlur', payload => {
        try {
          this.socket.disconnect();
        } catch (error) {}
        this.setChatAtivo(false);
      }),
    ];

    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.chatativo != this.state.ativo)
        this.setState({chatativo: store.chatativo});
      if (store.tema != this.state.tema) this.setState({tema: store.tema});
    });

    this.setChatAtivo(true);

    if (this.unsubscribe) this.unsubscribe();
  }

  setChatAtivo(bool) {
    Actions.setChatAtivo(bool);
  }

  openGif() {
    this.props.navigation.navigate('GifPicker', {
      activity: this,
      oldState: this.state,
      callback: data => {
        this.setState({url_image_upload: data.images.original.url});
      },
    });
  }

  componentWillUnmount() {
    this.setChatAtivo(false);

    try {
      this.didFocusSubscription.remove();
    } catch (error) {}
    try {
      this.socket.disconnect();
    } catch (error) {}

    this.backHandler.remove();
  }

  addDivida() {
    this.state.contato.tela = 'amigos';
    this.props.navigation.navigate('NovaDivida', {
      activity: this,
      oldState: this.state,
      contato: this.state.contato,
    });
  }

  backPageBottom() {
    this.props.navigation.goBack();
  }

  renderInputToolbar(props) {
    // console.log("props", props);
    if (
      this.pai.state &&
      (this.pai.state.loadGravar || !this.pai.state.connected)
    ) {
      return null;
    } else {
      if (this.pai.state.tema == 'preto') {
        return (
          <InputToolbar
            ref={v => (this.pai.toolbar = v)}
            {...props}
            containerStyle={{padding: 2.5, backgroundColor: '#303030'}}
          />
        );
      } else {
        return (
          <InputToolbar
            ref={v => (this.pai.toolbar = v)}
            containerStyle={{padding: 2.5, backgroundColor: '#F0F0F0'}}
            {...props}
          />
        );
      }
    }
  }
  renderComposer(props) {
    if (this.pai.state.tema == 'preto') {
      return (
        <View style={{flex: 1, flexDirection: 'column'}}>
          {this.pai.state.citacao && (
            <View
              style={{
                flex: 1,
                marginBottom: 5,
                marginLeft: 20,
                flexDirection: 'row',
              }}>
              <Text numberOfLines={2} style={{color: 'grey', flex: 1}}>
                {this.pai.state.citacao}
              </Text>
              <TouchableOpacity
                style={styles.buttoncancelaquote}
                onPress={() => {
                  this.pai.setState({citacao: false});
                }}>
                <Icon
                  style={[Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Icons'}
                  name={'cancel'}
                />
              </TouchableOpacity>
            </View>
          )}
          <Composer
            {...props}
            placeholderTextColor={'white'}
            style={{
              minHeight: 40,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            textInputStyle={{
              backgroundColor: '#424242',
              color: 'white',
              marginBottom: 5,
              placeholderTextColor: '#fff',
              borderRadius: 20,
              paddingLeft: 10,
              minHeight: 30,
            }}>
            {' '}
          </Composer>
        </View>
      );
    } else {
      return (
        <View style={{flex: 1, flexDirection: 'column'}}>
          {this.pai.state.citacao && (
            <View
              style={{
                flex: 1,
                marginBottom: 5,
                marginLeft: 20,
                flexDirection: 'row',
              }}>
              <Text numberOfLines={2} style={{color: 'grey', flex: 1}}>
                {this.pai.state.citacao}
              </Text>
              <TouchableOpacity
                style={styles.buttoncancelaquote}
                onPress={() => {
                  this.pai.setState({citacao: false});
                }}>
                <Icon
                  style={[Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Icons'}
                  name={'cancel'}
                />
              </TouchableOpacity>
            </View>
          )}
          <Composer
            {...props}
            style={{
              minHeight: 40,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            textInputStyle={{
              backgroundColor: 'white',
              marginBottom: 5,
              borderRadius: 20,
              paddingLeft: 10,
              minHeight: 30,
            }}
          />
        </View>
      );
    }
  }

  renderBubble(props) {
    if (this.pai.state.tema == 'preto') {
      return (
        <SwipeRow
          disableLeftSwipe={true}
          leftOpenValue={50}
          // rightOpenValue={-50}
        >
          <View style={styles.standaloneRowBack}>
            <TouchableOpacity
              style={styles.button2quitar}
              onPress={() => {
                this.pai.setState({
                  citacao: this.pai.preparaReply(
                    JSON.stringify(props.currentMessage),
                  ),
                });
              }}>
              <Icon
                style={[styles.icon6quitar, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'reply'}
              />
            </TouchableOpacity>
          </View>
          <View
            style={[styles.standaloneRowFront, Actions.getTema('titlebar')]}>
            <Bubble
              {...props}
              textStyle={{
                right: {
                  color: '#fff',
                },
                left: {
                  color: 'white',
                },
              }}
              wrapperStyle={{
                right: {
                  backgroundColor: '#424242',
                  borderRadius: 5,
                  borderColor: '#fff',
                  width: '85%',
                  maxWidth: Dimensions.get('window').width - 80,
                },
                left: {
                  backgroundColor: 'rgba(48,129,219,1)',
                  borderRadius: 5,
                  borderColor: '#fff',
                  width: '85%',
                  maxWidth: Dimensions.get('window').width - 80,
                },
              }}
            />
          </View>
        </SwipeRow>
      );
    } else {
      return (
        <SwipeRow
          disableLeftSwipe={true}
          leftOpenValue={50}
          // rightOpenValue={-50}
        >
          <View style={styles.standaloneRowBack}>
            <TouchableOpacity
              style={styles.button2quitar}
              onPress={() => {
                this.pai.setState({
                  citacao: this.pai.preparaReply(
                    JSON.stringify(props.currentMessage),
                  ),
                });
              }}>
              <Icon
                style={[styles.icon6quitar, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'reply'}
              />
            </TouchableOpacity>
          </View>
          <View
            style={[styles.standaloneRowFront, Actions.getTema('titlebar')]}>
            <Bubble
              {...props}
              textStyle={{
                right: {
                  color: 'black',
                },
                left: {
                  color: 'white',
                },
              }}
              wrapperStyle={{
                right: {
                  backgroundColor: '#F0F0F0',
                  borderRadius: 5,
                  borderColor: '#fff',
                  maxWidth: Dimensions.get('window').width - 80,
                },
                left: {
                  backgroundColor: 'rgba(48,129,219,1)',
                  borderRadius: 5,
                  borderColor: '#fff',
                  maxWidth: Dimensions.get('window').width - 80,
                },
              }}
            />
          </View>
        </SwipeRow>
      );
    }
  }

  renderSend(props) {
    return (
      <View style={styles.viewrendercomposer}>
        <Send
          ref={v => (this.pai.sendbtn = v)}
          {...props}
          // alwaysShowSend={true}
        >
          <Icon
            style={styles.iconsend}
            fromFontFamily={'Material Design Icons'}
            name={'send'}
          />
        </Send>

        {!props.text &&
        !this.pai.state.url_image_upload &&
        !this.pai.state.load_foto &&
        Platform.OS !== 'web' ? (
          <TouchableOpacity
            style={styles.buttonrender}
            onPress={() => {
              Cloud.upLoadFoto(this.pai, data => {
                this.pai.setState({
                  load_foto: false,
                  url_image_upload: data.url,
                });
                // console.log(this.pai.toolbar);
                // this.pai.toolbar.props.onSend();
              });
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'paperclip'}
            />
          </TouchableOpacity>
        ) : null}

        {!props.text &&
        !this.pai.state.url_image_upload &&
        !this.pai.state.load_foto &&
        Platform.OS == 'web' ? (
          <ImageUpload
            style={styles.buttonrender}
            directUpload
            path={'casher/perfil'}
            onChange={value => {
              this.pai.setState({
                load_foto: false,
                url_image_upload: value,
              });
            }}
            label={'Img'}
          />
        ) : null}

        {!props.text &&
        !this.pai.state.url_image_upload &&
        !this.pai.state.load_foto ? (
          <TouchableOpacity
            style={styles.buttonrender}
            onPress={() => {
              this.pai.openGif();
            }}>
            {this.pai.state.tema == 'preto' && (
              <Image
                style={styles.image2}
                resizeMode={'cover'}
                source={require('../../../img/gif_white.png')}
              />
            )}

            {this.pai.state.tema == 'white' && (
              <Image
                style={styles.image2}
                resizeMode={'cover'}
                source={require('../../../img/gif_blue.png')}
              />
            )}
          </TouchableOpacity>
        ) : null}

        {this.pai.state.load_foto ? (
          <Progress style={styleGlobal.progress} />
        ) : null}
        {!props.text && this.pai.state.url_image_upload ? (
          <TouchableOpacity
            style={styles.buttonrender}
            onPress={() => {
              this.pai.setState({url_image_upload: ''});
            }}>
            <Icon
              style={styles.icon}
              fromFontFamily={'Material Design Icons'}
              name={'bookmark-remove'}
            />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }

  renderTicks = currentMessage => {
    var color = {color: '#28FF19'};

    if (currentMessage.user._id == this.state.user._id) {
      return (
        <View style={styles.tickView}>
          {currentMessage.sent ? (
            <Text
              style={[
                styles.tick,
                Actions.getTema('iconstitlebar'),
                styles.tickStyle,
                currentMessage.readed ? color : '',
              ]}>
              ✓
            </Text>
          ) : null}
          {currentMessage.received ? (
            <Text
              style={[
                styles.tick,
                Actions.getTema('iconstitlebar'),
                styles.tickStyle,
                currentMessage.readed ? color : '',
              ]}>
              ✓
            </Text>
          ) : null}
        </View>
      );
    } else {
      //return ( <View></View> );
      return false;
    }
  };

  renderTime(props) {
    return (
      <Time
        {...props}
        textStyle={{
          right: Actions.getTema('iconstitlebar'),
          left: {
            color: 'white',
          },
        }}
      />
    );
  }

  renderMessage(props) {
    return <Message {...props} />;
  }

  renderMessageText(props) {
    let cit = false;
    if (props.currentMessage && props.currentMessage.citacao) {
      cit = true;
    }
    return (
      <View style={{}}>
        {cit && (
          <View
            style={{
              paddingLeft: 5,
              paddingRight: 5,
              marginTop: 5,
              justifyContent: 'center',
            }}>
            <Text
              style={[
                Actions.getTema('textcell'),
                {fontStyle: 'italic', justifyContent: 'center'},
              ]}>
              {props.currentMessage.citacao}
            </Text>
          </View>
        )}
        {cit && (
          <View
            style={{
              height: 1,
              paddingLeft: 5,
              paddingRight: 5,
              marginBottom: 5,
              width: '100%',
            }}>
            <View
              style={[
                Actions.getTema('icon_checkbox'),
                {height: 1, width: '100%'},
              ]}
            />
          </View>
        )}

        <CompMensagem {...props} />
      </View>
    );
  }

  preparaReply(item) {
    var text = JSON.parse(item).text;
    // JSON.parse(this.state.messareply)
    return text + '\n';
  }

  setCustomText(text) {
    this.setState({messareply: text});
  }

  render() {
    return (
      <View style={[styles.content, Actions.getTema('titlebar')]}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
          </TouchableOpacity>
          <View style={{flexDirection: 'row', flex: 1}}>
            <TouchableOpacity
              style={styles.button11}
              onPress={() => {
                Actions.setShowPreInfos({show: true, ...this.getInfos()});
              }}>
              <View style={styles.view3}>
                <Image
                  style={styles.image}
                  resizeMode={'cover'}
                  source={{uri: this.state.contato.foto}}
                />
              </View>
              <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
                {Util.subString(this.state.contato.nome, 20)}
              </Text>
            </TouchableOpacity>
          </View>
          {this.state.loadGravar ? (
            <Progress style={styleGlobal.progress} />
          ) : null}
          <TouchableOpacity
            style={styles.button100}
            onPress={() => {
              this.addDivida();
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'plus'}
            />
          </TouchableOpacity>
          {/* <View style={styles.view} /> */}
        </TitleBar>
        {/* <View style={{ flex: 97 }}> */}
        {Platform.OS == 'web' ? (
          <GiftedChat
            style={styles.fragment}
            messages={this.state.messages}
            showUserAvatar
            placeholder={'Digite uma mensagem'}
            onSend={messages => this.onSend(messages)}
            renderComposer={this.renderComposer}
            onLongPress={(user, msg) => {
              // this.removerMsg(msg);
            }}
            locale="pt-br"
            renderAvatar={null}
            text={this.state.messareply}
            onInputTextChanged={text => this.setCustomText(text)}
            maxInputLength={20000}
            // scrollToBottomOffset={400}

            timeFormat={'HH:mm'}
            renderTime={this.renderTime}
            pai={this}
            renderMessageImage={props => {
              return (
                <Image
                  source={{uri: props.currentMessage.image}}
                  resizeMode={'cover'}
                  onClick={() => {
                    window.open(props.currentMessage.image);
                  }}
                  style={{borderRadius: 5, width: 280, height: 180}}
                />
              );
            }}
            listViewProps={{style: {marginBottom: 10}}}
            scrollToBottom={true}
            renderTicks={this.renderTicks}
            renderMessage={this.renderMessage}
            renderMessageText={this.renderMessageText}
            renderBubble={this.renderBubble}
            renderInputToolbar={this.renderInputToolbar}
            renderSend={this.renderSend}
            user={this.state.user}
          />
        ) : (
          <GiftedChat
            style={styles.fragment}
            messages={this.state.messages}
            showUserAvatar
            placeholder={'Digite uma mensagem'}
            onSend={messages => this.onSend(messages)}
            renderComposer={this.renderComposer}
            onLongPress={(user, msg) => {
              // this.removerMsg(msg);
            }}
            locale="pt-br"
            renderAvatar={null}
            text={this.state.messareply}
            onInputTextChanged={text => this.setCustomText(text)}
            maxInputLength={20000}
            // scrollToBottomOffset={400}

            timeFormat={'HH:mm'}
            renderTime={this.renderTime}
            pai={this}
            listViewProps={{style: {marginBottom: 10}}}
            scrollToBottom={true}
            renderTicks={this.renderTicks}
            renderMessage={this.renderMessage}
            renderMessageText={this.renderMessageText}
            renderBubble={this.renderBubble}
            renderInputToolbar={this.renderInputToolbar}
            renderSend={this.renderSend}
            user={this.state.user}
          />
        )}
        {/* </View> */}
        {/* <View style={{ flex: 3 }} /> */}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: '#fff',
    flex: 1,
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignSelf: 'stretch',
  },
  viewrendercomposer: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  button1: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    width: 50,
    // flex: 1
  },
  button11: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    // paddingLeft: 12,
    flex: 1,
  },
  button2quitar: {
    width: 50,
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
  },
  icon6quitar: {
    // alignSelf:"center"
  },
  buttonrender: {
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    width: 50,
  },
  button100: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    width: 50,
  },
  listview: {
    alignSelf: 'stretch',
    flex: 1,
  },
  tick: {
    fontSize: 10,
    backgroundColor: 'transparent',
  },
  standaloneRowBack: {
    alignItems: 'center',
    // backgroundColor: '#8BC645',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    // padding: 5
  },
  standaloneRowFront: {
    alignItems: 'center',

    justifyContent: 'center',
    // height: 50,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  iconsend: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
    marginBottom: 10,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
    justifyContent: 'center',
    flex: 1,
  },
  tickView: {
    flexDirection: 'row',
    marginRight: 10,
  },
  tickStyle: Text.propTypes.style,
  view3: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 50,
    height: 50,
    marginLeft: 10,
  },
  buttoncancelaquote: {
    justifyContent: 'center',
  },
  image: {
    width: 32,
    height: 32,
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 16,
  },
  image2: {
    width: 32,
    height: 32,
    marginRight: 5,
  },
  fragment: {},
});
