import React, {Component} from 'react';
import ProgressBar from 'react-native-progress/Bar';
import {
  Content,
  Icon,
  Image,
  ListView,
  Progress,
  TitleBar,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  Alert,
} from 'react-native';
import * as Divida from '../../worker/divida';
import styleGlobal from '../../styleGlobal';
import TodoSwipeList from '../../components/TodoSwipeList';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import {Util} from '../../infra';
import * as Actions from '../../redux/actions';
import moment from 'moment';
import CellListaMain from './CellListaMain';
import Banner from './Banner';
export default class Lista extends Component {
  constructor(props) {
    super(props);
    //this.props.screenProps.store.getState()
    this.state = {
      faturas: [],
      flag_tipo: '',
      flag_status: 'N',
      coluna: 'data_vencimento',
      order: 'asc',
      load: false,
      dif: 0,
      pra_mim: 0,
      por_mim: 0,
      pos: true,
      data_ini: this.props.screenProps.store.getState().data_ini,
      data_fim: this.props.screenProps.store.getState().data_fim,
      tipo_data: this.props.screenProps.store.getState().tipo_data,
      desc_datas: this.props.screenProps.store.getState().desc_datas,
      nome_pessoa: this.props.screenProps.store.getState().nome_pessoa,
      id_usuario_divida: this.props.screenProps.store.getState()
        .id_usuario_divida,
      id_contato_proprio_divida: this.props.screenProps.store.getState()
        .id_contato_proprio_divida,
      qtdnot: this.props.screenProps.store.getState().qtdnot,
      add1: false,
      add2: false,
      aceitasqtd: this.props.screenProps.store.getState().aceitasqtd,
      fazer_pesquisa: this.props.screenProps.store.getState().fazer_pesquisa,
      mostrarvalheader: this.props.screenProps.store.getState()
        .mostrarvalheader,
    };
  }

  trocaFlagTipo() {
    var flag_tipo = '';
    if (this.state.flag_tipo == '') {
      flag_tipo = '1';
    } else if (this.state.flag_tipo == '1') {
      flag_tipo = '2';
    } else if (this.state.flag_tipo == '2') {
      flag_tipo = '';
    }
    this.preparaFitros({flag_tipo: flag_tipo});
  }

  trocaCrescente() {
    var order = '';
    if (this.state.order == 'asc') {
      order = 'desc';
    } else if (this.state.order == 'desc') {
      order = 'asc';
    }
    this.preparaFitros({order: order});
  }

  quitarDivida(item) {
    Alert.alert(
      'Aviso',
      'Tem certeza que deseja quitar esta dívida? Os valores não poderão ser modificados após essa operação',
      [
        {text: 'Não', onPress: () => {}, style: 'cancel'},
        {
          text: 'Sim',
          onPress: () => {
            this.setState({load: true});
            Divida.quitar(item, (data, error) => {
              this.setState({load: false});
              if (error) {
                showMessage({
                  message: 'Erro ao quitar',
                  description: error.msg,
                  type: 'warning',
                  icon: {icon: 'warning', position: 'left'},
                  duration: 2000,
                });
              } else if (data.id) {
                showMessage({
                  message: 'Divida quitada!',
                  type: 'success',
                  duration: 2000,
                });
              }
              this.preparaFitros({});
            });
          },
        },
      ],
      {cancelable: false},
    );
  }

  getColor() {
    if (this.state.flag_tipo == '') {
      if (!this.state.pos) {
        return {
          color: 'rgba(255,0,0,1)',
        };
      } else {
        return {
          color: 'rgba(48,129,219,1)',
        };
      }
    } else if (this.state.flag_tipo == '1') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    } else if (this.state.flag_tipo == '2') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    }
  }

  getArrow() {
    if (this.state.flag_tipo == '') {
      if (this.state.pos) {
        return 'arrow-up';
      } else {
        return 'arrow-down';
      }
    } else if (this.state.flag_tipo == '1') {
      return 'arrow-up';
    } else if (this.state.flag_tipo == '2') {
      return 'arrow-down';
    }
  }

  trocaColuna() {
    var coluna = '';
    if (this.state.coluna == 'data_vencimento') {
      coluna = 'data_debito';
    } else if (this.state.coluna == 'data_debito') {
      coluna = 'val_montante';
    } else if (this.state.coluna == 'val_montante') {
      coluna = 'data_vencimento';
    }
    this.preparaFitros({coluna: coluna});
  }

  escolherData() {
    this.props.navigation.navigate('EscolherData', {
      activity: this,
      oldState: this.state,
      onGoBack: state => {
        // this.setState({data_ini:state.data_ini, data_fim:state.data_fim ,  tipo_data:state.tipo_data});
        // this.preparaFitros({ data_ini: state.data_ini, data_fim: state.data_fim, tipo_data: state.tipo_data });
      },
    });
  }

  openrelatorio(item) {
    this.props.navigation.navigate('Relatorio', {
      activity: this,
      oldState: this.state,
      id_divida: item.id,
      onGoBack: state => {
        // this.setState({data_ini:state.data_ini, data_fim:state.data_fim ,  tipo_data:state.tipo_data});
        // this.preparaFitros({ data_ini: state.data_ini, data_fim: state.data_fim, tipo_data: state.tipo_data });
      },
    });
  }

  trocaStatus() {
    var flag_status = '';
    if (this.state.flag_status == '') {
      flag_status = 'P';
    } else if (this.state.flag_status == 'P') {
      flag_status = 'N';
    } else if (this.state.flag_status == 'N') {
      flag_status = '';
    }
    this.preparaFitros({flag_status: flag_status});
  }

  preparaFitros(param) {
    if (param.flag_tipo == undefined || param.flag_tipo == null) {
      param.flag_tipo = this.state.flag_tipo;
    }
    if (param.flag_status == undefined || param.flag_status == null) {
      param.flag_status = this.state.flag_status;
    }
    if (param.coluna == undefined || param.coluna == null) {
      param.coluna = this.state.coluna;
    }
    if (param.order == undefined || param.order == null) {
      param.order = this.state.order;
    }
    if (param.data_ini == undefined || param.data_ini == null) {
      param.data_ini = this.state.data_ini;
    }
    if (param.data_fim == undefined || param.data_fim == null) {
      param.data_fim = this.state.data_fim;
    }
    if (param.tipo_data == undefined || param.tipo_data == null) {
      param.tipo_data = this.state.tipo_data;
    }

    if (param.nome_pessoa == undefined || param.nome_pessoa == null) {
      param.nome_pessoa = this.state.nome_pessoa;
    }

    if (
      param.id_usuario_divida == undefined ||
      param.id_usuario_divida == null
    ) {
      param.id_usuario_divida = this.state.id_usuario_divida;
    }

    if (
      param.id_contato_proprio_divida == undefined ||
      param.id_contato_proprio_divida == null
    ) {
      param.id_contato_proprio_divida = this.state.id_contato_proprio_divida;
    }

    this.carregarDividas(param, (data, error) => {
      this.setState({
        flag_tipo: param.flag_tipo,
        flag_status: param.flag_status,
        coluna: param.coluna,
        data_ini: param.data_ini,
        data_fim: param.data_fim,
        tipo_data: param.tipo_data,
        order: param.order,
        nome_pessoa: param.nome_pessoa,
        id_usuario_divida: param.id_usuario_divida,
        id_contato_proprio_divida: param.id_contato_proprio_divida,
      });
    });
  }

  calculaTotalfromRaw() {
    let array = this.state.faturas;
    let total = 0;
    for (let a = 0; a < array.length; a++) {
      let element = array[a];
      if (element.id) {
        if (element.flag_tipo == '1') {
          total = total + element.val_montante;
        } else if (element.flag_tipo == '2') {
          total = total - element.val_montante;
        }
      }
    }

    this.setState({
      total_filtro_pessoa: total < 0 ? total * -1 : total,
      cor_filtro_pessoa: total < 0 ? 'red' : 'rgba(48,129,219,1)',
    });
  }

  carregarDividas(obj, callback) {
    this.setState({load: true});
    if (!obj) {
      obj = {};
    }

    if (obj.data_fim === 'Invalid date')
      obj.data_fim = moment().format('YYYY-MM-DD');
    if (obj.data_ini === 'Invalid date')
      obj.data_ini = moment().format('YYYY-MM-DD');

    Divida.listarHome(obj, (data, error) => {
      try {
        data.lista = this.preparaAds(data.lista);
      } catch (error) {
        data.lista = [];
      }
      this.setState(
        {
          faturas: data.lista,
          load: false,
          pra_mim: data.pra_mim,
          por_mim: data.por_mim,
          dif: data.dif,
          pos: data.pos,
        },
        () => {
          if (
            this.state.id_contato_proprio_divida ||
            this.state.id_usuario_divida
          ) {
            this.calculaTotalfromRaw();
          }
        },
      );
      if (callback) callback();
    });
  }

  returnTextTopLeft() {
    if (this.state.flag_status == 'P') {
      return 'R$ 0,00';
    }
    if (this.state.flag_tipo == '') {
      return this.state.dif;
    } else if (this.state.flag_tipo == '1') {
      return this.state.pra_mim;
    } else if (this.state.flag_tipo == '2') {
      return this.state.por_mim;
    }
  }

  preparaAds(array) {
    let pos = 0;
    let interval = 8;
    var id = 1;
    if (array && array.length > 0) {
      while (pos < array.length) {
        array.splice(pos, 0, {add: true, id: id++});
        pos += interval;
      }
      if (!array[array.length - 1].add) {
        array.push({add: true, id: id++});
      }
    }
    return array;
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (
        store.user != this.state.user ||
        store.data_ini != this.state.data_ini ||
        store.data_fim != this.state.data_fim ||
        store.tipo_data != this.state.tipo_data ||
        store.desc_datas != this.state.desc_datas ||
        store.nome_pessoa != this.state.nome_pessoa ||
        store.id_usuario_divida != this.state.id_usuario_divida ||
        store.id_contato_proprio_divida !=
          this.state.id_contato_proprio_divida ||
        store.qtdnot != this.state.qtdnot ||
        store.aceitasqtd != this.state.aceitasqtd ||
        store.mostrarvalheader != this.state.mostrarvalheader
      ) {
        this.setState({
          user: store.user,
          data_ini: store.data_ini,
          data_fim: store.data_fim,
          tipo_data: store.tipo_data,
          desc_datas: store.desc_datas,
          nome_pessoa: store.nome_pessoa,
          id_usuario_divida: store.id_usuario_divida,
          id_contato_proprio_divida: store.id_contato_proprio_divida,
          qtdnot: store.qtdnot,
          aceitasqtd: store.aceitasqtd,
          mostrarvalheader: store.mostrarvalheader,
        });
      }
      if (!store.nome_pessoa && this.state.nome_pessoa) {
        this.state.nome_pessoa = '';
        this.cleanFiltro();
      }
      if (store.fazer_pesquisa != this.state.fazer_pesquisa) {
        if (store.fazer_pesquisa == true) {
          this.preparaFitros({
            nome_pessoa: store.nome_pessoa,
            id_usuario_divida: store.id_usuario_divida,
            id_contato_proprio_divida: store.id_contato_proprio_divida,
          });
          Actions.setFazerPesquisa(false);
        }
      }
    });

    this.props.navigation.addListener('didFocus', payload => {
      this.preparaFitros({});
      Actions.setChatAtivo(false);
    });

    this.preparaFitros({});
  }

  abrirPessoas() {
    this.props.navigation.navigate('ListaPessoasDivida', {
      activity: this,
      oldState: this.state,
      tela: 'home',
    });
  }

  cleanFiltro() {
    Actions.setFiltroPessoa('', 0, 0);
    this.state.id_usuario_divida = undefined; //({ id_usuario_divida: undefined, nome_pessoa: undefined })
    this.state.nome_pessoa = undefined;
    this.state.id_contato_proprio_divida = undefined;
    this.preparaFitros({});
  }

  abrirNots() {
    this.props.navigation.navigate('Notificacao', {
      activity: this,
      oldState: this.state,
    });
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();

    if (this.subs) this.subs.forEach(sub => sub.remove());
  }

  render() {
    return (
      <Content style={styles.content} keyboard={true}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button2}
            onPress={() => {
              this.props.activity.menuLeft.openDrawer();
            }}>
            <Icon
              style={[styles.icon2, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'dots-vertical'}
            />

            {this.state.aceitasqtd > 0 && (
              <View style={styles.viewnot1}>
                <View style={styles.viewnot10} />
              </View>
            )}
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.setState({mostrarvalheader: !this.state.mostrarvalheader});
            }}>
            {!this.state.mostrarvalheader ? (
              <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
                {'Casher'}
              </Text>
            ) : null}
            {this.state.mostrarvalheader ? (
              <Text style={[styles.text2, {...this.getColor()}]}>
                {this.returnTextTopLeft()}
              </Text>
            ) : null}

            {this.state.mostrarvalheader ? (
              <Icon
                style={[styles.icon, {...this.getColor()}]}
                fromFontFamily={'Material Design Icons'}
                name={this.getArrow()}
              />
            ) : null}
          </TouchableOpacity>

          <View style={styles.view} />
          <TouchableOpacity
            style={styles.button2}
            onPress={() => {
              this.escolherData();
            }}>
            <Icon
              style={[styles.icon2, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'calendar'}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.button41}
            onPress={() => {
              this.abrirPessoas();
            }}>
            <Icon
              style={[styles.icon4, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'account-search'}
            />
          </TouchableOpacity>

          <TouchableOpacity
            style={styles.button4}
            onPress={() => {
              this.abrirNots();
            }}>
            <Icon
              style={[styles.icon4, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Icons'}
              name={'notifications'}
            />

            {this.state.qtdnot > 0 && (
              <View style={styles.view11}>
                <View style={[styles.view12, Actions.getTema('notification')]}>
                  <Text style={styles.label9}>{this.state.qtdnot}</Text>
                </View>
              </View>
            )}
          </TouchableOpacity>
        </TitleBar>
        <TodoSwipeList
          style={[styles.listview, Actions.getTema('scroll')]}
          ListHeaderComponent={() => {
            return (
              <View style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}>
                <View style={styles.view41}>
                  <TouchableOpacity
                    style={styleGlobal.botao_abilitado}
                    onPress={() => {
                      this.trocaFlagTipo();
                    }}>
                    {this.state.flag_tipo == '' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Tudo'}
                      </Text>
                    ) : null}

                    {this.state.flag_tipo == '1' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Receber'}
                      </Text>
                    ) : null}

                    {this.state.flag_tipo == '2' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Pagar'}
                      </Text>
                    ) : null}
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styleGlobal.botao_abilitado}
                    onPress={() => {
                      this.trocaColuna();
                    }}>
                    {this.state.coluna == 'data_vencimento' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Vencimento'}
                      </Text>
                    ) : null}

                    {this.state.coluna == 'data_debito' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Ocorrido'}
                      </Text>
                    ) : null}

                    {this.state.coluna == 'val_montante' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Valor'}
                      </Text>
                    ) : null}
                  </TouchableOpacity>
                  {/* </View>
                    <View style={styles.view4}> */}
                  <TouchableOpacity
                    style={styleGlobal.botao_abilitado}
                    onPress={() => {
                      this.trocaStatus();
                    }}>
                    {this.state.flag_status == '' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Todos'}
                      </Text>
                    ) : null}

                    {this.state.flag_status == 'P' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Pagos'}
                      </Text>
                    ) : null}

                    {this.state.flag_status == 'N' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Não Pagos'}
                      </Text>
                    ) : null}
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styleGlobal.botao_abilitado}
                    onPress={() => {
                      this.trocaCrescente();
                    }}>
                    {this.state.order == 'asc' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Crescente'}
                      </Text>
                    ) : null}

                    {this.state.order == 'desc' ? (
                      <Text style={styleGlobal.botao_text_abilitado}>
                        {'Decrescente'}
                      </Text>
                    ) : null}
                  </TouchableOpacity>
                </View>
                <View style={styles.viewprogress}>
                  {this.state.load ? (
                    <Progress style={styleGlobal.progress} />
                  ) : null}
                </View>
                <View style={styles.view61}>
                  <View style={styles.view7} />
                  <Text style={styles.text7}>{this.state.desc_datas}</Text>
                  <View style={styles.view8} />
                </View>
                {this.state.faturas.length == 0 ? (
                  <Banner screenProps={this.props.screenProps} />
                ) : null}
                {this.state.faturas.length == 0 ? (
                  <View style={styles.view6}>
                    <Text style={styles.nenhumreg}>
                      {'Nenhum registro para exibir'}
                    </Text>
                  </View>
                ) : null}

                {this.state.nome_pessoa ? (
                  <View style={[styles.viewnomepess]}>
                    <TouchableOpacity
                      style={[
                        styles.buttonfiltroremove,
                        {...Util.elevation()},
                        Actions.getTema('titlebar'),
                      ]}
                      onPress={() => {
                        this.cleanFiltro();
                      }}>
                      <View style={[styles.view9999]}>
                        <Text
                          style={[
                            styles.text888,
                            Actions.getTema('labelfields'),
                          ]}>
                          {this.state.nome_pessoa}
                        </Text>
                      </View>
                      <View style={[styles.view99991]}>
                        <Text
                          style={[
                            styles.text8881,
                            {color: this.state.cor_filtro_pessoa},
                          ]}>
                          {Util.parseMoney(this.state.total_filtro_pessoa)}
                        </Text>
                      </View>
                      <Icon
                        style={[styles.icon4, Actions.getTema('iconstitlebar')]}
                        fromFontFamily={'Material Design Icons'}
                        name={'close-circle'}
                      />
                    </TouchableOpacity>
                  </View>
                ) : null}
              </View>
            );
          }}
          renderItem={({item, index}) => {
            return (
              <CellListaMain
                item={item}
                screenProps={this.props.screenProps}
                activity={this}
                navigation={this.props.navigation}
                rowID={index}
                tela={'lista'}
              />
            );
          }}
          data={this.state.faturas}
          keyExtractor={(item, index) => index}
          rightSubView={(item, index) => {
            let pode = false;
            if (item.flag_status != 'P') {
              pode = true;
            } else {
              pode = false;
            }

            if (item.flag_pode_editar == 'C' && item.flag_espelho == 'S') {
              pode = false;
            }

            if (item.add == true) {
              pode = false;
            }

            if (pode)
              return (
                <View style={styles.view9988}>
                  <TouchableOpacity
                    style={styles.button2quitar}
                    onPress={() => {
                      this.openrelatorio(item);
                    }}>
                    <Icon
                      style={styles.icon6quitar}
                      fromFontFamily={'Material Design Icons'}
                      name={'clipboard-text'}
                    />
                    <Text style={styles.text33}>{'Relatório'}</Text>
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.button2quitar}
                    onPress={() => {
                      this.quitarDivida(item);
                    }}>
                    <Icon
                      style={styles.icon6quitar}
                      fromFontFamily={'Material Icons'}
                      name={'attach_money'}
                    />
                    <Text style={styles.text33}>{'Quitar'}</Text>
                  </TouchableOpacity>
                </View>
              );
            else
              return (
                <View style={styles.view9988}>
                  <TouchableOpacity
                    style={styles.button2quitar}
                    onPress={() => {
                      this.openrelatorio(item);
                    }}>
                    <Icon
                      style={styles.icon6quitar}
                      fromFontFamily={'Material Design Icons'}
                      name={'clipboard-text'}
                    />
                    <Text style={styles.text33}>{'Relatório'}</Text>
                  </TouchableOpacity>
                </View>
              );
          }}
          leftSubView={(item, index) => {
            return <View />;
          }}
        />
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(249,249,249,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  button1: {
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 2.5,
    paddingRight: 20,
  },
  text33: {
    textAlign: 'center',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginLeft: 5,
    marginBottom: 2.5,
  },
  view: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view9988: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    
    width: 175,
  },
  button2: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  icon6quitar: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  button2quitar: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 50,
    flexDirection: 'column',
    width: 70,
    marginLeft: 2.5,
    marginRight: 2.5,
  },

  button4: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
    marginRight: 10,
  },
  button41: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  icon4: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  view11: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    padding: 3,
  },
  view12: {
    alignSelf: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    minWidth: 20,
    minHeight: 20,
    backgroundColor: 'rgba(222,34,33,1)',
    borderRadius: 10,
    marginLeft: 30,
  },
  label9: {
    textAlign: 'center',
    flexWrap: 'wrap',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: '700',
    fontSize: 12,
    flex: 1,
  },
  view50: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view41: {
    alignSelf: 'stretch',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: 20,
  },
  viewprogress: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  view61: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: 35,
  },
  view7: {
    alignSelf: 'center',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderColor: 'rgba(177,177,184,1)',
    backgroundColor: 'rgba(177,177,184,1)',
    height: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  text7: {
    textAlign: 'center',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'center',
    fontWeight: 'normal',
  },
  view8: {
    alignSelf: 'center',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'rgba(177,177,184,1)',
    height: 1,
    marginLeft: 10,
    marginRight: 10,
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: 75,
  },
  viewnomepess: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: 40,
  },
  viewnot10: {
    alignSelf: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    minWidth: 12,
    minHeight: 12,
    backgroundColor: 'rgba(222,34,33,1)',
    borderRadius: 6,
    marginLeft: 30,
  },
  viewnot1: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    padding: 3,
  },
  nenhumreg: {
    textAlign: 'center',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'center',
    fontWeight: 'normal',
    flex: 1,
  },
  listview: {
    alignSelf: 'stretch',
    flex: 1,
  },
  buttonfiltroremove: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 8,
    margin: 5,
  },
  view9999: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  view99991: {
    alignSelf: 'stretch',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'row',
    marginRight: 10,
    width: 100,
  },
  text888: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 15,
  },

  text8881: {
    textAlign: 'right',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 15,
  },
});
