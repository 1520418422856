import React, { Component } from "react";
import { Content, Icon, Image, Progress, TitleBar, ImageUpload, } from "react-native-1app";
import ImagePicker from 'react-native-image-picker';
import {
  StyleSheet,
  View,
  Alert,
  TouchableOpacity,
  Text,
  BackHandler,
  ActivityIndicator
} from "react-native";
import styleGlobal from "../../styleGlobal";
import { GiftedChat, InputToolbar, Send, Color, Message, Bubble, Composer, Time, MessageText } from "react-native-gifted-chat";
import * as Chat from "../../worker/chat";
import * as Actions from "../../redux/actions";
import { Util, Cloud } from "../../infra";
import 'moment/locale/pt-br'
import SocketIO from "socket.io-client";

export default class ComMensagem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 1000,
      msgoriginal: this.props.currentMessage,
      textmsgoriginal: this.props.currentMessage.text + "",
      estoroulimite: false
    };


  }



  getMsg() {
    let newmsgobj = JSON.parse(JSON.stringify(this.state.msgoriginal));
    let limit = 0;
    let estoroulimite = false;
    if (this.state.textmsgoriginal.length < this.state.limit) {
      limit = this.state.textmsgoriginal.length;
    } else {
      limit = this.state.limit;
      estoroulimite = true;
    }
    let message = newmsgobj.text.substring(0, limit);;
    newmsgobj.text = message;
    if (estoroulimite) {
      newmsgobj.text = newmsgobj.text + "...";
    }


    return newmsgobj;
  }

  render() {
    return (
      <View style={{}}>
        <MessageText
          {...this.props}
          currentMessage={this.getMsg()}
        />
        {this.state.textmsgoriginal.length > this.state.limit &&
          <TouchableOpacity style={{ marginRight: 10, marginBottom: 10 }} onPress={() => {
            this.setState({ limit: this.state.limit + 25000 });
          }}>
            <Text style={[{textAlign:"right"},(this.props.position == "right" ? { color: "#28FF19" } : { color: "#28FF19" })]}>
              {"Ler Mais"}
            </Text>
          </TouchableOpacity>
        }
      </View>
    );
  }
}

var styles = StyleSheet.create({

});
