import React, {Component} from 'react';

import {Content, TextInput, TitleBar, Progress, Icon} from 'react-native-1app';
import DatePicker from 'react-native-1app/lib/DatePicker';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  Platform,
} from 'react-native';
// import DatePicker from "../../components/DatePicker";
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import * as Usuario from '../../worker/usuario';
import {logarUser} from '../../redux/actions';
import {Util} from '../../infra';
import styleGlobal from '../../styleGlobal';
import AlterarSenha from './AlterarSenha';
import md5 from 'md5';
import moment from 'moment-timezone';
import * as Actions from '../../redux/actions';
export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.screenProps.store.getState().user,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user) this.setState({user: store.user});
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  backPageButton() {
    this.props.navigation.goBack();
    Actions.logout();
  }

  salvar() {
    let erro = Usuario.validarUserMod({
      ...this.state.user,
    });
    if (!erro) {
      // if (this.state.senha && !this.state.user.senha) {
      //   this.state.user.senha = md5(this.state.senha);
      // }
      this.setState({load: true});
      Usuario.atualizar(this.state.user, (user = {}, error) => {
        if (error) {
          showMessage({
            message: 'Falha atualizar a conta',
            description: error.msg,
            type: 'warning',
            icon: {icon: 'warning', position: 'left'},
          });
        } else {
          this.props.screenProps.store.dispatch({
            type: 'SET_USER',
            user,
          });
          showMessage({
            message: 'Cadastro atualizado',
            type: 'success',
          });
        }
        this.setState({load: false});
      });
    } else {
      showMessage({
        message: 'Verifique o cadastro',
        description: erro,
      });
    }
  }

  openRecuperarSenha() {
    openDialog({
      title: 'Alterar senha',
      descricao: `Confirme os dados para alterar a senha.`,
      align: 'top',
      styleDescricao: {margin: 5},
      body: (
        <AlterarSenha
          user={this.state.user}
          screenProps={this.props.screenProps}
        />
      ),
      actions: [
        {
          title: 'CANCELAR',
        },
      ],
    });
  }

  render() {
    return (
      <Content style={styles.content} keyboard={true}>
        <TitleBar style={styleGlobal.titlebar}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[styles.icon]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
            <Text style={[styles.text1]}>{'Informações necessárias'}</Text>
          </TouchableOpacity>
          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
        </TitleBar>
        <ScrollView style={styles.scroll}>
          <View style={styles.view4}>
            <Text style={styles.text4}>
              {
                'Por favor, cadastre seu telefone! Através dele você se conectará aos seus amigos. Utilize o formato conforme o exemplo: (xx) xxxxx-xxxx'
              }
            </Text>
            <TextInput
              style={styles.textinput5}
              value={this.state.user.telefone}
              onChange={value => {
                this.state.user.telefone = Util.maskFormate(value, 'phone');
                this.setState({user: this.state.user});
              }}
              keyboardType={Platform.OS != 'web' ? 'numeric' : 'default'}
              label={'Telefone'}
            />
            <DatePicker
              style={styles.datepicker1}
              value={
                this.state.user.data_nascimento
                  ? moment(
                      this.state.user.data_nascimento,
                      'YYYY-MM-DDTHH:mm:ss.SSSSZ',
                    )
                      .set(12, 'hours')
                      .toJSON()
                  : moment()
              }
              onChange={value => {
                try {
                  value = moment(value)
                    .set({hour: 0, minute: 0, second: 0, millisecond: 0})
                    .format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
                } catch (error) {}
                this.state.user.data_nascimento = value;
                this.setState({user: this.state.user});
              }}
              disabledClear={true}
              datePickerModeAndroid={'calendar'}
              type={Platform.OS == 'web' ? 'date' : null}
              label={'Data de Nascimento'}
            />
            <Text style={styles.text4}>
              {
                'Os seus dados são usados somente internamente para trazer uma melhor experiência a você'
              }
            </Text>
          </View>
          <View style={styles.view6}>
            <TouchableOpacity
              style={styleGlobal.botao_abilitado2}
              onPress={() => {
                this.salvar();
              }}>
              <Text style={styleGlobal.botao_text_abilitado}>{'Salvar'}</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(242,242,242,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  datepicker1: {
    color: 'black',
    alignSelf: 'stretch',
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
  },
  text1: {
    alignSelf: 'auto',
    textAlign: 'center',
    fontWeight: 'normal',
    color: 'rgba(48,129,219,1)',
    flex: 1,
  },
  scroll: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1,
  },
  view4: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: 20,
  },
  text4: {
    textAlign: 'center',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginBottom: 20,
  },
  textinput5: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 30,
  },
});
