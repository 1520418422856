import React, {Component} from 'react';
import moment from 'moment-timezone';
import {Content, Icon, TitleBar} from 'react-native-1app';
import * as Actions from '../../redux/actions';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  FlatList,
} from 'react-native';
import styleGlobal from '../../styleGlobal';
import {setQtdNot} from '../../redux/actions';
import * as Notificacao from '../../worker/notificacao';
import * as Usuario from '../../worker/usuario';
export default class notificacoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listNotificacoes: [],
      load: true,
    };
  }

  componentDidMount() {
    this.buscarDados();
    Notificacao.setLidas(() => {
      setQtdNot();
    });
  }

  buscarDados() {
    Notificacao.getNotificacoes((listNotificacoes = [], error) => {
      if (error) {
        this.setState({load: false});
        return showMessage(
          'Erro na busca de dados verifique sua conexão com internet',
        );
      }
      this.setState({listNotificacoes, load: false});
    });
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  componentWillUnmount() {}

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
            <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
              {'Notificações'}
            </Text>
          </TouchableOpacity>
        </TitleBar>
        <View
          style={[styles.scroll, Actions.getTema('scroll')]}
          keyboardShouldPersistTaps={true}>
          <FlatList
            style={[styles.listview, Actions.getTema('scroll')]}
            ListHeaderComponent={() => {
              if (this.state.listNotificacoes.length == 0)
                return (
                  <View style={styles.view506}>
                    <Text style={styleGlobal.botao_text_desabilitado}>
                      {'Nenhuma notificação'}
                    </Text>
                  </View>
                );
              else return null;
            }}
            data={this.state.listNotificacoes}
            renderItem={({item, index}) => {
              return (
                <Cell
                  item={item}
                  screenProps={this.props.screenProps}
                  activity={this}
                  navigation={this.props.navigation}
                  rowID={index}
                />
              );
            }}
            keyExtractor={(item, index) => index}
          />
        </View>
      </View>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  abrir(item) {
    if (item.data.id_divida) {
      this.props.navigation.navigate('NovaDivida', {
        activity: this,
        oldState: this.state,
        id_divida: item.data.id_divida,
      });
    } else if (item.data.id_usuario_contato) {
      this.props.navigation.navigate('Conversa', {
        activity: {},
        oldState: {},
        id_usuario_contato: item.data.id_usuario_contato,
      });
    }
  }

  getDisabled() {}

  render() {
    return (
      <TouchableOpacity
        style={[styles.Cell, Actions.getTema('scroll')]}
        disabled={
          !this.props.item.data ||
          (!this.props.item.data.id_divida &&
            !this.props.item.data.id_usuario_contato)
        }
        onPress={() => {
          if (
            this.props.item.data &&
            (this.props.item.data.id_divida ||
              this.props.item.data.id_usuario_contato)
          ) {
            this.abrir(this.props.item);
          }
        }}>
        <View style={styles.view131}>
          <Text style={[styles.text81, Actions.getTema('lbl_tituloconfig')]}>
            {this.props.item.title}
          </Text>
          <Text style={[styles.text11, Actions.getTema('textcell3')]}>
            {this.props.item.body}
          </Text>
          <Text
            style={[styles.text11, Actions.getTema('textcell3')]}
            numberOfLines={parseInt('1')}>
            {moment(this.props.item.atualizado).format('DD') +
              ' de ' +
              moment(this.props.item.atualizado).format('MMMM') +
              ' ás ' +
              moment(this.props.item.atualizado).format('HH:mm')}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view506: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 10,
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    flex: 1,
  },
  view50: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  listview: {
    alignSelf: 'stretch',
    // backgroundColor: "rgba(249,249,249,s1)"
  },
  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(249,249,249,1)',
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 2,
  },
  view71: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  view61: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  text81: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 10,
  },
  view72: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  view73: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  text11: {
    textAlign: 'justify',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 10,
  },
});
