// import React from 'react';
import {OpenDialog} from 'react-1app/lib/DialogAlert';

export function openDialog(props) {
  props.action = props.actions.map(action => {
    return {
      ...action,
      onClick: action.onPress,
      label: action.title,
    };
  });

  OpenDialog(props);
}
