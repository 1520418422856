'use strict';
import React, {Component} from 'react';
import {Icon} from 'react-native-1app';
import {Keyboard, Platform} from 'react-native';
import DatePicker2 from 'react-native-1app/lib/DatePicker';
import {
  StyleSheet,
  Animated,
  View,
  Switch,
  ActivityIndicator,
  TouchableOpacity,
  Text,
} from 'react-native';

// var pt =require('moment/locale/pt-br');
import moment from 'moment-timezone';
// moment.locale('pt-br');

import DateTimePicker from 'react-native-modal-datetime-picker';
import * as Actions from '../redux/actions';
class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontSize: new Animated.Value(
        this.validarValue(this.props.value) ? 12 : 15,
      ),
      marginTop: new Animated.Value(
        this.validarValue(this.props.value) ? 0 : 14,
      ),
    };
  }
  getDate(value) {
    if (this.props.time == 'timeOnly') {
      return new Date(
        moment(
          moment().format('DD/MM/YYYY') + ' ' + (value ? value : '00:00'),
          'DD/MM/YYYY HH:mm',
        ).toJSON(),
      );
    } else {
      return new Date(value);
    }
  }
  getDateBr(date) {
    if (!date) {
      return '';
    }
    var br = moment(date).format('DD/MM/YYYY');
    if (this.props.time == 'timeOnly') {
      br = moment(date).format('HH:mm');
    } else if (this.props.time) {
      br = moment(date).format('DD/MM/YYYY HH:mm');
    }
    // alert(br)
    return br;
  }

  showDateTimePicker() {
    this.setState({isDateTimePickerVisible: true});
  }

  hideDateTimePicker() {
    this.setState({isDateTimePickerVisible: false});
  }

  handleDatePicked(date) {
    var value = moment(date).toJSON();
    var br = this.getDateBr(date);
    try {
      if (this.props.time == 'timeOnly') {
        value = br;
      }
      this.setState({value: value, isDateTimePickerVisible: false, br: br});

      if (this.props.onChange) {
        this.props.onChange(value, br);
      }
      if (this.props.onChangeText) {
        this.props.onChange(value, br);
      }
    } catch (e) {
      console.log(e);
      // alert(e)
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    let {fontSize, marginTop} = this.state;

    if (
      this.validarValue(props.value) &&
      !this.validarValue(this.props.value)
    ) {
      Animated.timing(fontSize, {toValue: 12, duration: 300}).start();
      Animated.timing(marginTop, {toValue: 0, duration: 200}).start();
    }
    if (
      !this.validarValue(props.value) &&
      this.validarValue(this.props.value)
    ) {
      Animated.timing(fontSize, {toValue: 15, duration: 300}).start();
      Animated.timing(marginTop, {toValue: 14, duration: 300}).start();
    }
  }
  validarValue(v) {
    return v == 0 || v;
  }
  render() {
    const {
      label = '-',
      value = '',
      time,
      onChange = () => console.log('onChange'),
      style = {},
      disabled,
      disabledClear,
      datePickerModeAndroid = 'calendar',
    } = this.props;
    let {fontSize, marginTop, isDateTimePickerVisible} = this.state;
    // let styleLabel={color:style.color?style.color:styles.value.color,fontSize:style.fontSize?style.fontSize:styles.value.fontSize}
    return (
      <TouchableOpacity
        style={[styles.button, style, Actions.getTema('datepicker')]}
        disabled={disabled}
        onPress={() => {
          Keyboard.dismiss();
          this.showDateTimePicker();
        }}>
        <View style={styles.view}>
          <Animated.Text
            style={[
              styles.label,
              {marginTop, fontSize},
              Actions.getTema('labelfields'),
            ]}>
            {label}
          </Animated.Text>
          <Text style={[styles.value, Actions.getTema('labelfields')]}>
            {this.getDateBr(value)}
          </Text>
        </View>
        {!disabledClear ? (
          <TouchableOpacity
            onPress={() => {
              onChange(null, null);
            }}>
            <Icon icon={'close'} style={{marginTop: 10}} />
          </TouchableOpacity>
        ) : null}
        <DateTimePicker
          date={value ? this.getDate(value) : new Date()}
          is24Hour={true}
          datePickerModeAndroid={datePickerModeAndroid}
          mode={time == 'timeOnly' ? 'time' : time ? 'datetime' : 'date'}
          isVisible={isDateTimePickerVisible}
          onConfirm={date => {
            console.log('datepicker', date);
            this.handleDatePicked(date);
          }}
          onCancel={() => {
            this.hideDateTimePicker();
          }}
        />
      </TouchableOpacity>
    );
  }
}
var styles = StyleSheet.create({
  button: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    flexDirection: 'row',
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 0.8,
    paddingRight: 5,
    marginTop: 5,
  },
  view: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
  },
  value: {
    alignSelf: 'stretch',
    fontWeight: 'normal',
    paddingTop: 2,
  },
  label: {
    alignSelf: 'stretch',
    fontWeight: 'normal',
    fontSize: 12,
    marginBottom: 2,
  },
});
export default Platform.select({web: DatePicker2, default: DatePicker});
