import React, { Component } from "react";

import {
  Icon,
  Progress,
  TextInput,
  TitleBar,


} from "react-native-1app";
import DatePicker from "../../components/DatePicker";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  Switch,
  Alert
} from "react-native";
import * as Actions from "../../redux/actions";
import * as TransacoesWorker from "../../worker/transacao";
import { showMessage } from "react-native-1app/lib/FlashMessage";
import styleGlobal from "../../styleGlobal";
import { Util } from "../../infra";
import moment from "moment-timezone";

export default class NovaTransacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transacao: {
        load: false,
        data_transacao: moment(),
        id_divida: this.propsNav().id_divida,

        flag_tipo: parseInt(this.propsNav().divida.flag_tipo) == 2 ? "E" : "S"
      },
      ajuda: this.props.screenProps.store.getState().ajuda,
      carregar: true,
      aumentar_divida: true,
      diminuir_divida: false,
      atualizar_data: false,
      divida: this.propsNav().divida,
      total: this.propsNav().totais.total,
      pago: this.propsNav().totais.pago,
      id_transacao: this.propsNav().id_transacao
    };
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  getColor(flag, tipo) {
    if (tipo == "pendente") {
      return {
        color: "rgba(255,0,0,1)"
      };
    } else {
      return {
        color: "rgba(48,129,219,1)",
      };
    }
  }
  ajustarCampos() {

    this.state.transacao.val_transacao = this.state.transacao.val_transacao
      ? Util.maskMoneyServicetoRawValue(this.state.transacao.val_transacao)
      : 0;

    this.state.transacao.data_vencimento_divida = moment(this.state.transacao.data_transacao);
    this.state.transacao.data_vencimento_divida.set({ hour: 23, minute: 59, second: 59 });
    this.state.transacao.data_vencimento_divida = this.state.transacao.data_vencimento_divida.toJSON();
  }
  insert() {
    // this.state.transacao.data_transacao = JSON.stringify(moment(this.state.transacao.data_transacao))
    this.ajustarCampos(this.state.transacao);
    // console.log("kothe", JSON.stringify(this.state.transacao));
    // console.log(moment(this.state.transacao.data_transacao));
    // console.log(this.state.transacao.data_transacao);
    // console.log(JSON.stringify(moment(this.state.transacao.data_transacao)));
    // return;
    this.setState({ load: true });

    TransacoesWorker.criar(this.state.transacao, (data, error) => {

      this.setState({ load: false });
      if (error) {
        showMessage({
          message: "Erro salvar",
          description: error.msg,
          type: "warning",
          icon: { icon: "warning", position: "left" },
          duration: 2000
        });
      } else if (data.id) {
        showMessage({
          message: "Transação salva !",
          type: "success",
          duration: 3000
        });
        if (this.props.navigation.state.params.onGoBack)
          this.props.navigation.state.params.onGoBack(); //func

        this.props.navigation.goBack();
      }
    });
  }

  atualizar() {
    this.setState({ load: true });

    this.ajustarCampos();

    TransacoesWorker.atualizar(this.state.transacao, (data, error) => {
      // console.log(data, error);
      this.setState({ load: false });
      if (error) {
        showMessage({
          message: "Erro salvar",
          description: error.msg,
          type: "warning",
          icon: { icon: "warning", position: "left" },
          duration: 2000
        });
      } else {
        showMessage({
          message: "Transação salva!",
          type: "success",
          duration: 3000
        });
        if (this.props.navigation.state.params.onGoBack)
          this.props.navigation.state.params.onGoBack(); //func

        this.props.navigation.goBack();
      }
    });
  }

  salvar() {
    if (this.state.id_transacao) {
      this.atualizar();
    } else {
      this.insert();
    }
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }


  ajustaFlag() {
    if (this.state.divida.flag_tipo == 1) {//se for uma divida para mim,
      if (this.state.transacao.flag_tipo == "E") { // e for uma entrada, qer dizer que diminui a divida
        this.setState({ aumentar_divida: false, diminuir_divida: true });
      }

      if (this.state.transacao.flag_tipo == "S") {// e for uma saida, qer dizer que aumenta a divida
        this.setState({ aumentar_divida: true, diminuir_divida: false });
      }
    } else {//se for uma divida por mim 
      if (this.state.transacao.flag_tipo == "E") { // e for uma entrada, qer dizer que auemnta a divida
        this.setState({ aumentar_divida: true, diminuir_divida: false });
      }

      if (this.state.transacao.flag_tipo == "S") { // e for uma saida, qer dizer que diminui a divida
        this.setState({ aumentar_divida: false, diminuir_divida: true });
      }
    }

  }
  abrir(id) {
    this.setState({ load: true });
    TransacoesWorker.listarid({ id: id }, (data, error) => {
      data.val_transacao = (data.val_transacao.toFixed(2) + "");
      this.setState({ load: false });
      if (data) {
        this.setState({ transacao: data }, (data, error) => {
          this.ajustaFlag();
          this.calcula(this.state.transacao.val_transacao, 'v');
        });
      }
    });
  }

  componentDidMount() {

    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.ajuda != this.state.ajuda)
        this.setState({ ajuda: store.ajuda });
    });

    if (this.state.id_transacao) {
      this.subs = [
        this.props.navigation.addListener("didFocus", payload => {
          if (this.state.carregar) {
            this.abrir(this.propsNav().id_transacao);
          } else {
            this.setState({ carregar: true })
          }

        })
      ];
    }
  }

  calcula(value, flag) {
    var montante_divida = this.state.divida.val_montante;
    if (flag == 'v') {
      var perc = 0;
      var mont = Util.maskMoneyServicetoRawValue(value);

      perc = (mont * 100) / montante_divida;

      this.state.transacao.val_transacao = Util.maskMoneyKotheService(mont);
      this.state.transacao.percentual = Util.maskNumberKotheService(perc.toFixed(2));

    } else {
      var perc = Util.maskNumberServicetoRawValue(value);
      var mont = 0;

      mont = (montante_divida * perc) / 100
      this.state.transacao.val_transacao = Util.maskMoneyKotheService(mont.toFixed(2));
      this.state.transacao.percentual = Util.maskNumberKotheService(perc);

    }

    this.setState({ transacao: this.state.transacao });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
    if (this.subs) this.subs.forEach(sub => sub.remove());
  }

  escolherData() {
    this.setState({ carregar: false })
    this.props.navigation.navigate("EscolherTransacao", {
      activity: this,
      oldState: this.state,
      onGoBack: state => {
        this.state.transacao.data_transacao = state.data_vencimento.toJSON();


        this.setState({ divida: this.state.divida, transacao: this.state.transacao }, (data, error) => {
          console.log("apos picjk", this.state);
        });
      }
    });
  }


  limparTransacao() {
    this.setState({
      transacao: {
        load: false,
        data_transacao: moment(),
        id_divida: this.propsNav().id_divida,

        flag_tipo: parseInt(this.propsNav().divida.flag_tipo) == 2 ? "E" : "S"
      },
      ajuda: this.props.screenProps.store.getState().ajuda,
      carregar: true,
      aumentar_divida: true,
      atualizar_data: false,
      diminuir_divida: false,
      divida: this.propsNav().divida,
      total: this.propsNav().totais.total,
      pago: this.propsNav().totais.pago,
      id_transacao: this.propsNav().id_transacao
    });

  }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema("titlebar")]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}
          >
            <Icon
              style={[styles.icon, Actions.getTema("iconstitlebar")]}
              fromFontFamily={"Material Design Icons"}
              name={"arrow-left"}
            />
            <Text style={[styles.text2, Actions.getTema("iconstitlebar")]} >
              {this.state.id_transacao ? "Editar Transação" : "Nova Transação"}
            </Text>

            {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
          </TouchableOpacity>
          {!this.state.transacao.id &&
            <TouchableOpacity
              style={[styles.button21]}
              onPress={() => {
                this.limparTransacao();
              }}
            >
              <Icon
                style={[styles.icon41, Actions.getTema("iconstitlebar"), Actions.getTema("footericones_ativo"),]}
                fromFontFamily={"Material Design Icons"}
                name={"close-circle"}
              />
            </TouchableOpacity>
          }
          <View style={styles.view} />
          {!this.state.load ? (
            <TouchableOpacity
              style={[styles.button3]}
              onPress={() => {
                this.salvar();
              }}
            >
              <Icon
                style={[styles.icon8, Actions.getTema("iconstitlebar")]}
                fromFontFamily={"Material Icons"}
                name={"save"}
              />
            </TouchableOpacity>
          ) : null}
        </TitleBar>
        <ScrollView style={[styles.scroll, Actions.getTema("scroll")]} keyboardShouldPersistTaps={true}>
          <View style={styles.view7}>
            <TouchableOpacity
              style={styles.button9}
              onPress={() => {
                let value = "";
                value = parseInt(this.state.divida.flag_tipo) == 2 ? "E" : "S";

                this.state.transacao.flag_tipo = value;
                this.setState({ transacao: this.state.transacao, aumentar_divida: true, diminuir_divida: false });
              }}
              disabled={this.state.transacao.id}
            >
              <Icon
                style={[styles.icon7, Actions.getTema("iconstitlebar")]}
                fromFontFamily={"Material Design Icons"}
                name={
                  this.state.aumentar_divida
                    ? "check-circle"
                    : "checkbox-blank-circle-outline"
                }
              />
              <View style={styles.view611}>
                <Text style={[styles.text6111, Actions.getTema("labelfields")]}>{"Aumentar a Dívida"}</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button8}
              onPress={() => {
                let value = "";
                value = parseInt(this.state.divida.flag_tipo) == 2 ? "S" : "E";
                this.state.transacao.flag_tipo = value;
                this.setState({ transacao: this.state.transacao, aumentar_divida: false, diminuir_divida: true });
              }}
              disabled={this.state.transacao.id}
            >
              <Icon
                style={[styles.icon7, Actions.getTema("iconstitlebar")]}
                fromFontFamily={"Material Design Icons"}
                name={
                  this.state.diminuir_divida
                    ? "check-circle"
                    : "checkbox-blank-circle-outline"
                }
              />
              <View style={styles.view62}>
                <Text style={[styles.text612, Actions.getTema("labelfields")]}>{"Diminuir a Dívida"}</Text>
              </View>
            </TouchableOpacity>
            {this.state.ajuda &&
              <TouchableOpacity
                style={styles.buttonhelp}
                onPress={() => {
                  Alert.alert(
                    "Ajuda",
                    "Aumente ou diminua o valor da dívida",
                    [{ text: "Ok", onPress: () => { }, style: "cancel" },],
                  );
                }}
              >
                <Icon
                  style={[styles.icon399, Actions.getTema("iconstitlebar")]}
                  fromFontFamily={"Material Design Icons"}
                  name={"help-circle"}
                />
              </TouchableOpacity>
            }
          </View>

          <TextInput
            style={[styles.textinput11, Actions.getTema("labelfields")]}
            value={this.state.transacao.val_transacao}
            onChange={value => {
              value = Util.maskMoneyKotheService(value);
              this.calcula(value, "v");
              // this.state.transacao.val_transacao = Util.maskMoneyKotheService(value);
              // this.setState({ transacao: this.state.transacao });
            }}
            contextMenuHidden={true}
            keyboardType={"numeric"}
            label={"Montante em valor (obrigatório)"}
          />
          <View style={styles.view22}>
            <TextInput
              style={[styles.textinput11, Actions.getTema("labelfields")]}
              value={this.state.transacao.percentual}
              onChange={value => {
                value = Util.maskNumberKotheService(value);
                this.calcula(value, "p");

              }}
              contextMenuHidden={true}
              keyboardType={"numeric"}
              label={"Montante em % da dívida (obrigatório)"}
            />
            {this.state.ajuda &&
              <TouchableOpacity
                style={styles.buttonhelp}
                onPress={() => {
                  Alert.alert(
                    "Ajuda",
                    "Coloque o valor no montante ou a porcentagem que você deseja aumentar ou diminuir a dívida",
                    [{ text: "Ok", onPress: () => { }, style: "cancel" },],
                  );
                }}
              >
                <Icon
                  style={[styles.icon399, Actions.getTema("iconstitlebar")]}
                  fromFontFamily={"Material Design Icons"}
                  name={"help-circle"}
                />
              </TouchableOpacity>
            }
          </View>
          <View style={styles.view22}>
            <TextInput
              style={[styles.textinput1, Actions.getTema("labelfields")]}
              value={this.state.transacao.descricao}
              onChange={value => {
                this.state.transacao.descricao = value;
                this.setState({ transacao: this.state.transacao });
              }}
              maxLength={100}
              keyboardType={"default"}
              label={"Descrição "}
            />
            {this.state.ajuda &&
              <TouchableOpacity
                style={styles.buttonhelp}
                onPress={() => {
                  Alert.alert(
                    "Ajuda",
                    "Coloque uma breve descrição para descrever porque está criando uma nova transação",
                    [{ text: "Ok", onPress: () => { }, style: "cancel" },],
                  );
                }}
              >
                <Icon
                  style={[styles.icon399, Actions.getTema("iconstitlebar")]}
                  fromFontFamily={"Material Design Icons"}
                  name={"help-circle"}
                />
              </TouchableOpacity>
            }
          </View>

          <View style={styles.view22}>
            <DatePicker
              style={[styles.datepicker1, Actions.getTema("labelfields")]}
              value={this.state.transacao.data_transacao}
              onChange={value => {
                this.state.transacao.data_transacao = value;
                this.setState({ transacao: this.state.transacao });
              }}
              disabledClear={true}
              datePickerModeAndroid={"calendar"}
              label={"Data"}
            />

            <TouchableOpacity
              style={styles.button9999}
              onPress={() => {
                this.escolherData();
              }}
            >
              <Icon
                style={[styles.icon50, Actions.getTema("labelfields")]}
                fromFontFamily={"Material Design Icons"}
                name={"chevron-down"}
              />
            </TouchableOpacity>
            {this.state.ajuda &&
              <TouchableOpacity
                style={styles.buttonhelp}
                onPress={() => {
                  Alert.alert(
                    "Ajuda",
                    "Coloque a data que foi feita a transação",
                    [{ text: "Ok", onPress: () => { }, style: "cancel" },],
                  );
                }}
              >
                <Icon
                  style={[styles.icon399, Actions.getTema("iconstitlebar")]}
                  fromFontFamily={"Material Design Icons"}
                  name={"help-circle"}
                />
              </TouchableOpacity>
            }
          </View>

          {/* {this.state.transacao.id ? null : */}
          <View style={styles.view5}>
            {/* <Switch style={styles.switch} onTintColor={Actions.getTema("swtichtint")} thumbTintColor={Actions.getTema("swtichtint2")}
              value={this.state.transacao.atualizar_data}
              onValueChange={value => {
                this.state.transacao.atualizar_data = value;
                this.setState({ transacao: this.state.transacao });
              }} /> */}





            <TouchableOpacity
              style={styles.button9}
              onPress={() => {
                this.state.transacao.atualizar_data = !this.state.transacao.atualizar_data;
                this.setState({ transacao: this.state.transacao });
              }}
            >
              <Icon
                style={[styles.icon7, Actions.getTema("iconstitlebar")]}
                fromFontFamily={"Material Design Icons"}
                name={
                  this.state.transacao.atualizar_data
                    ? "check-circle"
                    : "checkbox-blank-circle-outline"
                }
              />
              <View style={styles.view611}>
                <Text style={[styles.text6111, Actions.getTema("labelfields")]}>  {"Atualizar a data de vencimento da dívida"}</Text>
              </View>
            </TouchableOpacity>

            {this.state.ajuda &&
              <TouchableOpacity
                style={styles.buttonhelp2}
                onPress={() => {
                  Alert.alert(
                    "Ajuda",
                    "O vencimento da dívida será alterado pela data da transação",
                    [{ text: "Ok", onPress: () => { }, style: "cancel" },],
                  );
                }}
              >
                <Icon
                  style={[styles.icon399, Actions.getTema("iconstitlebar")]}
                  fromFontFamily={"Material Design Icons"}
                  name={"help-circle"}
                />
              </TouchableOpacity>
            }
          </View>
          {/* } */}


        </ScrollView>

        <View style={[styles.footer, Actions.getTema("titlebar")]} >
          <View style={styles.view11}>
            {this.state.divida.flag_status != "P" ? (
              <View style={styles.view99up}>
                <View style={styles.view99}>
                  <Text style={[styles.text82, Actions.getTema("labelfields")]}>{"Pago:"}</Text>
                  <Text
                    style={[
                      styles.text92,
                      { ...this.getColor(this.propsNav().divida.flag_tipo, "pago") }
                    ]}
                  >
                    {"R$ " + this.state.pago}
                  </Text>
                </View>
                <View style={styles.view99}>
                  <Text style={[styles.text82, Actions.getTema("labelfields")]}>{"Pendente:"}</Text>
                  <Text
                    style={[
                      styles.text92,
                      { ...this.getColor(this.propsNav().divida.flag_tipo, "pendente") }
                    ]}
                  >
                    {"R$ " + this.state.total}
                  </Text>
                </View>

              </View>
            ) : null}

          </View>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  view22: {
    flexDirection: "row",
    
  },
  datepicker1: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    marginTop: 5,
    marginBottom: 5,
    flex: 1
  },
  view8: {
    
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 7
  },
  switch: {},
  button1: {
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "rgba(44,126,218,1)",
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1
  },
  icon41: {
    fontSize: 25,
    marginBottom: 1
  },
  button3: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    width: 50
  },
  icon50: {
    color: "rgba(0,0,0,1)",
    fontSize: 25,
  },
  icon: {
    color: "rgba(48,129,219,1)",
    fontSize: 25,
    marginRight: 5
  },
  text2: {
    textAlign: "left",
    color: "rgba(48,129,219,1)",
    alignSelf: "auto",
    fontWeight: "normal",
    flex: 1
  },
  view: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  scroll: {
    alignSelf: "stretch",
    flexDirection: "column",
    backgroundColor: "rgba(252,252,252,1)",
    flex: 1,
    padding: 20,
    paddingTop: 10
  },
  button9999: {
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "rgba(44,126,218,1)",
    height: 50,
    padding: 10,
    
  },
  view7: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  button9: {
    alignSelf: "stretch",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    flex: 1
  },
  icon8: {
    color: "rgba(48,129,219,1)",
    fontSize: 25
  },
  view611: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column"
  },
  text6111: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal",
    marginLeft: 3
  },
  button8: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
    flex: 1,
    borderColor: null
  },
  icon7: {
    color: "rgba(48,129,219,1)",
    fontSize: 25
  },
  view62: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column"
  },
  text612: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal",
    marginLeft: 3
  },
  textinput11: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    flex: 1
  },
  textinput1: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    flex: 1
  },

  view5: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 15
  },
  text3: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal",
    marginLeft: 5,
    flex: 1
  },
  buttonhelp: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    
  },
  buttonhelp2: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    
    paddingBottom: 5
  },
  viewnot3: {
    alignSelf: "auto",
    flexDirection: "column",
    // alignItems: "flex-start",
    // justifyContent: "flex-start",
    flexWrap: "nowrap",
    minWidth: 20,
    minHeight: 20,
    backgroundColor: "rgba(48,129,219,1)",
    borderRadius: 50,
    maxWidth: 50,
    maxHeight: 50,

  },
  view99: {
    alignSelf: "auto",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
    padding: 2
  },
  text82: {
    textAlign: "right",
    color: "rgba(0,0,0,1)",
    alignSelf: "center",
    fontWeight: "bold",
    marginLeft: 10,
    fontSize: 16,
    marginRight: 5
  },
  text92: {
    textAlign: "right",
    color: "rgba(48,129,219,1)",
    alignSelf: "center",
    fontWeight: "bold",
    marginRight: 5
  },
  icon3: {
    // color: "rgba(48,129,219,1)",
    color: "rgba(255,255,255,1)",
    fontSize: 45
  },
  view99up: {
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: 2
  },
  view11: {
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    flex: 1
  },
  button21: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    width: 50
  },
  footer: {
    alignSelf: "stretch",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "row",
  },
});
