import React, {Component} from 'react';
import AlphaScrollFlatList from 'alpha-scroll-flat-list';
import {Icon, Image, TextInput, TitleBar, Progress} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  Alert,
  Dimensions,
  FlatList,
} from 'react-native';
import styleGlobal from '../../styleGlobal';
import {Util} from '../../infra';
import {requestContatosPermission} from '../../redux/actions';
import {atualizarPrimeiraVez, enviarContatosTeste} from '../../worker/usuario';
import Contacts from 'react-native-contacts';
import * as Chat from '../../worker/chat';
import * as actions from '../../redux/actions';
const ITEM_HEIGHT = 50;
const WIDTH = Dimensions.get('window').width;
export default class PrimeiraVez extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.screenProps.store.getState().user,
      load: true,
      lista: [],
      nome: '',
      listamigos: [],
    };
    this.state.alunos = [];
  }

  getContatos(callback) {
    Contacts.getAll((err, contacts) => {
      if (err) return console.log(err);
      var lista = [];
      for (let i = 0; i < contacts.length; i++) {
        let item = contacts[i];
        lista.push({
          foto: item.thumbnailPath,
          contatos: true,
          id: i,
          nome:
            item.givenName +
            '' +
            (item.familyName ? ' ' + item.familyName : ''),
          email_contato: item.emailAddresses[0]
            ? item.emailAddresses[0].email
            : '',
          email: item.emailAddresses[0] ? item.emailAddresses[0].email : '',
          telefone: item.phoneNumbers[0] ? item.phoneNumbers[0].number : '',
        });
      }

      lista.sort(function(a, b) {
        var alc = a.nome.toLowerCase(),
          blc = b.nome.toLowerCase();
        return alc > blc ? 1 : alc < blc ? -1 : 0;
      });

      if (callback) return callback(lista, null);
      // this.setState({ alunos: lista, lista: lista, load: false });
    });
  }

  testarContatos() {
    requestContatosPermission(() => {
      this.getContatos((contatos, error) => {
        let telefonesarray = [];
        for (let index = 0; index < contatos.length; index++) {
          const element = contatos[index];
          telefonesarray.push(element.telefone);
        }
        enviarContatosTeste(telefonesarray, (lista, error) => {
          if (lista.length > 0) this.setState({listamigos: lista, load: false});
          else this.continuar();
        });
      });
    });
  }

  continuar() {
    this.setState({load: true});
    let amigos = this.state.listamigos;
    let telefones = [];
    for (let index = 0; index < amigos.length; index++) {
      let item = amigos[index];
      if (item.marcado) telefones.push(item.telefone);
    }

    Chat.salvarContatoMultiplo({telefones: telefones}, (data, error) => {
      atualizarPrimeiraVez((data, error) => {
        let user = JSON.parse(JSON.stringify(this.state.user));
        user.flag_primeira_vez = false;
        this.props.screenProps.store.dispatch({
          type: 'LOGIN',
          user: user,
        });
        this.setState({load: false});
      });
    });
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  removeFromarray(telefone) {
    var array = JSON.parse(JSON.stringify(this.state.listamigos));
    for (let index = 0; index < array.length; index++) {
      if (array[index].telefone == telefone) {
        array.splice(index, 1);
      }
    }

    if (array.length == 0) {
      this.continuar();
    }
    this.setState({listamigos: array, load: false});
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user) this.setState({user: store.user});
    });

    actions.contatosPermission((data, error) => {
      if (data) {
        this.testarContatos();
      } else {
        this.continuar();
      }
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={styleGlobal.titlebar}>
          <Text style={styles.text2}>{'Contatos no Casher'}</Text>
        </TitleBar>

        <ScrollView style={styles.scroll}>
          {this.state.listamigos && this.state.listamigos.length == 0 ? (
            <View style={styles.view506}>
              <Text style={styleGlobal.botao_text_desabilitado}>
                {'Nenhum contato encontrado'}
              </Text>
            </View>
          ) : null}

          {this.state.listamigos && this.state.listamigos.length > 0 ? (
            <View style={styles.view506}>
              <Text style={styleGlobal.botao_text_desabilitado}>
                {'Contatos encontrados no Casher'}
              </Text>
            </View>
          ) : null}
          {this.state.listamigos && this.state.listamigos.length > 0 ? (
            <View style={styles.view506}>
              <Text style={styleGlobal.botao_text_desabilitado}>
                {'Marque os contatos que deseja e clique em continuar'}
              </Text>
            </View>
          ) : null}

          <View style={styleGlobal.viewprogress}>
            {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
          </View>
          <FlatList
            style={styles.listview}
            data={this.state.listamigos}
            renderItem={({item, index}) => {
              return (
                <Cell
                  item={item}
                  screenProps={this.props.screenProps}
                  activity={this}
                  navigation={this.props.navigation}
                  rowID={index}
                />
              );
            }}
            keyExtractor={(item, index) => index}
          />
          <View style={[styleGlobal.viewprogress, {marginTop: 30}]}>
            <TouchableOpacity
              style={styles.button402}
              onPress={() => {
                this.continuar();
              }}>
              <Text style={styles.text33}>{'Continuar'}</Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
    this.state = {item: this.props.item};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <TouchableOpacity
        style={styles.cell2}
        onPress={() => {
          this.props.item.marcado = !this.props.item.marcado;
          this.setState({
            item: JSON.parse(JSON.stringify(this.props.item.marcado)),
          });
        }}
        screenProps={this.props.screenProps}
        navigation={this.props.navigation}
        disabled={this.props.activity.state.load}
        activity={this}>
        <View style={styles.view5}>
          <Image source={{uri: this.props.item.foto}} style={styles.image} />
        </View>
        <View style={styles.view131}>
          <View style={styles.view611}>
            <Text style={styles.text811}>{this.props.item.nome}</Text>
          </View>
          <View style={styles.view6111}>
            <Icon
              style={styles.icon7}
              fromFontFamily={'Material Design Icons'}
              name={
                this.props.item.marcado
                  ? 'check-circle'
                  : 'checkbox-blank-circle-outline'
              }
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 2,
  },
  view506: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 10,
  },
  button402: {
    flex: 1,
    maxWidth: 300,
    height: 50,
    backgroundColor: 'rgba(48,129,219,1)',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  },
  text811: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'auto',
    marginLeft: 10,
    fontWeight: 'bold',
  },
  view611: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  view6111: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },

  text33: {
    color: 'rgba(255,255,255,1)',
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(249,249,249,1)',
  },
  cell2: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  view5: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginRight: 10,
    borderRadius: 5,
    
  },
  image: {
    width: 50,
    height: 50,
  },
  listview: {
    alignSelf: 'stretch',
    flex: 1,
  },
});
