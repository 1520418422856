import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {Content, Icon, Progress, TitleBar} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  Alert,
} from 'react-native';
import styleGlobal from '../../styleGlobal';
import moment from 'moment-timezone';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import DatePicker from '../../components/DatePicker';
export default class EscolherData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_ini: '',
      data_fim: '',
      load: false,
      tipo_data: 'data_vencimento',
      manual: false,
      ajuda: this.props.screenProps.store.getState().ajuda,
    };
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  pressedOk(texto) {
    if (!this.state.tipo_data) {
      showMessage({
        message: 'Você deve escolher um tipo de data;',
        type: 'warning',
        icon: {icon: 'warning', position: 'left'},
        duration: 2000,
      });
    } else {
      if (this.state.data_ini && !this.state.data_fim) {
        showMessage({
          message: 'Você deve preencher ambas as datas;',
          type: 'warning',
          icon: {icon: 'warning', position: 'left'},
          duration: 2000,
        });
      } else if (!this.state.data_ini && this.state.data_fim) {
        showMessage({
          message: 'Você deve preencher ambas as datas;',
          type: 'warning',
          icon: {icon: 'warning', position: 'left'},
          duration: 2000,
        });
      } else {
        var desc = '';

        if (this.state.tipo_data == 'data_debito' && this.state.data_ini) {
          if (this.state.manual) {
            desc = desc + 'Débito - Data Manual';
          } else {
            desc = desc + 'Débito - ' + texto;
          }
        } else if (
          this.state.tipo_data == 'data_vencimento' &&
          this.state.data_ini
        ) {
          if (this.state.manual) {
            desc = desc + 'Vencimento - Data Manual';
          } else {
            desc = desc + 'Vencimento - ' + texto;
          }
        } else {
          desc = desc + 'Todos';
        }

        // return console.log(
        //   moment(this.state.data_ini).format('DD/MM/YYYY - HH:mm'),
        //   moment(this.state.data_fim).format('DD/MM/YYYY - HH:mm'),
        // );

        this.props.screenProps.store.dispatch({
          type: 'SET_FILTROS',
          data_ini: this.state.data_ini,
          data_fim: this.state.data_fim,
          tipo_data: this.state.tipo_data,
          desc: desc,
        });

        this.backPageButton();
      }
    }
  }

  thisSetDays(dias, operator) {
    let data_fim = '';
    let data_ini = '';
    dias = dias > 0 ? dias - 1 : dias;

    if (operator == '+') {
      data_fim = moment().add(dias, 'd');
      data_ini = moment();
    } else {
      data_fim = moment();
      data_ini = moment().subtract(dias, 'd');
    }

    data_ini.set({hour: 0, minute: 0, second: 0});
    data_fim.set({hour: 23, minute: 59, second: 59});
    /*  this.setState({
        data_fim: data_fim,
        data_ini: data_ini,
        dias: dias,
        manual: false
      });
      */

    this.state.data_fim = data_fim.format('YYYY-MM-DD HH:mm:ss');
    this.state.data_ini = data_ini.format('YYYY-MM-DD HH:mm:ss');
    this.state.dias = dias;
    this.state.manual = false;
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user) this.setState({user: store.user});
      if (store.data_ini != this.state.data_ini)
        this.setState({data_ini: store.data_ini});
      if (store.data_fim != this.state.data_fim)
        this.setState({data_fim: store.data_fim});
      if (store.tipo_data != this.state.tipo_data)
        this.setState({tipo_data: store.tipo_data});
      if (store.ajuda != this.state.ajuda) this.setState({ajuda: store.ajuda});
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
            <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
              {'Escolher Data'}
            </Text>
          </TouchableOpacity>
          <View style={styles.view} />

          {this.state.ajuda && (
            <TouchableOpacity
              style={styles.button3}
              onPress={() => {
                Alert.alert(
                  'Ajuda',
                  'Selecione a data que você quer que apareça na sua tela inicial.',
                  [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                );
              }}>
              <Icon
                style={[styles.icon3, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'help-circle'}
              />
            </TouchableOpacity>
          )}

          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
        </TitleBar>
        <ScrollView style={[styles.scroll, Actions.getTema('scroll')]}>
          <View style={styles.view7}>
            <TouchableOpacity
              style={styles.button9}
              onPress={() => {
                this.setState({tipo_data: 'data_vencimento'});
              }}>
              <Icon
                style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={
                  this.state.tipo_data == 'data_vencimento'
                    ? 'check-circle'
                    : 'checkbox-blank-circle-outline'
                }
              />
              <View style={styles.view61}>
                <Text style={[styles.text611, Actions.getTema('labelfields')]}>
                  {'Data Vencimento'}
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button8}
              onPress={() => {
                this.setState({tipo_data: 'data_debito'});
              }}>
              <Icon
                style={[styles.icon7, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={
                  this.state.tipo_data == 'data_debito'
                    ? 'check-circle'
                    : 'checkbox-blank-circle-outline'
                }
              />
              <View style={styles.view6}>
                <Text style={[styles.text611, Actions.getTema('labelfields')]}>
                  {'Data Débito'}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
          <View style={styles.view50}>
            <View style={styles.view3} />
          </View>
          <View style={styles.view8}>
            <TouchableOpacity
              style={styles.button41}
              onPress={() => {
                // this.setState({ data_fim: null, data_ini: null });
                this.state.data_fim = '';
                this.state.data_ini = '';
                this.pressedOk('Todos');
              }}>
              <Text style={styles.text311}>{'Todos'}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button424}
              onPress={() => {
                this.thisSetDays(20);
                this.pressedOk('Últimos 20 dias');
              }}>
              <Text style={styles.text3124}>{'Últimos 20 dias'}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.view9}>
            <TouchableOpacity
              style={styles.button411}
              onPress={() => {
                this.thisSetDays(7);
                this.pressedOk('Últimos 7 dias');
              }}>
              <Text style={styles.text3111}>{'Últimos 7 dias'}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button4241}
              onPress={() => {
                this.thisSetDays(30);
                this.pressedOk('Últimos 30 dias');
              }}>
              <Text style={styles.text31241}>{'Últimos 30 dias'}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.view10}>
            <TouchableOpacity
              style={styles.button4111}
              onPress={() => {
                this.thisSetDays(10);
                this.pressedOk('Últimos 10 dias');
              }}>
              <Text style={styles.text31111}>{'Últimos 10 dias'}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button42411}
              onPress={() => {
                this.state.data_fim = moment()
                  .set({hour: 23, minute: 59, second: 59})
                  .format('YYYY-MM-DD HH:mm:ss');
                this.state.data_ini = moment()
                  .set({hour: 0, minute: 0, second: 0})
                  .format('YYYY-MM-DD HH:mm:ss');
                this.pressedOk('Hoje');
              }}>
              <Text style={styles.text312411}>{'Hoje'}</Text>
            </TouchableOpacity>
          </View>

          <View style={styles.view10}>
            <TouchableOpacity
              style={styles.button4111}
              onPress={() => {
                this.thisSetDays(7, '+');
                this.pressedOk('Próximos 7 dias');
              }}>
              <Text style={styles.text31111}>{'Próximos 7 dias'}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button42411}
              onPress={() => {
                this.thisSetDays(10, '+');
                this.pressedOk('Próximos 10 dias');
              }}>
              <Text style={styles.text312411}>{'Próximos 10 dias'}</Text>
            </TouchableOpacity>
          </View>

          <View style={styles.view10}>
            <TouchableOpacity
              style={styles.button4111}
              onPress={() => {
                this.thisSetDays(20, '+');
                this.pressedOk('Próximos 20 dias');
              }}>
              <Text style={styles.text31111}>{'Próximos 20 dias'}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button42411}
              onPress={() => {
                this.thisSetDays(30, '+');
                this.pressedOk('Próximos 30 dias');
              }}>
              <Text style={styles.text312411}>{'Próximos 30 dias'}</Text>
            </TouchableOpacity>
          </View>

          <DatePicker
            style={[styles.datepicker, Actions.getTema('labelfields')]}
            value={this.state.data_ini}
            onChange={value => {
              this.setState({data_ini: value, manual: true});
            }}
            disabledClear={true}
            datePickerModeAndroid={'calendar'}
            label={'Data inicial'}
          />
          <DatePicker
            style={[styles.datepicker, Actions.getTema('labelfields')]}
            value={this.state.data_fim}
            onChange={value => {
              this.setState({data_fim: value, manual: true});
              this.pressedOk();
            }}
            disabledClear={true}
            datePickerModeAndroid={'calendar'}
            label={'Data final'}
          />
          <View style={styleGlobal.viewfantasma} />
        </ScrollView>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },
  view: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(252,252,252,1)',
    flex: 1,
    padding: 20,
    paddingTop: 10,
  },
  button3: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  view7: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  button9: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    flexDirection: 'row',
    flex: 1,
  },
  icon8: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  view61: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text611: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 5,
  },
  button8: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'row',
    flex: 1,
    borderColor: null,
  },
  icon7: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  view6: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  view50: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view3: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view8: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  button41: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    backgroundColor: 'rgba(44,126,218,1)',
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginRight: 5,
  },
  text311: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  button424: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    backgroundColor: 'rgba(44,126,218,1)',
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginLeft: 5,
  },
  text3124: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  view9: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  button411: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    backgroundColor: 'rgba(44,126,218,1)',
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginRight: 5,
  },
  text3111: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  button4241: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    backgroundColor: 'rgba(44,126,218,1)',
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginLeft: 5,
  },
  text31241: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  view10: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  button4111: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    backgroundColor: 'rgba(44,126,218,1)',
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginRight: 5,
  },
  text31111: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  button42411: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    backgroundColor: 'rgba(44,126,218,1)',
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginLeft: 5,
  },
  text312411: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  datepicker: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    marginTop: 20,
    marginBottom: 5,
  },
});
