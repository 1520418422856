import App from './src/index.init';

import {AppRegistry, Platform} from 'react-native';

AppRegistry.registerComponent('projeto', () => App);

if (Platform.OS === 'web') {
  const rootTag = document.getElementById('root');
  rootTag.innerHTML = '';
  AppRegistry.runApplication('projeto', {rootTag});
}
