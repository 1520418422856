import * as types from './actionTypes';

export default function app(state = stateINIT, action = {}) {
  // console.log("aeeeeeeeeeeeeeeeeeeeeeeeeeeee ",state, action);
  switch (action.type) {
    case types.LOGOUT:
      return Object.assign(state, {user: null, tema: 'white'});

    case types.INIT:
      return Object.assign(state, {...action});

    case types.LOGIN:
      return Object.assign(state, {user: action.user});

    case types.SET_SOBRE:
      return Object.assign(state, state, {sobre: action.dados});

    case types.SET_LASTEXIT:
      return Object.assign(state, state, {lastexit: action.lastexit});

    case types.SET_PIN:
      return Object.assign(state, state, {pin: action.pin});
    case types.TELAINI:
      return Object.assign(state, state, {telaini: action.telaini});
    case types.SET_TEMPOPIN:
      return Object.assign(state, state, {tempopin: action.tempopin});

    case types.SET_RECEMATIVOU:
      return Object.assign(state, state, {recemativou: action.recemativou});

    case types.SET_QTDMSGS:
      return Object.assign(state, state, {qtdmsgs: action.qtdmsgs});
    case types.SET_CHATATIVO:
      return Object.assign(state, state, {chatativo: action.chatativo});
    case 'SET_LISTAMSGTELA':
      return Object.assign(state, state, {listamsgativa: action.listamsgativa});
    case 'AJUDA':
      return Object.assign(state, state, {ajuda: action.ajuda});
    case types.SET_ACEITASQTD:
      return Object.assign(state, state, {aceitasqtd: action.aceitasqtd});
    case types.TEMA:
      return Object.assign(state, state, {tema: action.tema});
    case 'FAZERPESQ':
      return Object.assign(state, state, {
        fazer_pesquisa: action.fazer_pesquisa,
      });
    case 'FAZERPESQ2':
      return Object.assign(state, state, {
        fazer_pesquisa2: action.fazer_pesquisa2,
      });
    case 'SET_INDEX':
      return Object.assign(state, state, {
        index: action.index,
      });
    case types.SET_QTDNOT:
      return Object.assign(state, state, {qtdnot: action.qtdnot});
    case 'MOSTRARVALHEADER':
      return Object.assign(state, state, {
        mostrarvalheader: action.mostrarvalheader,
      });

    case types.TROCA_PAG:
      return Object.assign(state, state, {tabdash: action.tabdash});
    case types.SHOWPREINFOS:
      return Object.assign(state, state, {
        showpreinfos: action.showpreinfos,
        preinfos_id_usuario: action.preinfos_id_usuario,
        preinfos_boolcontatoproprio: action.preinfos_boolcontatoproprio,
      });

    case types.SET_FILTROS:
      return Object.assign(state, state, {
        data_ini: action.data_ini,
        data_fim: action.data_fim,
        tipo_data: action.tipo_data,
        desc_datas: action.desc,
      });

    case types.SET_NOMEPESSOA:
      return Object.assign(state, state, {
        nome_pessoa: action.nome_pessoa,
        id_usuario_divida: action.id_usuario_divida,
        id_contato_proprio_divida: action.id_contato_proprio_divida,
      });

    default:
      console.log('not type .......................' + action.type);
      delete action.type;
      return Object.assign(state, action);
  }
}
const stateINIT = {
  user: null,
  listBuscaAnterios: [],
  sobre: {},
  pin: '',
  tempopin: 5,
  tema: 'white',
  recemativou: false,
  desc_datas: 'Todos',
  nome_pessoa: '',
  ajuda: true,
  telaini: 0,
  chatativo: false,
  tabdash: 0,
  showpreinfos: false,
  preinfos_id_usuario: 0,
  data_fim: '',
  data_init: '',
  mostrarvalheader: true,
  tipo_data: 'data_debito',
  preinfos_boolcontatoproprio: false,
  index: 0,
};
