import React, { Component } from "react";
import * as Actions from "../../redux/actions";
import {
  Content,
  Icon,
  Image,
  Progress,
  TextInput,
  ImageUpload,
  TitleBar
} from "react-native-1app";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  Alert,
  Dimensions,
  Image as img,
} from "react-native";
import styleGlobal from "../../styleGlobal";
import * as Usuario from "../../worker/usuario";
import DatePicker from "../../components/DatePicker";
import { Util } from "../../infra";
import moment from "moment-timezone";
export default class FotoUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      usuario: this.propsNav().usuario,
    };
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  backPageButton(page) {
    if (this.props.navigation.state.params.onGoBack)
      this.props.navigation.state.params.onGoBack(page);
    this.props.navigation.goBack();
  }



  abrir() {
    let obj = { id: this.state.id_usuario, flag_eh_contato: this.state.boolcontato }
    this.setState({ load: true })
    Usuario.getUsuarioInfos(obj, (data, error) => {
      if (error) {
        Alert.alert("Erro", error.msg, [{ text: "Ok", onPress: () => { } }], { cancelable: false });
      } else {
        this.setState({ usuario: data })
      }

      this.setState({ load: false })
    })
  }

  componentDidMount() {
    
    var foto = this.state.usuario.foto_large ?  this.state.usuario.foto_large : Usuario. returnFotoGen();
    img.getSize(foto, (srcWidth, srcHeight) => {
      const maxHeight = Dimensions.get('window').height; // or something else
      const maxWidth = Dimensions.get('window').width;

      const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
      this.setState({ width: srcWidth * ratio, height: srcHeight * ratio });
    }, error => {
      console.log('error:', error);
    });
  }

  componentWillUnmount() {
  }




  render() {
    var foto = this.state.usuario.foto_large ?  this.state.usuario.foto_large : Usuario. returnFotoGen();
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema("titlebar")]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}
          >
            <Icon
              style={[styles.icon, Actions.getTema("iconstitlebar")]}
              fromFontFamily={"Material Design Icons"}
              name={"arrow-left"}
            />
            <Text style={[styles.text2, Actions.getTema("iconstitlebar")]}>
              {this.state.usuario.nome}
            </Text>
          </TouchableOpacity>

        </TitleBar>
          <View style={[styles.view3, Actions.getTema("scroll") ]}>
            <Image
              style={[styles.image,{ width: this.state.width, height: this.state.height }]}
              source={{ uri: foto }}
              resizeMode={"cover"}
            />
          </View>
      </View >
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
  button1: {
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "rgba(44,126,218,1)",
    height: 50,
    paddingLeft: 10,
    flex: 1
  }, 
  view3: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flex:1
  },
  icon: {
    color: "rgba(48,129,219,1)",
    fontSize: 25,
    marginRight: 5
  },
  image: {
    // width: "100%",
    // height: "100%",
    // alignSelf: "stretch",
    alignItems: "center",
    // flex:1

  },
  text2: {
    textAlign: "left",
    color: "rgba(48,129,219,1)",
    alignSelf: "auto",
    fontWeight: "normal"
  },
});
