var firebaseConfig = {
  apiKey: 'AIzaSyAKmlQNGn08hm1UwkubNB5q36Ex3rTAEO4',
  authDomain: 'casher-6a470.firebaseapp.com',
  databaseURL: 'https://casher-6a470.firebaseio.com',
  projectId: 'casher-6a470',
  storageBucket: 'casher-6a470.appspot.com',
  messagingSenderId: '232447396816',
  appId: '1:232447396816:web:682be95cb11e15ca5887d5',
};

function loadScript(d, s, id, jsSrc, cb) {
  const element = d.getElementsByTagName(s)[0];
  const fjs = element;
  let js = element;
  js = d.createElement(s);
  js.id = id;
  js.src = jsSrc;
  if (fjs && fjs.parentNode) {
    fjs.parentNode.insertBefore(js, fjs);
  } else {
    d.head.appendChild(js);
  }
  js.onload = cb;
}

let messag = null;

loadScript(
  document,
  'script',
  'firebase-app-jssdk',
  'https://www.gstatic.com/firebasejs/7.4.0/firebase-app.js',
  () => {
    window.firebase.initializeApp(firebaseConfig);
    loadScript(
      document,
      'script',
      'firebase-messaging-jssdk',
      'https://www.gstatic.com/firebasejs/5.8.4/firebase-messaging.js',
      () => {
        if (window.firebase && window.firebase.messaging)
          messag = window.firebase.messaging();
          console.log('====>Caregado firebasejs-jssdk<====');
      },
    );
  },
);

const messaging = () => ({
  hasPermission: () => ({then: res => res(false)}),
  requestPermission: () => ({
    then: res => ({catch: res => res({msg: 'Erro no suporte'})}),
  }),
  ...messag,
});

export default {
  // messaging:()=>(
  //   {
  //     hasPermission:()=>({
  //       then:(res)=>{
  //         messaging.requestPermission().then(() {}
  //       }
  //     })
  //   }
  // ),
  messaging,
  notifications: () => ({
    onNotification: res => {
      messaging.onMessage(function(payload) {
        console.log('Message received. ', payload);
        var opcoes = {
          body: payload.notification.body,
          icon: 'img/logo.png',
        };
        var n = new Notification(payload.notification.title, opcoes);
      });
    },
    onNotificationOpened: console.log,
    getInitialNotification: () => new Promise(function(resolve, reject) {}),
  }),
};

// export function init(callback) {
//   if (messaging) {
//     messaging
//       .requestPermission()
//       .then(function() {
//         console.log('Notification permission granted.');
//         getToken(token => {
//           callback(token, 'web');
//         });
//       })
//       .catch(function(err) {
//         console.log('Unable to get permission to notify.', err);
//       });
//   }
// }
