// var host = "http://192.168.25.199:7000/v1";
// var socket = "http://192.168.25.199:7000";
// host = "http://localhost:3002/v2";
// // host = "http://165.227.218.55:3002/v2";
var socket = 'https://casher-api.1app.com.br';
var host = 'https://casher-api.1app.com.br/v1';
// host = 'http://192.168.0.106:7002/v1';
var token_api = 'a97e2517a53845d82cbd87787bc94315';

var token_user = '';
import {Util} from '../infra';
if (!__DEV__) {
  console.log = function() {};
  host = 'https://casher-api.1app.com.br/v1';
  socket = 'https://casher-api.1app.com.br';
}
module.exports = {
  getHost() {
    return host;
  },
  getSocket() {
    return socket;
  },
  getToken() {
    return token_api;
  },
  setTokenUser(token) {
    token_user = token;
  },
  getTokenUser() {
    return token_user;
  },
  post(metodo, data, retorno) {
    var url = host + '/' + metodo;
    // console.log(url);
    var config = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    if (token_api) {
      config.headers['x-request-id'] = token_api;
    }
    if (token_user) {
      config.headers['token-user'] = token_user;
    }
    fetch(url, config)
      .then(response => {
        this.resolverResponse(response, retorno);
      })
      .catch(error => {
        if (retorno) retorno(null, error);
      });
  },
  get(metodo, data, retorno) {
    var url = host + '/' + metodo;
    if (data) {
      url += '?';
      var lista = Object.keys(data);
      for (var i = 0; i < lista.length; i++) {
        var item = lista[i];
        if (
          data[item] + '' == 'undefined' ||
          data[item] + '' == 'null' ||
          data[item] + '' == '' ||
          data[item] + '' == 'false'
        )
          continue;
        if (i != 0) {
          url += '&';
        }
        url +=
          '' +
          item +
          '=' +
          (this.isObject(data[item]) || this.isArray(data[item])
            ? JSON.stringify(data[item])
            : encodeURI(data[item]));
      }
    }
    var config = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };
    if (token_api) {
      config.headers['x-request-id'] = token_api;
    }
    if (token_user) {
      config.headers['token-user'] = token_user;
    }
    fetch(url, config)
      .then(response => {
        this.resolverResponse(response, retorno);
      })
      .catch(error => {
        if (retorno) retorno(undefined, error);
      });
  },
  put(metodo, data, retorno) {
    var url = host + '/' + metodo;
    // console.log(url);
    var config = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    if (token_api) {
      config.headers['x-request-id'] = token_api;
    }
    if (token_user) {
      config.headers['token-user'] = token_user;
    }
    fetch(url, config)
      .then(response => {
        this.resolverResponse(response, retorno);
      })
      .catch(error => {
        if (retorno) retorno(undefined, error);
      });
  },
  delete(metodo, data, retorno) {
    var url = host + '/' + metodo;
    // console.log(url);
    var config = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    if (token_api) {
      config.headers['x-request-id'] = token_api;
    }
    if (token_user) {
      config.headers['token-user'] = token_user;
    }
    fetch(url, config)
      .then(response => {
        this.resolverResponse(response, retorno);
      })
      .catch(error => {
        if (retorno) retorno(undefined, error);
      });
  },
  resolverResponse(response, retorno) {
    var p1 = response.json();

    p1.then((responseData, error) => {
      if (response.status != 200) {
        retorno(undefined, responseData);
      } else if (error) {
        retorno(undefined, error);
      } else {
        retorno(responseData);
      }
    });
  },
  isObject(val) {
    return typeof val === 'object';
  },

  isArray(object) {
    if (object && JSON.stringify(object) == '[]') {
      return true;
    }
    if (object && object.constructor && object.constructor === Array) {
      return true;
    } else {
      return false;
    }
  },

  upLoadFoto(componente, retorno) {
    if (!host)
      return alert(
        'Configure o caminho de upLoad! ( ImageUpload.setHost(host) )',
      );
    Util.pegarFoto(componente, base64 => {
      var url = host + '/imageMake';
      if (!base64) return console.log('base64 null no salvarImagem');
      var send = {data: base64, nome: 'up.jpg'};
      var config = {
        method: 'POST',
        headers: {
          'x-request-id': token_api,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(send),
      };
      componente.setState({load_foto: true});
      fetch(url, config)
        .then(response => {
          this.resolverResponse(response, retorno);
        })
        .catch(error => {
          alert(error);
          console.log(error);
        });
    });
  },

  upLoadFotoSemLib(fto_url, retorno) {
    if (!host)
      return alert(
        'Configure o caminho de upLoad! ( ImageUpload.setHost(host) )',
      );
    var url = host + '/imageMake';
    if (!fto_url) return console.log('base64 null no salvarImagem');
    var send = {data: fto_url, nome: 'up.jpg'};
    var config = {
      method: 'POST',
      headers: {
        'x-request-id': token_api,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(send),
    };
    fetch(url, config)
      .then(response => {
        this.resolverResponse(response, retorno);
      })
      .catch(error => {
        alert(error);
        console.log(error);
      });
  },
};
