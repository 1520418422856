import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {
  Platform,
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  TouchableOpacity,
  Image,
} from 'react-native';
import {Icon} from 'react-native-1app';
import CommonFn from './commonFn';
import moment from 'moment';
import styles from './styles/CalendarSelectStyles';
import _ from 'lodash';
import MCIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import * as Actions from '../../redux/actions';
class CalendarSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      viewMode: 'day',
    };
  }

  renderDay(day) {
    var {
      calendarMonth,
      date,
      warpDayStyle,
      dateSelectedWarpDayStyle,
      renderChildDay,
      renderCountNames,
      textDayStyle,
      currentDayStyle,
      notCurrentDayOfMonthStyle,
      renderCustomDay,
      selectedDate,
    } = this.props;

    var isCurrentMonth = calendarMonth === CommonFn.ym();
    var isCurrent = isCurrentMonth && CommonFn.ymd() === day;
    var dateSelected = date && CommonFn.ymd(date) === day;
    var notCurrentMonth = day.indexOf && day.indexOf(calendarMonth) !== 0;
    var arraystyle = [];
    var daysarraystyle2 = [];

    if (renderCustomDay && notCurrentMonth)
      return (
        <View
          style={[
            styles.customWarpDay,
            {flex: 1},
            notCurrentMonth
              ? {backgroundColor: Actions.getTema('cellcalendarnotcurrent')}
              : {},
          ]}
        />
      );

    if (day.buttonini) {
      return (
        <TouchableOpacity
          style={[
            renderCustomDay ? styles.customWarpDay : styles.warpicon,
            {backgroundColor: Actions.getTema('cellcalendarnotcurrent')},
            {flex: 1},
          ]}
          disabled={renderCustomDay}
          onPress={() => this.yearMonthChange(-1, 'month')}>
          {!renderCustomDay && (
            <Icon
              name={'chevron-left'}
              fontFamilyName={'Material Design Icons'}
              style={{
                fontSize: 30,
                color:
                  this.props.screenProps.store.getState().tema == 'preto'
                    ? 'white'
                    : 'black',
              }}
            />
          )}
        </TouchableOpacity>
      );
    } else if (day.buttonend) {
      return (
        <TouchableOpacity
          style={[
            renderCustomDay ? styles.customWarpDay : styles.warpicon,
            {backgroundColor: Actions.getTema('cellcalendarnotcurrent')},
            {flex: 1},
          ]}
          disabled={renderCustomDay}
          onPress={() => this.yearMonthChange(1, 'month')}>
          {!renderCustomDay && (
            <Icon
              name={'chevron-right'}
              fontFamilyName={'Material Design Icons'}
              style={{
                fontSize: 30,
                color:
                  this.props.screenProps.store.getState().tema == 'preto'
                    ? 'white'
                    : 'black',
              }}
            />
          )}
        </TouchableOpacity>
      );
    }

    if (dateSelected) {
      arraystyle = [
        renderCustomDay ? styles.customWarpDay : styles.warpDay,
        Actions.getTema('cellcalendar'),
        notCurrentMonth
          ? {backgroundColor: Actions.getTema('cellcalendarnotcurrent')}
          : {},
      ];
    } else {
      arraystyle = [
        renderCustomDay ? styles.customWarpDay : styles.warpDay,
        Actions.getTema('cellcalendar'),
        notCurrentMonth
          ? {backgroundColor: Actions.getTema('cellcalendarnotcurrent')}
          : {},
      ];
    }

    daysarraystyle2 = [
      styles.day,
      Actions.getTema('labelfields'),
      isCurrent ? {color: 'rgba(48,129,219,1)'} : {},
      notCurrentMonth
        ? {color: Actions.getTema('cellcalendarnotcurrentday')}
        : {},
    ];
    let circulo = {};
    if (selectedDate == day) {
      daysarraystyle2.push({color: 'white'});
      circulo.backgroundColor = 'rgba(48,129,219,1)';
    }

    if (renderCustomDay) {
      return (
        <View
          style={[
            arraystyle,
            {flex: 1, alignItems: 'center', justifyContent: 'center'},
          ]}>
          {renderCustomDay(day)}
        </View>
      );
    }

    return (
      <TouchableOpacity
        onPress={() => this.selectDate(day, !notCurrentMonth)}
        style={[arraystyle, {flex: 1}]}>
        <View style={[styles.cell]}>
          <View style={styles.cell2}>
            <View style={styles.cell4}>
              <View style={[styles.cell3, circulo]}>
                <Text style={daysarraystyle2}>{day.split('-')[2]}</Text>
              </View>
            </View>
            <View style={styles.cell3}>{renderCountNames(day)}</View>
          </View>
          {renderChildDay(day)}
        </View>
      </TouchableOpacity>
    );
  }

  selectDate(date, notCurrentMonth) {
    if (this.isDateEnable(date)) {
      this.props.selectDate(date, notCurrentMonth);
    }
  }

  yearMonthChange(type, unit) {
    let {viewMode, currentYear} = this.state;
    if (viewMode === 'day') {
      this.props.calendarChange(type, unit);
    } else {
      this.setState({
        currentYear: currentYear + (type < 0 ? -12 : 12),
      });
    }
  }

  isDateEnable(date) {
    var {minDate, maxDate} = this.props;
    return date >= minDate && date <= maxDate;
  }

  render() {
    var {
      calendarMonth,
      renderPrevYearButton,
      renderPrevMonthButton,
      renderNextYearButton,
      renderNextMonthButton,
      weekdayStyle,
      customWeekdays,
      warpRowWeekdays,
      warpRowControlMonthYear,
      renderCustomDay,
      month,
    } = this.props;
    var weekdays = customWeekdays || [
      'DOM',
      'SEG',
      'TER',
      'QUA',
      'QUI',
      'SEX',
      'SÁB',
    ];
    var data = CommonFn.calendarArray(calendarMonth);
    data[0] = {buttonini: true};
    data[data.length - 1] = {buttonend: true};
    var dayOfWeek = [];
    _.forEach(weekdays, element => {
      if (!renderCustomDay)
        dayOfWeek.push([
          <Text
            key={element}
            style={[
              styles.weekdays,
              Actions.getTema('calendardias'),
              weekdayStyle,
            ]}>
            {element}
          </Text>,
        ]);
      else
        dayOfWeek.push([
          <View style={styles.customweekdayscontainer}>
            <Text
              key={element}
              style={[
                styles.customweekdays,
                Actions.getTema('calendardias'),
                weekdayStyle,
              ]}>
              {element}
            </Text>
          </View>,
        ]);
    });
    // console.log(data,this.state.currentYear);
    return (
      <View style={styles.container}>
        {month ? (
          <View style={month.containerStyle}>
            <Text style={month.style}>{month.name}</Text>
          </View>
        ) : null}
        <View
          style={[
            {
              flexDirection: 'row',
              justifyContent: 'space-around',
            },
            warpRowWeekdays,
          ]}>
          {dayOfWeek}
        </View>
        <FlatList
          data={data}
          listKey={(item2, index) => 'D' + index.toString()}
          keyExtractor={(item, index) => 'k' + index.toString()}
          renderItem={({item}) => this.renderDay(item)}
          extraData={this.state}
          numColumns={7}
          style={{alignSelf: 'stretch'}}
        />
      </View>
    );
  }
}

var propTypes = {
  customWeekdays: PropTypes.array,
  renderPrevYearButton: PropTypes.func,
  renderPrevMonthButton: PropTypes.func,
  renderNextYearButton: PropTypes.func,
  renderNextMonthButton: PropTypes.func,
  //style
  warpRowControlMonthYear: PropTypes.object,
  warpRowWeekdays: PropTypes.object,
  weekdayStyle: PropTypes.object,
  textDayStyle: PropTypes.object,
  currentDayStyle: PropTypes.object,
  notCurrentDayOfMonthStyle: PropTypes.object,
  warpDayStyle: PropTypes.object,
  dateSelectedWarpDayStyle: PropTypes.object,
};

var defaultProps = {
  customWeekdays: ['Sun', 'Mon', 'Tus', 'Wes', 'Thu', 'Fri', 'Sat'],
};

CalendarSelect.propTypes = propTypes;
CalendarSelect.defaultProps = defaultProps;
export default CalendarSelect;
