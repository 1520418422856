import React, {Component} from 'react';
import {ImageCrop} from 'react-native-image-cropper';
import * as Actions from '../../redux/actions';
import ImagePicker from 'react-native-image-crop-picker';
import DatePicker from '../../components/DatePicker';
import moment from 'moment-timezone';
import Dialog, {DialogContent} from 'react-native-popup-dialog';
import {
  Content,
  Icon,
  Image,
  openDialog,
  Progress,
  SelectInput,
  TextInput,
  TitleBar,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  Platform,
} from 'react-native';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import * as Usuario from '../../worker/usuario';
import {Util, Cloud} from '../../infra';
import styleGlobal from '../../styleGlobal';
import AlterarSenha from './AlterarSenha';
import ImageUpload from '../../components/ImageUpload';
import ImageUp1App from 'react-native-1app/lib/ImageUpload';
import md5 from 'md5';

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: JSON.parse(
        JSON.stringify(this.props.screenProps.store.getState().user),
      ),
      showdataop: false,
      modalFiltroVisible: false,
    };
    this.cropper = {};
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user)
        this.setState({user: JSON.parse(JSON.stringify(store.user))});
    });
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  salvar() {
    let erro = Usuario.validarUserMod({
      ...this.state.user,
      senha: this.state.user.senha ? this.state.user.senha : this.state.senha,
    });
    if (!erro) {
      if (this.state.senha && !this.state.user.senha) {
        this.state.user.senha = md5(this.state.senha);
      }
      this.setState({load: true});
      Usuario.atualizar(this.state.user, (user = {}, error) => {
        if (error) {
          showMessage({
            message: 'Falha atualizar a conta',
            description: error.msg,
            type: 'warning',
            icon: {icon: 'warning', position: 'left'},
          });
        } else {
          this.props.screenProps.store.dispatch({
            type: 'SET_USER',
            user,
          });
          showMessage({
            message: 'Cadastro atualizado',
            type: 'success',
          });
        }
        this.setState({load: false});
      });
    } else {
      showMessage({
        message: 'Verifique o cadastro',
        description: erro,
      });
    }
  }

  openRecuperarSenha() {
    this.setState({modalFiltroVisible: true});
  }
  makeCrop() {
    ImagePicker.openCropper({
      path: this.state.user.foto_large,
      data: '',
      width: 300,
      height: 400,
    }).then(image => {
      Util.parseImgContatctsToBase64(image.path, (base64, error) => {
        Cloud.upLoadFotoSemLib(base64, data => {
          this.state.user.foto_large = data.url;
          this.setState({user: this.state.user});
        });
      });
    });
  }
  recarregar() {
    var store = this.props.screenProps.store.getState();

    this.setState({user: JSON.parse(JSON.stringify(store.user))});
  }

  render() {
    return (
      <Content style={styles.content} keyboard={true}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styleGlobal.titlebar_voltar}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[
                styleGlobal.titlebar_voltar_icone,
                Actions.getTema('iconstitlebar'),
              ]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
          </TouchableOpacity>
          <Text
            style={[
              styleGlobal.titlebar_textoprincipal,
              Actions.getTema('iconstitlebar'),
            ]}>
            {'Meus Dados'}
          </Text>

          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
          {!this.state.load && (
            <TouchableOpacity
              style={[styles.buttoncircle]}
              onPress={() => {
                this.recarregar();
              }}>
              <Icon
                style={[styles.icon3, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'close-circle'}
              />
            </TouchableOpacity>
          )}

          {!this.state.load && this.state.user.senha && (
            <TouchableOpacity
              style={[styles.button3]}
              onPress={() => {
                this.openRecuperarSenha();
              }}>
              <Icon
                style={[styles.icon3, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'key'}
              />
            </TouchableOpacity>
          )}

          {!this.state.load && (
            <TouchableOpacity
              style={[styles.button3]}
              onPress={() => {
                this.salvar();
              }}>
              <Icon
                style={[styles.icon3, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Icons'}
                name={'save'}
              />
            </TouchableOpacity>
          )}
        </TitleBar>
        <ScrollView
          style={[styles.scroll, Actions.getTema('scroll')]}
          keyboardShouldPersistTaps={true}>
          <View style={styles.view3}>
            {Platform.OS == 'web' ? (
              <ImageUp1App
                style={{...styles.imageupload, minWidth: 100, minHeight: 100}}
                value={this.state.user.foto_large}
                aspectRatio={1}
                onChange={value => {
                  this.state.user.foto_large = value;
                  this.state.user.foto = value;
                  this.setState({user: this.state.user});
                }}
                path={'casher/perfil'}
              />
            ) : (
              <ImageUpload
                style={styles.imageupload}
                value={this.state.user.foto_large}
                onChange={value => {
                  this.state.user.foto_large = value;
                  this.state.user.foto = value;
                  this.setState({user: this.state.user});
                }}
                path={'casher/perfil'}
              />
            )}
          </View>

          <View style={styles.view4}>
            <TextInput
              style={[styles.textinput51, Actions.getTema('labelfields')]}
              value={this.state.user.nome}
              onChange={value => {
                this.state.user.nome = value;
                this.setState({user: this.state.user});
              }}
              len
              keyboardType={'default'}
              label={'Nome'}
              maxLength={50}
            />
            <TextInput
              style={[styles.textinput6, Actions.getTema('labelfields')]}
              value={this.state.user.email}
              onChange={value => {
                this.state.user.email = value;
                this.setState({user: this.state.user});
              }}
              disabled={true}
              keyboardType={'default'}
              label={'Email'}
            />

            {!this.state.user.senha ? (
              <TextInput
                style={styles.textinput5}
                value={this.state.senha}
                onChange={value => {
                  this.state.senha = value;
                  this.setState({senha: this.state.senha});
                }}
                keyboardType={'default'}
                label={'Senha'}
              />
            ) : null}
            <View style={styles.view22}>
              <DatePicker
                style={[styles.datepicker1, Actions.getTema('labelfields')]}
                value={
                  this.state.user.data_nascimento &&
                  moment(
                    this.state.user.data_nascimento,
                    'YYYY-MM-DDTHH:mm:ss.SSSSZ',
                  )
                    .set(12, 'hours')
                    .toJSON()
                }
                onChange={value => {
                  try {
                    value = moment(value)
                      .set({hour: 0, minute: 0, second: 0, millisecond: 0})
                      .format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
                  } catch (error) {}
                  this.state.user.data_nascimento = value;
                  this.setState({user: this.state.user});
                }}
                disabledClear={true}
                type={Platform.OS == 'web' ? 'date' : null}
                datePickerModeAndroid={'calendar'}
                label={'Data de Nascimento'}
              />
              <TouchableOpacity
                style={styles.button999}
                onPress={() => {
                  this.setState({showdataop: !this.state.showdataop});
                }}>
                <Icon
                  style={[styles.icon50, Actions.getTema('labelfields')]}
                  fromFontFamily={'Material Design Icons'}
                  name={this.state.showdataop ? 'chevron-up' : 'chevron-down'}
                />
              </TouchableOpacity>
            </View>
            {this.state.showdataop && (
              <View style={styles.view3339}>
                <TouchableOpacity
                  style={styles.button9}
                  onPress={() => {
                    this.state.user.flag_mostrar_data_nascimento = !this.state
                      .user.flag_mostrar_data_nascimento;
                    this.setState({user: this.state.user});
                  }}>
                  <Icon
                    style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={
                      this.state.user.flag_mostrar_data_nascimento == true
                        ? 'check-circle'
                        : 'checkbox-blank-circle-outline'
                    }
                  />
                  <View style={styles.view61}>
                    <Text
                      style={[
                        styles.textinput611,
                        Actions.getTema('labelfields'),
                      ]}>
                      {' Exibir data de nascimento para amigos'}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            )}
            {/* <SelectInput
              style={styles.selectinput}
              value={this.state.user.genero}
              onChange={(value, data, index) => {
                this.state.user.genero = value;
                this.setState({ user: this.state.user });
              }}
              label={"Sexo"}
              list={[
                { nome: "Masculino", id: "male", data: {} },
                { nome: "Feminino", id: "female", data: {} }
              ]}
            /> */}
            <TextInput
              style={[styles.textinput5, Actions.getTema('labelfields')]}
              value={this.state.user.telefone}
              onChange={value => {
                this.state.user.telefone = Util.maskFormate(value, 'phone');
                this.setState({user: this.state.user});
              }}
              keyboardType={Platform.OS != 'web' ? 'numeric' : 'default'}
              label={'Telefone'}
            />

            <TextInput
              style={[styles.textinput5, Actions.getTema('labelfields')]}
              value={this.state.user.obs_pessoal}
              onChange={value => {
                this.state.user.obs_pessoal = value;
                this.setState({user: this.state.user});
              }}
              multiline={true}
              numberOfLines={50}
              keyboardType={'default'}
              label={'Nota Sobre Mim'}
            />
          </View>

          <View style={styles.view6}>
            <Dialog
              visible={this.state.modalFiltroVisible}
              onTouchOutside={() => {
                this.setState({modalFiltroVisible: false});
                //
              }}
              onHardwareBackPress={() => {
                this.setState({modalFiltroVisible: false});
                return true;
              }}>
              <DialogContent
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  paddingBottom: 0,
                  margin: 0,
                  height: 300,
                  width: 300,
                }}>
                <AlterarSenha
                  user={this.state.user}
                  screenProps={this.props.screenProps}
                  pai={this}
                />
              </DialogContent>
            </Dialog>
          </View>
        </ScrollView>
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(242,242,242,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  icon50: {
    fontSize: 30,
  },
  view3339: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    // paddingLeft: 20,
    paddingRight: 20,
  },
  button999: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(44,126,218,1)',
    height: 50,
    minWidth: 50,
    // padding: 10,
  },
  view22: {
    flexDirection: 'row',
    alignSelf: 'stretch',
  },
  button9: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    flexDirection: 'row',
    flex: 1,
  },
  datepicker1: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
  },
  icon3: {
    fontSize: 25,
    color: 'rgba(48,129,219,1)',
  },
  scroll: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
  },
  view3: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  button3: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  imageupload: {
    width: 190,
    height: 190,
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 95,
  },
  view4: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: 20,
  },
  textinput51: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  textinput6: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  textinput5: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 30,
  },
  buttoncircle: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
});
