import React, {Component} from 'react';

import {Content, Progress} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  StatusBar,
  Image,
  Platform,
} from 'react-native';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
// import Photos from "../../components/Photos";
import * as LoginApp from '../../worker/login';
import {logarUser, loginPadrao, setCor} from '../../redux/actions';
import {GoogleSignin} from '@react-native-community/google-signin';
import {Cloud} from '../../infra';
import Icon from 'react-1app/lib/Icon';

let FacebookLogin = View;
let GoogleLogin = View;

if (Platform.OS == 'web') {
  GoogleLogin = require('react-google-login/dist/google-login');
  GoogleLogin = GoogleLogin.GoogleLogin;
  FacebookLogin = require('react-facebook-login/dist/facebook-login-render-props');
  FacebookLogin = FacebookLogin.default;
}

export default class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      fotos: [
        {
          url_img:
            'https://www.gazetalusofona.ch/wp-content/uploads/2017/12/contas-1.jpg',
        },
      ],
    };
    if (Platform.OS == 'ios')
      GoogleSignin.configure({
        iosClientId:
          '232447396816-tokiik9ns0uui8itsbboetj4km1dtb5v.apps.googleusercontent.com',
      });
    this.dispatch = this.props.screenProps.store.dispatch;
  }

  validarGoogle() {
    GoogleSignin.currentUserAsync()
      .then(user => {
        if (user) GoogleSignin.signOut();
      })
      .done();
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
    });

    // loginPadrao({ email: 'g.kothe@hotmail.com', senha: "oi" }, this.dispatch, (user, error) => {
    //   // console.log(user,error)
    //   if (error) {
    //     showMessage({
    //       message: "Falha no login",
    //       description: error.msg,
    //       type: "warning",
    //       icon: { icon: "warning", position: "left" },
    //       duration: 2000
    //     });
    //   } else {
    //     this.props.navigation.popToTop();
    //   }
    //   this.setState({ load: false });
    // });
    //
    // this.logarFace();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  openPageButton111() {
    this.props.navigation.navigate('LoginEmail');
  }

  logarGoogle() {
    this.setState({load: true});
    GoogleSignin.signIn()
      .then(user => {
        console.log(user);
        this.logarComUserGoogle(user.user);
      })
      .catch(err => {
        console.log(err);
        this.setState({load: false});
      })
      .done();
  }

  logarComUserGoogle(user) {
    var nome = user.name;
    var email = user.email;
    var foto = user.photo;
    var idGoogle = user.id;
    LoginApp.loginGoogle({nome, email, foto, idGoogle}, (user, error) => {
      this.setState({load: false});
      if (error)
        return showMessage({
          message: 'Falha no login',
          description: error.msg,
          type: 'warning',
          icon: {icon: 'warning', position: 'left'},
        });
      logarUser(user, this.props.screenProps.store.dispatch);
      this.props.navigation.goBack();
    });
  }

  logarFace() {
    this.setState({load: true});
    LoginApp.fb(token => {
      console.log(1, token);
      if (!token) {
        this.setState({load: false});
        return showMessage({
          message: 'Falha no login com facebook',
          description: 'Verifique sua conta',
          type: 'warning',
          icon: {icon: 'warning', position: 'left'},
        });
      }
      LoginApp.loginFacebook(token, (user, error) => {
        console.log(2);
        this.setState({load: false});
        if (error)
          return showMessage({
            message: 'Falha no login',
            description: error.msg,
            type: 'warning',
            icon: {icon: 'warning', position: 'left'},
          });
        console.log(3);
        logarUser(user, this.props.screenProps.store.dispatch);
        this.props.navigation.goBack();
      });
    });
  }
  entrerSemLogar() {
    this.props.screenProps.store.dispatch({
      type: 'LOGIN',
      user: {
        id: 0,
        nome: 'Anônimo',
        telefone: '00000000000',
        email: 'anonimo@com.br',
      },
    });
    Cloud.setTokenUser('1');
    this.props.navigation.goBack();
  }

  responseGoogle = resposta => {
    if (!resposta || resposta.error) return;
    this.logarComUserGoogle(resposta.profileObj);
  };

  checkLoginState = resposta => {
    if (!resposta || resposta.error) return;
    this.setState({load: true});
    LoginApp.loginFacebook(resposta.accessToken, (user, error) => {
      this.setState({load: false});
      if (error)
        return showMessage({
          message: 'Falha no login',
          description: error.msg,
          type: 'warning',
          icon: {icon: 'warning', position: 'left'},
        });
      logarUser(user, this.props.screenProps.store.dispatch);
      this.props.navigation.goBack();
    });
  };

  render() {
    return (
      <View style={styles.content}>
        <StatusBar
          translucent
          backgroundColor="rgba(0,0,0,0)"
          barStyle={'light-content'}
        />
        {/* <Photos style={styles.photos} list={this.state.fotos} /> */}
        <View style={styles.photos}>
          <View style={{flex: 1}}>
            <Image
              style={styles.imagelogo}
              source={require('../../../img/logo-com-escrita_branco.png')}
              resizeMode={'contain'}
            />
            <Image
              resizeMode={'cover'}
              source={require('../../../img/fundo_casher_2.png')}
              style={[styles.page, {overflow: 'hidden'}]}
            />
          </View>
        </View>

        <View style={styles.view2}>
          {!this.state.load ? (
            <View style={styles.view1}>
              <Text style={styles.text21}>{'Logar com'}</Text>
              {Platform.OS != 'web' ? (
                <TouchableOpacity
                  style={styles.button1}
                  onPress={() => {
                    this.logarFace();
                  }}>
                  <Text style={styles.text11}>{'Facebook'}</Text>
                </TouchableOpacity>
              ) : (
                <FacebookLogin
                  appId="269870997030925"
                  autoLoad={false}
                  callback={this.checkLoginState}
                  disableMobileRedirect={true}
                  render={renderProps => (
                    <button
                      style={{
                        height: 44,
                        borderRadius: 22,
                        width: '100%',
                        borderStyle: 'solid',
                        borderWidth: 2,
                        backgroundColor: 'rgba(0,0,0,0)',
                        borderColor: 'rgba(58,121,172,1)',
                        cursor: 'pointer',
                      }}
                      onClick={renderProps.onClick}>
                      <p
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: 14,
                          marginTop: 9,
                        }}>
                        Facebook
                      </p>
                    </button>
                  )}
                />
              )}
              {Platform.OS != 'web' ? (
                <TouchableOpacity
                  style={styles.button11}
                  onPress={() => {
                    this.logarGoogle();
                  }}>
                  <Text style={styles.text111}>{'Google'}</Text>
                </TouchableOpacity>
              ) : (
                <GoogleLogin
                  clientId="232447396816-1nc779ufk9u7sfdfu83kp69mc96ipbp0.apps.googleusercontent.com"
                  autoLoad={false}
                  buttonText="Google"
                  onSuccess={this.responseGoogle}
                  render={renderProps => (
                    <button
                      style={{
                        height: 44,
                        borderRadius: 22,
                        width: '100%',
                        borderStyle: 'solid',
                        borderWidth: 2,
                        backgroundColor: 'rgba(0,0,0,0)',
                        borderColor: 'rgba(232,69,60,1)',
                        marginTop: 25,
                        cursor: 'pointer',
                      }}
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}>
                      <p
                        style={{
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: 14,
                          marginTop: 9,
                        }}>
                        Google
                      </p>
                    </button>
                  )}
                  onFailure={this.responseGoogle}
                  cookiePolicy={'single_host_origin'}
                />
              )}
              <TouchableOpacity
                style={styles.button111}
                onPress={() => {
                  this.openPageButton111();
                }}>
                <Text style={styles.text1111}>{'Email'}</Text>
              </TouchableOpacity>
              {/* <TouchableOpacity
                style={styles.button112}
                onPress={() => {
                  this.entrerSemLogar();
                }}
              >
                <Text style={styles.text1112}>{"Entrar sem logar"}</Text>
              </TouchableOpacity> */}
            </View>
          ) : null}

          {this.state.load ? <Progress style={styles.progress} /> : null}
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(242,242,242,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  photos: {
    alignSelf: 'stretch',
    height: 210,
    flex: 1,
  },
  view2: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  imagelogo: {
    width: 250,
    height: 250,
    zIndex: 10,
    top: '15%',
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  view1: {
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: 250,
    margin: 20,
    marginBottom: 30,
  },
  text21: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    margin: 20,
    fontSize: 20,
  },
  button1: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    flexDirection: 'column',
    borderRadius: 22,
    backgroundColor: 'rgba(58,121,172,0)',
    borderColor: 'rgba(58,121,172,1)',
    borderWidth: 2,
  },
  text11: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: '600',
  },
  button11: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    flexDirection: 'column',
    borderRadius: 22,
    backgroundColor: 'rgba(232,69,60,0)',
    borderColor: 'rgba(232,69,60,1)',
    borderWidth: 2,
    marginTop: 25,
  },

  page: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    alignSelf: 'stretch',
  },
  text111: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: '600',
  },
  button111: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    flexDirection: 'column',
    borderRadius: 22,
    backgroundColor: 'rgba(232,69,60,0)',
    borderColor: 'rgba(255,255,255,1)',
    borderWidth: 2,
    marginTop: 25,
  },
  text1111: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: '600',
  },
  progress: {
    width: 35,
    height: 35,
  },
});
