import React, {Component} from 'react';

import * as Actions from '../../redux/actions';
import {openModal} from 'react-native-1app/lib/PgModal';
import {Icon, Image} from 'react-native-1app';
import {StyleSheet, View, TouchableOpacity, Text, Alert} from 'react-native';
import * as Usuario from '../../worker/usuario';
export default class PreInfos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      usuario: {},
      // id_usuario: props.infos.id_usuario,
      // boolcontatoproprio: props.infos.boolcontatoproprio,
      // modalFiltroVisible: props.modalFiltroVisible
      id_usuario: this.props.screenProps.store.getState().preinfos_id_usuario,
      boolcontatoproprio: this.props.screenProps.store.getState()
        .preinfos_boolcontatoproprio,
      modalFiltroVisible: this.props.screenProps.store.getState().showpreinfos,
    };
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  /*

    componentWillReceiveProps(next) {
      if (this.state.modalFiltroVisible != next.modalFiltroVisible || this.state.id_usuario != next.infos.id_usuario) {
        this.setState({ modalFiltroVisible: next.modalFiltroVisible, id_usuario: next.infos.id_usuario, boolcontatoproprio: next.infos.boolcontatoproprio }, (data, error) => {
          if (this.state.id_usuario) {
            this.abrir();
          }
        })
      }
    }
  */
  abrir() {
    let obj = {
      id: this.state.id_usuario,
      flag_eh_contato: this.state.boolcontatoproprio,
    };
    this.setState({load: true});
    Usuario.getUsuarioInfos(obj, (data, error) => {
      if (error) {
        Alert.alert('Erro', error.msg, [{text: 'Ok', onPress: () => {}}], {
          cancelable: false,
        });
      } else {
        this.setState({usuario: data}, (data, error) => {
          this.abrirModal();
        });
      }

      this.setState({load: false});
    });
  }

  abrirImg() {
    this.props.navigation.navigate('FotoUser', {
      activity: this,
      oldState: this.state,
      usuario: this.state.usuario,
      onGoBack: () => {
        this.props.onClose();
      },
    });
    this.props.onClose();
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();

      // if (store.showpreinfos) this.abrirModal();

      if (store.showpreinfos != this.state.modalFiltroVisible) {
        this.setState(
          {
            modalFiltroVisible: store.showpreinfos,
            id_usuario: store.preinfos_id_usuario,
            boolcontatoproprio: store.preinfos_boolcontatoproprio,
          },
          (data, error) => {
            if (this.state.id_usuario) {
              this.abrir(this.state.id_usuario);
            }
          },
        );
      }
    });
  }

  abrirModal() {
    var foto = this.state.usuario.foto_large
      ? this.state.usuario.foto_large
      : Usuario.returnFotoGen();

    openModal(props => (
      <TouchableOpacity
        style={styles.viewFora}
        activeOpacity={1}
        onPress={() => {
          Actions.setShowPreInfos({show: false, id_usuario: 0});
          props.closeModal();
        }}>
        <View style={styles.content}>
          <TouchableOpacity
            onPress={() => {
              this.abrirImg();
              props.closeModal();
            }}>
            <Image
              // style={[styles.image, { width: imgWidth, height: imgHeight }]}
              style={[styles.image]}
              source={{uri: foto}}
              resizeMode={'cover'}>
              <Text style={styles.labelnot1}>{this.state.usuario.nome}</Text>
            </Image>
          </TouchableOpacity>
          <View style={[styles.view3, Actions.getTema('titlebar')]}>
            <TouchableOpacity
              style={[styles.button3, Actions.getTema('titlebar')]}
              onPress={() => {
                this.addDivida();
                props.closeModal();
              }}>
              <Icon
                style={[styles.icon, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'plus'}
              />
            </TouchableOpacity>
            {/* {this.props.mostrarFiltro && */}
            <TouchableOpacity
              style={[styles.button3, Actions.getTema('titlebar')]}
              onPress={() => {
                this.filtrar();
                props.closeModal();
              }}>
              <Icon
                style={[styles.icon, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'account-search'}
              />
            </TouchableOpacity>
            {/* } */}
            {/* <View style={styles.view32}> */}
            {!this.state.boolcontatoproprio && (
              <TouchableOpacity
                style={[styles.button3, Actions.getTema('titlebar')]}
                onPress={() => {
                  this.abrirMsgs();
                  props.closeModal();
                }}>
                <Icon
                  style={[styles.icon, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'message-text'}
                />
              </TouchableOpacity>
            )}
            {/* <View style={{ width: 25 }} /> */}
            <TouchableOpacity
              style={[styles.button3, Actions.getTema('titlebar')]}
              onPress={() => {
                this.abrirInfos();
                props.closeModal();
              }}>
              <Icon
                style={[styles.icon, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'information-outline'}
              />
            </TouchableOpacity>
            {/* </View> */}
          </View>
        </View>
      </TouchableOpacity>
    ));
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  filtrar() {
    if (this.state.boolcontatoproprio) {
      Actions.setFiltroPessoa(
        this.state.usuario.nome,
        0,
        this.state.id_usuario,
      );
    } else {
      Actions.setFiltroPessoa(
        this.state.usuario.nome,
        this.state.id_usuario,
        0,
      );
    }

    // if (this.props.tela == "calendario")
    //   Actions.setFazerPesquisa2(true);
    // if (this.props.tela == "lista")
    Actions.setFazerPesquisa(true);

    this.props.onClose('pesquisa');
  }

  abrirMsgs() {
    this.props.navigation.navigate('Conversa', {
      activity: this,
      oldState: this.state,
      id_usuario_contato: this.state.id_usuario,
      onGoBack: () => {
        this.props.onClose();
      },
    });
    this.props.onClose();
  }

  abrirInfos() {
    if (this.state.boolcontatoproprio) {
      this.props.navigation.navigate('NovoContato', {
        activity: this,
        oldState: this.state,
        id_contato: this.state.id_usuario,
        onGoBack: () => {
          this.props.onClose();
        },
      });
    } else {
      this.props.navigation.navigate('InfosUser', {
        activity: this,
        oldState: this.state,
        id_usuario: this.state.id_usuario,
        onGoBack: () => {
          this.props.onClose();
        },
      });
    }
    this.props.onClose();
  }

  addDivida() {
    this.props.navigation.navigate({
      routeName: 'NovaDivida',
      key: Math.random() * 10000 + 'sd',
      params: {
        activity: this,
        oldState: this.state,
        contato: {
          tela: 'preinfo',
          boolcontatoproprio: this.state.boolcontatoproprio,
          id: this.state.id_usuario,
          ...this.state.usuario,
        },
      },
    });
    this.props.onClose();
  }
  render() {
    return null;
  }
}

var styles = StyleSheet.create({
  content: {
    // backgroundColor: "red",
    alignSelf: 'center',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 5,
    overflow: 'hidden',
    margin: 'auto',
  },
  viewFora: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 250,
    height: 250.5,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    // borderRadius: 25,
    // marginTop: 15
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    // borderRadius: 22,
    backgroundColor: 'white',
    // marginTop: 20,
    flex: 1,
  },
  view3: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',

    // padding: 20
  },
  labelnot1: {
    color: 'white',
    backgroundColor: 'rgba(198, 198, 198,0.5)',
    width: '100%',
    textAlign: 'left',
    padding: 7.5,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 30,
  },
});
