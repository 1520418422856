import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {Content, Icon, Progress, TitleBar} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  BackHandler,
  ToastAndroid,
  Platform,
} from 'react-native';
import Alert from 'react-native-1app/lib/Alert';
import * as actions from '../../redux/actions';
import FingerprintScanner from 'react-native-fingerprint-scanner';
import styleGlobal from '../../styleGlobal';
var lendo = false;
export default class InserirPin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pin: '',
      pinredux: this.props.screenProps.store.getState().pin,
    };
    this.interval = {};
  }

  bolinhaPin(bol) {
    var style = {
      alignSelf: 'stretch',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      borderRadius: 50,
      borderColor: 'rgba(137,137,137,1)',
      borderWidth: 1,
      width: 20,
      height: 20,
      marginLeft: 5,
      marginRight: 5,
      borderStyle: 'solid',
    };
    if (bol == 1 && this.state.pin.length >= 1) {
      style.backgroundColor = Actions.getTema('bolinhapin');
    } else if (bol == 2 && this.state.pin.length >= 2) {
      style.backgroundColor = Actions.getTema('bolinhapin');
    } else if (bol == 3 && this.state.pin.length >= 3) {
      style.backgroundColor = Actions.getTema('bolinhapin');
    } else if (bol == 4 && this.state.pin.length >= 4) {
      style.backgroundColor = Actions.getTema('bolinhapin');
    }
    return style;
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  addPinNumber(num) {
    let pin = this.state.pin;
    if (num == undefined) {
      pin = pin.slice(0, -1);
    } else {
      if (pin.length >= 4) {
        return;
      } else {
        pin = pin + (num + '');
      }
    }
    if (pin.length == 4) {
      if (pin == this.state.pinredux) {
        this.backPageButton();
      } else {
        this.setState({pin: ''});
        Alert.alert(
          'Aviso',
          'PIN inválido',
          [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
          {cancelable: false},
        );
      }
    } else {
      this.setState({pin: pin});
    }
  }

  fingerFuncs() {
    if (!lendo) {
      lendo = true;
      FingerprintScanner.release();
      FingerprintScanner.authenticate({
        onAttempt: this.handleAuthenticationAttempted,
      })
        .then(() => {
          try {
            clearInterval(this.interval);
          } catch (error) {}
          lendo = false;
          this.backPageButton();
        })
        .catch(error => {
          lendo = false;
          console.log('errofinger', error);
        });
    }
  }
  finger() {
    this.fingerFuncs();
    this.interval = setInterval(() => {
      try {
        this.fingerFuncs();
      } catch (error) {
        console.log('error2', error);
      }
    }, 2000);
  }

  handleAuthenticationAttempted = error => {
    lendo = false;
    ToastAndroid.show('Autenticação inválida', ToastAndroid.SHORT);
  };

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.pin != this.state.pinredux)
        this.setState({pinredux: store.pin});
    });

    if (Platform.OS == 'android') {
      FingerprintScanner.isSensorAvailable()
        .then(biometryType => {
          if (biometryType == 'Fingerprint') {
            this.setState({finger: true});
            this.finger();
          } else {
            this.setState({finger: false});
          }
        })
        // .catch(error => this.setState({ errorMessage: error.message }));
        .catch(error => this.setState({finger: false}));
    } else {
      this.setState({finger: true});
      this.finger();
    }
    BackHandler.addEventListener('hardwareBackPress', this.handleBackButton);
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();

    BackHandler.removeEventListener('hardwareBackPress', this.handleBackButton);
    if (Platform.OS == 'android' && this.state.finger) {
      FingerprintScanner.release();
    }
    clearInterval(this.interval);
  }

  handleBackButton() {
    ToastAndroid.show('Você deve inserir o PIN', ToastAndroid.SHORT);
    return true;
  }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity style={styles.button1}>
            <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
              {' '}
              {'Inserir PIN'}
            </Text>
          </TouchableOpacity>
          <View style={styles.view} />

          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
        </TitleBar>
        <ScrollView style={[styles.scroll, Actions.getTema('scroll')]}>
          <View style={styles.view31}>
            <Icon
              style={[styles.icon4, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'lock-outline'}
            />
            <Text style={[styles.text3, Actions.getTema('textcell3')]}>
              {'Inserir PIN de 4 dígitos'}
            </Text>
            <View style={styles.view311}>
              <View style={this.bolinhaPin(1)} />
              <View style={this.bolinhaPin(2)} />
              <View style={this.bolinhaPin(3)} />
              <View style={this.bolinhaPin(4)} />
            </View>
          </View>
          <View style={styles.view41} />
          <View style={styleGlobal.viewpins}>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(1);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'1'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(2);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'2'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(3);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'3'}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styleGlobal.viewpins}>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(4);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'4'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(5);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'5'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(6);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'6'}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styleGlobal.viewpins}>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(7);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'7'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(8);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'8'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(9);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'9'}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styleGlobal.viewpins}>
            <TouchableOpacity style={styles.button163} disabled={true} />
            <TouchableOpacity
              style={[styleGlobal.buttonpin, Actions.getTema('bolinhapinbt')]}
              onPress={() => {
                this.addPinNumber(0);
              }}>
              <Text
                style={[styleGlobal.text4, Actions.getTema('bolinhapinbt')]}>
                {'0'}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button162}
              onPress={() => {
                this.addPinNumber();
              }}>
              <Icon
                style={[styleGlobal.icon41, Actions.getTema('bolinhapinbt')]}
                fromFontFamily={'Material Design Icons'}
                name={'backspace'}
              />
            </TouchableOpacity>
          </View>
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 30,
            }}>
            <Icon
              style={[styles.icon45, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'fingerprint'}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },
  view: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(252,252,252,1)',
    flex: 1,
    padding: 20,
  },
  view31: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 5,
    paddingTop: 10,
  },
  icon4: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  text3: {
    textAlign: 'center',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'stretch',
    fontWeight: 'bold',
    marginTop: 10,
  },
  view311: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 5,
    paddingTop: 10,
  },
  view41: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'row',
    marginTop: 20,
    marginBottom: 20,
    height: 1,
    backgroundColor: 'rgba(177,177,184,1)',
  },
  button163: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    marginRight: 10,
    width: 50,
    borderRadius: 50,
  },
  button162: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderRadius: 50,
    width: 50,
    borderColor: null,
  },
  icon45: {
    color: 'rgba(48,129,219,1)',
    fontSize: 45,
  },
});
