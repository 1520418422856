import React from 'react';
import {Modal,Platform} from 'react-native';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";

const WebModal=({classes,...props})=>(
  <Dialog
  children={props.children}
    fullScreen
    open={Boolean(props.visible)}
    onClose={props.onRequestClose}
    style={{ overflowX: "auto",...props.style }}
    PaperProps={{
      classes: { root: classes.root }
    }}
  />
)

const styles = theme => ({
  root: {
    backgroundColor: "rgba(0,0,0,0.2)"
  }
});

const wm=withStyles(styles)(WebModal);

export default Platform.select({
  web: wm,
  default: Modal,
})
