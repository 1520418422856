import {
  Platform,
  Linking,
  NetInfo,
  ImageEditor,
  ImageStore,
  Image,
} from 'react-native';
import RNFetchBlob from 'rn-fetch-blob';
import {MaskService} from 'react-native-masked-text';
import VMasker from 'vanilla-masker';
import ImagePicker from 'react-native-image-picker';
// var make_token = require("jsonwebtoken");

// export function parseToken(token) {
//   try {
//     var decoded = make_token.verify(token, "abrate");
//     return decoded;
//   } catch (err) {
//     return false;
//   }
// }
// export function makeToken(obj) {
//   var token = make_token.sign(obj, "abrate", { noTimestamp: true });
//   return token;
// }

export function elevation(value, shadowColor) {
  value = value ? value : 1;
  return {
    elevation: 5 / value,
    shadowColor: shadowColor ? shadowColor : '#000000',
    shadowOpacity: 0.5 / value,
    shadowRadius: 2 / value,
    shadowOffset: {
      height: 1,
      width: 0,
    },
  };
}
export function parseReal(string) {
  try {
    string = string + '';
    var val = string.replace(',', '.');
    var nnn = parseFloat(string);
    var num = nnn.toFixed(2);
    var str = '' + num;
    var numero = '' + str.replace('.', ',');
    var vfinal = numero
      .replace(/[.]/g, ',')
      .replace(/\d(?=(?:\d{3})+(?:\D|$))/g, '$&.');
    return vfinal;
  } catch (e) {
    return '0,00';
  }
}
export function pegarFoto(componente, retorno) {
  var rem = {'Remover imagem': 'remover'};
  var options = {
    title: 'Selecione uma imagem',
    videoQuality: 'medium', // 'low', 'medium', or 'high'
    durationLimit: 30, // video recording max time in seconds
    maxWidth: parseInt(componente.props.maxWidth) || 500, // photos only
    maxHeight: parseInt(componente.props.maxHeight) || 750, // photos only
    quality: 1, // 0 to 1, photos only
    customButtons: [],
    chooseFromLibraryButtonTitle: 'Pegar no telefone',
    cancelButtonTitle: 'Cancelar',
    takePhotoButtonTitle: 'Tirar foto', // specify null or empty string to remove this button
    storageOptions: {
      skipBackup: true,
      path: 'images',
    },
  };
  ImagePicker.showImagePicker(options, response => {
    if (response.error) {
      console.log('ImagePickerManager Error: ', response.error);
      retorno(null);
    } else if (response.customButton) {
      retorno(null);
    } else {
      retorno(response.data);
    }
  });
}
export function parseCnae(value) {
  var str = value; //"63.11-9-00";
  if (value) {
    str = str.replace('-', '');
    str = str.replace('-', '');
    str = str.replace('.', '');
    var res = str.substring(0, str.length - 2);
    return parseInt(res);
  } else {
    return value;
  }
}

export function subString(value, limit) {
  var str = value + ''; //"63.11-9-00";
  var limit = Number(limit);
  if (value && limit) {
    if (str.length > limit) {
      return str.substring(0, limit) + '...';
    } else {
      return str;
    }
  } else {
    return value;
  }
}
export function isEmail(email) {
  var er = /^[a-zA-Z0-9][a-zA-Z0-9\._-]+@([a-zA-Z0-9\._-]+\.)[a-zA-Z-0-9]{2,3}/;
  if (!er.exec(email)) {
    return false;
  } else {
    return true;
  }
}
export function isPhone(t) {
  var RegExp = /\(\d{2}\)\s\d{4,5}-?\d{4,5}/g;
  if (RegExp.test(t)) {
    return true;
  } else {
    return false;
  }
}

export function openMap(data) {
  openRoute(data);
}

export function openRoute(data) {
  var latitude = data.latitude;
  var longitude = data.longitude;
  if (Platform.OS === 'android') {
    var uri = 'geo:' + latitude + ',' + longitude;
    Linking.canOpenURL(uri)
      .then(supported => {
        if (supported) {
          Linking.openURL(uri);
        } else {
          console.log("Don't know how to go");
        }
      })
      .catch(err => console.error('An error occurred', err));
  } else {
    Linking.canOpenURL('comgooglemaps://').then(supported => {
      if (supported) {
        Linking.openURL(
          'comgooglemaps://?q=' + data.latitude + ',' + data.longitude + '',
        );
      } else {
        if (Platform.OS === 'android') {
          Linking.openURL(
            'maps://maps.apple.com/?q=' +
              data.latitude +
              ',' +
              data.longitude +
              '',
          );
        } else {
          Linking.openURL(
            'maps://maps.apple.com/?q=' +
              data.latitude +
              ',' +
              data.longitude +
              '',
          );
        }
      }
    });
  }
  //
  //    const rla = this.region.latitude;
  //   const rlo = this.region.longitude;
  //   const url = `http://maps.apple.com/?saddr=${rla},${rlo}&daddr=${la},${lo}&dirflg=d`;
  // "http://maps.apple.com/maps?daddr=\(destinationLocation.latitude),\(destinationLocation.longitude)&dirflg=d"
  //http://maps.google.com/?saddr=%1.6f,%1.6f&daddr=%1.6f,%1.6f
}

export function web(address) {
  if (!startsWith(address, 'http')) {
    address = 'http://' + address;
  }
  Linking.canOpenURL(address).then(supported => {
    if (supported) {
      Linking.openURL(address);
    } else {
      console.log("Don't know how to open URI: " + address);
    }
  });
}

export function call(phoneNumber) {
  var link;
  if (Platform.OS !== 'android') {
    link = phoneNumber ? 'telprompt:' : 'tel:';
  } else {
    link = 'tel:';
  }
  link += phoneNumber;
  Linking.canOpenURL(link).then(supported => {
    if (supported) {
      Linking.openURL(link);
    } else {
      console.log("Don't know how to open URI: " + link);
    }
  });
}
export function openEmail(email, subject, body) {
  var link = encodeURI(
    'mailto:' +
      email +
      (subject ? '&subject=' + subject : '') +
      (body ? '&body=' + body : ''),
  );
  Linking.canOpenURL(link).then(supported => {
    if (supported) {
      Linking.openURL(link);
    } else {
      console.log("Don't know how to open URI: " + link);
    }
  });
}

export function parseMoney(value) {
  if (!value) {
    return 'R$ 0,00';
  }
  value = this.parseNumeroDuasCasas(value);
  if (!value) {
    return 'R$ 0,00';
  }
  value = value + '';
  return 'R$ ' + value.replace('.', ',');
}

export function replaceAll(string, str, key) {
  try {
    if (!string) {
      return '';
    }
    if (!str) {
      return string;
    }
    if (!key) {
      key = '';
    }
    return string.replace(
      new RegExp(str.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'g'),
      key,
    );
  } catch (e) {
    return string;
  }
}

export function parseNumeroDuasCasas(string) {
  if (!string) {
    return 0.0;
  }
  try {
    string = string + '';
    var val = string.replace(',', '.');
    var nnn = parseFloat(val);
    if (!nnn) {
      nnn = 0.0;
    }
    var num = nnn.toFixed(2);
    if (!num || num < 0) {
      num = 0.0;
    }
    return num;
  } catch (e) {
    console.log(e);
    return 0.0;
  }
}

export function contemString(string, key) {
  if (!string || !key) {
    return false;
  }
  try {
    string = string + '';
    if (string && string.indexOf(key) >= 0) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
export function startsWith(string, key) {
  if (string) {
    string = string + '';
  } else {
    return false;
  }
  if (string && string.indexOf(key) === 0) {
    return true;
  } else {
    return false;
  }
}
export function endWith(string, key) {
  if (string) {
    string = string + '';
  } else {
    return false;
  }
  if (string && string.indexOf(key, string.length - key.length) === 0) {
    return true;
  } else {
    return false;
  }
}

export function cleanNumber(string) {
  try {
    string = string + '';
    // console.log(string);"de33sl".replace(/[^,.,0-9]/g, '')
    var val = string.replace(',', '.');
    val = val.replace(/[^,-.,0-9]/g, '');
    return val.trim();
  } catch (e) {
    // console.log(e);
    return '';
  }
}

export function cleanString(s) {
  if (!s) {
    return '';
  }
  var r = s; //.toLowerCase();
  r = r.replace(new RegExp(/\s/g), '');
  r = r.replace(new RegExp(/[àáâãäå]/g), 'a');
  r = r.replace(new RegExp(/æ/g), 'ae');
  r = r.replace(new RegExp(/ç/g), 'c');
  r = r.replace(new RegExp(/[èéêë]/g), 'e');
  r = r.replace(new RegExp(/[ìíîï]/g), 'i');
  r = r.replace(new RegExp(/ñ/g), 'n');
  r = r.replace(new RegExp(/[òóôõö]/g), 'o');
  r = r.replace(new RegExp(/œ/g), 'oe');
  r = r.replace(new RegExp(/[ùúûü]/g), 'u');
  r = r.replace(new RegExp(/[ýÿ]/g), 'y');
  r = r.replace(new RegExp(/\W/g), '');
  return r;
}
export function distance(data, state) {
  return this.getDistance(data, state.user_local);
}
export function getDistance(origin, destino) {
  if (!origin || !destino) {
    return '';
  }
  var foco = origin;
  var user_local = destino;
  if (user_local && user_local.latitude && foco.longitude && foco.longitude) {
    var latitude = user_local.latitude;
    var longitude = user_local.longitude;
    var mts = distLatLongEmMt(
      latitude,
      longitude,
      foco.latitude,
      foco.longitude,
    );
    return humanizarDistancia(mts);
  } else {
    return '';
  }
}
export function distLatLongEmMt(lat1, lon1, lat2, lon2) {
  lat1 = Number(lat1);
  lon1 = Number(lon1);
  lat2 = Number(lat2);
  lon2 = Number(lon2);
  function deg2rad(deg) {
    return deg * (Math.PI / 180);
  }
  var R = 6371; // Radius of the earth in kilometers
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in KM
  return d * 1000;
}
export function humanizarDistancia(metros) {
  if (metros < 50) {
    return 'Poucos metros';
  } else if (metros < 1000) {
    return parseInt(metros + '') + ' m';
  } else {
    return parseInt(metros / 1000 + '') + ' km';
  }
}

export function maskFormate(value, mask, limit, font) {
  if (!value) return '';
  if (mask == 'money') {
    value = VMasker.toMoney(value);
    value = replaceAll(value, '.', '');
    value = replaceAll(value, ',', '.');
  } else if (mask == 'int') {
    value = VMasker.toNumber(value);
  } else if (mask == 'doub') {
    if (!value) {
      value = 0;
    } else {
      value = parseFloat(value);
    }
    value = value ? value : 0;
  } else if (mask == 'phone') {
    // console.log(value.length);
    var telMask = ['(99) 9999-9999', '(99) 99999-9999', '9999 999 9999'];
    if (contemString(limparStringNumber(value), '0800')) {
      value = VMasker.toPattern(value, telMask[2]);
    } else {
      value = VMasker.toPattern(
        value,
        telMask[limparStringNumber(value).length < 11 ? 0 : 1],
      );
    }
  } else if (mask == 'doc') {
    value = VMasker.toPattern(
      value,
      validarCadastroDocCpf(value) ? '999.999.999-99' : '99.999.999/9999-99',
    );
  } else if (mask) {
    value = VMasker.toPattern(value, mask);
  }
  if (limit && value.length > limit) {
    value = value.substring(0, limit);
  }
  if (font == 1) {
    value = value.toUpperCase();
  } else if (font == 2) {
    value = value.toLowerCase();
  }
  return value;
}

export function validarCadastroDocCpf(doc) {
  if (doc) {
    return limparStringNumber(doc).length <= 11;
  } else {
    return false;
  }
}

function limparStringNumber(value) {
  if (value) {
    return value.replace(/[^\d]+/g, '');
  } else {
    return '';
  }
}

export function maskMoneyServicetoRawValue(value) {
  return MaskService.toRawValue('money', value, {
    unit: 'R$ ',
    separator: ',',
    delimiter: '.',
  });
}

export function maskNumberServicetoRawValue(value) {
  return MaskService.toRawValue('money', value, {
    unit: '',
    separator: ',',
    delimiter: '.',
  });
}

export function maskMoneyKotheService(value) {
  if (value == null || value == undefined || value == '') {
    value = 0;
  }
  return MaskService.toMask('money', value, {
    unit: 'R$ ',
    separator: ',',
    delimiter: '.',
    precision: 2,
  });
}

export function maskNumberKotheService(value) {
  if (value == null || value == undefined || value == '') {
    value = 0;
  }
  return MaskService.toMask('money', value, {
    unit: '',
    separator: ',',
    delimiter: '.',
    precision: 2,
  });
}

export function onlyNumbers(value) {
  return MaskService.toMask('only-numbers', value, {});
}

export function maskMoneyKothe(value) {
  value = value.replace('R$ R', '');
  value = value.replace('R$ ', '');
  value = value.replace('R$', '');
  value = value.replace(' ', '');
  value = value.replace('(', '');
  value = value.replace(')', '');
  value = value.replace(/[=+*\/-]/g, '');
  value = value.replace(/[{()}]/g, '');
  value = 'R$ ' + value.replace('.', ',');
  value = value.replace(/,*,/g, ',');
  value = value.replace('-', '');
  if ((value.match(/,/g) || []).length > 1) {
    value = value.replace(/,([^,]*)$/, '$1'); //a_bc
  }
  let valtest = value.split(',');
  if (valtest[1] && valtest[1].length > 2) {
    value = value.substring(0, value.length - 1);
  }

  value = value.replace('R$ R', 'R$');

  return value;
}

export function maskNumberKothe(value) {
  value = value.replace('R$ ', '');
  value = value.replace('R$', '');
  value = value.replace('.', ',');
  if ((value.match(/,/g) || []).length > 1) {
    value = value.replace(/,([^,]*)$/, '$1'); //a_bc
  }
  let valtest = value.split(',');
  if (valtest[1] && valtest[1].length > 2) {
    value = value.substring(0, value.length - 1);
  }
  return value;
}

export function unmaskMoneyKothe(value) {
  value = value.replace('R$ ', '');
  value = value.replace('R$', '');
  value = value.replace(',', '.');
  return value;
}

export async function parseImgContatctsToBase64(imageURL, callback) {
  const RNFS = require('react-native-fs');
  if (!imageURL) return callback(null);
  try {
    if (Platform.OS === 'android') {
      let data = '';
      RNFetchBlob.fs.readStream(imageURL, 'base64', 4095).then(ifstream => {
        ifstream.open();
        ifstream.onData(chunk => {
          data += chunk;
        });
        ifstream.onError(err => {
          console.log('oops', err);
        });
        ifstream.onEnd(() => {
          if (callback) callback(data);
          RNFS.unlink(imageURL);
        });
      });
    } else {
      Image.getSize(
        imageURL,
        (width, height) => {
          console.log(width, height);
          var imageSize = {
            size: {
              width: width,
              height: height,
            },
            offset: {
              x: 0,
              y: 0,
            },
          };
          ImageEditor.cropImage(
            imageURL,
            imageSize,
            imageURI => {
              console.log(imageURI);
              ImageStore.getBase64ForTag(
                imageURI,
                base64Data => {
                  console.log(base64Data);
                  if (callback) callback(base64Data);
                  ImageStore.removeImageForTag(imageURI);
                },
                reason => console.log(reason),
              );
            },
            reason => console.log(reason),
          );
        },
        reason => console.log(reason),
      );
    }
  } catch (e) {
    if (callback) callback(null);
    console.log(e);
  }
}
