import React, { Component } from "react";

import { Content, TextInput, closeDialog, Icon,  Progress, } from "react-native-1app";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native";
import * as Usuario from "../../worker/usuario";
import { showMessage } from "react-native-1app/lib/FlashMessage";
import * as Actions from "../../redux/actions";
import md5 from "md5"
export default class AlterarSenha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user
    };
  }

  componentDidMount() { }

  componentWillUnmount() { }
  validarUser() {
    let { senha, senha_old, senha_c, erro } = this.state;
    if (senha != senha_c) erro = "A nova senhas no corresponde";
    if (!senha_c) erro = "Adicione a confimação de senha";
    if (!senha) erro = "Adicione nova senha";
    if (senha_old && md5(senha_old) != this.state.user.senha) erro = "A senha digitada nao corresponde com a antiga";
    if (!senha_old) erro = "Adicione a senha antiga";

    return erro;
  }
  alterar() {

    this.setState({ load: true });
    // if (!this.validarUser()) {
    Usuario.atualizarSenha({ senha: md5(this.state.senha), senha_c: md5(this.state.senha_c), senha_old: md5(this.state.senha_old) }, (user = {}, error) => {
      this.setState({ load: false });
      if (error) {
        return showMessage({
          message: "Falha alterar a senha",
          description: error.msg,
          type: "warning",
          icon: { icon: "warning", position: "left" }
        });
      }
      this.props.screenProps.store.dispatch({
        type: "SET_USER",
        user
      });
      this.props.pai.setState({ modalFiltroVisible: false });
      return showMessage({
        message: "Senha atualizada",
        type: "success"
      });
    });
  }
  render() {
    return (
      <Content style={[styles.content, Actions.getTema("titlebar")]} keyboard={true}>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.buttoncircle}></View>
          <Text style={[styles.text2, Actions.getTema("labelfields")]} >{"Alterar Senha"}</Text>
          <TouchableOpacity
            style={[styles.buttoncircle]}
            onPress={() => {
              this.props.pai.setState({ modalFiltroVisible: false });
            }}
          >
            <Icon
              style={[styles.icon3, Actions.getTema("iconstitlebar")]}
              fromFontFamily={"Material Design Icons"}
              name={"close-circle"}
            />
          </TouchableOpacity>
        </View>
        <TextInput
          style={[styles.textinput, Actions.getTema("labelfields")]}
          value={this.state.senha_old}
          onChange={value => {
            this.state.senha_old = value;
            this.setState({ item: this.state.item });
          }}
          keyboardType={"default"}
          label={"Senha Antiga"}
          secureTextEntry={true}
          autoCapitalize='none'
          onSubmitEditing={() => {
            this.textinputSenha1.focus();
          }}
        />
        <TextInput
          style={[styles.textinput, Actions.getTema("labelfields")]}
          value={this.state.senha}
          onChange={value => {
            this.state.senha = value;
            this.setState({ item: this.state.item });
          }}
          keyboardType={"default"}
          label={"Nova Senha"}
          secureTextEntry={true}
          autoCapitalize='none'
          ref={v => (this.textinputSenha1 = v)}
          onSubmitEditing={() => {
            this.textinputSenha2.focus();
          }}
        />
        <TextInput
          style={[styles.textinput, Actions.getTema("labelfields")]}
          autoCapitalize='none'
          value={this.state.senha_c}
          onChange={value => {
            this.state.senha_c = value;
            this.setState({ item: this.state.item });
          }}
          keyboardType={"default"}
          label={"Confirmar Nova Senha"}
          secureTextEntry={true}
          ref={v => (this.textinputSenha2 = v)}
          onSubmitEditing={() => {
            this.alterar();
          }}
        />
        <TouchableOpacity
          style={styles.button}
          disabled={this.state.load}
          onPress={() => {
            this.alterar();
          }}
        >
          {!this.state.load ? (
            <Text style={styles.text}>{"Salvar"}</Text>
          ) : null}
          {this.state.load ? <Progress style={styles.progress} /> : null}
        </TouchableOpacity>
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: 10
  },
  textinput: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal"
  },
  buttoncircle: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 25,
    flexDirection: "column",
    width: 25,
  },
  button: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    borderStyle: "solid",
    marginTop: 20,
    borderRadius: 5,
    backgroundColor: "rgba(48,129,219,1)"
  },
  text: {
    textAlign: "center",
    // color: "rgba(215,172,46,1)",
    color: "white",
    alignSelf: "stretch",
  },
  text2: {
    textAlign: "center",
    // color: "rgba(215,172,46,1)",
    color: "black",
    fontSize: 16,
    flex: 1,
    alignSelf: "stretch",
  }
});
