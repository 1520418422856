import React from 'react';
import FlashMessage from 'react-native-1app/lib/FlashMessage';
import PgModal from './PgModal';
// import DialogAlert from './components';
import InitApp2 from 'react-1app/lib/InitApp';
import Alert from './Alert';

export default function InitApp(props) {
  return [
    <InitApp2 {...props} />,
    // <DialogAlert {...props} key="alerta_custom" />,
    <PgModal {...props} key="modal_custom" />,
    <Alert {...props} key="alert_custom" />,
    <FlashMessage
      position={props.position || 'bottom'}
      key="notificacao_custom"
    />,
  ];
}
