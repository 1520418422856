import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {
  Content,
  Icon,
  Image,
  ListView,
  TitleBar,
  Progress,
  TextInput,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  Alert,
  Platform,
} from 'react-native';
import styleGlobal from '../../styleGlobal';
import {Util} from '../../infra';
import * as Chat from '../../worker/chat';
import * as Divida from '../../worker/divida';
import * as Usuarios from '../../worker/usuario';
import TodoSwipeList from '../../components/TodoSwipeList';
import {
  ViewPager,
  PagerTabIndicator,
  IndicatorViewPager,
  PagerTitleIndicator,
  PagerDotIndicator,
} from 'rn-viewpager';
import CellListaMain from './CellListaMain';
export default class ListaPessoasDivida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ajuda: this.props.screenProps.store.getState().ajuda,
      load: false,
      lista: [],
      pessoa: {
        ids: this.propsNav().ids || [],
        nome: this.propsNav().nome || '',
      },
      id_contato: this.propsNav().id_contato,
      total_filtro_pessoa: 0,
    };
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  getLista() {
    this.setState({load: true});
    Divida.listarDividasRelatorio(
      {id: this.state.pessoa.ids},
      (data, error) => {
        if (('Divida.listarDividasRelatorio', error)) {
          Alert.alert(
            'Erro',
            error.msg,
            [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
            {cancelable: false},
          );
        } else {
          var array = data.lista ? data.lista : [];
          let total = 0;
          for (let a = 0; a < array.length; a++) {
            let element = array[a];
            if (element.id) {
              if (element.flag_tipo == '1') {
                total = total + element.val_montante;
              } else if (element.flag_tipo == '2') {
                total = total - element.val_montante;
              }
            }
          }

          this.setState({
            fullrequest: data,
            load: false,
            lista: data.lista,
            total_filtro_pessoa: total < 0 ? total * -1 : total,
            cor_filtro_pessoa: total < 0 ? 'red' : 'rgba(48,129,219,1)',
          });
        }
      },
    );
  }
  componentDidMount() {
    this.getLista();
  }
  componentWillUnmount() {}

  componentDidUpdate() {}

  backPageButton() {
    if (this.props.navigation.state.params.onGoBack)
      this.props.navigation.state.params.onGoBack();
    this.props.navigation.goBack();
  }

  render() {
    // alert(JSON.stringify(this.state.pessoa));
    return (
      <Content style={styles.container} keyboard={true}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <View style={styles.viewhead99}>
            <View style={styles.view778}>
              <TouchableOpacity
                style={styles.button3}
                onPress={() => {
                  this.backPageButton();
                }}>
                <Icon
                  style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'arrow-left'}
                />
              </TouchableOpacity>

              {!this.state.pesquisar ? (
                <Text
                  style={[
                    styles.text2,
                    Platform.OS == 'web' ? {minWidth: 200} : {},
                    Actions.getTema('iconstitlebar'),
                  ]}>
                  {'Relatórios'}
                </Text>
              ) : null}
            </View>
          </View>
        </TitleBar>

        <View
          style={[
            {flexDirection: 'row', alignSelf: 'stretch'},
            Actions.getTema('scroll'),
          ]}>
          <View style={styles.button4} onPress={() => {}}>
            <Text style={[styles.text5]}>{'Individual'}</Text>
          </View>
          <TouchableOpacity
            style={styles.button5}
            onPress={() => {
              this.props.navigation.navigate('RelatorioAgrupado', {
                activity: this,
                oldState: this.state,
                ids: this.state.pessoa.ids,
                fullrequest: this.state.fullrequest,
              });
            }}>
            <Text style={[styles.text5, Actions.getTema('iconstitlebar')]}>
              {'Todos'}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={[styles.viewnomepess, Actions.getTema('scroll')]}>
          <TouchableOpacity
            style={[
              styles.buttonfiltroremove,
              {...Util.elevation()},
              Actions.getTema('titlebar'),
            ]}
            onPress={() => {
              this.backPageButton();
            }}>
            <View style={[styles.view9999]}>
              <Text style={[styles.text888, Actions.getTema('labelfields')]}>
                {this.state.pessoa.nome}
              </Text>
            </View>
            <View style={[styles.view99991]}>
              <Text
                style={[
                  styles.text8881,
                  {color: this.state.cor_filtro_pessoa},
                ]}>
                {Util.parseMoney(this.state.total_filtro_pessoa)}
              </Text>
            </View>
            <Icon
              style={[styles.icon4, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'close-circle'}
            />
          </TouchableOpacity>
        </View>
        <View
          style={[styles.scroll, Actions.getTema('scroll')]}
          keyboardShouldPersistTaps={true}>
          {this.state.todos && this.state.todos.length == 0 ? (
            <View style={styles.view506}>
              <Text style={styleGlobal.botao_text_desabilitado}>
                {'Nenhuma dívida encontrada'}
              </Text>
            </View>
          ) : null}
          {this.state.load ? (
            <View style={styleGlobal.viewprogress}>
              <Progress style={styleGlobal.progress} />
            </View>
          ) : null}

          <TodoSwipeList
            style={[styles.listview, Actions.getTema('scroll')]}
            renderItem={({item, index}) => {
              return (
                <CellListaMain
                  item={item}
                  screenProps={this.props.screenProps}
                  activity={this}
                  navigation={this.props.navigation}
                  rowID={index}
                  onTouch={() => {
                    this.props.navigation.navigate('Relatorio', {
                      activity: this,
                      oldState: this.state,
                      id_divida: item.id,
                    });
                  }}
                />
              );
            }}
            data={this.state.lista}
            keyExtractor={(item, index) => index}
            rightSubView={(item, index) => {
              return <View />;
            }}
            leftSubView={(item, index) => {
              return <View />;
            }}
          />
        </View>
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },

  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    minWidth: 50,
  },
  text8881: {
    textAlign: 'right',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 15,
  },
  view9999: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  view99991: {
    alignSelf: 'stretch',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'row',
    marginRight: 10,
    width: 125,
  },
  text888: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 15,
  },
  buttonmag: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    paddingTop: 10,
    width: 50,
  },
  icon4: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  viewnomepess: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: 40,
  },
  buttonhelp: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  text5: {color: 'white'},
  button4: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderRadius: 22,
    backgroundColor: 'rgba(58,121,172,1)',
    borderColor: 'rgba(58,121,172,1)',
    borderWidth: 2,
    borderBottomWidth: 2,
    marginTop: 15,
    flex: 1,
    margin: 10,
  },
  button5: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderRadius: 22,
    // backgroundColor: "rgba(58,121,172,0)",
    borderColor: 'rgba(58,121,172,1)',
    borderWidth: 2,
    borderBottomWidth: 2,
    marginTop: 15,
    flex: 1,
    margin: 10,
  },
  textinput: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'center',
    fontWeight: 'normal',
    marginLeft: 5,
    paddingRight: 5,
  },
  buttonfiltroremove: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 8,
    margin: 5,
  },
  container: {
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: 'rgba(255,255,255,1)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  view777: {
    borderRadius: 25,
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 5,
    // backgroundColor:"red"
  },
  view779: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  view778: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },

  text2: {
    // alignSelf: "stretch",
    // textAlign: "left",

    color: 'rgba(48,129,219,1)',
    // alignSelf: "auto",
    fontWeight: 'normal',
    // flex: 1,
    marginLeft: 10,
  },
  viewhead99: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
  },
  fragment2: {
    alignSelf: 'stretch',
    flex: 1,
  },
  content21: {
    backgroundColor: null,
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  scroll: {
    alignSelf: 'stretch',
    flex: 1,
  },
  listview: {
    alignSelf: 'stretch',
    paddingTop: 5,
  },
  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 8,
    margin: 5,
  },
  view9: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 36,
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    // marginTop: 2
  },
  view61: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  text81: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'bold',
    marginLeft: 10,
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginLeft: 10,
    
  },
  view2: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'rgba(238,238,238,1)',
    flex: 1,
  },
  text9: {
    textAlign: 'right',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'center',
  },
  text9_1: {
    textAlign: 'right',
    color: 'rgba(44,126,218,1)',
    alignSelf: 'center',
  },
  text9_2: {
    textAlign: 'right',

    color: 'rgba(255,0,0,1)',
    alignSelf: 'center',
  },
  view73: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  view74: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
  },
  view506: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 10,
  },
});
