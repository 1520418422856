import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {Content, Icon, Progress, TitleBar} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Dimensions,
} from 'react-native';

import {Calendar, LocaleConfig} from 'react-native-calendars';
import styleGlobal from '../../styleGlobal';
import moment from 'moment';
import CalendarioPequeno from './CalendarioPequeno';
import FlatGrid from 'react-native-super-grid';
import {Util} from '../../infra';
import * as WorkerDivida from '../../worker/divida';

export default class Calendarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listaRelatorios: [],
      load: false,
    };
  }

  componentDidMount() {
    this.getDividas({data: this.props.data});
  }

  getDividas(params) {
    if (!params.data) {
      params.data = moment()
        .set(12, 'hours')
        .format('YYYY-MM-DD HH:MM:SS');
    }

    this.setState({load: true});

    WorkerDivida.listarDatasRelatorio(params, (res = [], error) => {
      this.setState({load: false, listaRelatorios: this.mapDividas(res)});
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.load !== nextState.load) return true;
    if (this.props.data !== nextProps.data) {
      this.getDividas({
        data: moment(nextProps.data)
          .set(12, 'hours')
          .format('YYYY-MM-DD HH:MM:SS'),
      });

      return true;
    }
  }

  mapDividas(res) {
    let lista = [];
    let data = moment(this.props.data)
      .startOf('year')
      .format('YYYY-MM');
    let cont = 0;
    while (cont < 12) {
      let receber = 0;
      let pagar = 0;
      let saldo = 0;

      receber = (
        res.find(mes => {
          if (mes.data == data) return mes;
        }) || {}
      ).receber;

      pagar = (
        res.find(mes => {
          if (mes.data == data) return mes;
        }) || {}
      ).pagar;

      saldo = (
        res.find(mes => {
          if (mes.data == data) return mes;
        }) || {}
      ).saldo;

      lista.push({
        data: moment(data).format('YYYY-MM'),
        receber: receber || 0,
        pagar: pagar || 0,
        saldo: saldo || 0,
      });
      data = moment(data)
        .add(1, 'month')
        .format('YYYY-MM');
      cont++;
    }

    return lista;
  }

  getRowMax() {
    let width = Dimensions.get('window').width;
    if (width < 500) return 2;
    if (width < 800) return 4;
    return 6;
  }

  render() {
    if (this.state.load)
      return (
        <View
          style={{
            alignSelf: 'stretch',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Progress style={{width: 80, height: 80, color: 'rgb(66,126,218)'}} />
        </View>
      );
    console.log(this.state.listaRelatorios);
    return (
      <View
        style={[{alignSelf: 'stretch', flex: 1}, Actions.getTema('scroll')]}>
        <FlatGrid
          items={this.state.listaRelatorios}
          rowMax={this.getRowMax()}
          itemDimension={120}
          renderItem={({item, index}) => {
            return (
              <Cell
                screenProps={this.props.screenProps}
                load={this.state.load}
                item={item}
                onMonth={data => {
                  this.props.onMonth(data);
                }}
              />
            );
          }}
          style={[
            {
              alignSelf: 'stretch',
              flex: 1,
            },
            Actions.getTema('scroll'),
          ]}
        />
      </View>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <TouchableOpacity
        style={{
          borderRadius: 5,
          margin: 5,
          flex: 1,
          backgroundColor:
            this.props.screenProps.store.getState().tema == 'preto'
              ? 'rgba(48,48,48,1)'
              : 'white',
          ...Util.elevation(
            1,
            this.props.screenProps.store.getState().tema == 'preto'
              ? 'white'
              : 'rgba(48,48,48,1)',
          ),
          overflow: 'hidden',
          padding: 10,
        }}
        activeOpacity={0.7}
        onPress={() => {
          this.props.onMonth(moment(this.props.item.data));
        }}>
        <Text style={styles.txMes}>
          {moment(this.props.item.data).format('MMMM')}
        </Text>
        <Text style={{color: 'rgb(66,126,218)'}}>
          {Util.maskMoneyKotheService(this.props.item.receber)}
        </Text>
        <Text style={{color: 'red'}}>
          {Util.maskMoneyKotheService(this.props.item.pagar)}
        </Text>
        <Text
          style={{
            color:
              this.props.item.receber - this.props.item.pagar >= 0
                ? 'rgb(66,126,218)'
                : 'red',
          }}>
          {Util.maskMoneyKotheService(this.props.item.saldo)}
        </Text>
      </TouchableOpacity>
    );
  }
}

var styles = StyleSheet.create({
  viewDia: {
    width: 25,
    height: 25,
    borderRadius: 12.5,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  txMes: {
    fontSize: 15,
    color: 'rgb(66,126,218)',
    fontWeight: 'bold',
    marginBottom: 20,
    alignSelf: 'stretch',
    textAlign: 'center',
  },
  progress: {width: 50, height: 50, color: styleGlobal.azul.color},
  viewSetas: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 30,
  },
  viewhead99: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    width: 50,
  },
  view778: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  progress: {width: 50, height: 50, color: styleGlobal.azul.color},
  viewAzul: {
    height: 30,
    width: 30,
    backgroundColor: 'rgb(66,126,218)',
    position: 'absolute',
  },
  viewVermelha: {
    height: 30,
    width: 30,
    backgroundColor: 'rgb(247,49,66)',
    position: 'absolute',
  },
});
