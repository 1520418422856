import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {
  Content,
  Icon,
  Image,
  ListView,
  Progress,
  TitleBar,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ScrollView,
  FlatList,
  RefreshControl,
} from 'react-native';
import Alert from 'react-native-1app/lib/Alert';
import moment from 'moment-timezone';
import * as Divida from '../../worker/divida';
import HeaderAceitar from './HeaderAceitar';
import {setAceitaQtd} from '../../redux/actions';
import styleGlobal from '../../styleGlobal';
import TodoSwipeList from '../../components/TodoSwipeList';
import {ViewPager} from 'rn-viewpager';
import {Util} from '../../infra';
import PreInfos from '../user/PreInfos';
import * as Usuario from '../../worker/usuario';
export default class ListaDividaAceitar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faturas: [],
      rejeitadas: [],
      espera: [],
      load: false,
      currentPosition: 0,
      viewpager: {},
      ajuda: this.props.screenProps.store.getState().ajuda,
    };
  }

  getColor() {
    if (this.state.flag_tipo == '') {
      if (!this.state.pos) {
        return {
          color: 'rgba(255,0,0,1)',
        };
      } else {
        return {
          color: 'rgba(48,129,219,1)',
        };
      }
    } else if (this.state.flag_tipo == '1') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    } else if (this.state.flag_tipo == '2') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    }
  }

  getArrow() {
    if (this.state.flag_tipo == '') {
      if (this.state.pos) {
        return 'arrow-up';
      } else {
        return 'arrow-down';
      }
    } else if (this.state.flag_tipo == '1') {
      return 'arrow-up';
    } else if (this.state.flag_tipo == '2') {
      return 'arrow-down';
    }
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  listar(callback) {
    this.setState({load: true});
    Divida.listarNaoAceitas({}, (data, error) => {
      this.setState({
        faturas: data ? data : [],
        load: false,
      });
      setAceitaQtd(data.length);
      if (callback) callback();
    });
  }

  listarEspera(callback) {
    this.setState({load: true});
    Divida.listarEspera({}, (data, error) => {
      this.setState({
        espera: data ? data : [],
        load: false,
      });
      if (callback) callback();
    });
  }

  listarRejeitadas(callback) {
    this.setState({load: true});
    Divida.listarRejeitadas({}, (data, error) => {
      this.setState({
        rejeitadas: data ? data : [],
        load: false,
      });
      if (callback) callback();
    });
  }
  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user) this.setState({user: store.user});
      if (store.ajuda != this.state.ajuda) this.setState({ajuda: store.ajuda});
    });
    this.listarRejeitadas();
    this.listar();
    this.listarEspera();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  aceitarLista(lista, callback) {
    this.aceitarLoop(0, lista, {}, (data, error) => {
      this.listar();
      this.listarRejeitadas();
      let body = '';
      if (error) {
        body =
          'Houver um erro em aceitar todas dívidas, verifique as que ainda estão pendentes';
      } else {
        body = 'Dívidas aceitas com sucesso';
      }
      Alert.alert(
        'Mensagem',
        body,
        [
          {
            text: 'Ok',
            onPress: () => {},
            style: 'cancel',
          },
        ],
        {cancelable: false},
      );
    });
  }

  aceitarLoop(pos, list, objextra, callback) {
    if (pos < list.length) {
      var item = list[pos];
      this.aceitar(item.id, (ret, error) => {
        if (error && callback) {
          return callback(null, error);
        } else {
          if (pos == 0) {
            objextra.primeiro = ret;
          }
          pos++;
          this.aceitarLoop(pos, list, objextra, callback);
        }
      });
    } else {
      if (callback) return callback(objextra.primeiro, null);
    }
  }

  aceitar(id, callback) {
    this.setState({load: true});
    Divida.aceitar({id: id}, (data, error) => {
      this.setState({load: false});
      if (callback) return callback(data, error);
    });
  }

  alertAceitar() {
    Alert.alert(
      'Confirmação',
      'Deseja aceitar todas as dívidas pendentes? O processo pode levar alguns minutos',
      [
        {
          text: 'Não',
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: 'Sim',
          onPress: () => {
            this.aceitarLista(this.state.faturas);
          },
          style: 'cancel',
        },
      ],
      {cancelable: false},
    );
  }

  alertAceitarRejeitadas() {
    Alert.alert(
      'Confirmação',
      'Deseja aceitar todas as dívidas rejeitadas? O processo pode levar alguns minutos',
      [
        {
          text: 'Não',
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: 'Sim',
          onPress: () => {
            this.aceitarLista(this.state.rejeitadas);
          },
          style: 'cancel',
        },
      ],
      {cancelable: false},
    );
  }
  render() {
    return (
      <Content style={styles.content} keyboard={true}>
        <TitleBar
          style={[
            styleGlobal.titlebar,
            Actions.getTema('titlebar'),
            {flexDirection: 'column', height: 100},
          ]}>
          <View style={styles.viewhead99}>
            <TouchableOpacity
              style={styles.button1}
              onPress={() => {
                this.backPageButton();
              }}>
              <Icon
                style={[styles.icon, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'arrow-left'}
              />
              <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
                {'Dívidas para aceitar'}
              </Text>
            </TouchableOpacity>
            {(this.state.currentPosition == 1 ||
              this.state.currentPosition == 0) && (
              <TouchableOpacity
                style={styles.button3}
                onPress={() => {
                  if (this.state.currentPosition == 0) {
                    this.alertAceitar();
                  } else if (this.state.currentPosition == 1) {
                    this.alertAceitarRejeitadas();
                  }
                }}>
                <Icon
                  style={[styles.icon3, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'checkbox-marked-circle'}
                />
              </TouchableOpacity>
            )}
            {this.state.ajuda && (
              <TouchableOpacity
                style={styles.buttonhelp}
                onPress={() => {
                  Alert.alert(
                    'Ajuda',
                    'As dívidas que estão aqui, foram enviadas pelos seus amigos, aceite elas para vincular as suas dívidas e aparecer na sua linha do tempo ou recuse para não vincular',
                    [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                  );
                }}>
                <Icon
                  style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'help-circle'}
                />
              </TouchableOpacity>
            )}
            {/* <View style={styles.view} /> */}
          </View>
          <View style={styles.viewhead99}>
            {/* {this.viewpager ? */}
            <HeaderAceitar
              screenProps={this.props.screenProps}
              activity={this}
              navigation={this.props.navigation}
              currentPosition={this.state.currentPosition}
              viewpager={this.viewpager}
            />

            {/* : null} */}
          </View>
        </TitleBar>

        <View style={styles.view2}>
          <ViewPager
            horizontalScroll={true}
            style={[styles.fragment2, {flexGrow: 1}]}
            ref={viewpager => {
              this.viewpager = viewpager;
            }}
            onPageSelected={page => {
              if (page == 0) {
                this.listar();
              } else {
                this.listarRejeitadas();
              }
              this.setState({currentPosition: page.position});
            }}>
            {/* page Todos */}
            <View style={styles.content21}>
              <FlatList
                style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                refreshControl={
                  <RefreshControl
                    refreshing={this.state.load}
                    onRefresh={() => {
                      this.listar();
                    }}
                  />
                }
                renderItem={({item, index}) => {
                  return (
                    <Cell
                      item={item}
                      screenProps={this.props.screenProps}
                      activity={this}
                      navigation={this.props.navigation}
                      rowID={index}
                      tela={'pendentes'}
                    />
                  );
                }}
                ListEmptyComponent={() => {
                  return (
                    <Text
                      style={{
                        textAlign: 'center',
                        color: 'gray',
                        margin: 10,
                      }}>
                      {'Nenhuma dívida foi enviada à você.'}
                    </Text>
                  );
                }}
                data={
                  Array.isArray(this.state.faturas) ? this.state.faturas : []
                }
                keyExtractor={(item, index) => index}
              />
            </View>

            {/* page relacionados */}
            <View style={styles.content21}>
              <FlatList
                style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                refreshControl={
                  <RefreshControl
                    refreshing={this.state.load}
                    onRefresh={() => {
                      this.listarRejeitadas();
                    }}
                  />
                }
                renderItem={({item, index}) => {
                  return (
                    <Cell
                      item={item}
                      screenProps={this.props.screenProps}
                      activity={this}
                      navigation={this.props.navigation}
                      rowID={index}
                      tela={'rejeitadas'}
                    />
                  );
                }}
                ListEmptyComponent={() => {
                  return (
                    <Text
                      style={{
                        textAlign: 'center',
                        color: 'gray',
                        margin: 10,
                      }}>
                      {'Nenhuma dívida rejeitada por você.'}
                    </Text>
                  );
                }}
                data={
                  Array.isArray(this.state.rejeitadas)
                    ? this.state.rejeitadas
                    : []
                }
                keyExtractor={(item, index) => index}
              />
            </View>

            <View style={styles.content21}>
              <FlatList
                style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                refreshControl={
                  <RefreshControl
                    refreshing={this.state.load}
                    onRefresh={() => {
                      this.listarEspera();
                    }}
                  />
                }
                renderItem={({item, index}) => {
                  return (
                    <Cell
                      item={item}
                      screenProps={this.props.screenProps}
                      activity={this}
                      navigation={this.props.navigation}
                      rowID={index}
                      tela={'aguardando'}
                    />
                  );
                }}
                ListEmptyComponent={() => {
                  return (
                    <Text
                      style={{
                        textAlign: 'center',
                        color: 'gray',
                        margin: 10,
                      }}>
                      {'Nenhuma dívida em espera.'}
                    </Text>
                  );
                }}
                data={Array.isArray(this.state.espera) ? this.state.espera : []}
                keyExtractor={(item, index) => index}
              />
            </View>
          </ViewPager>
        </View>
      </Content>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  getColor(flag) {
    if (flag == '2') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    } else if (flag == '1') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    }
  }

  getColorStatus(flag) {
    if (flag == 'N') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    } else if (flag == 'P') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    }
  }

  aceitar() {
    this.props.activity.setState({load: true});
    Divida.aceitar({id: this.props.item.id}, (data, error) => {
      this.props.activity.setState({load: false});
      this.props.activity.listar((data, error) => {
        this.props.activity.listarRejeitadas();
      });
    });
  }
  rejeitar() {
    this.props.activity.setState({load: true});
    Divida.rejeitar({id: this.props.item.id}, (data, error) => {
      this.props.activity.setState({load: false});
      this.props.activity.listar((data, error) => {
        this.props.activity.listarRejeitadas();
      });
    });
  }
  abrir() {
    Alert.alert(
      'Aceitar Dívida',
      'Deseja aceitar esta dívida?',
      [
        {
          text: 'Voltar',
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: 'Não',
          onPress: () => {
            this.rejeitar();
          },
          style: 'cancel',
        },
        {
          text: 'Sim',
          onPress: () => {
            this.aceitar();
          },
        },
      ],
      {cancelable: false},
    );
  }

  getInfos() {
    let id_usuario = 0;
    var contato = false;

    if (this.props.tela == 'aguardando') {
      id_usuario = this.props.item.id_usuario;
    } else {
      if (this.props.item.id_usuario_divida) {
        id_usuario = this.props.item.id_usuario_divida;
      }
    }

    return {
      id_usuario: id_usuario,
      boolcontatoproprio: contato,
    };
  }

  componentWillUnmount() {}

  foto() {
    if (this.props.tela == 'aguardando' && !this.props.item.fotodouser) {
      return (
        <Image
          source={{uri: Usuario.returnFotoGen()}}
          style={styles.image}
          resizeMode={'cover'}
        />
      );
    } else if (this.props.tela == 'aguardando' && this.props.item.fotodouser) {
      return (
        <Image
          source={{uri: this.props.item.fotodouser}}
          style={styles.image}
          resizeMode={'cover'}
        />
      );
    } else if (this.props.item.foto) {
      return (
        <Image
          source={{uri: this.props.item.foto}}
          style={styles.image}
          resizeMode={'cover'}
        />
      );
    } else {
      return (
        <Image
          source={{uri: Usuario.returnFotoGen()}}
          style={styles.image}
          resizeMode={'cover'}
        />
      );
    }
  }

  render() {
    return (
      <View
        style={[
          styles.Cell,
          {...Util.elevation()},
          Actions.getTema('titlebar'),
        ]}
        disabled={this.props.tela == 'aguardando'}>
        <TouchableOpacity
          style={styles.view9}
          onPress={() => {
            Actions.setShowPreInfos({show: true, ...this.getInfos()});
          }}>
          {this.foto()}
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.view131}
          onPress={() => {
            this.abrir();
          }}
          disabled={this.props.tela == 'aguardando'}>
          <View style={styles.view133}>
            <Text style={[styles.text8, Actions.getTema('textcell')]}>
              {this.props.tela == 'aguardando'
                ? this.props.item.nomedouser
                : this.props.item.nome_pessoa}
            </Text>
            <Text
              style={[
                styles.text9,
                {...this.getColor(this.props.item.flag_tipo)},
              ]}>
              {this.props.item.val_montante_f}
            </Text>
          </View>
          <View style={styles.view13}>
            <Text style={[styles.text10, Actions.getTema('textcell2')]}>
              {this.props.item.descricao}
            </Text>
          </View>
          <View style={[styles.view10, Actions.getTema('linhadivida')]} />
          <View style={styles.view15}>
            <Text style={styles.text11}>
              {moment(this.props.item.data_vencimento).format('DD/MM/YYYY')}
            </Text>
            <Text
              style={[
                styles.text91,
                Actions.getTema('textcell'),
                {...this.getColorStatus(this.props.item.flag_status)},
              ]}>
              {this.props.item.descstatus}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(249,249,249,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1,
  },
  view2: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'rgba(238,238,238,1)',
    flex: 1,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
    paddingLeft: 10,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginLeft: 5,
  },
  viewhead99: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
  },
  button3: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  fragment2: {
    alignSelf: 'stretch',
    flex: 1,
  },
  icon3: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(249,249,249,1)',
  },
  view50: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  content21: {
    backgroundColor: null,
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    height: 75,
    marginTop: 10,
  },
  nenhumreg: {
    textAlign: 'center',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'center',
    fontWeight: 'normal',
    flex: 1,
  },
  listview: {
    alignSelf: 'stretch',
    flex: 1,
  },
  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 8,
    margin: 5,
  },
  buttonhelp: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  view9: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 25,
  },
  text8: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
  },
  text9: {
    textAlign: 'right',
    alignSelf: 'center',
    fontWeight: 'bold',
  },
  view10: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    height: 3,
    backgroundColor: 'rgba(249,249,249,1)',
    marginTop: 5,
    marginLeft: 10,
    marginBottom: 5,
  },
  view15: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  view13: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 5,
  },
  text10: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    marginLeft: 10,
    flex: 1,
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginLeft: 5,
  },
  view133: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 5,
  },
  text11: {
    textAlign: 'left',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 10,
    flex: 1,
  },
  text91: {
    textAlign: 'right',
    alignSelf: 'center',
    fontWeight: 'bold',
  },
});
