import React, {PureComponent} from 'react';
import {Image} from 'react-native-1app';
import {StyleSheet, View} from 'react-native';
import {AdMobBanner} from 'react-native-admob';
import * as Actions from '../../redux/actions';
//import DateTime from 'react-native-customize-selected-date'
export default class CellListaMain extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      add: false,
      showmodal: false,
      tema: this.props.screenProps.store.getState().tema,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.unsubscribe = this.props.screenProps.store.subscribe(() => {
        var store = this.props.screenProps.store.getState();
        if (store.tema != this.state.tema) {
          this.setState({tema: store.tema});
        }
      });
    }, 5000);
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  renderBanner() {
    let obj_existe = {
      elevation: 5 / 1,
      shadowColor: '#000000',
      shadowOpacity: 0.5 / 1,
      shadowRadius: 2 / 1,
      shadowOffset: {
        height: 1,
        width: 0,
      },
      flexDirection: 'column',
      alignSelf: 'stretch',
      flex: 1,
      padding: 5,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
      margin: 5,
    };
    return obj_existe;
  }

  render() {
    return (
      <View style={[this.renderBanner(), Actions.getTema('titlebar')]}>
        <View
          style={[
            !this.state.add
              ? {overflow: 'hidden', height: 0}
              : {
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  alignSelf: 'stretch',
                },
          ]}>
          <AdMobBanner
            adSize="{50,50}"
            adUnitID="ca-app-pub-4372253307366883/6382447197"
            onAdFailedToLoad={error => {
              this.setState({add: false});
            }}
            onAdLoaded={error => {
              this.setState({add: true});
            }}
          />
        </View>
        <View style={[this.state.add ? {overflow: 'hidden', height: 0} : {}]}>
          {this.state.tema == 'preto' && (
            <Image
              style={styles.imagelogo}
              source={require('../../../img/Casher_logoescrita2.png')}
              resizeMode={'contain'}
            />
          )}
          {this.state.tema == 'white' && (
            <Image
              style={styles.imagelogo}
              source={require('../../../img/Casher_logoescrita.png')}
              resizeMode={'contain'}
            />
          )}
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginLeft: 5,
  },

  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    borderRadius: 8,
    margin: 5,
  },
  view9: {
    alignSelf: 'stretch',
    
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 25,
  },
  imagelogo: {
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  text8: {
    textAlign: 'left',
    alignSelf: 'center',
    fontWeight: 'bold',
    flex: 1,
    marginLeft: 10,
    fontSize: 16,
  },

  text9: {
    textAlign: 'right',
    alignSelf: 'center',
    fontWeight: 'bold',
    
  },
  view13: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 5,
  },
  view133: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginTop: 5,
  },
  text10: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    marginLeft: 10,
    flex: 1,
  },
  progresslinhas: {
    borderRadius: 20,
    borderWidth: 0,
    color: 'rgba(62,162,67,1)',
    height: 3,
    backgroundColor: 'rgba(177,177,184,1)',
    flex: 1,
    marginLeft: 10,
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginLeft: 5,
  },
  text11: {
    textAlign: 'left',

    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 10,
    flex: 1,
  },
  text91: {
    textAlign: 'right',
    alignSelf: 'center',
    // fontWeight: "bold",
    
  },
});
