import React, { Component } from "react";
import Camera from "react-native-camera";
import { RNCamera } from 'react-native-camera';
import QRCode from 'react-native-qrcode-svg';
import * as Actions from "../../redux/actions";
import { Content, Icon, Image, ListView, TitleBar, Progress, TextInput } from "react-native-1app";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  Alert,
  ImageBackground
} from "react-native";
import styleGlobal from "../../styleGlobal";
import { Util } from "../../infra";
import * as Chat from "../../worker/chat";

import { ViewPager } from 'rn-viewpager';
import Header from "./Header";
export default class ListaPessoasDivida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      user: this.props.screenProps.store.getState().user,
      currentPosition: 0,
      camera: false,
      back: true,
      torch: false,
      tema: this.props.screenProps.store.getState().tema,
      viewpager:{}
    };


  }

  addContato(id) {
    this.setState({ load: true, camera: false });
    Chat.salvarContato({ id_usuario_contato: id, qrcode: true }, (data, error) => {
      let msg = "";
      if (error) {
        msg = error.msg
      } else {
        msg = "Contato adicionado"
      }
      Alert.alert(
        "Aviso",
        msg,
        [
          {
            text: "Ok",
            onPress: () => {
            },
            style: "cancel"
          },
        ],
        { cancelable: false }
      );
      this.setState({ load: false, telefone: "" })
      if (!error) {
        this.backPageButton();
      }
    });
  }



  componentDidMount() {

    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();

      if (store.tema != this.state.tema) {
        this.setState({ tema: store.tema });
      }

    });
    setTimeout( ()=> {
      this.setState({ alterado: true });

    }, 10);

  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }


  backPageButton() {
    if (this.props.navigation.state.params.onGoBack)
      this.props.navigation.state.params.onGoBack();
    this.props.navigation.goBack();
  }


  render() {
    return (
      <Content style={styles.container} keyboard={true}>
        <TitleBar style={[styleGlobal.titlebar, { "flexDirection": "column" }, Actions.getTema("titlebar")]}>
          <View style={styles.viewhead99}>
            {this.viewpager?(
              <Header
                screenProps={this.props.screenProps}
                activity={this}
                navigation={this.props.navigation}
                currentPosition={this.state.currentPosition}
                viewpager={this.viewpager}

                />

            ):null}
          </View>
        </TitleBar>

        <View style={styles.view2}>
          <ViewPager
            horizontalScroll={true}
            style={[styles.fragment2, { flexGrow: 1 }]}
            ref={(viewpager) => { this.viewpager = viewpager }}
            onPageSelected={page => {
              this.setState({ currentPosition: page.position });
            }}
          >
            <View style={styles.content21}>
              {this.state.tema == "white" &&
                <ImageBackground source={require("../../../img/fundo_qrcode.png")} style={{ width: '100%', height: '100%' }}>
                  <View style={styles.view50}>
                    <View style={styles.view5000}>
                      <ImageBackground source={require("../../../img/sqare2.png")} style={styles.imgsqare}>
                        <QRCode
                          style={styles.view10}
                          size={200}
                          color={"rgba(44,126,218,1)"}
                          value={"" + this.state.user.id}
                        />
                      </ImageBackground>
                    </View>
                    {/* <Image source={require("../../../img/qr_logo.png")} style={styles.image2} resizeMode={"cover"} />
                  <Text style={styles.textcontatos}>{"Contatos do Casher"}</Text> */}
                  </View>
                </ImageBackground>
              }
              {this.state.tema == "preto" &&
                <ImageBackground source={require("../../../img/fundo_qrcode_black.png")} style={{ width: '100%', height: '100%' }}>
                  <View style={styles.view50}>
                    <View style={styles.view5000}>
                      <ImageBackground source={require("../../../img/sqare2_black.png")} style={styles.imgsqare}>
                        <QRCode
                          style={styles.view10}
                          size={200}
                          color={"rgba(0,0,0,1)"}
                          value={"" + this.state.user.id}
                        />
                      </ImageBackground>
                    </View>
                    {/* <Image source={require("../../../img/qr_logo.png")} style={styles.image2} resizeMode={"cover"} />
                  <Text style={styles.textcontatos}>{"Contatos do Casher"}</Text> */}
                  </View>
                </ImageBackground>
              }

            </View>

            <View style={styles.content21}>
              <RNCamera
                style={styles.camera}
                type={
                  this.state.back
                    ?
                    RNCamera.Constants.Type.back
                    : RNCamera.Constants.Type.front
                }
                // flashMode={Camera.constants.FlashMode.on}
                flashMode={this.state.torch ? RNCamera.Constants.FlashMode.torch : RNCamera.Constants.FlashMode.off}
                // barCodeTypes={[BarCodeType.qr]}
                onBarCodeRead={code => {
                  if (code && code.data) {
                    this.addContato(code.data);
                  }
                }}
              >
                <View style={styles.view2222}>
                  <TouchableOpacity
                    style={styles.button998}
                    onPress={() => {
                      this.setState({ back: !this.state.back, torch: Camera.constants.TorchMode.off });
                    }}
                  >
                    <Icon
                      style={styles.icon22}
                      fromFontFamily={"Material Design Icons"}
                      name={"camera"}
                    />
                    <Icon
                      style={styles.icon22}
                      fromFontFamily={"Material Design Icons"}
                      name={"repeat"}
                    />
                  </TouchableOpacity>
                  <View style={styles.view2222}></View>
                  {this.state.back ?
                    <TouchableOpacity
                      style={styles.button999}
                      onPress={() => {
                        this.setState({ torch: !this.state.torch });
                      }}
                    >
                      <Icon
                        style={styles.icon22}
                        fromFontFamily={"Material Design Icons"}
                        name={"flash"}
                      />
                    </TouchableOpacity> : null}
                </View>
              </RNCamera>

            </View>

          </ViewPager>
        </View>
      </Content>
    );
  }


}


var styles = StyleSheet.create({
  camera: {
    alignSelf: "stretch",
    flex: 1
  },
  view10: {
    color: "rgba(44,126,218,1)",
    zIndex: -1
  },

  button998: {
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "rgba(44,126,218,1)",
    height: 50,
    padding: 10,
    
  },
  imgsqare: {
    alignItems: "center",
    width: '100%',
    height: '100%',
    alignSelf: "center",
    justifyContent: "center",
  }
  ,
  button999: {
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "rgba(44,126,218,1)",
    height: 50,
    padding: 10,
    
  },
  icon22: {
    color: "rgba(48,129,219,1)",
    fontSize: 45,
    marginRight: 5
  },

  container: {
    flex: 1,
    alignSelf: "stretch",
    backgroundColor: "rgba(255,255,255,1)",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start"
  },


  viewhead99: {
    flexDirection: "row",
    flex: 1,
    alignSelf: "stretch",
  },
  fragment2: {
    alignSelf: "stretch",
    flex: 1

  },
  content21: {
    backgroundColor: null,
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  view50: {
    alignSelf: "center",
    alignItems: "stretch",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1
  },
  view5000: {
    // borderColor: "rgba(48,129,219,1)",
    // borderStyle: "solid",
    // borderWidth: 1,
    height: 300,
    width: 300,
    elevation: (5 / 1),
    shadowColor: "#000000",
    shadowOpacity: (0.5 / 1),
    shadowRadius: (2 / 1),
    shadowOffset: {
      height: 1,
      width: 0
    },
    backgroundColor: "rgba(255,255,255,1)",
    borderRadius: 25,
    padding: 20,
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "rgba(255,255,255,1)"
  },
  view2: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    backgroundColor: "rgba(238,238,238,1)",
    flex: 1
  },
  view2222: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    flexDirection: "row",
    flex: 1

  },
});
