function loadScript(d, s, id, jsSrc, cb) {
  const element = d.getElementsByTagName(s)[0];
  const fjs = element;
  let js = element;
  js = d.createElement(s);
  js.id = id;
  js.src = jsSrc;
  if (fjs && fjs.parentNode) {
    fjs.parentNode.insertBefore(js, fjs);
  } else {
    d.head.appendChild(js);
  }
  js.onload = cb;
}

let FB = window.FB || {};
window.fbAsyncInit = function() {
  FB = window.FB;
  FB.init({
    appId: '269870997030925',
    cookie: true,
    xfbml: true,
    version: 'v5.0',
  });

  FB.AppEvents.logPageView();
  FB = window.FB;
};

loadScript(
  document,
  'script',
  'facebook-jssdk',
  'https://connect.facebook.net/en_US/sdk.js',
  () => {
    console.log('====>Caregado facebook-jssdk<====');
  },
);

const AccessToken = {
  getCurrentAccessToken: () =>
    new Promise(async (resolve, reject) => {
      if (!FB.login) return reject({msg: 'error'});
      FB.login(
        res => {
          if (res && res.authResponse && res.authResponse.accessToken)
            resolve(res.authResponse.accessToken);
          else reject({msg: 'error'});
        },
        {scope: 'public_profile,email'},
      );
    }),
};

const LoginManager = {
  logInWithReadPermissions: () => ({
    then: r => {
      r(true);
    },
  }),
  logInWithPermissions: () => ({
    then: r => {
      r(true);
    },
  }),
};
export {LoginManager, AccessToken};

export default {
  LoginManager,
  AccessToken,
};
