import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {Content, Icon} from 'react-native-1app';
import {StyleSheet, View, TouchableOpacity, Text, Platform} from 'react-native';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPosition: this.props.activity.state.currentPosition,
    };
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    console.log(this.props.currentPosition);
    return (
      <View style={[styles.content, Actions.getTema('titlebar')]}>
        <TouchableOpacity
          onPress={() => {
            this.props.viewpager.setPage(0);
          }}
          style={[
            this.props.currentPosition == 0 ? styles.button : styles.button2,
            Actions.getTema(
              this.props.currentPosition == 0
                ? 'buttonpageview_ativo'
                : 'buttonpageview_desativo',
            ),
          ]}>
          <Text
            style={[
              this.props.currentPosition == 0 ? styles.text : styles.text2,
              Actions.getTema(
                this.props.currentPosition == 0
                  ? 'buttonpageview_ativolbl'
                  : 'nothing',
              ),
            ]}>
            {'Conversas'}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            this.props.currentPosition == 1 ? styles.button : styles.button2,
            Actions.getTema(
              this.props.currentPosition == 1
                ? 'buttonpageview_ativo'
                : 'buttonpageview_desativo',
            ),
          ]}
          onPress={() => {
            this.props.viewpager.setPage(1);
          }}>
          <Text
            style={[
              this.props.currentPosition == 1 ? styles.text : styles.text2,
              Actions.getTema(
                this.props.currentPosition == 1
                  ? 'buttonpageview_ativolbl'
                  : 'nothing',
              ),
            ]}>
            {'Amigos'}
          </Text>
        </TouchableOpacity>
        {Platform.OS != 'web' ? (
          <TouchableOpacity
            style={styles.button3}
            onPress={() => {
              this.props.activity.props.navigation.navigate(
                'ListaContatosTelefone',
                {
                  activity: this,
                  oldState: this.state,
                  tela: 'mensagens',
                  onGoBack: contato => {
                    this.props.activity.setContato(contato);
                  },
                },
              );
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'account-plus'}
            />
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  button: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    borderBottomColor: 'rgba(48,129,219,1)',
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 2,
  },
  text: {
    textAlign: 'center',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  button2: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    backgroundColor: 'rgba(255,255,255,1)',
    flex: 1,
  },
  text2: {
    textAlign: 'center',
    color: 'rgba(198,198,198,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  button3: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    width: 50,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
});
