import React, {Component} from 'react';
// import TextInput from "../../components/OutlinedInput";
import {
  Content,
  Icon,
  Image,
  Progress,
  TitleBar,
  TextInput,
} from 'react-native-1app';
import {openDialog} from 'react-native-1app/lib/DialogAlert';
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  ImageBackground,
} from 'react-native';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import {Util} from '../../infra';
import {loginPadrao} from '../../redux/actions';
import Cadastro from './Cadastro';
import * as LoginApp from '../../worker/login';
import {recuperarSenha} from '../../worker/usuario';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // email: "te",
      // senha: "oi",
      secure: true,
      load: false,
      rEmail: '',
    };
    this.dispatch = this.props.screenProps.store.dispatch;
  }

  componentDidMount() {
    // this.logarApp();
  }

  componentWillUnmount() {}

  logarApp() {
    if (!this.state.email || !this.state.senha)
      return showMessage('Digite email e senha para continuar');
    this.setState({load: true});
    loginPadrao(this.state, this.dispatch, (user, error) => {
      // console.log(user,error)
      if (error) {
        showMessage({
          message: 'Falha no login',
          description: error.msg,
          type: 'warning',
          icon: {icon: 'warning', position: 'left'},
          duration: 2000,
        });
      } else {
        this.props.navigation.popToTop();
      }
      this.setState({load: false});
    });
  }

  abrirEmail() {
    Util.openEmail(
      'email',
      'Solicitação de login',
      'Olá, gostaria de um login e senha para utilizar o aplicativo',
    );
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  openPageButton111() {
    this.props.navigation.navigate('Cadastro');
  }

  openRecuperarSenha() {
    openDialog({
      title: 'Recuperar senha',
      descricao: `Uma nova senha sera enviada para seu email.`,
      align: 'top',
      actions: [
        {
          title: 'RECUPERAR',
          onPress: () => this.recuperar(),
        },
        {
          title: 'CANCELAR',
        },
      ],
      input: {
        label: 'Email',
        placeholder: 'Email de sua conta cadastrada',
        onChange: rEmail => this.setState({rEmail}),
        onSubmitEditing: () => this.recuperar(),
        returnKeyType: 'search',
        autoFocus: true,
      },
    });
  }

  recuperar() {
    recuperarSenha(this.state.rEmail, (res, error) => {
      if (error) {
        return showMessage({
          message: 'Falha alterar a senha',
          description: error.msg,
          type: 'warning',
          duration: 2000,
        });
      }
      return showMessage({
        message: 'Senha alterada',
        description: res.msg,
        type: 'success',
        duration: 2000,
      });
    });
  }

  render() {
    return (
      <Content style={styles.content} keyboard={true}>
        <ImageBackground
          resizeMode={'cover'} // or cover
          style={{flex: 1, width: '100%'}}
          source={require('../../../img/fundo_casher_2.png')}>
          <TitleBar style={styles.titlebar} removeShadow={true}>
            <TouchableOpacity
              style={styles.button3}
              onPress={() => {
                this.backPageButton();
              }}>
              <Icon
                style={styles.icon}
                fromFontFamily={'Material Icons'}
                name={'keyboard_backspace'}
              />
            </TouchableOpacity>
          </TitleBar>
          <ScrollView style={styles.scroll} keyboardShouldPersistTaps={true}>
            <View style={{justifyContent: 'center', alignItems: 'center'}}>
              <Image
                style={styles.imagelogo}
                source={require('../../../img/logo-com-escrita_branco.png')}
                resizeMode={'contain'}
              />
              <View style={styles.view}>
                <View style={styles.viewvolta}>
                  <Icon
                    style={styles.icon}
                    fromFontFamily={'Material Design Icons'}
                    name={'account'}
                  />
                  <TextInput
                    style={styles.textinput1}
                    value={this.state.email}
                    onChange={value => {
                      this.setState({email: value});
                    }}
                    autoCapitalize="none"
                    keyboardType={'email-address'}
                    placeholder={'Email'}
                    inputNative={true}
                    onSubmitEditing={() => {
                      this.textinputSenha.focus();
                    }}
                  />
                </View>

                <View style={styles.viewvolta}>
                  <Icon
                    style={styles.icon}
                    fromFontFamily={'Material Design Icons'}
                    name={'lock'}
                  />
                  <TextInput
                    style={styles.textinput1}
                    value={this.state.senha}
                    onChange={value => {
                      this.setState({senha: value});
                    }}
                    autoCapitalize="none"
                    placeholder={'Senha'}
                    secureTextEntry={this.state.secure}
                    inputNative={true}
                    onSubmitEditing={() => {
                      this.logarApp();
                    }}
                    ref={v => (this.textinputSenha = v)}
                  />

                  <TouchableOpacity
                    style={styles.button4}
                    onPress={() => {
                      this.setState({secure: !this.state.secure});
                    }}>
                    <Icon
                      style={styles.icon}
                      fromFontFamily={'Material Design Icons'}
                      name={!this.state.secure ? 'eye' : 'eye-off'}
                    />
                  </TouchableOpacity>
                </View>

                <TouchableOpacity
                  style={styles.button}
                  onPress={() => {
                    this.logarApp();
                  }}
                  disabled={this.state.load}
                  elevation={2}>
                  {!this.state.load ? (
                    <Text style={styles.text1}>{'Logar'}</Text>
                  ) : null}

                  {this.state.load ? (
                    <Progress style={styles.progress} />
                  ) : null}
                </TouchableOpacity>

                <TouchableOpacity
                  style={styles.button111}
                  onPress={() => {
                    this.openPageButton111();
                  }}>
                  <Text style={styles.text1111}>{'Cadastrar'}</Text>
                </TouchableOpacity>
              </View>
            </View>
          </ScrollView>
          <View style={[styles.footer]}>
            <TouchableOpacity
              style={styles.button1}
              onPress={() => {
                this.openRecuperarSenha();
              }}
              elevation={2}>
              <Text style={styles.text11}>{'Esqueci minha senha'}</Text>
            </TouchableOpacity>
          </View>
        </ImageBackground>
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  scroll: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
  },
  content: {
    backgroundColor: 'rgba(242,242,242,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  titlebar: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignSelf: 'stretch',
    height: 50,
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  button111: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    flexDirection: 'column',
    borderRadius: 22,
    backgroundColor: 'rgba(232,69,60,0)',
    borderColor: '#555D71',
    borderWidth: 2,
    marginTop: 25,
  },
  button4: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 25,
    flexDirection: 'column',
    width: 50,
  },
  icon: {
    color: 'white',
    fontSize: 25,
  },
  image: {
    width: 180,
    height: 80,
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 15,
  },
  view: {
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: 300,
    paddingTop: 20,
  },
  text2: {
    textAlign: 'center',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    margin: 15,
    fontSize: 20,
    marginTop: 5,
  },
  view2: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 45,
    backgroundColor: 'rgba(255,255,255,1)',
    paddingLeft: 10,
    borderRadius: 5,
  },
  textinput1: {
    color: 'rgba(255,255,255,1)',
    backgroundColor: 'rgba(0,0,0,0)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
    flex: 1,
    marginLeft: 10,
  },
  view3: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 45,
    backgroundColor: 'rgba(255,255,255,1)',
    paddingLeft: 10,
    borderRadius: 5,
    marginTop: 20,
  },
  textinput2: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  button: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    flexDirection: 'column',
    borderRadius: 22,
    backgroundColor: 'rgba(58,121,172,1)',
    marginTop: 50,
  },
  text1: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: '600',
  },
  progress: {
    width: 35,
    height: 35,
    color: 'white',
  },
  button1: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    flexDirection: 'column',
    borderRadius: 22,
    backgroundColor: 'rgba(210,210,210,0)',
    marginTop: 20,
  },
  text11: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    // fontWeight: "600"
  },
  view4: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: 20,
    marginTop: 15,
    borderStyle: 'solid',
    borderTopColor: 'rgba(150,150,150,0.3)',
    borderTopWidth: 1,
  },
  text5: {
    textAlign: 'center',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    fontSize: 12,
  },
  button21: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    flexDirection: 'column',
    marginTop: 10,
    borderRadius: 22,
    backgroundColor: 'rgba(215,172,46,1)',
    paddingLeft: 20,
    paddingRight: 20,
  },
  text4: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: '600',
  },
  imagelogo: {
    width: 150,
    height: 150,
    zIndex: 10,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  viewvolta: {
    borderBottomColor: 'white',
    borderBottomWidth: 1,
    paddingBottom: 10,
    flexDirection: 'row',
    width: 300,
    marginTop: 20,
  },
  text1111: {color: 'white'},
});
