import React, {Component, PureComponent} from 'react';
import * as Actions from '../../redux/actions';
import {Content, Icon, Progress} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Dimensions,
  Platform,
} from 'react-native';

import {Calendar, LocaleConfig} from 'react-native-calendars';
import styleGlobal from '../../styleGlobal';
import moment from 'moment';
import DateTime from '../../components/DateTime';
import Calendario from './CalendarioPelotas';

LocaleConfig.locales['pt-br'] = {
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul.',
    'Ago',
    'Set.',
    'Out.',
    'Nov.',
    'Dez',
  ],
  dayNames: [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ],
  dayNamesShort: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
};

LocaleConfig.defaultLocale = 'pt-br';

export default class CalendarioPequeno extends Component {
  constructor(props) {
    super(props);
    this.state = {
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <TouchableOpacity
        style={{padding: 5}}
        activeOpacity={0.7}
        disabled={this.props.load}
        onPress={() => {
          if (this.props.onMonth) this.props.onMonth(this.props.data);
        }}>
        <Calendario
          {...this.props}
          dia={this.props.data}
          renderCustomDay={date => {
            return (
              <DayComponent
                listaDividas={this.props.listaDividas}
                screenProps={this.props.screenProps}
                date={{
                  day: moment(date).format('DD'),
                  month: moment(date).format('MM'),
                  year: moment(date).format('YYYY'),
                  dateString: date,
                }}
                load={this.props.load}
                existente={this.props.listaDividas.find(divida => {
                  if (
                    moment(Object.keys(divida)[0]).isSame(moment(date), 'day')
                  )
                    return divida;
                })}
              />
            );
          }}
        />
      </TouchableOpacity>
    );
  }
}

class DayComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderDivida(date) {
    if (this.props.load) return null;
    if (this.props.existente) {
      if (this.props.existente[Object.keys(this.props.existente)[0]] == 3) {
        return (
          <View style={{position: 'absolute', flexDirection: 'column'}}>
            <View
              style={{
                height: 30,
                width: 30,
                backgroundColor: 'rgb(247,49,66)',
              }}
            />
            <View
              style={{
                height: 30,
                width: 30,
                backgroundColor: 'rgb(66,126,218)',
              }}
            />
          </View>
        );
      } else if (
        this.props.existente[Object.keys(this.props.existente)[0]] == 1
      )
        return <View style={styles.viewAzul} />;
      else if (this.props.existente[Object.keys(this.props.existente)[0]] == 2)
        return <View style={styles.viewVermelha} />;
    }

    return null;
  }

  getColorText(date) {
    return this.props.existente
      ? 'white'
      : this.props.screenProps.store.getState().tema == 'preto'
      ? 'rgb(230,230,230)'
      : 'rgb(100,100,100)';
  }

  render() {
    return (
      <View style={styles.viewDia}>
        <Text
          style={{
            color: this.getColorText(this.props.date),
            fontWeight: '600',
            zIndex: 50,
          }}>
          {this.props.date.day}
        </Text>
        {this.renderDivida(this.props.date)}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  viewDia: {
    width: 20,
    height: 20,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    margin: 2,
  },
  progress: {width: 50, height: 50, color: styleGlobal.azul.color},
  viewAzul: {
    height: 30,
    width: 30,
    backgroundColor: 'rgb(66,126,218)',
    position: 'absolute',
  },
  viewVermelha: {
    height: 30,
    width: 30,
    backgroundColor: 'rgb(247,49,66)',
    position: 'absolute',
  },
});
