import React, {Component} from 'react';

import {Content} from 'react-native-1app';
import {StyleSheet, View, Platform} from 'react-native';
import Dashboard from './Dashboard.js';
import PrimeiraVez from './user/PrimeiraVez.js';
import Login from './acesso';
import Telefone from './user/Telefone';
import * as actions from '../redux/actions';

import PreInfos from './user/PreInfos';
import moment from 'moment-timezone';
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.screenProps.store.getState().user,
      lastexit: this.props.screenProps.store.getState().lastexit,
      tempopin: this.props.screenProps.store.getState().tempopin,
      pin: this.props.screenProps.store.getState().pin,
      recemativou: this.props.screenProps.store.getState().recemativou,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user) this.setState({user: store.user});
      if (store.lastexit != this.state.lastexit)
        this.setState({lastexit: store.lastexit});
      if (store.pin != this.state.pin) this.setState({pin: store.pin});
      if (store.tempopin != this.state.tempopin)
        this.setState({tempopin: store.tempopin});

      if (store.pin && store.recemativou && store.tempopin) {
        this.testaTempoPin();
      }
    });

    if (this.state.pin && this.state.user) {
      this.testaTempoPin();
    }
    actions.setChatAtivo(false);
    // actions.setCor("white");
  }
  testaTempoPin() {
    var date1 = this.state.lastexit;
    var date2 = moment();
    var duration = moment.duration(date2.diff(date1));
    duration = duration.asSeconds();

    if (parseInt(this.state.tempopin) < duration) {
      this.props.navigation.navigate('InserirPin', {
        activity: this,
        oldState: this.state,
      });
    }
    actions.setRecemAtivou(false);
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  render() {
    return (
      <Content style={styles.content}>
        {this.state.user &&
        this.state.user.telefone &&
        this.state.user.flag_primeira_vez &&
        Platform.OS != 'web' ? (
          <PrimeiraVez
            style={styles.fragment1}
            screenProps={this.props.screenProps}
            navigation={this.props.navigation}
            activity={this}
          />
        ) : this.state.user &&
          (!this.state.user.telefone || !this.state.user.data_nascimento) ? (
          <Telefone
            style={styles.fragment1}
            screenProps={this.props.screenProps}
            navigation={this.props.navigation}
            activity={this}
          />
        ) : null}

        {this.state.user &&
        this.state.user.telefone &&
        this.state.user.data_nascimento &&
        !this.state.user.flag_primeira_vez ? (
          <Dashboard
            style={styles.fragment1}
            screenProps={this.props.screenProps}
            navigation={this.props.navigation}
            activity={this}
          />
        ) : null}

        {!this.state.user ? (
          <Login
            style={styles.fragment2}
            screenProps={this.props.screenProps}
            navigation={this.props.navigation}
            activity={this}
          />
        ) : null}

        {/* <PreInfos
          onClose={(data) => {
            if (data == "pesquisa") {
              actions.setTrocaPagdash(0)
            }
            actions.setShowPreInfos({ show: false, id_usuario: 0 })
          }}
          navigation={this.props.navigation}
          screenProps={this.props.screenProps}
        /> */}
      </Content>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(218,218,218,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  fragment1: {
    alignSelf: 'stretch',
    flex: 1,
  },
  fragment2: {
    alignSelf: 'stretch',
    flex: 1,
  },
});
