import React, {Component} from 'react';

import {Content, Icon, Image, Progress, TitleBar} from 'react-native-1app';
import TextInput from 'react-native-1app/lib/TextInput';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  BackHandler,
  Platform,
} from 'react-native';
import Alert from 'react-native-1app/lib/Alert';
import {MaskService} from 'react-native-masked-text';
import styleGlobal from '../../styleGlobal';
import DatePicker from '../../components/DatePicker';
import {Util} from '../../infra';
import * as Divida from '../../worker/divida';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import {TextInputMask} from 'react-native-masked-text';
import * as Actions from '../../redux/actions';
import {requestContatosPermission} from '../../redux/actions';
import moment from 'moment-timezone';
export default class NovaDivida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      divida: {
        flag_tipo: 1,
        data_debito: moment(),
        num_vezes: '1',
        carregar: true,
        dividir_montante: false,
        enumerar: false,
      },
      ajuda: this.props.screenProps.store.getState().ajuda,
      openparcelasfields: false,
      contato: this.propsNav().contato,
      arquivada: this.propsNav().arquivada,
      excluida: this.propsNav().excluida ? true : false,
    };
  }

  relacionarPessoaDivida() {
    Alert.alert(
      'Relacionar Contato a Dívida',
      'Ao escolher um contato, a ação não poderá ser desfeita. Deseja continuar?',
      [
        {text: 'Cancelar', onPress: () => {}, style: 'cancel'},
        {
          text: 'Ok',
          onPress: () => {
            this.props.navigation.navigate('Amigos', {
              activity: this,
              oldState: this.state,
              id_divida: this.state.divida.id,
              onGoBack: () => {
                this.abrir(this.state.divida.id);
              },
            });
          },
        },
      ],
      {cancelable: false},
    );
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  backPageButton() {
    if (this.propsNav().atualizar) this.propsNav().atualizar();
    if (this.props.navigation.state.params.onGoBack)
      this.props.navigation.state.params.onGoBack();
    this.props.navigation.goBack();
  }

  status(flag) {
    if (flag == 'P') {
      return {color: 'rgba(44,126,218,1)'};
    } else if (flag == 'N') {
      return {color: 'rgba(255, 0, 0,1)'};
    }
  }

  salvar() {
    var montante = 0;
    this.setState({load: true});
    if (!this.state.divida.id) {
      try {
        montante = this.state.divida.val_montante
          ? Util.maskMoneyServicetoRawValue(this.state.divida.val_montante)
          : 0;
      } catch (error) {
        if (error) {
          Alert.alert(
            'Erro',
            JSON.stringify(error),
            [{text: 'Ok', onPress: () => {}}],
            {cancelable: false},
          );
        }
        return;
      }

      if (!this.state.divida.data_vencimento) {
        return Alert.alert(
          'Erro',
          'Data de vencimento não informada.',
          [{text: 'Ok', onPress: () => {}}],
          {cancelable: false},
        );
      } else {
        this.state.divida.data_vencimento = moment(
          this.state.divida.data_vencimento,
        );
        this.state.divida.data_vencimento.set({
          hour: 23,
          minute: 59,
          second: 59,
        });
      }

      Divida.insert(
        {...this.state.divida, ...{val_montante: montante}},
        (data, error) => {
          if (error) {
            showMessage({
              message: 'Erro salvar',
              description: error.msg,
              type: 'warning',
              icon: {icon: 'warning', position: 'left'},
              duration: 2000,
            });
            this.setState({load: false});
          } else if (data.id) {
            showMessage({
              message: 'Dívida criada!',
              type: 'success',
              duration: 2000,
            });
            this.abrir(data.id);
            // this.setState({ divida: data, load: false });
          }
        },
      );
    } else {
      Divida.alterar(
        {...this.state.divida, ...{val_montante: montante}},
        (data, error) => {
          if (error) {
            showMessage({
              message: 'Erro salvar',
              description: error.msg,
              type: 'warning',
              icon: {icon: 'warning', position: 'left'},
              duration: 2000,
            });
            this.setState({load: false});
          } else if (data.id) {
            showMessage({
              message: 'Divida salva!',
              type: 'success',
              duration: 2000,
            });
            if (this.propsNav().atualizar) this.propsNav().atualizar();
            this.abrir(data.id);
          }
          // this.setState({ divida: data, load: false });
        },
      );
    }
  }

  quitarDivida() {
    Alert.alert(
      'Aviso',
      'Tem certeza que deseja quitar esta dívida? Os valores não poderão ser modificados após essa operação',
      [
        {text: 'Não', onPress: () => {}, style: 'cancel'},
        {
          text: 'Sim',
          onPress: () => {
            this.setState({load: true});
            Divida.quitar(this.state.divida, (data, error) => {
              this.setState({load: false});
              if (error) {
                showMessage({
                  message: 'Erro ao quitar',
                  description: error.msg,
                  type: 'warning',
                  icon: {icon: 'warning', position: 'left'},
                  duration: 2000,
                });
              } else if (data.id) {
                showMessage({
                  message: 'Divida quitada!',
                  type: 'success',
                  duration: 2000,
                });
                if (this.propsNav().atualizar) this.propsNav().atualizar();
                //this.abrir(data.id);
                this.backPageButton();
              }
            });
          },
        },
      ],
      {cancelable: false},
    );
  }

  restaurarDivida() {
    Alert.alert(
      'Aviso',
      'Tem certeza que deseja restaurar esta dívida?',
      [
        {text: 'Não', onPress: () => {}, style: 'cancel'},
        {
          text: 'Sim',
          onPress: () => {
            this.setState({load: true});
            Divida.restaurarDivida(this.state.divida, (data, error) => {
              this.setState({load: false});
              if (error) {
                showMessage({
                  message: 'Erro ao restaurar',
                  description: error.msg,
                  type: 'warning',
                  icon: {icon: 'warning', position: 'left'},
                  duration: 2000,
                });
              } else if (data.id) {
                showMessage({
                  message: 'Divida restaurada!',
                  type: 'success',
                  duration: 2000,
                });
                if (this.propsNav().atualizar) this.propsNav().atualizar();
                this.setState({excluida: false}, () => {
                  this.abrir(data.id);
                });
              }
            });
          },
        },
      ],
      {cancelable: false},
    );
  }

  limparDivida() {
    if (this.state.divida.id) {
      this.abrir(this.state.divida.id);
    } else {
      this.setState({
        divida: {
          flag_tipo: 1,
          data_debito: moment(),
          num_vezes: '1',
          carregar: true,
          dividir_montante: false,
        },
      });
    }
  }

  deletecancelarfunc() {
    if (this.state.divida.id) {
      this.setState({load: true});
      Divida.cancelar({id: this.state.divida.id}, (data, error) => {
        if (error) {
          showMessage({
            message: 'Erro salvar',
            description: error.msg,
            type: 'warning',
            icon: {icon: 'warning', position: 'left'},
            duration: 2000,
          });
          this.setState({load: false});
        } else if (data.id) {
          showMessage({
            message: 'Divida arquivada!',
            type: 'success',
            duration: 2000,
          });
          if (this.propsNav().atualizar) this.propsNav().atualizar();
          this.setState({divida: {}, load: false});
        }
      });
    } else {
      this.backPageButton();
    }
  }

  deletecancelar() {
    Alert.alert(
      'Aviso',
      'Tem certeza que deseja arquivar esta dívida?',
      [
        {text: 'Não', onPress: () => {}, style: 'cancel'},
        {
          text: 'Sim',
          onPress: () => {
            this.deletecancelarfunc();
          },
        },
      ],
      {cancelable: false},
    );
  }

  desarquivar() {
    this.setState({load: true});
    Divida.desarquivar({id: this.state.divida.id}, (data, error) => {
      this.setState({load: false});
      if (data) {
        if (this.propsNav().atualizar) this.propsNav().atualizar();
        this.setState({arquivada: false}, () => {
          this.abrir(data.id);
        });
      } else {
        Alert.alert(
          'Aviso',
          error.msg,
          [
            {
              text: 'Ok',
              onPress: () => {},
            },
          ],
          {cancelable: false},
        );
      }
    });
  }

  abrir(id) {
    this.setState({load: true});
    if (this.state.arquivada) {
      Divida.listarArquivada({id: id}, (data, error) => {
        this.setState({load: false});
        if (data && data[0]) {
          this.setState({divida: data[0]});
        }
      });
    } else if (this.state.excluida) {
      Divida.listar(
        {id: id, mostrarexcluidas: this.state.excluida},
        (data, error) => {
          this.setState({load: false});
          if (data && data[0]) {
            this.setState({divida: data[0]});
          }
        },
      );
    } else {
      Divida.listar({id: id}, (data, error) => {
        this.setState({load: false});
        if (data && data[0]) {
          this.setState({divida: data[0]});
        }
      });
    }
  }

  escolherData() {
    this.setState({carregar: false});
    this.props.navigation.navigate('EscolherVencimento', {
      activity: this,
      oldState: this.state,
      onGoBack: state => {
        this.state.divida.data_vencimento = state.data_vencimento;
        this.setState({divida: this.state.divida});
        // setTimeout(() => {
        //   this.setState({ carregar: true });
        // }, 2000);
      },
    });
  }
  setContato(contato) {
    console.log(contato);
    if (contato.tela == 'amigos') {
      this.state.divida.id_usuario_divida = contato.id_usuario_contato;
      this.state.divida.id_contato_proprio_divida = undefined;
    } else if (contato.tela == 'contatos') {
      this.state.divida.id_contato_proprio_divida = contato.id;
      this.state.divida.id_usuario_divida = undefined;
      this.state.divida.flag_pode_editar = 'C';
    } else if (contato.tela == 'preinfo') {
      if (contato.boolcontatoproprio) {
        this.state.divida.id_contato_proprio_divida = contato.id;
      } else {
        this.state.divida.id_usuario_divida = contato.id;
      }
      this.state.divida.flag_pode_editar = 'C';
    }

    this.state.divida.nome_pessoa = contato.nome;
    this.state.divida.foto = contato.foto;
    this.setState({divida: this.state.divida});
  }

  dividaExcluirAlerta() {
    Alert.alert(
      'Aviso',
      'Deseja mesmo excluir esta dívida?',
      [
        {text: 'Cancelar', onPress: () => {}, style: 'cancel'},
        {
          text: 'Ok',
          onPress: () => {
            this.dividaExcluir();
          },
        },
      ],
      {cancelable: false},
    );
  }

  dividaExcluirAlertaDisabled() {
    Alert.alert(
      'Aviso',
      'A dívida só poderá ser excluída após estar quitada',
      [
        {
          text: 'Ok',
          onPress: () => {},
        },
      ],
      {cancelable: false},
    );
  }

  dividaExcluir() {
    this.setState({load: true});
    Divida.dividaExcluir({id: this.state.divida.id}, (data, error) => {
      this.setState({load: false});
      let msg = '';
      if (error) {
        msg = error.msg;
      } else {
        if (this.propsNav().atualizar) this.propsNav().atualizar();
        msg = 'Dívida excluída';
      }
      Alert.alert(
        'Aviso',
        msg,
        [
          {
            text: 'Ok',
            onPress: () => {},
          },
        ],
        {cancelable: false},
      );

      this.backPageButton();
    });
  }

  componentDidMount() {
    BackHandler.addEventListener('hardwareBackPress', () => {
      this.backPageButton();
      return true;
    });

    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.ajuda != this.state.ajuda) this.setState({ajuda: store.ajuda});
    });

    if (this.propsNav().id_divida) {
      this.abrir(this.propsNav().id_divida);

      this.subs = [
        this.props.navigation.addListener('didFocus', payload => {
          if (this.state.carregar) this.abrir(this.propsNav().id_divida);
          else {
            this.setState({carregar: true});
          }
        }),
      ];
    }
    if (this.state.contato) {
      this.setContato(this.state.contato);
    }
    console.log(this.propsNav());
  }

  trocarPermission() {
    Alert.alert(
      'Aviso',
      'Deseja mudar as permissões de edição desta dívida?',
      [
        {
          text: 'Não',
          onPress: () => {},
        },
        {
          text: 'Sim',
          onPress: () => {
            this.setState({load: true});
            Divida.trocarPermission(this.state.divida, (data, error) => {
              this.setState({load: false});
              if (error) {
                Alert.alert('Erro', error, [{text: 'Ok', onPress: () => {}}], {
                  cancelable: false,
                });
              } else {
                if (this.propsNav().atualizar) this.propsNav().atualizar();
                this.abrir(this.propsNav().id_divida);
              }
            });
          },
        },
      ],
      {cancelable: false},
    );
  }
  getInfos() {
    let id_usuario = 0;
    var contato = false;
    if (this.state.divida.id_contato_proprio_divida) {
      id_usuario = this.state.divida.id_contato_proprio_divida;
      contato = true;
    }

    if (this.state.divida.id_usuario_divida) {
      id_usuario = this.state.divida.id_usuario_divida;
    }

    return {
      id_usuario: id_usuario,
      boolcontatoproprio: contato,
      tela: 'divida',
    };
  }

  componentWillUnmount() {
    if (this.subs) this.subs.forEach(sub => sub.remove());
    if (this.unsubscribe) this.unsubscribe();
  }

  goContatos() {
    this.props.navigation.navigate('ListaContatos', {
      activity: this,
      oldState: this.state,
      tela: 'divida',
      onGoBack: contato => {
        this.setContato(contato);
      },
    });
  }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
            <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
              {this.state.divida.id ? 'Editar a dívida' : 'Nova dívida'}
            </Text>
          </TouchableOpacity>
          {/* <View style={styles.view} /> */}

          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}

          {!this.state.excluida && (
            <TouchableOpacity
              style={[styles.button21]}
              onPress={() => {
                this.limparDivida();
              }}
              disabled={!Divida.testeDividaEdicao(this.state.divida)}>
              <Icon
                style={[
                  styles.icon41,
                  Actions.getTema('iconstitlebar'),
                  Divida.testeDividaEdicaoColor(this.state.divida),
                ]}
                fromFontFamily={'Material Design Icons'}
                name={'close-circle'}
              />
            </TouchableOpacity>
          )}

          {this.state.divida.id &&
            this.state.divida.flag_status == 'P' &&
            this.state.excluida == false && (
              <TouchableOpacity
                style={[styles.button21]}
                onPress={() => {
                  this.dividaExcluirAlerta();
                }}>
                <Icon
                  style={[
                    styles.icon41,
                    Divida.testeDividaEdicaoColor(this.state.divida),
                    Actions.getTema('iconstitlebar'),
                  ]}
                  fromFontFamily={'Material Design Icons'}
                  name={'delete'}
                />
              </TouchableOpacity>
            )}

          {this.state.divida.id &&
            this.state.divida.flag_status != 'P' &&
            this.state.excluida == false && (
              <TouchableOpacity
                style={[styles.button21]}
                onPress={() => {
                  this.dividaExcluirAlertaDisabled();
                }}>
                <Icon
                  style={[styles.icon49]}
                  fromFontFamily={'Material Design Icons'}
                  name={'delete'}
                />
              </TouchableOpacity>
            )}
          {!this.state.load && this.state.excluida == false ? (
            <TouchableOpacity
              style={[styles.button3]}
              onPress={() => {
                this.salvar();
              }}
              disabled={!Divida.testeDividaEdicao(this.state.divida)}>
              <Icon
                style={[
                  styles.icon3,
                  Actions.getTema('iconstitlebar'),
                  Divida.testeDividaEdicaoColor(this.state.divida),
                ]}
                fromFontFamily={'Material Icons'}
                name={'save'}
              />
            </TouchableOpacity>
          ) : null}

          {this.state.divida.id &&
          this.state.excluida == false &&
          !this.state.divida.id_usuario_divida ? (
            <TouchableOpacity
              style={styles.button3}
              onPress={() => {
                this.relacionarPessoaDivida();
              }}>
              <Icon
                style={[styles.icon3, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'account-switch'}
              />
            </TouchableOpacity>
          ) : null}
        </TitleBar>
        <ScrollView
          style={[styles.scroll, Actions.getTema('scroll')]}
          keyboardShouldPersistTaps={true}>
          <View style={styles.view7}>
            <TouchableOpacity
              style={styles.button9}
              onPress={() => {
                this.state.divida.flag_tipo = 1;
                this.setState({divida: this.state.divida});
              }}
              disabled={this.state.divida.id}>
              <Icon
                style={[styles.icon7, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={
                  this.state.divida.flag_tipo == 1
                    ? 'check-circle'
                    : 'checkbox-blank-circle-outline'
                }
              />
              <View style={styles.view61}>
                <Text style={[styles.text611, Actions.getTema('labelfields')]}>
                  {'Devido a mim'}
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button8}
              onPress={() => {
                this.state.divida.flag_tipo = 2;
                this.setState({divida: this.state.divida});
              }}
              disabled={this.state.divida.id}>
              <Icon
                style={[styles.icon7, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={
                  this.state.divida.flag_tipo == 2
                    ? 'check-circle'
                    : 'checkbox-blank-circle-outline'
                }
              />
              <View style={styles.view6}>
                <Text style={[styles.text611, Actions.getTema('labelfields')]}>
                  {'Devido por mim'}
                </Text>
              </View>
            </TouchableOpacity>
            {this.state.ajuda && (
              <TouchableOpacity
                style={styles.buttonhelp}
                onPress={() => {
                  Alert.alert(
                    'Ajuda',
                    'Devido a mim é quando seu amigo está em débito com você, Devido por mim é quando você está em débito com seu amigo.',
                    [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                  );
                }}>
                <Icon
                  style={[styles.icon39, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'help-circle'}
                />
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.view8}>
            <TextInput
              style={[styles.textinput3, Actions.getTema('labelfields')]}
              value={this.state.divida.nome_pessoa}
              onChange={value => {
                this.state.divida.nome_pessoa = value;
                this.setState({divida: this.state.divida});
              }}
              keyboardType={'default'}
              label={'Nome (obrigatório)'}
              editable={false}
            />
            {this.state.divida.id ? null : (
              <TouchableOpacity
                style={styles.button10}
                onPress={() => {
                  if (Platform.OS == 'web') return this.goContatos();

                  requestContatosPermission((data, error) => {
                    this.goContatos();
                  });
                }}>
                {this.state.divida.foto ? (
                  <Image
                    source={{uri: this.state.divida.foto}}
                    style={styles.image}
                    resizeMode={'cover'}
                  />
                ) : (
                  <Image
                    source={require('../../../img/icone_add.png')}
                    style={styles.image}
                    resizeMode={'cover'}
                  />
                )}
              </TouchableOpacity>
            )}

            {this.state.divida.id && (
              <TouchableOpacity
                style={styles.button10}
                onPress={() => {
                  Actions.setShowPreInfos({show: true, ...this.getInfos()});
                }}>
                {this.state.divida.foto ? (
                  <Image
                    source={{uri: this.state.divida.foto}}
                    style={styles.image}
                    resizeMode={'cover'}
                  />
                ) : (
                  <Image
                    source={require('../../../img/icone_add.png')}
                    style={styles.image}
                    resizeMode={'cover'}
                  />
                )}
              </TouchableOpacity>
            )}
          </View>

          {this.state.divida.id_usuario_divida && !this.state.divida.id && (
            <View style={styles.viewhelp}>
              <TouchableOpacity
                style={styles.button9}
                onPress={() => {
                  this.state.divida.flag_pode_editar =
                    this.state.divida.flag_pode_editar == 'A' ? 'C' : 'A';
                  this.setState({divida: this.state.divida});
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.divida.flag_pode_editar == 'A'
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {'  Permitir edição por ambos'}
                  </Text>
                </View>
              </TouchableOpacity>
              {this.state.ajuda && (
                <TouchableOpacity
                  style={styles.buttonhelp2}
                  onPress={() => {
                    Alert.alert(
                      'Ajuda',
                      'Deixe selecionado caso deseje que o seu amigo também possa fazer alteração na dívida, senão somente você poderá editar a dívida',
                      [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                    );
                  }}>
                  <Icon
                    style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={'help-circle'}
                  />
                </TouchableOpacity>
              )}
            </View>
          )}

          {this.state.divida.id_usuario_divida && this.state.divida.id && (
            <View style={styles.viewhelp}>
              <TouchableOpacity
                style={styles.button9}
                onPress={() => {
                  this.trocarPermission();
                }}
                disabled={this.state.divida.flag_espelho == 'S'}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.divida.flag_pode_editar == 'A'
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {this.state.divida.flag_espelho == 'S'
                      ? '  Permitida a edição por mim'
                      : '  Permitida a edição por ambos'}
                  </Text>
                </View>
              </TouchableOpacity>
              {this.state.ajuda && (
                <TouchableOpacity
                  style={styles.buttonhelp2}
                  onPress={() => {
                    Alert.alert(
                      'Ajuda',
                      'Deixe selecionado caso deseje que o seu amigo também possa fazer alteração na dívida, senão somente você poderá editar a dívida',
                      [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                    );
                  }}>
                  <Icon
                    style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={'help-circle'}
                  />
                </TouchableOpacity>
              )}
            </View>
          )}

          {this.state.divida.id ? (
            <TextInput
              style={[styles.textinput1, Actions.getTema('labelfields')]}
              value={this.state.divida.val_montante_f}
              onChange={value => {
                this.state.divida.val_montante_f = value;
                this.setState({item: this.state.item});
              }}
              keyboardType={'numeric'}
              label={'Montante (obrigatório)'}
              editable={false}
            />
          ) : null}
          {!this.state.divida.id ? (
            <View style={styles.view22}>
              <TextInput
                style={[styles.textinput1, Actions.getTema('labelfields')]}
                value={this.state.divida.val_montante}
                onChange={value => {
                  this.state.divida.val_montante = Util.maskMoneyKotheService(
                    value,
                  );
                  this.setState({divida: this.state.divida});
                }}
                contextMenuHidden={true}
                keyboardType={'numeric'}
                label={'Montante (obrigatório)'}
              />

              <TouchableOpacity
                style={styles.button999}
                onPress={() => {
                  this.setState({
                    openparcelasfields: !this.state.openparcelasfields,
                  });
                }}>
                <Icon
                  style={[styles.icon50, Actions.getTema('labelfields')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.openparcelasfields
                      ? 'chevron-up'
                      : 'chevron-down'
                  }
                />
              </TouchableOpacity>
            </View>
          ) : null}
          {this.state.openparcelasfields && !this.state.divida.id ? (
            <View style={styles.viewhelp}>
              <TextInput
                style={[styles.textinput222, Actions.getTema('labelfields')]}
                value={this.state.divida.num_vezes}
                onChange={value => {
                  value = value.replace(/[^\d]/g, '');
                  if (value) {
                    value = parseInt(value);
                    value = value < 1 ? '1' : value;
                    value = value > 12 ? '12' : value;
                  }
                  this.state.divida.num_vezes = Util.onlyNumbers(value);
                  this.setState({divida: this.state.divida});
                }}
                keyboardType={'numeric'}
                label={'Número de vezes para repetir a dívida '}
              />
              {this.state.ajuda && (
                <TouchableOpacity
                  style={styles.buttonhelp}
                  onPress={() => {
                    Alert.alert(
                      'Ajuda',
                      'Quantidade de parcelas que terá a dívida, parcele em até 12 vezes',
                      [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                    );
                  }}>
                  <Icon
                    style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={'help-circle'}
                  />
                </TouchableOpacity>
              )}
            </View>
          ) : null}

          {this.state.openparcelasfields && !this.state.divida.id ? (
            <View style={styles.viewhelp}>
              <TouchableOpacity
                style={styles.button911}
                onPress={() => {
                  this.state.divida.dividir_montante = !this.state.divida
                    .dividir_montante;
                  this.setState({divida: this.state.divida});
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.divida.dividir_montante == true
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {' Dividir o montante entre as dívidas criadas'}
                  </Text>
                </View>
              </TouchableOpacity>

              {this.state.ajuda && (
                <TouchableOpacity
                  style={styles.buttonhelp}
                  onPress={() => {
                    Alert.alert(
                      'Ajuda',
                      'Essa função pega o valor do montante e divide pelo número de vezes, caso essa função não esteja selecionada, será repetido o valor do montante pelo número de vezes',
                      [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                    );
                  }}>
                  <Icon
                    style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={'help-circle'}
                  />
                </TouchableOpacity>
              )}
            </View>
          ) : null}

          {this.state.openparcelasfields && !this.state.divida.id ? (
            <View style={styles.viewhelp}>
              <TouchableOpacity
                style={styles.button911}
                onPress={() => {
                  this.state.divida.enumerar = !this.state.divida.enumerar;
                  this.setState({divida: this.state.divida});
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.divida.enumerar == true
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {' Enumerar as parcelas'}
                  </Text>
                </View>
              </TouchableOpacity>

              {this.state.ajuda && (
                <TouchableOpacity
                  style={styles.buttonhelp}
                  onPress={() => {
                    Alert.alert(
                      'Ajuda',
                      'Essa função vai enumerar todas as dívidas parceladas',
                      [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                    );
                  }}>
                  <Icon
                    style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={'help-circle'}
                  />
                </TouchableOpacity>
              )}
            </View>
          ) : null}

          <View style={styles.viewhelp}>
            <TextInput
              style={[styles.textinput2, Actions.getTema('labelfields')]}
              value={this.state.divida.descricao}
              onChange={value => {
                this.state.divida.descricao = value;
                this.setState({divida: this.state.divida});
              }}
              keyboardType={'default'}
              label={'Descrição'}
              maxLength={100}
              numberOfLines={1}
              disabled={!Divida.testeDividaEdicao(this.state.divida)}
            />
            {this.state.ajuda && (
              <TouchableOpacity
                style={styles.buttonhelp2}
                onPress={() => {
                  Alert.alert(
                    'Ajuda',
                    'Coloque uma breve descrição para descrever porque a dívida foi criada (opcional)',
                    [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                  );
                }}>
                <Icon
                  style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'help-circle'}
                />
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.view22}>
            <DatePicker
              style={[styles.datepicker, Actions.getTema('labelfields')]}
              value={this.state.divida.data_debito}
              onChange={value => {
                this.state.divida.data_debito = value;
                this.setState({divida: this.state.divida});
              }}
              disabledClear={true}
              datePickerModeAndroid={'calendar'}
              label={'Data do débito'}
              disabled={!Divida.testeDividaEdicao(this.state.divida)}
            />
            {this.state.ajuda && (
              <TouchableOpacity
                style={styles.buttonhelp2}
                onPress={() => {
                  Alert.alert('Ajuda', 'Data que foi feita a dívida', [
                    {text: 'Ok', onPress: () => {}, style: 'cancel'},
                  ]);
                }}>
                <Icon
                  style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'help-circle'}
                />
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.view22}>
            <DatePicker
              style={[styles.datepicker, Actions.getTema('labelfields')]}
              value={this.state.divida.data_vencimento}
              onChange={value => {
                this.state.divida.data_vencimento = value;
                // console.log(typeof this.state.divida.data_vencimento, moment(this.state.divida.data_vencimento));
                this.setState({divida: this.state.divida});
              }}
              disabledClear={true}
              datePickerModeAndroid={'calendar'}
              label={'Data do vencimento'}
              disabled={!Divida.testeDividaEdicao(this.state.divida)}
            />

            <TouchableOpacity
              style={styles.button999}
              onPress={() => {
                this.escolherData();
              }}
              disabled={!Divida.testeDividaEdicao(this.state.divida)}>
              <Icon
                style={[styles.icon50, Actions.getTema('labelfields')]}
                fromFontFamily={'Material Design Icons'}
                name={'chevron-down'}
              />
            </TouchableOpacity>
            {this.state.ajuda && (
              <TouchableOpacity
                style={styles.buttonhelp2}
                onPress={() => {
                  Alert.alert('Ajuda', 'Data que irá vencer a dívida', [
                    {text: 'Ok', onPress: () => {}, style: 'cancel'},
                  ]);
                }}>
                <Icon
                  style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'help-circle'}
                />
              </TouchableOpacity>
            )}
          </View>
          {this.state.divida.id ? (
            <View style={styles.view22}>
              <TextInput
                style={[
                  styles.descstatus,
                  {...this.status(this.state.divida.flag_status)},
                  Actions.getTema('labelfields'),
                ]}
                value={
                  this.state.divida.descstatus +
                  (this.state.divida.flag_espelho == 'S'
                    ? ' - Dívida não criada por mim'
                    : ' - Dívida criada por mim')
                }
                onChange={value => {
                  this.state.divida.descstatus = value;
                  this.setState({item: this.state.item});
                }}
                keyboardType={'default'}
                label={'Status'}
                editable={false}
              />
              {this.state.ajuda && (
                <TouchableOpacity
                  style={styles.buttonhelp2}
                  onPress={() => {
                    Alert.alert('Ajuda', 'Situação da dívida', [
                      {text: 'Ok', onPress: () => {}, style: 'cancel'},
                    ]);
                  }}>
                  <Icon
                    style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={'help-circle'}
                  />
                </TouchableOpacity>
              )}
            </View>
          ) : null}

          {this.state.divida.id ? (
            <View style={[styles.view5, {height: 30}]} />
          ) : null}
        </ScrollView>

        {this.state.divida.id &&
        this.state.load == false &&
        this.state.excluida == false ? (
          <View style={[styles.footer, Actions.getTema('titlebar')]}>
            {!this.state.arquivada ? (
              <TouchableOpacity
                style={[styles.button40]}
                onPress={() => {
                  this.deletecancelar();
                }}
                disabled={!Divida.testeDividaEdicao(this.state.divida, true)}>
                <Icon
                  style={[
                    styles.icon4,
                    Divida.testeDividaEdicaoColor(this.state.divida, true),
                  ]}
                  fromFontFamily={'Material Design Icons'}
                  name={'package-down'}
                />
                <Text
                  style={[
                    styles.text3,
                    Divida.testeDividaEdicaoColor(this.state.divida, true),
                  ]}>
                  {'Arquivar'}
                </Text>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                style={[styles.button40]}
                onPress={() => {
                  this.desarquivar();
                }}
                disabled={!Divida.testeDividaEdicao(this.state.divida, true)}>
                <Icon
                  style={[
                    styles.icon4,
                    Divida.testeDividaEdicaoColor(this.state.divida, true),
                  ]}
                  fromFontFamily={'Material Design Icons'}
                  name={'package-up'}
                />
                <Text
                  style={[
                    styles.text3,
                    Divida.testeDividaEdicaoColor(this.state.divida, true),
                  ]}>
                  {'Desarquivar'}
                </Text>
              </TouchableOpacity>
            )}

            <TouchableOpacity
              style={[styles.button402]}
              onPress={() => {
                this.props.navigation.navigate('Relatorio', {
                  activity: this,
                  oldState: this.state,
                  id_divida: this.state.divida.id,
                });
              }}>
              <Icon
                style={[styles.icon5, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'clipboard-text'}
              />
              <Text style={[styles.text32, Actions.getTema('iconstitlebar')]}>
                {'Relatório'}
              </Text>
            </TouchableOpacity>

            <TouchableOpacity
              style={styles.button401}
              onPress={() => {
                this.props.navigation.navigate('Transacoes', {
                  activity: this,
                  oldState: this.state,
                  id_divida: this.state.divida.id,
                  divida: this.state.divida,
                  onGoBack: () => {
                    this.abrir(this.state.divida.id);
                  },
                });
              }}>
              <Icon
                style={[styles.icon5, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Icons'}
                name={'swap_vert'}
              />
              <Text style={[styles.text32, Actions.getTema('iconstitlebar')]}>
                {'Transações'}
              </Text>
            </TouchableOpacity>

            {this.state.divida.flag_status == 'N' ? (
              <TouchableOpacity
                style={[styles.button402]}
                onPress={() => {
                  this.quitarDivida();
                }}
                disabled={!Divida.testeDividaEdicao(this.state.divida)}>
                <Icon
                  style={[
                    styles.icon6,
                    Divida.testeDividaEdicaoColor(this.state.divida),
                  ]}
                  fromFontFamily={'Material Icons'}
                  name={'attach_money'}
                />
                <Text
                  style={[
                    styles.text33,
                    Divida.testeDividaEdicaoColor(this.state.divida),
                  ]}>
                  {'Quitar'}
                </Text>
              </TouchableOpacity>
            ) : null}
          </View>
        ) : null}

        {this.state.divida.id &&
        this.state.excluida == true &&
        this.state.load == false ? (
          <View style={[styles.footer, Actions.getTema('titlebar')]}>
            <TouchableOpacity
              style={[styles.button402]}
              onPress={() => {
                this.restaurarDivida();
              }}
              disabled={this.state.load}>
              <Icon
                style={[styles.icon5, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={'package-up'}
              />
              <Text style={[styles.text32, Actions.getTema('iconstitlebar')]}>
                {'Restaurar Dívida'}
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view22: {
    flexDirection: 'row',
    alignSelf: 'stretch',
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1,
  },
  button999: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    width: 30,
    // padding: 10,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  icon50: {
    color: 'rgba(0,0,0,1)',
    fontSize: 25,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
    flex: 1,
  },
  button21: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  icon41: {
    fontSize: 25,
    marginBottom: 1,
  },
  icon49: {
    fontSize: 25,
    marginBottom: 1,
    color: 'rgba(177,177,184,1)',
  },

  button3: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  icon3: {
    fontSize: 25,
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    flex: 1,
    padding: 20,
    paddingTop: 10,
  },
  view7: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  button9: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    flexDirection: 'row',
    flex: 1,
  },
  button911: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    flexDirection: 'row',
    flex: 1,
    marginLeft: 15,
  },
  icon8: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  view61: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text611: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 3,
  },
  button8: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'row',
    flex: 1,
    borderColor: null,
  },
  icon7: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  view6: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  viewhelp: {
    flexDirection: 'row',
  },
  view8: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  textinput3: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
    flex: 1,
  },
  buttonhelp: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 30,
  },
  buttonhelp2: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 30,
    paddingTop: 10,
  },
  button10: {
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
    marginLeft: 5,
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 25,
  },
  textinput1: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
    flex: 1,
  },
  textinput2: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
    flex: 1,
  },

  textinput222: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
    flex: 1,
    marginLeft: 15,
  },
  datepicker: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
  },
  descstatus: {
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
    flex: 1,
  },
  view5: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  footer: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',

    minHeight: 50,
  },
  button40: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
  },
  icon4: {
    fontSize: 25,
    marginBottom: 1,
  },
  text3: {
    textAlign: 'center',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  button401: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
  },
  icon5: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginBottom: 2,
  },
  text32: {
    textAlign: 'center',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  button402: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 5,
    marginBottom: 5,
    flex: 1,
  },
  icon6: {
    // color: "rgba(48,129,219,1)",
    fontSize: 25,
    marginBottom: 2,
  },
  text33: {
    textAlign: 'center',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
});
