import {createAppContainer} from 'react-navigation';
import {createStackNavigator} from 'react-navigation-stack';
import {createBrowserApp} from '@react-navigation/web';
import {Platform, View} from 'react-native';
import {connect} from 'react-redux';
import React from 'react';
import Home from './views/Home';
import Pesquisa from './views/Pesquisa';
import LoginEmail from './views/acesso/Login';
import Cadastro from './views/acesso/Cadastro';
import Perfil from './views/user';
import Notificacao from './views/mensagens/Notificacoes';
import GifPicker from './views/mensagens/GifPicker';
import NovaDivida from './views/faturas/Novadivida';
import Transacoes from './views/faturas/Transacoes';
import NovaTransacao from './views/faturas/NovaTransacao';
import EscolherData from './views/faturas/EscolherData';
import ListaContatos from './views/user/ListaContatos';
import Configs from './views/configuracoes/Configs';
import CriarPin from './views/configuracoes/CriarPin';
import InserirPin from './views/configuracoes/InserirPin';
import Sobre from './views/configuracoes/Sobre';
import ListaArquivadas from './views/faturas/ListaArquivadas';
import ListaDividaAceitar from './views/faturas/ListaDividaAceitar';
import Qrcode from './views/user/Qrcode';
import Conversa from './views/mensagens/Conversa';
import Telefone from './views/user/Telefone';
import EscolherVencimento from './views/faturas/EscolherVencimento';
import EscolherTransacao from './views/faturas/EscolherTransacao';
import ListaPessoasDivida from './views/faturas/ListaPessoasDivida';
import Amigos from './views/faturas/Amigos';
import PrimeiraVez from './views/user/PrimeiraVez';
import Graficos from './views/faturas/Graficos';
import ListaContatosTelefone from './views/user/ListaContatosTelefone';
import NovoContato from './views/user/NovoContato';
import PreInfos from './views/user/PreInfos';
import InfosUser from './views/user/InfosUser';
import FotoUser from './views/user/FotoUser';
import Relatorio from './views/faturas/Relatorio';
import ListaExcluidas from './views/faturas/ListaExcluidas';
import ListaPessoasDividaRelatorio from './views/faturas/ListaPessoasDividaRelatorio';
import ListaPessoasDividaRelatorioPessoa from './views/faturas/ListaPessoasDividaRelatorioPessoa';
import RelatorioAgrupado from './views/faturas/RelatorioAgrupado';
import Calendarios from './views/faturas/Calendarios';

const AppNavigator = createStackNavigator({
  Home: {
    screen: connect()(Home),
    navigationOptions: {header: null},
    path: '/',
  },
  Calendarios: {
    screen: connect()(Calendarios),
    navigationOptions: {header: null},
    path: 'calendarios',
  },
  Pesquisa: {
    screen: connect()(Pesquisa),
    navigationOptions: {header: null},
    path: 'pesquisa',
  },
  LoginEmail: {
    screen: connect()(LoginEmail),
    navigationOptions: {header: null},
    path: 'login-email',
  },
  Cadastro: {
    screen: connect()(Cadastro),
    navigationOptions: {header: null},
    path: 'cadastro',
  },
  Perfil: {
    screen: connect()(Perfil),
    navigationOptions: {header: null},
    path: 'perfil',
  },
  Notificacao: {
    screen: connect()(Notificacao),
    navigationOptions: {header: null},
    path: 'notificacoes',
  },
  NovaDivida: {
    screen: connect()(NovaDivida),
    navigationOptions: {header: null},
    path: 'nova-divida',
  },
  Transacoes: {
    screen: connect()(Transacoes),
    navigationOptions: {header: null},
    path: 'transacoes',
  },
  NovaTransacao: {
    screen: connect()(NovaTransacao),
    navigationOptions: {header: null},
    path: 'nova-transacao',
  },
  EscolherData: {
    screen: connect()(EscolherData),
    navigationOptions: {header: null},
    path: 'escolher-data',
  },
  CriarPin: {
    screen: connect()(CriarPin),
    navigationOptions: {header: null},
    path: 'criar-pin',
  },
  Configs: {
    screen: connect()(Configs),
    navigationOptions: {header: null},
    path: 'configuracoes',
  },
  ListaContatos: {
    screen: connect()(ListaContatos),
    navigationOptions: {header: null},
    path: 'lista-contatos',
  },
  InserirPin: {
    screen: connect()(InserirPin),
    navigationOptions: {header: null},
    path: 'inserir-pin',
  },
  Sobre: {
    screen: connect()(Sobre),
    navigationOptions: {header: null},
    path: 'sobre',
  },
  ListaArquivadas: {
    screen: connect()(ListaArquivadas),
    navigationOptions: {header: null},
    path: 'lista-arquivadas',
  },
  Qrcode: {
    screen: connect()(Qrcode),
    navigationOptions: {header: null},
    path: 'qrcode',
  },
  Conversa: {
    screen: connect()(Conversa),
    navigationOptions: {header: null},
    path: 'conversa',
  },
  Telefone: {
    screen: connect()(Telefone),
    navigationOptions: {header: null},
    path: 'telefone',
  },
  EscolherVencimento: {
    screen: connect()(EscolherVencimento),
    navigationOptions: {header: null},
    path: 'escolher-vencimento',
  },
  EscolherTransacao: {
    screen: connect()(EscolherTransacao),
    navigationOptions: {header: null},
    path: 'escolher-transacao',
  },
  ListaPessoasDivida: {
    screen: connect()(ListaPessoasDivida),
    navigationOptions: {header: null},
    path: 'lista-pessoas-divida',
  },
  PrimeiraVez: {
    screen: connect()(PrimeiraVez),
    navigationOptions: {header: null},
    path: 'primeira-vez',
  },
  Amigos: {
    screen: connect()(Amigos),
    navigationOptions: {header: null},
    path: 'amigos',
  },
  Graficos: {
    screen: connect()(Graficos),
    navigationOptions: {header: null},
    path: 'graficos',
  },
  ListaDividaAceitar: {
    screen: connect()(ListaDividaAceitar),
    navigationOptions: {header: null},
    path: 'lista-divida-aceitar',
  },
  ListaContatosTelefone: {
    screen: connect()(ListaContatosTelefone),
    navigationOptions: {header: null},
    path: 'lista-contatos-telefone',
  },
  NovoContato: {
    screen: connect()(NovoContato),
    navigationOptions: {header: null},
    path: 'novo-contato',
  },
  GifPicker: {
    screen: connect()(GifPicker),
    navigationOptions: {header: null},
    path: 'gif-picker',
  },
  PreInfos: {
    screen: connect()(PreInfos),
    navigationOptions: {header: null},
    path: 'pre-infos',
  },
  InfosUser: {
    screen: connect()(InfosUser),
    navigationOptions: {header: null},
    path: 'infos-user',
  },
  FotoUser: {
    screen: connect()(FotoUser),
    navigationOptions: {header: null},
    path: 'foto-user',
  },
  Relatorio: {
    screen: connect()(Relatorio),
    navigationOptions: {header: null},
    path: 'relatorio',
  },
  ListaExcluidas: {
    screen: connect()(ListaExcluidas),
    navigationOptions: {header: null},
    path: 'lista-excluidas',
  },
  ListaPessoasDividaRelatorio: {
    screen: connect()(ListaPessoasDividaRelatorio),
    navigationOptions: {header: null},
    path: 'lista-pessoas-divida-relatorio',
  },
  ListaPessoasDividaRelatorioPessoa: {
    screen: connect()(ListaPessoasDividaRelatorioPessoa),
    navigationOptions: {header: null},
    path: 'lista-pessoas-divida-relatorio-pessoa',
  },
  RelatorioAgrupado: {
    screen: connect()(RelatorioAgrupado),
    navigationOptions: {header: null},
    path: 'relatorio-agrupado',
  },
});

const createApp = Platform.select({
  web: input => {
    let App = createBrowserApp(input, {history: 'hash'});
    return props => (
      <WebApp {...props}>
        <App {...props} />
      </WebApp>
    );
  },
  default: input => createAppContainer(input),
});

export default createApp(AppNavigator);

let {MuiThemeProvider, createMuiTheme} = require('@material-ui/core/styles');

class WebApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: this.props.store.getState().tema,
    };

    this.props.store.subscribe(() => {
      let store = this.props.store.getState();
      console.log('this.props.store', store);
      if (store.tema != this.state.theme) this.setState({theme: store.tema});
    });
  }

  render() {
    let theme = createMuiTheme({
      spacing: 4,
      palette: {
        type: this.state.theme == 'white' ? 'light' : 'dark',
      },
    });

    return (
      <MuiThemeProvider theme={theme}>{this.props.children}</MuiThemeProvider>
    );
  }
}
