import React, {Component} from 'react';

import Image from 'react-native-1app/lib/Image';
import Content from 'react-native-1app/lib/Content';
import Icon from 'react-native-1app/lib/Icon';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Alert,
  ScrollView,
} from 'react-native';
import {logout, setAceitaQtd} from '../redux/actions';
import * as Actions from '../redux/actions';
import * as Divida from '../worker/divida';
export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.screenProps.store.getState().user,
      tema: this.props.screenProps.store.getState().tema,
      aceitasqtd: 0,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user) this.setState({user: store.user});
      if (store.aceitasqtd != this.state.aceitasqtd) {
        this.setState({aceitasqtd: store.aceitasqtd});
      }

      if (store.tema != this.state.tema) {
        this.setState({tema: store.tema});
      }
    });
    this.getQtdAceitas();
    if (!this.state.user) {
      this.logout();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  openPageButton3() {
    this.props.navigation.navigate('Perfil');
  }
  openPageButtonsobre() {
    this.props.navigation.navigate('Sobre');
  }

  openPageButton1() {
    this.props.navigation.navigate('Notificacao');
  }

  openPageButton2() {
    this.props.navigation.navigate('Configs');
  }

  openPageButtonAceitar() {
    this.props.navigation.navigate('ListaDividaAceitar');
  }

  openPageButtonRelatorios() {
    this.props.navigation.navigate('ListaPessoasDividaRelatorio');
  }

  openPageButton4() {
    this.props.navigation.navigate('Mapa');
  }
  openPageArquivados() {
    this.props.navigation.navigate('ListaArquivadas');
  }

  openPageExcluidos() {
    this.props.navigation.navigate('ListaExcluidas');
  }
  getQtdAceitas() {
    Divida.listarNaoAceitas({}, (data, error) => {
      setAceitaQtd(data ? data.length : 0);
    });
  }

  openGraficos() {
    this.props.navigation.navigate('Graficos');
  }

  openPageButton7() {
    if (this.state.user.motorista && this.state.user.pendente) {
      return Alert.alert('Atenção', 'Estamos analisando seu perfil Motorista', [
        {text: 'Fechar', onPress: null},
      ]);
    }
    this.props.navigation.navigate('Motorista');
  }

  logout() {
    logout();
  }

  render() {
    if (this.state.user) {
      return (
        <View style={[styles.content, Actions.getTema('titlebar')]}>
          <View style={styles.view2} />
          <View style={styles.view3}>
            <TouchableOpacity
              // style={styles.button3}
              onPress={() => {
                this.openPageButton3();
              }}>
              <Image
                style={styles.image}
                source={{uri: this.state.user.foto_large}}
                resizeMode={'cover'}
                onPress={() => {
                  this.openPageButton3();
                }}
              />
            </TouchableOpacity>
            <View style={styles.view6}>
              <Text style={[styles.text1, Actions.getTema('lbl_menu')]}>
                {'Olá ' + this.state.user.nome}
              </Text>
            </View>
          </View>
          <View style={styles.view4}>
            <ScrollView
              style={[styles.scroll, Actions.getTema('titlebar')]}
              keyboardShouldPersistTaps={true}>
              {/* <TouchableOpacity
              style={styles.button3}
              onPress={() => {
                this.openPageButton3();
              }}
            >
              <Icon
                style={styles.icon2}
                fromFontFamily={"Material Design Icons"}
                name={"account-outline"}
              />
              <Text style={styles.text4}>{"Meu Perfil"}</Text>
            </TouchableOpacity> */}

              <TouchableOpacity
                style={styles.button313}
                onPress={() => {
                  this.openPageButtonRelatorios();
                }}>
                <Icon
                  style={[styles.icon211, Actions.getTema('lbl_menu')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'clipboard-text'}
                />
                <Text style={[styles.text413, Actions.getTema('lbl_menu')]}>
                  {'Relatórios'}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button313}
                onPress={() => {
                  this.openPageButtonAceitar();
                }}>
                <Icon
                  style={[styles.icon211, Actions.getTema('lbl_menu')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'link-variant'}
                />
                <Text style={[styles.text413, Actions.getTema('lbl_menu')]}>
                  {'Aceitar dívida'}
                </Text>
                {this.state.aceitasqtd > 0 && (
                  <View style={styles.viewnot2}>
                    <Text style={styles.labelnot1}>
                      {this.state.aceitasqtd}
                    </Text>
                  </View>
                )}
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button314}
                onPress={() => {
                  this.openGraficos();
                }}>
                {/* <Icon
                  style={[styles.icon211, Actions.getTema("lbl_menu")]}
                  fromFontFamily={"Material Design Icons"}
                  name={"chart-donut"}
                /> */}
                {this.state.tema == 'preto' && (
                  <Image
                    style={styles.image2}
                    resizeMode={'contain'}
                    source={require('../../img/chart-donut_white.png')}
                  />
                )}
                {this.state.tema == 'white' && (
                  <Image
                    style={styles.image2}
                    resizeMode={'contain'}
                    source={require('../../img/chart-donut_black.png')}
                  />
                )}
                <Text style={[styles.text413, Actions.getTema('lbl_menu')]}>
                  {'Gráficos'}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button314}
                onPress={() => {
                  this.openPageExcluidos();
                }}>
                <Icon
                  style={[styles.icon211, Actions.getTema('lbl_menu')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'delete'}
                />
                <Text style={[styles.text413, Actions.getTema('lbl_menu')]}>
                  {'Excluídos'}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button314}
                onPress={() => {
                  this.openPageArquivados();
                }}>
                <Icon
                  style={[styles.icon211, Actions.getTema('lbl_menu')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'archive'}
                />
                <Text style={[styles.text413, Actions.getTema('lbl_menu')]}>
                  {'Arquivados'}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button311}
                onPress={() => {
                  this.openPageButton1();
                }}>
                <Icon
                  style={[styles.icon211, Actions.getTema('lbl_menu')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'bell-outline'}
                />
                <Text style={[styles.text413, Actions.getTema('lbl_menu')]}>
                  {'Notificações'}
                </Text>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button313}
                onPress={() => {
                  this.openPageButton2();
                }}>
                <Icon
                  style={[styles.icon211, Actions.getTema('lbl_menu')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'settings'}
                />
                <Text style={[styles.text413, Actions.getTema('lbl_menu')]}>
                  {'Configurações'}
                </Text>
              </TouchableOpacity>

              {/* <TouchableOpacity
              style={styles.button314}
              onPress={() => {
                this.openPageButtonsobre();
              }}
            >
              <Icon
                style={styles.icon214}
                fromFontFamily={"Material Design Icons"}
                name={"information-outline"}
              />
              <Text style={styles.text414}>{"Sobre"}</Text>
            </TouchableOpacity> */}
            </ScrollView>
          </View>
          <View style={styles.view5}>
            <TouchableOpacity
              style={styles.button311}
              onPress={() => {
                this.logout();
              }}>
              <Icon
                style={[styles.icon211, Actions.getTema('lbl_menu')]}
                fromFontFamily={'Material Design Icons'}
                name={'power'}
              />
              <Text style={[styles.text413, Actions.getTema('lbl_menu')]}>
                {'Sair'}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      );
    } else {
      return <View style={styles.view5} />;
    }
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view2: {
    alignSelf: 'stretch',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'column',
    height: 40,
  },
  labelnot1: {
    textAlign: 'center',
    flexWrap: 'wrap',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: '700',
    fontSize: 12,
    flex: 1,
  },
  viewnot2: {
    alignSelf: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    minWidth: 20,
    minHeight: 20,
    backgroundColor: 'rgba(222,34,33,1)',
    borderRadius: 10,
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(255,255,255,1)',
    flex: 1,
  },
  view3: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  image: {
    width: 75,
    height: 75,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 36,
    marginBottom: 10,
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 10,
    marginLeft: 15,
  },
  text1: {
    textAlign: 'left',
    color: '#2c2d33',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    fontSize: 16,
  },
  view4: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: 10,
    paddingBottom: 20,
  },
  button311: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    flexDirection: 'row',
    paddingLeft: 25,
    paddingRight: 10,
    marginBottom: 15,
  },
  icon211: {
    color: '#a7a7a8',
    fontSize: 25,
  },
  button313: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    flexDirection: 'row',
    paddingLeft: 25,
    paddingRight: 10,
    marginBottom: 15,
  },
  text413: {
    textAlign: 'left',
    color: '#2c2d33',
    alignSelf: 'center',
    fontWeight: 'normal',
    flex: 1,
    marginLeft: 40,
  },
  button314: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    flexDirection: 'row',
    paddingLeft: 25,
    paddingRight: 10,
    marginBottom: 15,
  },
  view5: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderStyle: 'solid',
    height: 50,
    borderTopWidth: 1,
    borderTopColor: '#a7a7a8',
    paddingLeft: 10,
  },
  image2: {
    width: 25,
    height: 25,
  },
});
