'use strict';
import React from 'react';
import {
  Text,
  View,
  ActivityIndicator,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Image
} from "react-native";
import ImagePicker2 from 'react-native-image-crop-picker';
import ImagePicker from 'react-native-image-picker';
import {
  Icon,
} from "react-native-1app";
import { Util, Cloud } from "../infra";
let host = null;
let confg = {};
let metodo = "/imageMake";
let token_api = null;
export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false
    };
  }

  upLoad(retorno) {
    if (!host) return alert('Configure o caminho de upLoad! ( ImageUpload.setHost(host) )');
    this.pegarFoto(base64 => {
      var url = host + metodo;
      if (!base64) return console.log("base64 null no salvarImagem");

      var send = { data: base64, nome: "up.jpg" };
      var config = {
        method: "POST",
        headers: {
          "x-request-id": token_api,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        ...confg,
        body: JSON.stringify(send)
      };
      this.setState({ load: true });
      let { onResposta } = this.props
      fetch(url, config)
        .then(response => {
          this.resolverResponse(response, (res, er) => {
            let { result, error } = onResposta(res, er);
            if (retorno) {
              retorno(result.url_img);
            }

          });
        })

        .catch(error => {
          alert(error)
          console.log(error);
        });
    });
  }
  resolverResponse(response, retorno) {
    var p1 = response.json();
    p1.then((responseData, error) => {
      if (response.status != 200) {
        retorno(undefined, responseData);
      } else if (error) {
        retorno(undefined, error);
      } else {
        retorno(responseData);
      }
    });
  }
  pegarFoto(retorno) {
    var rem = { "Remover imagem": "remover" };

    var options = {
      title: "Selecione uma imagem",
      videoQuality: "medium", // 'low', 'medium', or 'high'
      durationLimit: 30, // video recording max time in seconds
      maxWidth: parseInt(this.props.maxWidth) || 500, // photos only
      maxHeight: parseInt(this.props.maxHeight) || 750, // photos only
      quality: 1, // 0 to 1, photos only
      customButtons: [
      ],
      chooseFromLibraryButtonTitle: "Pegar no telefone",
      cancelButtonTitle: "Cancelar",
      takePhotoButtonTitle: "Tirar foto", // specify null or empty string to remove this button
      storageOptions: {
        skipBackup: true,
        path: this.props.path ? this.props.path : "casher"
      }
    };
    ImagePicker.showImagePicker(options, response => {
      if (response.error) {
        console.log("ImagePickerManager Error: ", response.error);
        retorno(null);
      } else if (response.customButton) {
        retorno(null);
      } else {
        retorno(response.data);
      }
    });
  }

  crop(retorno) {
    ImagePicker2.openCropper({
      path: this.props.value,
      data: "",
      cropperToolbarTitle: "Editar foto",
      width: 300,
      height: 300
    }).then(image => {
      Util.parseImgContatctsToBase64(image.path, (base64, error) => {
        Cloud.upLoadFotoSemLib(base64, (data) => {
          if (retorno) {
            retorno(data.url_img);
          }
        })
      })
    });
  }

  render() {
    let { value, label, onChange, style, styleImage, resizeMode, body } = this.props;
    value = value ? value : '';
    return (
      <View>
        <TouchableOpacity
          style={[style, styles.button]}
          disabled={this.state.load}
          onPress={() => {
            if (value) {
              this.crop(url => {
                this.setState({ load: false });
                onChange(url);
              });
            } else {
              this.upLoad(url => {
                this.setState({ load: false });
                onChange(url);
              });
            }
          }}
        >
          <Image
            style={[styles.image, style, styleImage]}
            // resizeMode={resizeMode}
            onLoadStart={(e) => this.setState({ load: true })}
            onLoadEnd={(e) => this.setState({ load: false })}
            source={{ uri: value }}
          />
          <View style={styles.body}>
            {this.state.load &&
              <View style={styles.load}>
                <ActivityIndicator
                  style={{ height: 80 }}
                  size="large"
                />
              </View>
            }
            {!value && !this.state.load &&
              <View style={styles.containText}>
                {!value &&
                  <Text style={styles.add}>
                    {"+"}
                  </Text>
                }
                <Text style={styles.text}>
                  {value ? "" : "Adicionar Foto"}
                </Text>
              </View>
            }
          </View>
        </TouchableOpacity>
        {this.props.value ?
          <TouchableOpacity
            style={[styles.buttoncamera]}
            onPress={() => {
              this.upLoad(url => {
                this.setState({ load: false });
                onChange(url);
              });
            }}
          >
            <Icon
              style={[styles.icon3]}
              fromFontFamily={"Material Design Icons"}
              name={"camera"}
            />
          </TouchableOpacity>
          : null}
      </View>

    );
  }
}

ImageUpload.defaultProps = {
  value: '',
  label: null,
  onChange: () => console.log("not onChange"),
  style: {},
  styleImage: {},
  resizeMode: "cover",
  body: null,
  onResposta: (res, error) => ({ result: res, error })
};

var styles = StyleSheet.create(
  {
    body: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: "absolute",
      justifyContent: "center", alignItems: "center",
      // backgroundColor: "rgba(244, 66, 66,1)"
    },
    image: {
      flex: 1,
      alignSelf: "stretch"
    },
    button: {
      justifyContent: "center",
      alignItems: "center",
    },
    buttoncamera: {
      bottom: 0,
      right: 0,
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      height: 50,
      flexDirection: "column",
      width: 50,
      borderRadius: 25,
      backgroundColor: "rgba(48,129,219,1)"
    },
    load: {
      height: 60,
      width: 60,
      borderRadius: 30,
      backgroundColor: "rgba(0,0,0,0.4)",
      justifyContent: "center",
      alignItems: "center"
    },
    containText: {
      backgroundColor: "rgba(0,0,0,0.3)",
      padding: 10,
      borderRadius: 5,
      marginBottom: 5,
      justifyContent: "center",
      alignItems: "center"
    },
    text: {
      alignSelf: "stretch",
      textAlign: "center",
      fontSize: 11,
      fontWeight: "800",
      color: "#ffffff",
    },
    add: {
      fontSize: 30,
      fontWeight: "bold",
      color: "#ffffff",
    },
    icon3: {
      fontSize: 25,
      color: "rgba(255,255,255,1)"
    },

  }
);



ImageUpload.setHost = function (url = '') {
  host = url; //.replace("/v1","");
};
ImageUpload.setToken = function (token = '') {
  token_api = token;
};
ImageUpload.setConfigCloud = function (cf = {}) {
  confg = cf;
};
ImageUpload.setMetodo = function (m = '') {
  metodo = m;
};