import React, {Component} from 'react';

import {
  Content,
  DrawerLayout,
  Icon,
  MenuLeft,
  TitleBar,
} from 'react-native-1app';
import {
  View,
  Animated,
  TouchableOpacity,
  StyleSheet,
  Dimensions,
  Text,
} from 'react-native';

import FaturasLista from './faturas/Lista.js';
import MensagensLista from './mensagens/Lista.js';
import Calendario from './faturas/Calendario.js';

import styleGlobal from '../styleGlobal';
import {TabView as TabViewLib, SceneMap} from 'react-native-tab-view';
import MenuL from './Menu';
import * as FireBase from '../infra/firebase';
import {salvarToken} from '../worker/dados';
import * as Actions from '../redux/actions';
import {
  setNotsMsg,
  setQtdNot,
  testeToken,
  setAceitaQtd,
} from '../redux/actions';
import * as Divida from '../worker/divida';
import PreInfos from './user/PreInfos';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: this.props.screenProps.store.getState().telaini || 0,
      qtdmsgs: 0,
      telaini: this.props.screenProps.store.getState().telaini,
      tema: this.props.screenProps.store.getState().tema,
      showpreinfos: this.props.screenProps.store.getState().showpreinfos,
      aceitasqtd: 0,
      routes: [
        {key: 'first', title: 'Lista', icon: 'format-align-justify'},
        {key: 'second', title: 'Calendário', icon: 'calendar'},
        {key: 'third', title: 'Chat', icon: 'message'},
        {key: 'forth', title: '', icon: 'plus'},
      ],
    };
    FireBase.init(
      (token, os) => {
        console.log('FireBase.init', token, os);
        if (token) salvarToken({token, os});
      },
      this.props.screenProps,
      this.props.navigation,
      this.props.screenProps.store.dispatch,
    );

    this.scenes = SceneMap({
      first: () => {
        return (
          <View style={styles.view2}>
            <FaturasLista
              style={styles.fragment3}
              screenProps={this.props.screenProps}
              navigation={this.props.navigation}
              activity={this}
            />
          </View>
        );
      },
      second: () => {
        return (
          <View style={styles.view2}>
            <Calendario
              style={styles.fragment3}
              screenProps={this.props.screenProps}
              navigation={this.props.navigation}
              activity={this}
              ref={nha => (this.calendario = nha)}
            />
          </View>
        );
      },
      third: () => {
        return (
          <View style={styles.view2}>
            <MensagensLista
              style={styles.fragment3}
              screenProps={this.props.screenProps}
              navigation={this.props.navigation}
              activity={this}
            />
          </View>
        );
      },
      forth: () => {
        return <View style={styles.view2} />;
      },
    });
  }
  openTab(i) {
    // Actions.setTrocaPagdash(i);
    this.setState({index: i});
    this.props.screenProps.store.dispatch({type: 'SET_INDEX', index: i});
  }
  renderTabBar(props) {
    const inputRange = props.navigationState.routes.map((x, i) => i);
    // return console.log('props tabbar', props.position);
    return (
      <View style={styles.tabBar}>
        {props.navigationState.routes.map((route, i) => {
          let icon = {
            color:
              i == this.state.index
                ? Actions.getTema('footericones_ativo')
                : Actions.getTema('footericones_desativo'),
            fontSize: 20,
            marginLeft: 5,
          };

          let icon2 = {
            color: 'rgba(255,255,255,1)',
            fontSize: 45,
          };

          let color =
            i == this.state.index
              ? Actions.getTema('footericones_ativo')
              : Actions.getTema('footericones_desativo');

          if (i == 3) {
            return (
              <TouchableOpacity
                style={[styles.tabItem, Actions.getTema('footer')]}
                onPress={() => {
                  this.props.navigation.navigate('NovaDivida', {
                    activity: this,
                    oldState: this.state,
                    atualizar: () => {
                      if (
                        this.calendario &&
                        this.calendario.preparaFitros &&
                        this.calendario.loadCalendar
                      ) {
                        this.calendario.preparaFitros({});
                        this.calendario.loadCalendar({});
                      }
                    },
                  });
                }}>
                <View style={styles.viewnot1}>
                  <View style={styles.viewnot3}>
                    <Icon
                      style={icon2}
                      fromFontFamily={'Material Design Icons'}
                      name={route.icon}
                    />
                  </View>
                </View>
              </TouchableOpacity>
            );
          } else {
            return (
              <TouchableOpacity
                style={[styles.tabItem, Actions.getTema('footer')]}
                onPress={() => {
                  this.openTab(i);
                }}>
                <Icon
                  style={icon}
                  fromFontFamily={'Material Design Icons'}
                  name={route.icon}
                />
                <Animated.Text numberOfLines={1} style={{color}}>
                  {route.title}
                </Animated.Text>
                {i == 2 && this.state.qtdmsgs > 0 ? (
                  <View style={styles.viewnot1}>
                    <View
                      style={[
                        styles.viewnot2,
                        Actions.getTema('notification'),
                      ]}>
                      <Text style={styles.labelnot1}>{this.state.qtdmsgs}</Text>
                    </View>
                  </View>
                ) : null}
              </TouchableOpacity>
            );
          }
        })}
      </View>
    );
  }

  handleIndexChange(index) {
    this.setState({index});
  }

  renderPages() {
    return (
      <TabViewLib
        navigationState={this.state}
        style={{width: Dimensions.get('window').width, height: 150}}
        renderScene={this.scenes}
        renderTabBar={props => {
          return this.renderTabBar(props);
        }}
        swipeEnabled={false}
        tabBarPosition={'bottom'}
        onIndexChange={index => {
          return this.handleIndexChange(index);
        }}
      />
    );
  }

  openMenuLeftButton1() {
    this.menuLeft.openDrawer();
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (
        store.user != this.state.user ||
        store.qtdmsgs != this.state.qtdmsgs ||
        store.aceitasqtd != this.state.aceitasqtd ||
        store.tema != this.state.tema ||
        store.telaini != this.state.telaini
      )
        this.setState({
          user: store.user,
          qtdmsgs: store.qtdmsgs,
          aceitasqtd: store.aceitasqtd,
          tema: store.tema,
          telaini: store.telaini,
        });
    });
    setNotsMsg();
    setQtdNot();
    testeToken();
    // this.openTab(this.state.telaini);
    // this.openTab(1);
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  getQtdAceitas() {
    Divida.listarNaoAceitas({}, (data, error) => {
      setAceitaQtd(data ? data.length : 0);
    });
  }

  render() {
    return (
      <DrawerLayout
        drawerWidth={250}
        keyboardDismissMode="on-drag"
        onDrawerStateChanged={(a, b, c) => {
          if (a == 'Settling') this.getQtdAceitas();
        }}
        renderNavigationView={() => {
          return (
            <View style={styles.menuleft}>
              <View style={styles.view3}>
                <MenuL
                  style={styles.fragment2}
                  menu={this.menuLeft}
                  user={this.state.user}
                  navigation={this.props.navigation}
                  screenProps={this.props.screenProps}
                />
              </View>
            </View>
          );
        }}
        drawerPosition={DrawerLayout.positions.Left}
        ref={v => (this.menuLeft = v)}>
        <View style={styles.content}>
          <View style={styles.view7}>{this.renderPages()}</View>
          <PreInfos
            onClose={data => {
              if (data == 'pesquisa') {
                this.openTab(0);
                this.props.navigation.popToTop();
              }
              Actions.setShowPreInfos({show: false, id_usuario: 0});
            }}
            navigation={this.props.navigation}
            screenProps={this.props.screenProps}
          />
        </View>
      </DrawerLayout>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(238,238,238,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view2: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  fragment3: {
    alignSelf: 'stretch',
    flex: 1,
  },
  view7: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  view3: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  fragment2: {
    alignSelf: 'auto',
  },
  menuleft: {
    backgroundColor: 'rgba(255,255,255,1)',
    width: 250,
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  tabBar: {
    flexDirection: 'row',
  },
  tabItem: {
    flex: 1,
    alignItems: 'center',
    padding: 7,
  },

  viewnot1: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    padding: 3,
  },
  viewnot2: {
    alignSelf: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    minWidth: 20,
    minHeight: 20,
    backgroundColor: 'rgba(222,34,33,1)',
    borderRadius: 10,
    marginLeft: 30,
  },
  viewnot3: {
    alignSelf: 'auto',
    flexDirection: 'column',
    // alignItems: "flex-start",
    // justifyContent: "flex-start",
    flexWrap: 'nowrap',
    minWidth: 20,
    minHeight: 20,
    backgroundColor: '#3BD97B',
    width: 100,
    borderRadius: 50,
    maxWidth: 50,
    maxHeight: 50,
  },
  labelnot1: {
    textAlign: 'center',
    flexWrap: 'wrap',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: '700',
    fontSize: 12,
    flex: 1,
  },
});
