import React, { Component } from "react";
import AlphaScrollFlatList from 'alpha-scroll-flat-list';
import {
  Icon,
  Image,
  TextInput,
  TitleBar,
  Progress
} from "react-native-1app";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Dimensions,
  Alert
} from "react-native";
import * as Actions from "../../redux/actions";
import styleGlobal from "../../styleGlobal";
import * as Chat from "../../worker/chat";
import { Util, Cloud } from "../../infra";
import { requestContatosPermission } from "../../redux/actions";

import Contacts from "react-native-contacts";
const ITEM_HEIGHT = 60;
const WIDTH = Dimensions.get('window').width;
export default class ListaContatos extends Component {
  constructor(props) {
    super(props);
    this.state = { load: true, lista: [], nome: "" };
    this.state.alunos = [];
  }

  pesquisar(nome) {
    var alunos = this.state.lista;
    if (!nome) {
      return this.setState({ alunos: alunos });
    }
    nome = Util.cleanString(nome);
    name = nome.toUpperCase();
    var list = [];
    for (let i = 0; i < alunos.length; i++) {
      let aluno = alunos[i];
      var nomeAluno = Util.cleanString(aluno.nome);
      var pes = nomeAluno.toUpperCase();
      if (Util.contemString(pes, name)) {
        list.push(aluno);
      }
    }
    this.setState({ alunos: list });
    this.ativo = true;
  }

  // carregarAlunos() {
  //   Contacts.checkPermission((err, permission) => {
  //     console.log(err, permission);
  //     if (permission == "authorized") {
  //       this.carregarAlunosAgora();
  //     } else {
  //       Contacts.requestPermission((err, permission) => {
  //         if (permission == "authorized") {
  //           this.carregarAlunosAgora();
  //         } else {
  //           setTimeout(() => {
  //             if (this.ativo) this.carregarAlunos();
  //           }, 10 * 1000);
  //         }
  //       });
  //     }
  //   });
  // }

  carregarAlunos() {
    requestContatosPermission((data, error) => {
      this.carregarAlunosAgora();
    });
  }
  addAmigo(telefone, callback) {
    this.setState({ load: true });
    Chat.salvarContato({ telefone: telefone }, (data, error) => {
      let msg = "";
      if (!error) {
        msg = "Contato encontrado no sistema e adicionado"
        Alert.alert(
          "Aviso",
          msg,
          [
            {
              text: "Ok",
              onPress: () => {
              },
              style: "cancel"
            },
          ],
          { cancelable: false }
        );
        if (callback) { return callback(true, error) }
      } else {
        if (callback) return callback(false, error);
      }
    });
  }

  carregarAlunosAgora() {
    Contacts.getAll((err, contacts) => {
      if (err) return console.log(err);
      var lista = [];
      for (let i = 0; i < contacts.length; i++) {
        let item = contacts[i];
        lista.push({
          foto: item.thumbnailPath,
          contatos: true,
          id: i,
          nome:
            item.givenName +
            "" +
            (item.middleName ? " " + item.middleName : "") +
            (item.familyName ? " " + item.familyName : ""),
          email_contato: item.emailAddresses[0]
            ? item.emailAddresses[0].email
            : "",
          email: item.emailAddresses[0] ? item.emailAddresses[0].email : "",
          telefone: item.phoneNumbers[0] ? item.phoneNumbers[0].number : "",
        });
      }

      lista.sort(function (a, b) {
        var alc = a.nome.toLowerCase(), blc = b.nome.toLowerCase();
        return alc > blc ? 1 : alc < blc ? -1 : 0;
      });
      // console.log(err, contacts);

      // // this.montaAlfabeto(lista, (data, error) => {
      //   console.log("alfabeto", data);
      this.setState({ alunos: lista, lista: lista, load: false });
      // })
    });
  }


  backPageButton() {
    this.props.navigation.goBack();
  }

  escolherContato(item) {
    this.setState({ load: true })

    this.addAmigo(item.telefone, (data, erroradd) => {
      if (data) {
        if (this.props.navigation.state.params.onGoBack)
          this.props.navigation.state.params.onGoBack({ achou: true });
        this.backPageButton();
        this.setState({ load: false })
      } else {
        this.processaFoto(item, (data, error) => {
          item.error = erroradd;
          if (this.props.navigation.state.params.onGoBack)
            this.props.navigation.state.params.onGoBack(item);
          this.backPageButton();
          this.setState({ load: false })
        })
      }

    })


  }

  processaFoto(item, callback) {
    if (item.foto) {
      Util.parseImgContatctsToBase64(item.foto, (base64, error) => {
        Cloud.upLoadFotoSemLib(base64, (data) => {
          item.url_foto = data.url;
          if (callback) return callback(item, error);
        })
      })
    } else {
      if (callback) return callback(item, null);
    }
  }

  componentDidMount() {
    this.carregarAlunos();
  }

  keyExtractor(item) {
    return item.id;
  }

  renderItem({ item }) {
    return (
      <TouchableOpacity
        style={[styles.itemContainer, Actions.getTema("scroll")]}
        onPress={() => {
          this.escolherContato(item);
        }}
      >
        <View style={styles.view5}>
          <Image source={{ uri: item.foto }} style={styles.image} />
        </View>
        <View style={styles.view6}>
          <Text style={[styles.text121, Actions.getTema("labelfields")]}>{item.nome}</Text>
          <Text style={[styles.text131, Actions.getTema("labelfields")]}>{item.telefone}</Text>
        </View>
      </TouchableOpacity>
    );
  }


  componentWillUnmount() { }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema("titlebar")]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}
          >
            <Icon
              style={[styles.icon, Actions.getTema("iconstitlebar")]}
              fromFontFamily={"Material Design Icons"}
              name={"arrow-left"}
            />
            <Text style={[styles.text2, Actions.getTema("iconstitlebar")]} >{"Escolher Contato"}</Text>
          </TouchableOpacity>
          <View style={styles.view4}>
            <View style={[styles.view7, Actions.getTema("pesquisar")]} >
              <TextInput
                style={styles.textinput}
                value={this.state.nome}
                onChange={value => {
                  this.state.nome = value;
                  this.pesquisar(value);
                }}
                keyboardType={"default"}
                placeholder={"Pesquisar"}
                inputNative={true}
                selectionColor={"#fff"}
              />
            </View>
          </View>
          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
        </TitleBar>


        <View style={{ flex: 1 }}>

          <AlphaScrollFlatList
            keyExtractor={this.keyExtractor.bind(this)}
            data={this.state.alunos}
            renderItem={this.renderItem.bind(this)}
            scrollKey={'nome'}
            reverse={false}
            itemHeight={ITEM_HEIGHT}
            scrollBarColor={Actions.getTema("scrollalfa")}
            scrollBarContainerStyle={Actions.getTema("scroll")}
          />
        </View>
      </View>);



  }
}


var styles = StyleSheet.create({
  content: {
    backgroundColor: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  button1: {
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "rgba(44,126,218,1)",
    height: 50,
    paddingLeft: 10,
    paddingRight: 20
  },
  icon: {
    color: "rgba(48,129,219,1)",
    fontSize: 25,
    marginRight: 5
  },
  text2: {
    textAlign: "left",
    color: "rgba(48,129,219,1)",
    alignSelf: "auto",
    fontWeight: "normal"
  },
  itemContainer: {
    width: WIDTH,
    flex: 1,
    flexDirection: 'row',
    height: ITEM_HEIGHT,
    paddingLeft: 5
  },
  view4: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: 5,
    borderRadius: 25
  },
  view7: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "rgba(81,82,83,0.13)",
    borderRadius: 25,
    paddingLeft: 10
  },
  textinput: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    marginLeft: 5
  },
  view5: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column",
    marginRight: 10,
    
  },
  image: {
    width: 50,
    height: 50
  },
  view6: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  text121: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  text131: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
});
