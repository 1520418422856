import React, {Component, PureComponent} from 'react';
import * as Actions from '../../redux/actions';
import {Content, Icon, Progress, TitleBar} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  Dimensions,
} from 'react-native';

import Alert from 'react-native-1app/lib/Alert';

import {Calendar, LocaleConfig} from 'react-native-calendars';
import styleGlobal from '../../styleGlobal';
import moment from 'moment';
import CalendarioPequeno from './CalendarioPequeno';
import FlatGrid from 'react-native-super-grid';
import {Util} from '../../infra';
import {TabView, SceneMap, TabBar} from 'react-native-tab-view';
import ListaCalendarios from './ListaCalendarios';
import ListaRelatorios from './ListaRelatorios';

export default class Calendarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista: this.setMeses(moment()),
      data: moment(),
      index: 0,
    };
  }

  componentDidMount() {}

  setMeses(date, callback) {
    if (!date) date = this.state.data;
    let lista = [];
    let data = moment(date).startOf('year');
    let cont = 0;
    while (cont < 12) {
      lista.push(moment(data).format('YYYY-MM-DD'));
      data = moment(data).add(1, 'month');
      cont++;
    }

    // this.setState({lista});
    // this.getDividas({
    //   data: moment(date)
    //     .startOf('year')
    //     .set(12, 'hours')
    //     .format('YYYY-MM-DD HH:MM:SS'),
    // });

    if (callback) callback();
    return lista;
  }

  getProps() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  componentWillUnmount() {}

  onCell(data) {
    if (this.getProps().setMonth) this.getProps().setMonth(data);
    this.props.navigation.goBack();
  }

  openTab(index) {
    if (index === 1) {
      return Alert.alert(
        'Atenção',
        'Esta tela pode demorar para carregar dependendo do número de dívidas que há no ano, deseja continuar?',
        [
          {text: 'Não', onPress: null},
          {
            text: 'Sim',
            onPress: () => {
              this.setState({index});
            },
          },
        ],
      );
    }
    this.setState({index});
  }

  render() {
    return (
      <View
        style={[{alignSelf: 'stretch', flex: 1}, Actions.getTema('scroll')]}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button3}
            disabled={this.state.load}
            onPress={() => {
              this.props.navigation.goBack();
            }}>
            <Icon
              style={[styles.icon2, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
          </TouchableOpacity>
          <View style={styles.viewhead99}>
            <View style={styles.viewSetas}>
              <TouchableOpacity
                style={styles.button3}
                disabled={this.state.load}
                onPress={() => {
                  this.setState({
                    lista: this.setMeses(
                      moment(this.state.data).subtract(1, 'year'),
                    ),
                    data: moment(this.state.data).subtract(1, 'year'),
                  });
                }}>
                <Icon
                  style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'chevron-left'}
                />
              </TouchableOpacity>
              <Text
                style={{
                  fontSize: 30,
                  color:
                    this.props.screenProps.store.getState().tema == 'preto'
                      ? 'white'
                      : 'rgba(48,129,219,1)',
                }}>
                {moment(this.state.data).format('YYYY')}
              </Text>
              <TouchableOpacity
                style={styles.button3}
                disabled={this.state.load}
                onPress={() => {
                  this.setState({
                    lista: this.setMeses(
                      moment(this.state.data).add(1, 'year'),
                    ),
                    data: moment(this.state.data).add(1, 'year'),
                  });
                }}>
                <Icon
                  style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'chevron-right'}
                />
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.button3} />
        </TitleBar>
        <View style={styles.viewFora}>
          {this.state.index == 0 && (
            <ListaRelatorios
              {...this.props}
              onMonth={data => {
                this.onCell(data);
              }}
              data={this.state.data.toJSON()}
              lista={this.state.lista}
            />
          )}
          {this.state.index == 1 && (
            <ListaCalendarios
              {...this.props}
              onMonth={data => {
                this.onCell(data);
              }}
              data={this.state.data.toJSON()}
              lista={this.state.lista}
            />
          )}
        </View>
        <View style={styles.viewTab}>
          <TouchableOpacity
            style={styles.btTab}
            onPress={() => {
              this.openTab(0);
            }}>
            <Icon
              fromFontFamily={'Material Design Icons'}
              name={'format-align-justify'}
              style={{
                color: 0 == this.state.index ? 'rgb(66,126,218)' : '#aaa',
                fontSize: 20,
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.btTab}
            onPress={() => {
              this.openTab(1);
            }}>
            <Icon
              fromFontFamily={'Material Design Icons'}
              name={'calendar'}
              style={{
                color: 1 == this.state.index ? 'rgb(66,126,218)' : '#aaa',
                fontSize: 20,
              }}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  viewDia: {
    width: 25,
    height: 25,
    borderRadius: 12.5,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  viewFora: {alignSelf: 'stretch', flex: 1},
  btTab: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewTab: {
    height: 40,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderColor: '#aaa',
    flexDirection: 'row',
  },
  tabbar: {
    alignSelf: 'stretch',
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {width: 50, height: 50, color: styleGlobal.azul.color},
  viewSetas: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 30,
  },
  viewhead99: {
    flex: 1,
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    width: 50,
  },
  view778: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  progress: {width: 50, height: 50, color: styleGlobal.azul.color},
  viewAzul: {
    height: 30,
    width: 30,
    backgroundColor: 'rgb(66,126,218)',
    position: 'absolute',
  },
  viewVermelha: {
    height: 30,
    width: 30,
    backgroundColor: 'rgb(247,49,66)',
    position: 'absolute',
  },
});
