import { Cloud, Util } from "../infra";
export function salvar(obj, callback) {
  Cloud.post('chats', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function listarMsgsAntigas(obj, callback) {
  Cloud.get('chats/' + obj.id_chat, obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function listagemPrincipalComUltimaMsg(callback) {
  Cloud.get('chats', {}, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function getMsgsQtds(callback) {
  Cloud.get('chatsqtd', {}, (res, error) => {
    if (callback) callback(res, error);
  })
}


export function salvarContato(obj, callback) {
  Cloud.post('contatos', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function salvarContatoMultiplo(obj, callback) {
  Cloud.post('contatos/multiplo', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function deleteContato(obj, callback) {
  Cloud.delete('contatos', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function getContato({ obj }, callback) {
  Cloud.get('contatos', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}


function resolverResponse(response, retorno) {
  var p1 = response.json();

  p1.then((responseData, error) => {
    if (response.status != 200) {
      retorno(undefined, responseData);
    } else if (error) {
      retorno(undefined, error);
    } else {
      retorno(responseData);
    }
  });
}
export function getGifs(obj, retorno) {
  var url = "https://api.giphy.com/v1/gifs/search?api_key=sl7innQxbn4QSvNgWXOFLKCj6Thcyn9x&q=" + obj.pesquisa + "&limit=6&offset="+(obj.page * 6)+"&rating=R&lang=en";
  var config = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };
  fetch(url, config)
    .then(response => {
      resolverResponse(response, retorno);
    })
    .catch(error => {
      if (retorno) retorno(undefined, error);
    });
}