import React from 'react';

import {View} from 'react-native';

const AdMobBanner = View,
  AdMobInterstitial = View,
  PublisherBanner = View,
  AdMobRewarded = View;

export {View, AdMobBanner, AdMobRewarded, PublisherBanner, AdMobInterstitial};

export default View;
