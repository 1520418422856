import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {Content, Icon, Progress, TitleBar, TimePicker} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  Platform,
} from 'react-native';
import Alert from 'react-native-1app/lib/Alert';
import styleGlobal from '../../styleGlobal';
import SelectInput from '../../components/SelectInput';
import * as Notificacao from '../../worker/notificacao';
import * as Usuario from '../../worker/usuario';
import DatePicker2 from '../../components/DatePicker2';
import moment from 'moment-timezone';

import {OpenDialog} from 'react-1app/lib/DialogAlert';

export default class configs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configs: {
        tempopin: !this.props.screenProps.store.getState().tempopin
          ? 0
          : this.props.screenProps.store.getState().tempopin,
      },
      pin: this.props.screenProps.store.getState().pin,
      tema: this.props.screenProps.store.getState().tema,
      ajuda: this.props.screenProps.store.getState().ajuda,
      telaini: this.props.screenProps.store.getState().telaini,
      mostrarvalheader: this.props.screenProps.store.getState()
        .mostrarvalheader,
      opennotfields: false,
      isDateTimePickerVisible: false,
    };
  }

  removePin() {
    Alert.alert(
      'Aviso',
      'Tem certeza que deseja remover o PIN de segurança?',
      [
        {text: 'Não', onPress: () => {}, style: 'cancel'},
        {
          text: 'Sim',
          onPress: () => {
            Actions.setPin(undefined);
            Actions.setTempoPin(0);
          },
          style: 'cancel',
        },
      ],
      {cancelable: false},
    );
  }

  changePin() {
    this.props.navigation.navigate('CriarPin');
  }
  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.pin != this.state.pin) this.setState({pin: store.pin});
      if (store.tempopin != this.state.tempopin) {
        this.state.configs.tempopin = store.tempopin;
        this.setState({configs: this.state.configs});
      }
      if (store.tema != this.state.tema) {
        this.setState({tema: store.tema});
      }

      if (store.ajuda != this.state.ajuda) {
        this.setState({ajuda: store.ajuda});
      }
      if (store.telaini != this.state.telaini) {
        this.setState({telaini: store.telaini});
      }

      if (store.mostrarvalheader != this.state.mostrarvalheader) {
        this.setState({mostrarvalheader: store.mostrarvalheader});
      }
    });

    this.setState({load: true});
    Usuario.getUserLogado((data, error) => {
      this.state.configs.temponot = data.temponot;
      // this.state.configs.horario_notificacao =data.horario_notificacao //moment(data.horario_notificacao,"HH:mm:ss")
      this.state.configs.horario_notificacao = data.horario_notificacao; //moment(data.horario_notificacao,"HH:mm:ss")
      this.state.configs.not_1dia = data.not_1dia;
      this.state.configs.not_2dia = data.not_2dia;
      this.state.configs.not_3dia = data.not_3dia;
      this.state.configs.not_7dia = data.not_7dia;
      this.state.configs.flag_notificar_vencida = data.flag_notificar_vencida;
      this.state.configs.flag_notificar_aceitar = data.flag_notificar_aceitar;
      this.state.configs.flag_notificar_pendente = data.flag_notificar_pendente;
      this.setState({configs: this.state.configs, load: false});
    });
  }

  salvarTempo(obj) {
    this.setState({load: true});
    var m = moment().utcOffset(0);
    m.set({
      hour: obj.horario_notificacao.split(':')[0],
      minute: obj.horario_notificacao.split(':')[1],
      second: 0,
      millisecond: 0,
    });
    obj.horario_notificacao = m;
    Usuario.salvarTempoAviso(
      {temponot: obj.temponot, horario_notificacao: obj.horario_notificacao},
      (data, error) => {
        this.setState({load: false});
        if (data) {
          this.state.configs.temponot = data.temponot;
          this.state.configs.horario_notificacao = data.horario_notificacao;
          this.setState({configs: this.state.configs});
        } else {
          Alert.alert(
            'Erro',
            error.msg,
            [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
            {cancelable: false},
          );
        }
      },
    );
  }

  atualizarDiasNot() {
    this.setState({load: true});
    Usuario.atualizarDiasNot(
      {
        flag_notificar_pendente: this.state.configs.flag_notificar_pendente,
        flag_notificar_aceitar: this.state.configs.flag_notificar_aceitar,
        flag_notificar_vencida: this.state.configs.flag_notificar_vencida,
        not_1dia: this.state.configs.not_1dia,
        not_2dia: this.state.configs.not_2dia,
        not_3dia: this.state.configs.not_3dia,
        not_7dia: this.state.configs.not_7dia,
      },
      (data, error) => {
        this.setState({load: false});
        if (data) {
          this.state.configs.not_1dia = data.not_1dia;
          this.state.configs.not_2dia = data.not_2dia;
          this.state.configs.not_3dia = data.not_3dia;
          this.state.configs.not_7dia = data.not_7dia;
          this.state.configs.flag_notificar_vencida =
            data.flag_notificar_vencida;
          this.state.configs.flag_notificar_aceitar =
            data.flag_notificar_aceitar;
          this.state.configs.flag_notificar_pendente =
            data.flag_notificar_pendente;

          this.setState({configs: this.state.configs});
        } else {
          Alert.alert(
            'Erro',
            error.msg,
            [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
            {cancelable: false},
          );
        }
      },
    );
  }

  backPageButton() {
    this.props.navigation.goBack();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }
  showDateTimePicker() {
    this.setState({isDateTimePickerVisible: true});
  }

  hideDateTimePicker() {
    this.setState({isDateTimePickerVisible: false});
  }

  handleDatePicked(date) {
    let obj = {...this.state.configs, horario_notificacao: date};
    this.salvarTempo(obj);
    this.hideDateTimePicker();
  }
  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
            <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
              {'Configurações'}
            </Text>
          </TouchableOpacity>
          <View style={styles.view} />

          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
        </TitleBar>
        <ScrollView style={[styles.scroll, Actions.getTema('scroll')]}>
          <View style={styles.view3334}>
            <Text
              style={[styles.text3111, Actions.getTema('lbl_tituloconfig')]}>
              {'Horário das notificações das dívidas'}
            </Text>
            <View style={{flex: 1}} />
            {this.state.ajuda && (
              <TouchableOpacity
                style={styles.buttonhelp}
                onPress={() => {
                  Alert.alert(
                    'Ajuda',
                    'Defina o horário para lembrarmos você e se gostaria que o notificássemos no dia do vencimento e no dia anterior do vencimento',
                    [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                  );
                }}>
                <Icon
                  style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'help-circle'}
                />
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.view333}>
            <View style={styles.view4}>
              <DatePicker2
                style={
                  Platform.OS == 'web'
                    ? {flex: 1, ...Actions.getTema('textcell2')}
                    : styles.button4
                }
                value={this.state.data_fim}
                onChange={date => {
                  // alert(date)
                  if (Platform.OS == 'web')
                    return this.setState({data_fim: date});
                  this.handleDatePicked(date);
                }}
                onSubmitEditing={() => {
                  if (Platform.OS == 'web')
                    this.handleDatePicked(this.state.data_fim);
                }}
                datePickerModeAndroid={'calendar'}
                label={'Definir horário'}
                type={'time'}
                time={'timeOnly'}
                inputProps={{
                  step: 60,
                }}
                InputLabelProps={{
                  style: Actions.getTema('textcell2'),
                  shrink: true,
                }}
              />
            </View>
            {this.state.configs.horario_notificacao ? (
              <View style={styles.view444}>
                <Text style={[styles.text311, Actions.getTema('textcell2')]}>
                  {this.state.configs.horario_notificacao.substring(0, 5)}
                </Text>
              </View>
            ) : null}
          </View>

          <View style={styles.view3334}>
            <Text
              style={[styles.text3111, Actions.getTema('lbl_tituloconfig')]}>
              {'Abrir opções de notificações'}
            </Text>
            <View style={{flex: 1}} />
            <TouchableOpacity
              style={styles.buttonhelp}
              onPress={() => {
                this.setState({opennotfields: !this.state.opennotfields});
              }}>
              <Icon
                style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                fromFontFamily={'Material Design Icons'}
                name={this.state.opennotfields ? 'chevron-up' : 'chevron-down'}
              />
            </TouchableOpacity>
          </View>

          {this.state.opennotfields && (
            <View style={{}}>
              <View style={styles.view333}>
                <TouchableOpacity
                  style={styles.button9}
                  onPress={() => {
                    this.state.configs.not_1dia = !this.state.configs.not_1dia;
                    this.setState({configs: this.state.configs}, () => {
                      this.atualizarDiasNot();
                    });
                  }}>
                  <Icon
                    style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={
                      this.state.configs.not_1dia == true
                        ? 'check-circle'
                        : 'checkbox-blank-circle-outline'
                    }
                  />
                  <View style={styles.view61}>
                    <Text
                      style={[
                        styles.textinput611,
                        Actions.getTema('labelfields'),
                      ]}>
                      {' Notificar vencimento no dia'}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.view333}>
                <TouchableOpacity
                  style={styles.button9}
                  onPress={() => {
                    this.state.configs.not_2dia = !this.state.configs.not_2dia;
                    this.setState({configs: this.state.configs}, () => {
                      this.atualizarDiasNot();
                    });
                  }}>
                  <Icon
                    style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={
                      this.state.configs.not_2dia == true
                        ? 'check-circle'
                        : 'checkbox-blank-circle-outline'
                    }
                  />
                  <View style={styles.view61}>
                    <Text
                      style={[
                        styles.textinput611,
                        Actions.getTema('labelfields'),
                      ]}>
                      {' Notificar vencimento 1 dia antes'}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.view333}>
                <TouchableOpacity
                  style={styles.button9}
                  onPress={() => {
                    this.state.configs.not_3dia = !this.state.configs.not_3dia;
                    this.setState({configs: this.state.configs}, () => {
                      this.atualizarDiasNot();
                    });
                  }}>
                  <Icon
                    style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={
                      this.state.configs.not_3dia == true
                        ? 'check-circle'
                        : 'checkbox-blank-circle-outline'
                    }
                  />
                  <View style={styles.view61}>
                    <Text
                      style={[
                        styles.textinput611,
                        Actions.getTema('labelfields'),
                      ]}>
                      {' Notificar vencimento 3 dia antes'}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.view333}>
                <TouchableOpacity
                  style={styles.button9}
                  onPress={() => {
                    this.state.configs.not_7dia = !this.state.configs.not_7dia;
                    this.setState({configs: this.state.configs}, () => {
                      this.atualizarDiasNot();
                    });
                  }}>
                  <Icon
                    style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={
                      this.state.configs.not_7dia == true
                        ? 'check-circle'
                        : 'checkbox-blank-circle-outline'
                    }
                  />
                  <View style={styles.view61}>
                    <Text
                      style={[
                        styles.textinput611,
                        Actions.getTema('labelfields'),
                      ]}>
                      {' Notificar vencimento 7 dia antes'}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.view333}>
                <TouchableOpacity
                  style={styles.button9}
                  onPress={() => {
                    this.state.configs.flag_notificar_vencida = !this.state
                      .configs.flag_notificar_vencida;
                    this.setState({configs: this.state.configs}, () => {
                      this.atualizarDiasNot();
                    });
                  }}>
                  <Icon
                    style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={
                      this.state.configs.flag_notificar_vencida == true
                        ? 'check-circle'
                        : 'checkbox-blank-circle-outline'
                    }
                  />
                  <View style={styles.view61}>
                    <Text
                      style={[
                        styles.textinput611,
                        Actions.getTema('labelfields'),
                      ]}>
                      {' Notificar dívidas vencidas'}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.view333}>
                <TouchableOpacity
                  style={styles.button9}
                  onPress={() => {
                    this.state.configs.flag_notificar_aceitar = !this.state
                      .configs.flag_notificar_aceitar;
                    this.setState({configs: this.state.configs}, () => {
                      this.atualizarDiasNot();
                    });
                  }}>
                  <Icon
                    style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={
                      this.state.configs.flag_notificar_aceitar == true
                        ? 'check-circle'
                        : 'checkbox-blank-circle-outline'
                    }
                  />
                  <View style={styles.view61}>
                    <Text
                      style={[
                        styles.textinput611,
                        Actions.getTema('labelfields'),
                      ]}>
                      {' Notificar dívidas para aceitar'}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>

              <View style={styles.view333}>
                <TouchableOpacity
                  style={styles.button9}
                  onPress={() => {
                    this.state.configs.flag_notificar_pendente = !this.state
                      .configs.flag_notificar_pendente;
                    this.setState({configs: this.state.configs}, () => {
                      this.atualizarDiasNot();
                    });
                  }}>
                  <Icon
                    style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={
                      this.state.configs.flag_notificar_pendente == true
                        ? 'check-circle'
                        : 'checkbox-blank-circle-outline'
                    }
                  />
                  <View style={styles.view61}>
                    <Text
                      style={[
                        styles.textinput611,
                        Actions.getTema('labelfields'),
                      ]}>
                      {' Notificar dívidas aguardando'}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            </View>
          )}

          <View style={styles.view32} />
          <View style={styles.view31}>
            <View style={styles.view33345}>
              <Text style={[styles.text3, Actions.getTema('lbl_tituloconfig')]}>
                {'Segurança com PIN/DIGITAL'}
              </Text>
              <View style={{flex: 1}} />
              {this.state.ajuda && (
                <TouchableOpacity
                  style={styles.buttonhelp}
                  onPress={() => {
                    Alert.alert(
                      'Ajuda',
                      'Defina uma senha para entrar no app e/ou use sua digital',
                      [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                    );
                  }}>
                  <Icon
                    style={[styles.icon399, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={'help-circle'}
                  />
                </TouchableOpacity>
              )}
            </View>
            <Text style={[styles.text4, Actions.getTema('textcell3')]}>
              {this.state.pin ? 'PIN Ativado' : 'Não há Pin cadastrado'}
            </Text>
            <View style={styles.view5}>
              <TouchableOpacity
                style={styles.button4}
                onPress={() => {
                  this.changePin();
                }}>
                <Text style={[styles.text5, Actions.getTema('iconstitlebar')]}>
                  {'Definir'}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[
                  styles.button5,
                  Actions.getTema('buttionborderconfig3'),
                ]}
                onPress={() => {
                  this.removePin();
                }}>
                <Text style={[styles.text6, Actions.getTema('textcell3')]}>
                  {'Desabilitar'}
                </Text>
              </TouchableOpacity>
            </View>
            {this.state.pin ? (
              <SelectInput
                style={[styles.selectinput1, Actions.getTema('labelfields')]}
                value={this.state.configs.tempopin}
                type={'modal'}
                title={'Selecione o tempo do seu PIN'}
                key_label={'text'}
                key_value={'value'}
                onChange={(value, data, index) => {
                  // this.state.configs.tempopin = value;
                  // this.setState({ configs: this.state.configs });
                  Actions.setTempoPin(value); //redux
                }}
                label={'Tempo esgotado'}
                list={[
                  {text: 'Não definido', id: 0, value: 0},
                  {text: '5 segundos', id: 5, value: 5},
                  {text: '15 segundos', id: 15, value: 15},
                  {text: '30 segundos', id: 30, value: 30},
                  {text: '1 minuto', id: 60, value: 60},
                  {text: '2 minutos', id: 120, value: 120},
                  {text: '5 minutos', id: 300, value: 300},
                  {text: '10 minutos', id: 600, value: 600},
                  {text: '30 minutos', id: 1800, value: 1800},
                ]}
              />
            ) : null}
          </View>
          <View style={styles.view32} />
          <View style={styles.view31}>
            <Text style={[styles.text3, Actions.getTema('lbl_tituloconfig')]}>
              {'Tema'}
            </Text>
            {/* <View style={styles.view5}>
              <TouchableOpacity style={styles.button4} onPress={() => {
                Actions.setCor("white");
              }}>
                <Text style={styles.text5}>{"Branco"}</Text>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button4} onPress={() => {
                Actions.setCor("preto");
              }}>
                <Text style={styles.text5}>{"Preto"}</Text>
              </TouchableOpacity>
            </View> */}

            <View style={styles.view3339}>
              <TouchableOpacity
                style={styles.button9}
                onPress={() => {
                  Actions.setCor('white');
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.tema == 'white'
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {' Padrão'}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button9}
                onPress={() => {
                  Actions.setCor('preto');
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.tema == 'preto'
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {' Black'}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.view32} />
          <View style={styles.view31}>
            <Text style={[styles.text3, Actions.getTema('lbl_tituloconfig')]}>
              {'Ajuda'}
            </Text>
            <View style={styles.view3339}>
              <TouchableOpacity
                style={styles.button9}
                onPress={() => {
                  Actions.setAjuda(true);
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.ajuda == true
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {' Habilitar'}
                  </Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button9}
                onPress={() => {
                  Actions.setAjuda(false);
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.ajuda == false
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {' Desabilitar'}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.view32} />
          <View style={styles.view31}>
            <Text style={[styles.text3, Actions.getTema('lbl_tituloconfig')]}>
              {'Tela inicial'}
            </Text>
            <View style={styles.view3339}>
              <TouchableOpacity
                style={styles.button9}
                onPress={() => {
                  Actions.setTelaInicial(0);
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.telaini == 0
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {' Lista'}
                  </Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button9}
                onPress={() => {
                  Actions.setTelaInicial(1);
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.telaini == 1
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {' Calendário'}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>

          <View style={styles.view32} />
          <View style={styles.view31}>
            <Text style={[styles.text3, Actions.getTema('lbl_tituloconfig')]}>
              {'Mostrar o montante ao abrir o app'}
            </Text>
            <View style={styles.view3339}>
              <TouchableOpacity
                style={styles.button9}
                onPress={() => {
                  Actions.setMostrarValHeader(true);
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.mostrarvalheader == true
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {' Sim'}
                  </Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button9}
                onPress={() => {
                  Actions.setMostrarValHeader(false);
                }}>
                <Icon
                  style={[styles.icon8, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={
                    this.state.mostrarvalheader == false
                      ? 'check-circle'
                      : 'checkbox-blank-circle-outline'
                  }
                />
                <View style={styles.view61}>
                  <Text
                    style={[
                      styles.textinput611,
                      Actions.getTema('labelfields'),
                    ]}>
                    {' Não'}
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </ScrollView>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  icon8: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1,
  },
  view61: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },
  button9: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    flexDirection: 'row',
    flex: 1,
  },
  view: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    backgroundColor: 'rgba(252,252,252,1)',
    flex: 1,
  },
  view4: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flex: 1,
  },
  view444: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
    marginLeft: 15,
  },
  view3334: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  view33345: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  view333: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 20,
    paddingRight: 20,
  },
  view3339: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    // paddingLeft: 20,
    paddingRight: 20,
  },
  text311: {
    textAlign: 'center',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'bold',
    fontSize: 24,
  },
  text3111: {
    textAlign: 'center',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'bold',
  },
  view32: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    height: 1,
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'rgba(177,177,184,1)',
  },
  view31: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 5,
  },
  text3: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'bold',
  },
  text4: {
    textAlign: 'left',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginTop: 10,
  },
  view5: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  button4: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderRadius: 22,
    backgroundColor: 'rgba(58,121,172,0)',
    borderColor: 'rgba(58,121,172,1)',
    borderWidth: 2,
    borderBottomWidth: 2,
    marginTop: 15,
    flex: 1,
    marginRight: 10,
  },
  text5: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  button5: {
    borderRadius: 22,
    backgroundColor: 'rgba(232,69,60,0)',
    borderColor: 'rgba(255,255,255,1)',
    borderWidth: 2,

    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    // color: "rgba(177,177,184,1)",
    marginTop: 15,
    // backgroundColor: "rgba(177,177,184,1)",
    flex: 1,
    marginLeft: 10,
  },
  text6: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },
  selectinput1: {
    color: 'rgba(0,0,0,1)',
    minHeight: 40,
    alignSelf: 'stretch',
    marginTop: 5,
  },
});
