import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {
  Content,
  Icon,
  Image,
  ListView,
  TitleBar,
  Progress,
  TextInput,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  Alert,
  Platform,
  FlatList,
  RefreshControl,
} from 'react-native';
import styleGlobal from '../../styleGlobal';
import {Util} from '../../infra';
import * as Chat from '../../worker/chat';
import TodoSwipeList from '../../components/TodoSwipeList';
import {setNotsMsg, setListamsgTela} from '../../redux/actions';
import {ViewPager} from 'rn-viewpager';
import Header from './Header';
import PreInfos from '../user/PreInfos';
var carregandoconversas = false;
export default class Lista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mensagens: [],
      listamigos: [],
      listamigos_completa: [],
      mensagens_completa: [],
      conversar: true,
      amigos: false,
      qtdmsgs: this.props.screenProps.store.getState().qtdmsgs,
      load: false,
      aceitasqtd: this.props.screenProps.store.getState().aceitasqtd,
      telefone: '',
      user: this.props.screenProps.store.getState().user,
      currentPosition: 0,
      pesquisar: false,
      tema: this.props.screenProps.store.getState().tema,
    };
  }

  setContato(contato) {
    if (!contato.achou) {
      Alert.alert(
        'Aviso',
        contato.error.msg,
        [
          {
            text: 'Ok',
            onPress: () => {},
            style: 'cancel',
          },
        ],
        {cancelable: false},
      );
    } else {
      this.getAmigos(true);
    }

    // this.addAmigo(contato.telefone);
  }

  pesquisar_conversas(nome) {
    var alunos = this.state.mensagens_completa;
    if (!nome) {
      return this.setState({mensagens: this.state.mensagens_completa});
    }
    nome = Util.cleanString(nome);
    let name = nome.toUpperCase();
    var list = [];

    alunos.map(item => {
      let aluno = item;
      var nomeAluno = Util.cleanString(aluno.nome);
      var pes = nomeAluno.toUpperCase();
      if (Util.contemString(pes, name)) {
        list.push(aluno);
      }
    });
    this.setState({mensagens: list});
  }

  pesquisar_contatos(nome) {
    var alunos = this.state.listamigos_completa;
    if (!nome) {
      return this.setState({listamigos: this.state.listamigos_completa});
    }
    nome = Util.cleanString(nome);
    name = nome.toUpperCase();
    var list = [];
    for (let i = 0; i < alunos.length; i++) {
      let aluno = alunos[i];
      var nomeAluno = Util.cleanString(aluno.nome);
      var pes = nomeAluno.toUpperCase();
      if (Util.contemString(pes, name)) {
        list.push(aluno);
      }
    }
    this.setState({listamigos: list});
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (
        store.user != this.state.user ||
        store.qtdmsgs != this.state.qtdmsgs ||
        store.aceitasqtd != this.state.aceitasqtd ||
        store.tema != this.state.tema
      )
        this.setState({
          user: store.user,
          qtdmsgs: store.qtdmsgs,
          aceitasqtd: store.aceitasqtd,
          tema: store.tema,
        });
    });

    this.getAmigos(false);
    this.getConversas();
    setListamsgTela(() => {
      setNotsMsg();
      this.getConversas();
    });
  }
  componentWillUnmount() {
    setListamsgTela(false);
    if (this.unsubscribe) this.unsubscribe();
  }

  getConversas() {
    if (!this.state.carregandoconversas) {
      this.setState({load: true, carregandoconversas: true}, () => {
        Chat.listagemPrincipalComUltimaMsg((data, error) => {
          this.setState({
            mensagens_completa: data,
            nome: '',
            mensagens: data,
            conversar: true,
            amigos: false,
            load: false,
            carregandoconversas: false,
          });
        });
      });
    }
  }

  abrirQR() {
    this.props.navigation.navigate('Qrcode', {
      activity: this,
      oldState: this.state,
    });
  }

  addAmigo(telefone) {
    this.setState({load: true});
    Chat.salvarContato({telefone: telefone}, (data, error) => {
      let msg = '';
      if (error) {
        msg = error.msg;
      } else {
        msg = 'Contato adicionado';
      }
      Alert.alert(
        'Aviso',
        msg,
        [
          {
            text: 'Ok',
            onPress: () => {},
            style: 'cancel',
          },
        ],
        {cancelable: false},
      );
      this.setState({load: false, telefone: ''});
      this.getAmigos(true);
      this.getConversas(true);
    });
  }
  getAmigos(load) {
    if (load) {
      this.setState({load: true});
    }
    Chat.getContato({}, (data, error) => {
      this.setState({
        listamigos_completa: data,
        nome: '',
        listamigos: data,
        conversar: false,
        amigos: true,
      });
      if (load) this.setState({load: false});
    });
  }

  render() {
    return (
      <Content style={styles.container} keyboard={true}>
        <TitleBar
          style={[
            styleGlobal.titlebar,
            Actions.getTema('titlebar'),
            {flexDirection: 'column', height: 100},
          ]}>
          <View style={styles.viewhead99}>
            <View style={styles.view778}>
              <TouchableOpacity
                style={styles.button2}
                onPress={() => {
                  this.props.activity.menuLeft.openDrawer();
                }}>
                <Icon
                  style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'dots-vertical'}
                />

                {this.state.aceitasqtd > 0 ? (
                  <View style={styles.viewnot1}>
                    <View style={styles.viewnot10} />
                  </View>
                ) : null}
              </TouchableOpacity>
              {this.state.pesquisar ? (
                <TouchableOpacity
                  style={styles.button3}
                  onPress={() => {
                    this.setState({pesquisar: false});
                  }}>
                  <Icon
                    style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={'arrow-left'}
                  />
                </TouchableOpacity>
              ) : null}

              {!this.state.pesquisar ? (
                <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
                  {'Casher'}
                </Text>
              ) : null}
            </View>

            {this.state.pesquisar ? (
              <View
                style={[
                  styles.view777,
                  this.state.pesquisar ? Actions.getTema('pesquisar') : null,
                ]}>
                <TextInput
                  style={styles.textinput}
                  value={this.state.nome}
                  ref={input => {
                    this.pesquisacampo = input;
                  }}
                  onChange={value => {
                    this.state.nome = value;
                    if (this.state.currentPosition == 0) {
                      this.pesquisar_conversas(value);
                    } else {
                      this.pesquisar_contatos(value);
                    }
                  }}
                  keyboardType={'default'}
                  placeholder={'Pesquisar'}
                  inputNative={true}
                  selectionColor={'#fff'}
                />
              </View>
            ) : null}

            {this.state.pesquisar ? (
              <TouchableOpacity
                style={styles.button3}
                onPress={() => {
                  this.state.nome = '';
                  if (this.state.currentPosition == 0) {
                    this.pesquisar_conversas('');
                  } else {
                    this.pesquisar_contatos('');
                  }
                  this.pesquisacampo.focus();
                }}>
                <Icon
                  style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'close-circle'}
                />
              </TouchableOpacity>
            ) : null}
            {!this.state.pesquisar ? (
              <View
                style={[
                  styles.view777,
                  this.state.pesquisar ? Actions.getTema('pesquisar') : null,
                ]}>
                <View style={styles.view779}>
                  {!this.state.pesquisar ? (
                    <TouchableOpacity
                      style={styles.button3}
                      onPress={() => {
                        this.setState({pesquisar: true}, () => {
                          this.pesquisacampo.focus();
                        });
                      }}>
                      <Icon
                        style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                        fromFontFamily={'Material Design Icons'}
                        name={'magnify'}
                      />
                    </TouchableOpacity>
                  ) : null}
                  {!this.state.pesquisar && Platform.OS != 'web' ? (
                    <TouchableOpacity
                      style={styles.button3}
                      onPress={() => {
                        this.abrirQR();
                      }}>
                      <Icon
                        style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                        fromFontFamily={'Material Design Icons'}
                        name={'qrcode'}
                      />
                    </TouchableOpacity>
                  ) : null}
                </View>
              </View>
            ) : null}
          </View>

          <View style={styles.viewhead99}>
            {this.viewpager && this.viewpager.setPage ? (
              <Header
                screenProps={this.props.screenProps}
                activity={this}
                navigation={this.props.navigation}
                currentPosition={this.state.currentPosition}
                viewpager={this.viewpager}
              />
            ) : null}
          </View>
        </TitleBar>

        <View style={styles.view2}>
          <ViewPager
            horizontalScroll={true}
            style={[styles.fragment2, {flexGrow: 1}]}
            ref={viewpager => {
              this.viewpager = viewpager;
            }}
            onPageSelected={page => {
              this.setState({
                currentPosition: page.position,
                nome: '',
                mensagens: this.state.mensagens_completa,
                listamigos: this.state.listamigos_completa,
              });
            }}>
            {/* page conversa */}
            <View style={styles.content21}>
              <View style={[styles.scroll, Actions.getTema('scroll')]}>
                <FlatList
                  style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                  refreshControl={
                    <RefreshControl
                      refreshing={this.state.load}
                      onRefresh={() => {
                        this.getConversas();
                      }}
                    />
                  }
                  renderItem={({item, index}) => {
                    return (
                      <Celllistview
                        item={item}
                        screenProps={this.props.screenProps}
                        activity={this}
                        navigation={this.props.navigation}
                        rowID={index}
                        tela={'conversar'}
                      />
                    );
                  }}
                  ListEmptyComponent={() => {
                    return (
                      <Text
                        style={{
                          textAlign: 'center',
                          color: 'gray',
                          margin: 10,
                        }}>
                        {'Nenhuma conversa iniciada.'}
                      </Text>
                    );
                  }}
                  data={
                    Array.isArray(this.state.mensagens)
                      ? this.state.mensagens
                      : []
                  }
                  keyExtractor={(item, index) => index}
                />
              </View>
            </View>

            <View style={styles.content21}>
              <View style={[styles.scroll, Actions.getTema('scroll')]}>
                <FlatList
                  style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                  refreshControl={
                    <RefreshControl
                      refreshing={this.state.load}
                      onRefresh={() => {
                        this.getAmigos();
                      }}
                    />
                  }
                  renderItem={({item, index}) => {
                    return (
                      <Celllistview
                        item={item}
                        screenProps={this.props.screenProps}
                        activity={this}
                        navigation={this.props.navigation}
                        rowID={index}
                        tela={'amigos'}
                      />
                    );
                  }}
                  ListEmptyComponent={() => {
                    return (
                      <Text
                        style={{
                          textAlign: 'center',
                          color: 'gray',
                          margin: 10,
                        }}>
                        {'Nenhuma amigo adicionado.'}
                      </Text>
                    );
                  }}
                  data={
                    Array.isArray(this.state.listamigos)
                      ? this.state.listamigos
                      : []
                  }
                  keyExtractor={(item, index) => index}
                />
              </View>
            </View>
          </ViewPager>
        </View>
      </Content>
    );
  }
}

class Celllistview extends Component {
  constructor(props) {
    super(props);

    this.state = {item: {msgs: {}}};
  }
  componentDidMount() {}

  componentWillUnmount() {}

  getInfos() {
    return {
      id_usuario: this.props.item.id_usuario_contato,
      boolcontatoproprio: false,
    };
  }

  getWeight(item) {
    if (
      !item.msgs.readed &&
      item.msgs.id_sender != this.props.activity.state.user.id
    ) {
      return {
        fontWeight: 'bold',
        color: 'rgba(0,0,0,1)',
      };
    } else {
      return {
        fontWeight: 'normal',
        color: 'rgba(177,177,184,1)',
      };
    }
  }

  abrirChat(item) {
    this.props.navigation.update = this.onGoBack;

    this.props.navigation.navigate('Conversa', {
      id_usuario_contato: item.id_usuario_contato,
      atualizar: this.props.atualizar,
    });
  }

  onGoBack = () => {
    setNotsMsg();
    this.props.activity.getConversas();
  };

  render() {
    return (
      <View
        style={[styles.Cell, Actions.getTema('chatmsgs')]}
        screenProps={this.props.screenProps}
        navigation={this.props.navigation}
        activity={this}
        onPress={() => {}}>
        <TouchableOpacity
          style={styles.view9}
          onPress={() => {
            Actions.setShowPreInfos({show: true, ...this.getInfos()});
          }}>
          <Image
            style={styles.image}
            source={{uri: this.props.item.foto}}
            resizeMode={'cover'}
          />
        </TouchableOpacity>
        {this.props.tela == 'conversar' ? (
          <TouchableOpacity
            style={styles.view131}
            onPress={() => {
              this.abrirChat(this.props.item);
            }}>
            <View style={styles.view71}>
              <View style={styles.view61}>
                <Text
                  style={[styles.text81, Actions.getTema('lbl_tituloconfig')]}>
                  {this.props.item.nome}
                </Text>
              </View>

              <View style={styles.view6}>
                <Text style={styles.text9}>{this.props.item.data}</Text>
              </View>
            </View>
            <View style={styles.view72}>
              <View style={styles.view73}>
                {this.props.item &&
                this.props.item.msgs &&
                this.props.item.msgs.text ? (
                  <Text
                    style={[
                      styles.text11,
                      {...this.getWeight(this.props.item)},
                      Actions.getTema('lastmsg'),
                    ]}
                    numberOfLines={parseInt('1')}>
                    {(this.props.item.msgs.text.user._id ==
                    this.props.activity.state.user.id
                      ? 'Você: '
                      : '') + this.props.item.msgs.text.text}
                  </Text>
                ) : null}
              </View>
              {this.props.item &&
              this.props.item.msgs &&
              this.props.item.msgs.readed == false &&
              this.props.item.msgs.id_sender !=
                this.props.activity.state.user.id ? (
                <View style={styles.view7}>
                  <View style={styles.viewnot1}>
                    <View
                      style={[
                        styles.viewnot2,
                        Actions.getTema('notification'),
                      ]}>
                      <Text style={styles.labelnot1}>
                        {this.props.item.totalmsg}
                      </Text>
                    </View>
                  </View>
                </View>
              ) : null}
            </View>
          </TouchableOpacity>
        ) : null}
        {this.props.tela == 'amigos' ? (
          <TouchableOpacity
            style={styles.view131}
            onPress={() => {
              this.abrirChat(this.props.item);
            }}>
            <View style={styles.view611}>
              <Text
                style={[styles.text811, Actions.getTema('lbl_tituloconfig')]}>
                {this.props.item.nome}
              </Text>
            </View>
            <View style={styles.view611}>
              <Text style={[styles.text11, Actions.getTema('lastmsg')]}>
                {this.props.item.obs_pessoal}
              </Text>
            </View>
          </TouchableOpacity>
        ) : null}
      </View>
    );
  }
}

var styles = StyleSheet.create({
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  text33: {
    textAlign: 'center',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
  },

  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    width: 50,
  },
  textinput: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'center',
    fontWeight: 'normal',
    marginLeft: 5,
    paddingRight: 5,
  },
  button222: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 50,
  },
  button2: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  container: {
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: 'rgba(255,255,255,1)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  view777: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 25,
    margin: 5,
  },
  view779: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  view778: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },

  text2: {
    // alignSelf: "stretch",
    // textAlign: "left",

    color: 'rgba(48,129,219,1)',
    // alignSelf: "auto",
    fontWeight: 'normal',
    // flex: 1,
    marginLeft: 10,
  },
  viewhead99: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
  },
  fragment2: {
    alignSelf: 'stretch',
    flex: 1,
  },
  content21: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    flex: 1,
  },
  listview1: {
    alignSelf: 'stretch',
  },
  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    paddingTop: 15,
    paddingBottom: 15,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
  },
  view9: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 36,
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 2,
  },
  view71: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  view61: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  view611: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  text81: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 10,
  },
  text811: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
    marginLeft: 10,
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginLeft: 10,
  },
  view2: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flex: 1,
  },
  text9: {
    textAlign: 'right',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'center',
    fontWeight: 'bold',

    fontSize: 10,
  },
  view72: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  view73: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  text11: {
    textAlign: 'left',
    // color: "rgba(177,177,184,1)",
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 10,
  },
  view506: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 10,
  },
  view7: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginLeft: 10,
    marginRight: 15,
  },
  viewnot1: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    padding: 3,
  },
  viewnot2: {
    alignSelf: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    minWidth: 20,
    minHeight: 20,
    backgroundColor: 'rgba(222,34,33,1)',
    borderRadius: 10,
  },
  labelnot1: {
    textAlign: 'center',
    flexWrap: 'wrap',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: '700',
    fontSize: 12,
    flex: 1,
  },
  viewnot1: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'nowrap',
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    padding: 3,
  },
  viewnot10: {
    alignSelf: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    minWidth: 12,
    minHeight: 12,
    backgroundColor: 'rgba(222,34,33,1)',
    borderRadius: 6,
    marginLeft: 30,
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    width: 50,
  },
});
