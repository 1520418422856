import { Cloud, Util } from "../infra";
export function getNotificacoes(callback) {
  Cloud.get('notificacao', {}, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function getNotreaded(callback) {
  Cloud.get('notificacao/naolidas', {}, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function setLidas(callback) {
  Cloud.put('notificacao/setlidas', {}, (res, error) => {
    if (callback) callback(res, error);
  })
}


export function salvarToken(dados, callback) {
  Cloud.post('notificacao/token', dados, (res, error) => {
    if (callback) callback(res, error);
  })
}

