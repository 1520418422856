import React, { Component } from "react";
import * as Actions from "../../redux/actions";
import {
  Content,
  Icon,
  Image,
  Progress,
  TextInput,
  ImageUpload,
  TitleBar
} from "react-native-1app";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  ScrollView,
  Alert,
  Linking 
} from "react-native";
import styleGlobal from "../../styleGlobal";
import * as Usuario from "../../worker/usuario";
import DatePicker from "../../components/DatePicker";
import { Util } from "../../infra";
export default class InfosUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      usuario: { obsobj: {} },
      id_usuario: this.propsNav().id_usuario,
      boolcontato: this.propsNav().boolcontato
    };
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  backPageButton(page) {
    if (this.props.navigation.state.params.onGoBack)
      this.props.navigation.state.params.onGoBack(page);
    this.props.navigation.goBack();
  }


  salvar() {
    this.setState({ load: true })
    Usuario.salvarObs({ id_usuario_obs: this.state.id_usuario, obs: this.state.usuario.obsobj.obs }, (data, error) => {
      if (error) {
        Alert.alert("Erro", error.msg, [{ text: "Ok", onPress: () => { } }], { cancelable: false });
      } else {
        Alert.alert("Sucesso", "Dados salvos", [{ text: "Ok", onPress: () => { } }], { cancelable: false });
      }
      this.setState({ load: false })
    })
  }
  abrir() {
    let obj = { id: this.state.id_usuario, flag_eh_contato: this.state.boolcontato }
    this.setState({ load: true })
    Usuario.getUsuarioInfos(obj, (data, error) => {
      if (error) {
        Alert.alert("Erro", error.msg, [{ text: "Ok", onPress: () => { } }], { cancelable: false });
      } else {
        this.setState({ usuario: data })
      }

      this.setState({ load: false })
    })
  }

  componentDidMount() {

    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
    });
    if (this.state.id_usuario) {
      this.abrir(this.state.id_usuario);
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }




  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema("titlebar")]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton(0);
            }}
          >
            <Icon
              style={[styles.icon, Actions.getTema("iconstitlebar")]}
              fromFontFamily={"Material Design Icons"}
              name={"arrow-left"}
            />
            <Text style={[styles.text2, Actions.getTema("iconstitlebar")]}>
              {"Informações do Usuário"}
            </Text>
          </TouchableOpacity>

          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
          {!this.state.load ? (
            <TouchableOpacity
              style={[styles.button3]}
              onPress={() => {
                this.salvar();
              }}
            >
              <Icon
                style={[styles.icon3, Actions.getTema("iconstitlebar")]}
                fromFontFamily={"Material Icons"}
                name={"save"}
              />
            </TouchableOpacity>
          ) : null}

        </TitleBar>
        <ScrollView style={[styles.scroll, Actions.getTema("scroll")]} keyboardShouldPersistTaps={true}>
          <View style={styles.view3}>
            <Image
              style={styles.image}
              source={{ uri: this.state.usuario.foto_large }}
              resizeMode={"cover"}
            />
            <View style={styles.view4}>
              <TextInput
                style={[styles.textinput51, Actions.getTema("labelfields")]}
                value={this.state.usuario.nome}
                onChange={value => {

                }}
                disabled={true}
                keyboardType={"default"}
                label={"Nome"}
                maxLength={50}
              />

              {this.state.usuario.flag_mostrar_data_nascimento &&
                <DatePicker
                  style={[styles.datepicker1, Actions.getTema("labelfields")]}
                  value={this.state.usuario.data_nascimento}
                  onChange={value => {
                  }}
                  disabledClear={true}
                  disabled={true}
                  datePickerModeAndroid={"calendar"}
                  label={"Data de Nascimento"}
                />
              }

              <View style={styles.viewicons}>
                <TextInput
                  style={[styles.textinput5, Actions.getTema("labelfields")]}
                  value={Util.maskFormate(this.state.usuario.telefone, "phone")}
                  keyboardType={"numeric"}
                  disabled={true}
                  label={"Telefone"}
                />
                <TouchableOpacity
                  style={styles.button999}
                  onPress={() => {
                    Linking.openURL(`tel:${this.state.usuario.telefone}`)
                  }}
                >
                  <Icon
                    style={[styles.icon50, Actions.getTema("iconstitlebar")]}
                    fromFontFamily={"Material Design Icons"}
                    name={"phone"}
                  />
                </TouchableOpacity>

              </View>

              <View style={styles.viewicons}>
                <TextInput
                  style={[styles.textinput5, Actions.getTema("labelfields")]}
                  value={this.state.usuario.email}
                  keyboardType={"email"}
                  disabled={true}
                  label={"Email"}
                />
                <TouchableOpacity
                  style={styles.button999}
                  onPress={() => {
                    Linking.openURL('mailto:' + this.state.usuario.email)
                  }}
                >
                  <Icon
                    style={[styles.icon50, Actions.getTema("iconstitlebar")]}
                    fromFontFamily={"Material Design Icons"}
                    name={"email"}
                  />
                </TouchableOpacity>
              </View>

              {this.state.usuario.obs_pessoal &&
                <TextInput
                  style={[styles.textinput1, Actions.getTema("labelfields")]}
                  value={this.state.usuario.obs_pessoal}
                  multiline={true}
                  numberOfLines={50}
                  keyboardType={"default"}
                  label={"Sobre mim"}
                  disabled={true}
                />

              }
              <TextInput
                style={[styles.textinput1, Actions.getTema("labelfields")]}
                value={this.state.usuario.obsobj.obs}
                onChange={value => {
                  this.state.usuario.obsobj.obs = value;
                  this.setState({ usuario: this.state.usuario });
                }}
                multiline={true}
                numberOfLines={50}
                keyboardType={"default"}
                label={"Anotações"}
              />

            </View>
          </View>
          <View style={[styleGlobal.viewfantasma,]} />
        </ScrollView>

      </View >
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  }, view4: {
    flex: 1,
    width: "100%",
    marginTop: 10
  },
  button3: {
    alignSelf: "auto",
    justifyContent: "center",
    alignItems: "center",
    height: 44,
    flexDirection: "column",
    borderRadius: 22,
    backgroundColor: "rgba(48,129,219,1)",
    marginTop: 20,
    width: 125
  },
  textinput5: {
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    textAlign: "left",
    fontWeight: "normal",
    flex: 1
  }, button999: {
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    color: "rgba(44,126,218,1)",
    height: 50,
    width: 50,
    // padding: 10,
    
  },
  button1: {
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "rgba(44,126,218,1)",
    height: 50,
    paddingLeft: 10,
    flex: 1
  }, view3: {
    alignSelf: "stretch",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10
  },
  scroll: {
    alignSelf: "stretch",
    flexDirection: "column",
    backgroundColor: "rgba(252,252,252,1)",
    flex: 1,
    padding: 20,
    paddingTop: 10
  },
  icon: {
    color: "rgba(48,129,219,1)",
    fontSize: 25,
    marginRight: 5
  },
  image: {
    width: 250,
    height: 250,
    alignSelf: "auto",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: 25,
    marginTop: 20

  },
  button3: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    width: 50
  },
  viewicons: {
    flexDirection: "row",
    

  },
  icon3: {
    fontSize: 25
  },
  text2: {
    textAlign: "left",
    color: "rgba(48,129,219,1)",
    alignSelf: "auto",
    fontWeight: "normal"
  },
});
