import React, {Component} from 'react';

import {
  Content,
  Icon,
  Image,
  TextInput,
  TitleBar,
  Progress,
} from 'react-native-1app';

import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  Alert,
  FlatList,
} from 'react-native';
import styleGlobal from '../../styleGlobal';
import {Util} from '../../infra';
import * as Chat from '../../worker/chat';
import * as Divida from '../../worker/divida';
import * as Actions from '../../redux/actions';
export default class Amigos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listamigos: [],
      listamigos_completa: [],
      load: false,
      telefone: '',
      user: this.props.screenProps.store.getState().user,
      pesquisar: false,
      flag_pode_editar: 'C',
    };
  }

  setContato(contato) {
    this.addAmigo(contato.telefone);
  }

  pesquisar_contatos(nome) {
    var alunos = this.state.listamigos_completa;
    if (!nome) {
      return this.setState({listamigos: this.state.listamigos_completa});
    }
    nome = Util.cleanString(nome);
    name = nome.toUpperCase();
    var list = [];
    for (let i = 0; i < alunos.length; i++) {
      let aluno = alunos[i];
      var nomeAluno = Util.cleanString(aluno.nome);
      var pes = nomeAluno.toUpperCase();
      if (Util.contemString(pes, name)) {
        list.push(aluno);
      }
    }
    this.setState({listamigos: list});
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.user != this.state.user) this.setState({user: store.user});
    });

    this.getAmigos();
  }

  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  backPageButton() {
    if (this.props.navigation.state.params.onGoBack)
      this.props.navigation.state.params.onGoBack();
    this.props.navigation.goBack();
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }
  getAmigos() {
    this.setState({load: true});
    Chat.getContato({}, (data, error) => {
      this.setState({
        listamigos_completa: data,
        nome: '',
        listamigos: data,
        conversar: false,
        amigos: true,
        load: false,
      });
    });
  }

  relacionarAlert(item) {
    Alert.alert(
      'Relacionar Contato a Dívida',
      'Você deseja vincular a dívida a ' +
        item.nome +
        '? A ação não poderá ser desfeita',
      [
        {text: 'Cancelar', onPress: () => {}, style: 'cancel'},
        {
          text: 'Ok',
          onPress: () => {
            this.relacionar(item);
          },
        },
      ],
      {cancelable: false},
    );
  }

  relacionar(item) {
    this.setState({load: true});
    Divida.relacionar(
      {
        flag_pode_editar: this.state.flag_pode_editar,
        id_divida: this.propsNav().id_divida,
        id_usuario_divida: item.id_usuario_contato,
      },
      (data, error) => {
        let aviso = '';
        if (data && data.id) {
          aviso = 'Usuário relacionado com sucesso';
        } else {
          if (error && error.msg) {
            aviso = error.msg;
          } else {
            aviso = JSON.stringify(error);
          }
        }

        Alert.alert(
          'Mensagem',
          aviso,
          [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
          {cancelable: false},
        );
        this.setState({load: false});
        this.backPageButton();
      },
    );
  }

  render() {
    return (
      <Content style={styles.container} keyboard={true}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <View style={styles.viewhead99}>
            <View style={styles.view778}>
              <TouchableOpacity
                style={styles.button31}
                onPress={() => {
                  if (this.state.pesquisar) this.setState({pesquisar: false});
                  else {
                    this.backPageButton();
                  }
                }}>
                <Icon
                  style={[styles.icon, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'arrow-left'}
                />
              </TouchableOpacity>

              {!this.state.pesquisar ? (
                <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
                  {'Contatos'}
                </Text>
              ) : null}
            </View>
            {this.state.pesquisar ? (
              <View
                style={[
                  styles.view777,
                  this.state.pesquisar
                    ? {backgroundColor: 'rgba(81,82,83,0.13)'}
                    : null,
                ]}>
                <TextInput
                  style={[styles.textinput, Actions.getTema('textcell2')]}
                  value={this.state.nome}
                  onChange={value => {
                    this.state.nome = value;
                    this.pesquisar_contatos(value);
                  }}
                  keyboardType={'default'}
                  placeholder={'Pesquisar'}
                  inputNative={true}
                  selectionColor={'#fff'}
                />
              </View>
            ) : null}
            {!this.state.pesquisar ? (
              <View style={styles.view779}>
                <TouchableOpacity
                  style={styles.button3}
                  onPress={() => {
                    this.setState({pesquisar: true});
                  }}>
                  <Icon
                    style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                    fromFontFamily={'Material Design Icons'}
                    name={'magnify'}
                  />
                </TouchableOpacity>
              </View>
            ) : null}
          </View>
        </TitleBar>
        <View
          style={[styles.scroll, Actions.getTema('scroll')]}
          keyboardShouldPersistTaps={true}>
          <FlatList
            style={styles.listview}
            ListHeaderComponent={() => {
              return (
                <View style={{alignSelf: 'stretch'}}>
                  <View style={styleGlobal.viewprogress}>
                    {this.state.load ? (
                      <Progress style={styleGlobal.progress} />
                    ) : null}
                  </View>
                  {this.state.listamigos && this.state.listamigos.length > 0 && (
                    <View style={styles.viewflag}>
                      <TouchableOpacity
                        style={styles.button9}
                        onPress={() => {
                          this.state.flag_pode_editar =
                            this.state.flag_pode_editar == 'A' ? 'C' : 'A';
                          this.setState({
                            flag_pode_editar: this.state.flag_pode_editar,
                          });
                        }}>
                        <Icon
                          style={[
                            styles.icon8,
                            Actions.getTema('iconstitlebar'),
                          ]}
                          fromFontFamily={'Material Design Icons'}
                          name={
                            this.state.flag_pode_editar == 'A'
                              ? 'check-circle'
                              : 'checkbox-blank-circle-outline'
                          }
                        />
                        <View style={styles.view62}>
                          <Text
                            style={[
                              styles.text611,
                              Actions.getTema('labelfields'),
                            ]}>
                            {'Permitir edição pelo usuário'}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    </View>
                  )}
                  {this.state.listamigos &&
                  this.state.listamigos.length == 0 ? (
                    <View style={styles.view506}>
                      <Text style={styleGlobal.botao_text_desabilitado}>
                        {'Nenhum contato encontrado'}
                      </Text>
                    </View>
                  ) : null}
                </View>
              );
            }}
            data={this.state.listamigos}
            renderItem={({item, index}) => {
              return (
                <Cell
                  item={item}
                  screenProps={this.props.screenProps}
                  activity={this}
                  navigation={this.props.navigation}
                  rowID={index}
                />
              );
            }}
            keyExtractor={(item, index) => index}
          />
        </View>
      </Content>
    );
  }
}

class Cell extends Component {
  constructor(props) {
    super(props);
    this.state = {item: {msgs: {}}};
  }

  componentDidMount() {}

  componentWillUnmount() {}

  getWeight(item) {
    return {
      fontWeight: 'bold',
      color: 'rgba(0,0,0,1)',
    };
  }

  render() {
    return (
      <TouchableOpacity
        style={[styles.Cell, Actions.getTema('chatmsgs')]}
        screenProps={this.props.screenProps}
        navigation={this.props.navigation}
        activity={this}
        onPress={() => {
          this.props.activity.relacionarAlert(this.props.item);
        }}>
        <View style={styles.view9}>
          <Image
            style={styles.image}
            source={{uri: this.props.item.foto}}
            resizeMode={'cover'}
          />
        </View>
        <View style={styles.view131}>
          <View style={styles.view611}>
            <Text style={[styles.text811, Actions.getTema('lbl_tituloconfig')]}>
              {this.props.item.nome}
            </Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

var styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: 'rgba(255,255,255,1)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  viewhead99: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
  },
  view778: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    
  },
  button31: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  icon21: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  viewflag: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginLeft: 10,
    height: 50,
  },
  text2: {
    color: 'rgba(48,129,219,1)',
    fontWeight: 'normal',
    marginLeft: 10,
  },
  view777: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 25,
    margin: 5,
  },
  button9: {
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 50,
    flexDirection: 'row',
    flex: 1,
  },
  icon8: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  textinput: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'center',
    fontWeight: 'normal',
    marginLeft: 5,
    paddingRight: 5,
    borderRadius: 25,
  },
  view779: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  scroll: {
    alignSelf: 'stretch',
    flex: 1,
  },
  view506: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 10,
  },
  listview: {
    alignSelf: 'stretch',
    flex: 1,
  },
  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
  },
  view9: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 36,
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 2,
  },
  view611: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  text811: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
    marginLeft: 10,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  view62: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text611: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'normal',
    marginLeft: 3,
  },
});
