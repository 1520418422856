import React, {Component} from 'react';
import * as Actions from '../../redux/actions';
import {
  Content,
  Icon,
  Image,
  ListView,
  TitleBar,
  Progress,
  TextInput,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
  FlatList,
  RefreshControl,
  Platform,
} from 'react-native';

import Alert from 'react-native-1app/lib/Alert';

import styleGlobal from '../../styleGlobal';
import {Util} from '../../infra';
import * as Chat from '../../worker/chat';
import * as Divida from '../../worker/divida';
import TodoSwipeList from '../../components/TodoSwipeList';
import {
  ViewPager,
  PagerTabIndicator,
  IndicatorViewPager,
  PagerTitleIndicator,
  PagerDotIndicator,
} from 'rn-viewpager';
import ListaContatos from '../user/ListaContatos';
import Header from './Header';
import PreInfos from '../user/PreInfos';
export default class ListaPessoasDivida extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nao_relacionadas: [],
      relacionadas: [],
      todos: [],
      nao_relacionadas_completo: [],
      relacionadas_completo: [],
      todos_completo: [],
      ajuda: this.props.screenProps.store.getState().ajuda,
      load: false,
      currentPosition: 0,
      pesquisar: false,
    };
  }

  getNomes(nome, lista) {
    nome = Util.cleanString(nome);
    let name = nome.toUpperCase();
    var list = [];

    lista.map(item => {
      let aluno = item;
      var nomeAluno = Util.cleanString(aluno.nome);
      var pes = nomeAluno.toUpperCase();
      if (Util.contemString(pes, name)) {
        list.push(aluno);
      }
    });

    return list;
  }
  pesquisar(nome) {
    if (!nome) {
      return this.setState({
        todos: this.state.todos_completo,
        relacionadas: this.state.relacionadas_completo,
        nao_relacionadas: this.state.nao_relacionadas_completo,
        nome: '',
      });
    }

    this.setState({
      todos: this.getNomes(nome, this.state.todos_completo),
      relacionadas: this.getNomes(nome, this.state.relacionadas_completo),
      nao_relacionadas: this.getNomes(
        nome,
        this.state.nao_relacionadas_completo,
      ),
    });
  }

  getLista() {
    this.setState({load: true});
    Divida.getTelaDividasPorPessoa((data, error) => {
      if (error) {
        Alert.alert(
          'Erro',
          error.msg,
          [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
          {cancelable: false},
        );
      } else {
        data.load = false;
        data.nao_relacionadas_completo = data.nao_relacionadas;
        data.relacionadas_completo = data.relacionadas;
        data.todos_completo = data.todos;
        this.setState(data);
      }
    });
  }
  componentDidMount() {
    this.getLista();
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.ajuda != this.state.ajuda) this.setState({ajuda: store.ajuda});
    });
  }
  componentWillUnmount() {
    if (this.unsubscribe) this.unsubscribe();
  }

  componentDidUpdate() {
    // if (this.state.pesquisa) {
    //   this.pesquisacampo.focus();
    // }
  }

  backPageButton() {
    if (this.props.navigation.onGoBack) this.props.navigation.onGoBack();
    this.props.navigation.goBack();
  }

  render() {
    return (
      <Content style={styles.container} keyboard={true}>
        <TitleBar
          style={[
            styleGlobal.titlebar,
            Actions.getTema('titlebar'),
            {flexDirection: 'column', height: 100},
          ]}>
          <View style={styles.viewhead99}>
            <View style={styles.view778}>
              <TouchableOpacity
                style={styles.button3}
                onPress={() => {
                  this.backPageButton();
                }}>
                <Icon
                  style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'arrow-left'}
                />
              </TouchableOpacity>

              {!this.state.pesquisar ? (
                <Text
                  style={[
                    styles.text2,
                    Platform.OS == 'web' ? {minWidth: 200} : {},
                    Actions.getTema('iconstitlebar'),
                  ]}>
                  {'Relatórios'}
                </Text>
              ) : null}
            </View>
            {this.state.pesquisar ? (
              <View
                style={[
                  styles.view777,
                  this.state.pesquisar ? Actions.getTema('pesquisar') : null,
                ]}>
                <TextInput
                  style={styles.textinput}
                  ref={input => {
                    this.pesquisacampo = input;
                  }}
                  autoFocus={true}
                  value={this.state.nome}
                  onChange={value => {
                    this.state.nome = value;
                    this.pesquisar(value);
                  }}
                  keyboardType={'default'}
                  placeholder={'Pesquisar'}
                  inputNative={true}
                  selectionColor={'#fff'}
                />
              </View>
            ) : null}
            {!this.state.pesquisar ? (
              <View
                style={[
                  styles.view777,
                  Platform.OS == 'web' ? {flex: 1} : {},
                  this.state.pesquisar ? Actions.getTema('pesquisar') : null,
                ]}>
                <View style={styles.view779}>
                  <TouchableOpacity
                    style={styles.buttonmag}
                    onPress={() => {
                      this.setState({pesquisar: true}, () => {
                        this.pesquisacampo.focus();
                      });
                    }}>
                    <Icon
                      style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                      fromFontFamily={'Material Design Icons'}
                      name={'magnify'}
                    />
                  </TouchableOpacity>
                </View>
              </View>
            ) : null}
            {this.state.pesquisar ? (
              <TouchableOpacity
                style={styles.button3}
                onPress={() => {
                  this.state.nome = '';
                  this.pesquisar('');
                  this.pesquisacampo.focus();
                }}>
                <Icon
                  style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'close-circle'}
                />
              </TouchableOpacity>
            ) : null}

            {this.state.ajuda && (
              <TouchableOpacity
                style={styles.buttonhelp}
                onPress={() => {
                  Alert.alert(
                    'Ajuda',
                    'Selecione o amigo que você quer que apareça' +
                      (this.props.navigation.tela == 'home'
                        ? ' na sua tela inicial'
                        : ' no calendário'),
                    [{text: 'Ok', onPress: () => {}, style: 'cancel'}],
                  );
                }}>
                <Icon
                  style={[styles.icon3, Actions.getTema('iconstitlebar')]}
                  fromFontFamily={'Material Design Icons'}
                  name={'help-circle'}
                />
              </TouchableOpacity>
            )}
          </View>
          <View style={styles.viewhead99}>
            {this.viewpager ? (
              <Header
                screenProps={this.props.screenProps}
                activity={this}
                navigation={this.props.navigation}
                currentPosition={this.state.currentPosition}
                viewpager={this.viewpager}
              />
            ) : null}
          </View>
        </TitleBar>

        <View style={styles.view2}>
          <ViewPager
            horizontalScroll={true}
            style={[styles.fragment2, {flexGrow: 1}]}
            ref={viewpager => {
              this.viewpager = viewpager;
            }}
            onPageSelected={page => {
              this.setState({currentPosition: page.position});
            }}>
            {/* page Todos */}
            <View style={styles.content21}>
              <View
                style={[styles.scroll, Actions.getTema('scroll')]}
                keyboardShouldPersistTaps={true}>
                <FlatList
                  style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                  refreshControl={
                    <RefreshControl
                      refreshing={this.state.load}
                      onRefresh={() => {
                        this.getLista();
                      }}
                    />
                  }
                  renderItem={({item, index}) => {
                    return (
                      <Celllistview
                        item={item}
                        screenProps={this.props.screenProps}
                        activity={this}
                        navigation={this.props.navigation}
                        rowID={index}
                      />
                    );
                  }}
                  ListEmptyComponent={() => {
                    return (
                      <Text
                        style={{
                          textAlign: 'center',
                          color: 'gray',
                          margin: 10,
                        }}>
                        {'Nenhuma dívida encontrada.'}
                      </Text>
                    );
                  }}
                  data={Array.isArray(this.state.todos) ? this.state.todos : []}
                  keyExtractor={(item, index) => index}
                />
              </View>
            </View>

            {/* page relacionados */}
            <View style={styles.content21}>
              <View
                style={[styles.scroll, Actions.getTema('scroll')]}
                keyboardShouldPersistTaps={true}>
                <FlatList
                  style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                  refreshControl={
                    <RefreshControl
                      refreshing={this.state.load}
                      onRefresh={() => {
                        this.getLista();
                      }}
                    />
                  }
                  renderItem={({item, index}) => {
                    return (
                      <Celllistview
                        item={item}
                        screenProps={this.props.screenProps}
                        activity={this}
                        navigation={this.props.navigation}
                        rowID={index}
                      />
                    );
                  }}
                  ListEmptyComponent={() => {
                    return (
                      <Text
                        style={{
                          textAlign: 'center',
                          color: 'gray',
                          margin: 10,
                        }}>
                        {'Nenhuma dívida encontrada.'}
                      </Text>
                    );
                  }}
                  data={
                    Array.isArray(this.state.relacionadas)
                      ? this.state.relacionadas
                      : []
                  }
                  keyExtractor={(item, index) => index}
                />
              </View>
            </View>

            {/* Não relacionados */}
            <View style={styles.content21}>
              <View
                style={[styles.scroll, Actions.getTema('scroll')]}
                keyboardShouldPersistTaps={true}>
                <FlatList
                  style={[{alignSelf: 'stretch'}, Actions.getTema('scroll')]}
                  refreshControl={
                    <RefreshControl
                      refreshing={this.state.load}
                      onRefresh={() => {
                        this.getLista();
                      }}
                    />
                  }
                  renderItem={({item, index}) => {
                    return (
                      <Celllistview
                        item={item}
                        screenProps={this.props.screenProps}
                        activity={this}
                        navigation={this.props.navigation}
                        rowID={index}
                      />
                    );
                  }}
                  ListEmptyComponent={() => {
                    return (
                      <Text
                        style={{
                          textAlign: 'center',
                          color: 'gray',
                          margin: 10,
                        }}>
                        {'Nenhuma dívida encontrada.'}
                      </Text>
                    );
                  }}
                  data={
                    Array.isArray(this.state.nao_relacionadas)
                      ? this.state.nao_relacionadas
                      : []
                  }
                  keyExtractor={(item, index) => index}
                />
              </View>
            </View>
          </ViewPager>
        </View>
      </Content>
    );
  }
}

class Celllistview extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {}

  componentWillUnmount() {}

  getCor(item) {
    if (item.dif > 0) {
      return {
        color: 'rgba(44,126,218,1)',
      };
    } else {
      return {
        color: 'rgba(255,0,0,1)',
      };
    }
  }

  filtrar(item) {
    console.log('filtrar(item)', item);
    this.props.navigation.navigate('ListaPessoasDividaRelatorioPessoa', {
      ids: item.ids,
      nome: item.nome,
      id_contato: item.id_contato_proprio_divida,
    });
  }
  getInfos() {
    let id_usuario = 0;
    var contato = false;
    if (this.props.item.id_contato_proprio_divida) {
      id_usuario = this.props.item.id_contato_proprio_divida;
      contato = true;
    }

    if (this.props.item.id_usuario_divida) {
      id_usuario = this.props.item.id_usuario_divida;
    }

    return {
      id_usuario: id_usuario,
      boolcontatoproprio: contato,
    };
  }

  render() {
    return (
      <View
        style={[
          styles.Cell,
          {...Util.elevation()},
          Actions.getTema('titlebar'),
        ]}>
        <TouchableOpacity
          style={styles.view9}
          onPress={() => {
            Actions.setShowPreInfos({show: true, ...this.getInfos()});
          }}>
          <Image
            style={styles.image}
            source={{uri: this.props.item.foto}}
            resizeMode={'cover'}
          />
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.view131}
          onPress={() => {
            this.filtrar(this.props.item);
          }}>
          {/* <View style={styles.view71}> */}
          <View style={styles.view61}>
            <Text style={[styles.text81, Actions.getTema('textcell')]}>
              {this.props.item.nome}
            </Text>
          </View>

          <View style={styles.view6}>
            <View style={styles.view73}>
              <Text style={styles.text9}>{'Para mim: '}</Text>
              <Text style={styles.text9_1}>{this.props.item.para_mim_f}</Text>
            </View>
            <View style={styles.view74}>
              <Text style={[styles.text9, {...this.getCor(this.props.item)}]}>
                {this.props.item.dif_f}
              </Text>
            </View>
          </View>

          <View style={styles.view6}>
            <Text style={styles.text9}>{'Por mim: '}</Text>
            <Text style={styles.text9_2}>{this.props.item.por_mim_f}</Text>
          </View>

          {/* </View> */}
        </TouchableOpacity>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },

  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    minWidth: 50,
  },
  buttonmag: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    borderColor: 'rgba(255,255,255,1)',
    paddingTop: 10,
    width: 50,
  },
  buttonhelp: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  textinput: {
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    textAlign: 'center',
    fontWeight: 'normal',
    marginLeft: 5,
    paddingRight: 5,
  },
  container: {
    flex: 1,
    alignSelf: 'stretch',
    backgroundColor: 'rgba(255,255,255,1)',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  view777: {
    borderRadius: 25,
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: 5,
    // backgroundColor:"red"
  },
  view779: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },

  view778: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },

  text2: {
    // alignSelf: "stretch",
    // textAlign: "left",

    color: 'rgba(48,129,219,1)',
    // alignSelf: "auto",
    fontWeight: 'normal',
    // flex: 1,
    marginLeft: 10,
  },
  viewhead99: {
    flexDirection: 'row',
    flex: 1,
    alignSelf: 'stretch',
  },
  fragment2: {
    alignSelf: 'stretch',
    flex: 1,
  },
  content21: {
    backgroundColor: null,
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    flex: 1,
  },
  listview: {
    alignSelf: 'stretch',
    flex: 1,
    paddingTop: 5,
  },
  Cell: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 8,
    margin: 5,
  },
  view9: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 36,
  },
  view131: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    // marginTop: 2
  },
  view61: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  text81: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'stretch',
    fontWeight: 'bold',
    marginLeft: 10,
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    marginLeft: 10,
    flex: 1,
  },
  view2: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    backgroundColor: 'rgba(238,238,238,1)',
    flex: 1,
  },
  text9: {
    textAlign: 'right',
    color: 'rgba(177,177,184,1)',
    alignSelf: 'center',
  },
  text9_1: {
    textAlign: 'right',
    color: 'rgba(44,126,218,1)',
    alignSelf: 'center',
  },
  text9_2: {
    textAlign: 'right',

    color: 'rgba(255,0,0,1)',
    alignSelf: 'center',
  },
  view73: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flex: 1,
  },
  view74: {
    alignSelf: 'stretch',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    flex: 1,
  },
  view506: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    marginTop: 10,
  },
});
