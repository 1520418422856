import React, { Component } from "react";
import * as Actions from "../../redux/actions";
import {
  Content,
  DatePicker,
  Icon,
  Progress,
  TitleBar
} from "react-native-1app";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text
} from "react-native";
import styleGlobal from "../../styleGlobal";
import moment from "moment-timezone";
// import "moment-timezone";
import { showMessage } from "react-native-1app/lib/FlashMessage";

export default class EscolherVencimento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data_vencimento: moment.isMoment(this.getProps().oldState.divida.data_vencimento) ? this.getProps().oldState.divida.data_debito.clone() : moment(this.getProps().oldState.divida.data_vencimento, "YYYY-MM-DDTHH:mm:ss.SSSSZ"),
      load: false,

    };

    console.log("open", this.state);
  }

  backPageButton(voltardireto) {
    if (this.props.navigation.state.params.onGoBack && !voltardireto)
      this.props.navigation.state.params.onGoBack(this.state);
    this.props.navigation.goBack();
  }

  getProps() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  thisSetDays(num, flag) {
    let data_vencimento = this.state.data_vencimento;
    // var offset = data_vencimento.utcOffset();
    console.log("dat1", JSON.stringify(data_vencimento), JSON.stringify(data_vencimento.utcOffset()));
    data_vencimento.add(num, flag);
    console.log("dat2", JSON.stringify(data_vencimento), JSON.stringify(data_vencimento.utcOffset()));
    // data_vencimento.utcOffset(offset, true);
    // console.log("dat2.5", JSON.stringify(data_vencimento), JSON.stringify(data_vencimento.utcOffset()));
    // console.log("novo", moment("2019-11-11T02:59:59", "YYYY-MM-DDTHH:mm:ss").tz('America/Sao_Paulo'), moment().add(num, flag));
    // console.log("teste2", moment('2019-11-03').tz('America/Sao_Paulo'));
    data_vencimento.set({ hour: moment().hour(), minute: moment().minute(), second: moment().second(), millisecond: 0 });

    // console.log("dat5 ", data_vencimento.format());

    // const future = moment()
    //   .add("40", "days")
    //   .format();
    // console.log("dat6 ", future);

    this.state.data_vencimento = data_vencimento;
    this.setState({ data_vencimento: data_vencimento });
    this.backPageButton();
  }

  componentDidMount() {

  }

  componentWillUnmount() {
  }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema("titlebar")]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton(true);
            }}
          >
            <Icon
              style={[styles.icon, Actions.getTema("iconstitlebar")]}
              fromFontFamily={"Material Design Icons"}
              name={"arrow-left"}
            />
            <Text style={[styles.text2, Actions.getTema("iconstitlebar")]} > {"Escolher Data"}</Text>
          </TouchableOpacity>
          <View style={styles.view} />

          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
        </TitleBar>
        <ScrollView style={[styles.scroll, Actions.getTema("scroll")]} >
          <View style={styles.view7}>
            <View style={styles.view6}>
              <Text style={[styles.text61, Actions.getTema("labelfields")]}>{"Data da transação em relação ao Vencimento"}</Text>
            </View>
          </View>
          <View style={styles.view50}>
            <View style={styles.view3} />
          </View>
          <View style={styles.view8}>
            <TouchableOpacity
              style={styles.button41}
              onPress={() => {
                this.thisSetDays(0, "d");
              }}
            >
              <Text style={styles.text311}>{"Atual"}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button424}
              onPress={() => {
                this.thisSetDays(7, "d");
              }}
            >
              <Text style={styles.text3124}>{"7 dias"}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.view9}>
            <TouchableOpacity
              style={styles.button411}
              onPress={() => {
                this.thisSetDays(10, "d");

              }}
            >
              <Text style={styles.text3111}>{"10 dias"}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button4241}
              onPress={() => {
                this.thisSetDays(20, "d");
              }}
            >
              <Text style={styles.text31241}>{"20 dias"}</Text>
            </TouchableOpacity>
          </View>
          <View style={styles.view10}>
            <TouchableOpacity
              style={styles.button4111}
              onPress={() => {
                this.thisSetDays(30, "d");

              }}
            >
              <Text style={styles.text31111}>{"30 dias"}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.button42411}
              onPress={() => {
                this.thisSetDays(1, "M");
              }}
            >
              <Text style={styles.text312411}>{"1 mês"}</Text>
            </TouchableOpacity>
          </View>
          <View style={styleGlobal.viewfantasma}></View>
        </ScrollView>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  button1: {
    alignSelf: "flex-end",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    color: "rgba(44,126,218,1)",
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1
  },
  icon: {
    color: "rgba(48,129,219,1)",
    fontSize: 25,
    marginRight: 5
  },
  text2: {
    textAlign: "left",
    color: "rgba(48,129,219,1)",
    alignSelf: "auto",
    fontWeight: "normal"
  },
  view: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  scroll: {
    alignSelf: "stretch",
    flexDirection: "column",
    backgroundColor: "rgba(252,252,252,1)",
    flex: 1,
    padding: 20,
    paddingTop: 10

  },
  view7: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  view6: {
    alignSelf: "stretch",
    flex: 1,
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column"
  },
  text61: {
    textAlign: "left",
    color: "rgba(0,0,0,1)",
    alignSelf: "stretch",
    fontWeight: "normal",
    marginLeft: 3
  },
  view50: {
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  view3: {
    alignSelf: "stretch",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "column"
  },
  view8: {
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  button41: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    backgroundColor: "rgba(44,126,218,1)",
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginRight: 5
  },
  text311: {
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  button424: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    backgroundColor: "rgba(44,126,218,1)",
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginLeft: 5
  },
  text3124: {
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  view9: {
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  button411: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    backgroundColor: "rgba(44,126,218,1)",
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginRight: 5
  },
  text3111: {
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  button4241: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    backgroundColor: "rgba(44,126,218,1)",
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginLeft: 5
  },
  text31241: {
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  view10: {
    alignSelf: "auto",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexDirection: "row"
  },
  button4111: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    backgroundColor: "rgba(44,126,218,1)",
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginRight: 5
  },
  text31111: {
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
  button42411: {
    alignSelf: "stretch",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    flexDirection: "column",
    backgroundColor: "rgba(44,126,218,1)",
    borderRadius: 7,
    marginTop: 15,
    flex: 1,
    marginLeft: 5
  },
  text312411: {
    textAlign: "center",
    color: "rgba(255,255,255,1)",
    alignSelf: "stretch",
    fontWeight: "normal"
  },
});
