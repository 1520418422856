import React, {Component} from 'react';
import {Platform, StyleSheet, Text, View} from 'react-native';
import CalendarSelect from './CalendarSelect';
import CommonFn from './commonFn';
import moment from 'moment';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      format: props.format || 'x',
      date: moment(props.date || undefined),
      minDate: CommonFn.ymd(props.minDate || '1900-01-01'),
      maxDate: CommonFn.ymd(props.maxDate || '2200-01-01'),
      yearMonth: CommonFn.ym(props.date),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.date != nextProps.date)
      this.setState(
        {
          format: nextProps.format || 'x',
          date: moment(nextProps.date || undefined),
          minDate: CommonFn.ymd(nextProps.minDate || '1900-01-01'),
          maxDate: CommonFn.ymd(nextProps.maxDate || '2200-01-01'),
          yearMonth: CommonFn.ym(nextProps.date),
        },
        () => {
          this.selectDate(nextProps.date);
        },
      );
  }
  calendarChange(type, unit) {
    let data = moment(this.state.yearMonth)
      .add(type, unit)
      .format('YYYY-MM');
    this.setState({
      yearMonth: data,
    });
    if (this.props.changeMonthCallback) this.props.changeMonthCallback(data);
  }

  selectDate(val, notCurrentMonth) {
    var {date, needTime} = this.state;
    var yearMonthDayArr = val.split('-');
    this.setState(
      {
        date: moment(date)
          .utcOffset(0)
          .set({
            year: parseInt(yearMonthDayArr[0], 10),
            month: parseInt(yearMonthDayArr[1], 10) - 1,
            date: parseInt(yearMonthDayArr[2], 10),
          }),
      },
      () => {
        this.dateCallback(notCurrentMonth);
      },
    );
  }

  dateCallback(notCurrentMonth) {
    var {changeDate, format} = this.props;
    var {date} = this.state;
    changeDate &&
      changeDate(
        moment(date)
          .utcOffset(0)
          .set('millisecond', 0)
          .format(format),
        notCurrentMonth,
      );
  }

  renderCountNames(day) {
    var arraynovo = this.arrumarray(day);
    let amountextra = 0;
    let x = 0;
    for (var property in arraynovo) {
      if (x > 2) {
        amountextra++;
      }
      x++;
    }
    if (amountextra)
      return (
        <Text style={[styles.day2, {color: 'rgba(48,129,219,1)'}]}>
          {'+' + amountextra}
        </Text>
      );
    else {
      return null;
    }
  }

  arrumarray(day) {
    let arraynovo = [];
    for (var property in this.props.calendario) {
      if (this.props.calendario.hasOwnProperty(property) && property == day) {
        let array = this.props.calendario.hasOwnProperty(property)
          ? this.props.calendario[property]
          : [];
        for (let i = 0; i < array.length; i++) {
          let item = array[i];

          if (item.id_contato_proprio_divida) {
            let id_contato_proprio_divida = item.id_contato_proprio_divida;
            if (arraynovo[id_contato_proprio_divida + '_cp']) {
              arraynovo[id_contato_proprio_divida + '_cp'].qtd =
                arraynovo[id_contato_proprio_divida + '_cp'].qtd + 1;
            } else {
              arraynovo[id_contato_proprio_divida + '_cp'] = {
                flag_tipo: item.flag_tipo,
                nome_pessoa: item.nome_pessoa,
                qtd: 1,
              };
            }
          } else if (item.id_usuario_divida) {
            let id_usuario_divida = item.id_usuario_divida;
            if (arraynovo[id_usuario_divida + '_ud']) {
              arraynovo[id_usuario_divida + '_ud'].qtd =
                arraynovo[id_usuario_divida + '_ud'].qtd + 1;
            } else {
              arraynovo[id_usuario_divida + '_ud'] = {
                flag_tipo: item.flag_tipo,
                nome_pessoa: item.nome_pessoa,
                qtd: 1,
              };
            }
          }
        }
      }
      continue;
    }

    arraynovo = this.ordernaQuantidadeNome(arraynovo);
    return arraynovo;
  }

  ordernaQuantidadeNome(arraynovo) {
    var arraynovo2 = [];
    for (var property in arraynovo) {
      let element = arraynovo[property];
      arraynovo2.push(element);
    }
    if (arraynovo2.length > 0) {
      arraynovo2.sort(function(a, b) {
        var aSize = a.qtd;
        var bSize = b.qtd;
        var aLow = a.nome_pessoa.toLowerCase();
        var bLow = b.nome_pessoa.toLowerCase();

        if (aSize == bSize) {
          return aLow < bLow ? -1 : aLow > bLow ? 1 : 0;
        } else {
          return aSize < bSize ? 1 : -1;
        }
      });
    }

    return arraynovo2;
  }

  renderChildDay(day) {
    let names = [];

    var arraynovo = this.arrumarray(day);

    let x = 0;
    for (var property in arraynovo) {
      let element = arraynovo[property];
      if (x <= 2) {
        names.push(element);
        x++;
      }
    }
    if (names) {
      return (
        <View>
          {names[0] && (
            <View style={{flexDirection: 'row', width: '100%'}}>
              <Text
                numberOfLines={1}
                style={[
                  styles.text2,
                  {
                    color:
                      names[0].flag_tipo == '1'
                        ? 'rgba(62,162,67,1)'
                        : 'rgba(255,0,0,1)',
                  },
                ]}>
                {names[0].nome_pessoa.split(' ')[0]}
              </Text>
              {names[0].qtd > 1 && (
                <Text
                  numberOfLines={1}
                  style={[
                    styles.text3,
                    {
                      color:
                        names[0].flag_tipo == '1'
                          ? 'rgba(62,162,67,1)'
                          : 'rgba(255,0,0,1)',
                    },
                  ]}>
                  {names[0].qtd}
                </Text>
              )}
            </View>
          )}
          {names[1] && (
            <View style={{flexDirection: 'row', width: '100%'}}>
              <Text
                numberOfLines={1}
                style={[
                  styles.text2,
                  {
                    color:
                      names[1].flag_tipo == '1'
                        ? 'rgba(62,162,67,1)'
                        : 'rgba(255,0,0,1)',
                  },
                ]}>
                {names[1].nome_pessoa.split(' ')[0]}
              </Text>
              {names[1].qtd > 1 && (
                <Text
                  numberOfLines={1}
                  style={[
                    styles.text3,
                    {
                      color:
                        names[1].flag_tipo == '1'
                          ? 'rgba(62,162,67,1)'
                          : 'rgba(255,0,0,1)',
                    },
                  ]}>
                  {names[1].qtd}
                </Text>
              )}
            </View>
          )}
          {names[2] && (
            <View style={{flexDirection: 'row', width: '100%'}}>
              <Text
                numberOfLines={1}
                style={[
                  styles.text2,
                  {
                    color:
                      names[2].flag_tipo == '1'
                        ? 'rgba(62,162,67,1)'
                        : 'rgba(255,0,0,1)',
                  },
                ]}>
                {names[2].nome_pessoa.split(' ')[0]}
              </Text>
              {names[2].qtd > 1 && (
                <Text
                  numberOfLines={1}
                  style={[
                    styles.text3,
                    {
                      color:
                        names[2].flag_tipo == '1'
                          ? 'rgba(62,162,67,1)'
                          : 'rgba(255,0,0,1)',
                    },
                  ]}>
                  {names[2].qtd}
                </Text>
              )}
            </View>
          )}
        </View>
      );
    }
  }

  render() {
    var {minDate, maxDate, date, yearMonth} = this.state;
    var {containerStyle, selectedDate} = this.props;
    return (
      <View style={[styles.container, containerStyle]}>
        <CalendarSelect
          {...this.props}
          calendarMonth={yearMonth}
          date={date.format('YYYY-MM-DD')}
          minDate={minDate}
          maxDate={maxDate}
          selectedDate={selectedDate}
          dividas={this.props.dividas}
          selectDate={(item, notCurrentMonth) =>
            this.selectDate(item, notCurrentMonth)
          }
          calendarChange={(type, unit) =>
            this.calendarChange(type, unit, 'start')
          }
          redEvent={this.props.redEvent}
          greenEvent={this.props.greenEvent}
          renderChildDay={day => this.renderChildDay(day)}
          renderCountNames={day => this.renderCountNames(day)}
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#201216',
    height: 390,
    // paddingVertical: 20
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    fontWeight: 'normal',
    fontSize: 10,
    height: 13,
    flex: 1,
    marginLeft: 2,
  },
  text3: {
    textAlign: 'center',
    color: 'rgba(48,129,219,1)',
    fontWeight: 'normal',
    fontSize: 10,
    marginRight: 2,
    height: 13,
  },
  day2: {
    margin: 1,
    // backgroundColor:"red",
    color: '#fff',
    fontSize: 11,
    alignSelf: 'flex-end',
  },
});
