import React from 'react';
import 'react-native-gesture-handler';
import {AppRegistry, AppState, StyleSheet, AsyncStorage} from 'react-native';
import {View, FlashMessage} from 'react-native-1app';
import ImageUpload from 'react-native-1app/lib/ImageUpload';
import InitApp from 'react-native-1app/lib/InitApp';
import {StackNavigator} from 'react-navigation';
import {Cloud} from './infra';
import {Provider, connect} from 'react-redux';
import {registerScreens} from './index.pages.js';
import Abertura from './views/Abertura';
import ImageUpload2 from './components/ImageUpload';
import DialogAlert from './components/DialogAlert';
// import FlashMessage from "react-native-flash-message";
// import { logout } from "./redux/actions";

// var pt = require("moment/locale/pt-br");

let a = true;
import {createStore, applyMiddleware, combineReducers} from 'redux';
import reducers from './redux/reducer';
import * as actions from './redux/actions';
// import * as login from "./redux/actions/login";
import moment from 'moment-timezone';
var store = createStore(reducers);
import Nav from './index.pages.js';
console.disableYellowBox = true;

if (!__DEV__) {
  console.log = () => {};
}
console.error = () => {};

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {abertura: true, a: true};
    actions.setStore(store);
    actions.loadRedux();
    ImageUpload.setHost(Cloud.getHost());
    ImageUpload2.setHost(Cloud.getHost());
    ImageUpload.setToken(Cloud.getToken());
    ImageUpload2.setToken(Cloud.getToken());
    // File.setToken(Cloud.setToken());
    // File.setHost(Cloud.getHost());
    //qdo for fazer global, testar isso de alguma maneira
    moment.locale('pt-br');
    moment.tz.setDefault('America/Sao_Paulo');
    actions.cleanPessoaFiltro(store.dispatch);
  }

  componentDidMount() {
    // logout();
    setTimeout(() => {
      this.setState({abertura: false});
      actions.requestContatosPermission((data, error) => {
        console.log(data, error);
      });
    }, 1500);

    // setInterval(() => {
    //   if (this.state.a == true) {
    //     this.setState({ abertura: false, a: false });
    //   }
    //   else {
    //     this.setState({ abertura: true, a: true });
    //   }
    // }, 2500);

    AppState.addEventListener('change', state => {
      if (state == 'inactive' || state == 'background') {
        // actions.setFiltroPessoa("", 0, 0)
        actions.saveLastExit();
        actions.saveRedux();
      }
      if (state == 'active') {
        actions.setRecemAtivou(true);
      }
    });
    setTimeout(() => {
      actions.getSobre(store.dispatch);
    }, 2000);
  }

  render() {
    let screenProps = {
      store: store,
      actions: actions,
      dispatch: store.dispatch,
    };

    if (this.state.abertura) {
      return <Abertura />;
    }
    return (
      <View
        style={{
          alignSelf: 'stretch',
          flex: 1,
        }}>
        <Provider store={store}>
          <Nav
            {...this.props}
            store={store}
            screenProps={screenProps}
            onNavigationStateChange={null}
            store={store}
          />
        </Provider>
        <InitApp {...this.props} />
        <DialogAlert {...this.props} key="alerta_custom" />
      </View>
    );
  }
}
