import Cloud from "../infra/Cloud";


export function criar(obj, callback) {
  Cloud.post('transacoes', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function atualizar(obj, callback) {
  Cloud.put('transacoes', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function remover(item, callback) {
  Cloud.delete("transacoes", { id: item.id }, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function listar(obj, callback) {
  Cloud.get('transacoes', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}

export function listarid(obj, callback) {
  Cloud.get('transacoesbyid', obj, (res, error) => {
    if (callback) callback(res, error);
  })
}


