import React, {Component} from 'react';
import {Util} from '../../infra';
import {
  Content,
  Icon,
  Image,
  ImageUpload,
  Progress,
  TextInput,
  TitleBar,
  DatePicker,
  SelectInput,
} from 'react-native-1app';
import {
  StyleSheet,
  View,
  ScrollView,
  ImageBackground,
  Text,
  TouchableOpacity,
  Platform,
} from 'react-native';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import * as Usuario from '../../worker/usuario';
import {logarUser} from '../../redux/actions';

export default class Cadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cadastro: {
        // email:"wamberton@1app.com.br",
        // nome:"wteste",
        // senha:"aaaaaa",
        // confirmar_senha:"aaaaaa",
        // telefone:"55984970310"
      },
      load: false,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  backPageButton() {
    this.props.navigation.goBack();
  }

  salvar() {
    if (!Usuario.validarUser(this.state.cadastro)) {
      this.setState({load: true});
      Usuario.salvar(this.state.cadastro, (user = {}, error) => {
        if (error) {
          showMessage({
            message: 'Falha criar conta',
            description: error.msg,
            type: 'warning',
            icon: {icon: 'warning', position: 'left'},
            duration: 2000,
          });
        } else {
          showMessage({
            message: 'Sucesso!',
            // description: "Um E-mail foi enviado ao endereço preenchido, acesse-o para terminar o cadastro",
            description: 'Você ja pode logar no aplicativo',
            type: 'success',
            icon: {icon: 'warning', position: 'left'},
            duration: 2000,
          });
          this.props.navigation.goBack();
        }
        this.setState({load: false});
      });
    } else {
      showMessage({
        message: 'Verifique o cadastro',
        description: Usuario.validarUser(this.state.cadastro),
        duration: 2000,
      });
    }
  }

  render() {
    return (
      <View style={styles.content} keyboard={true}>
        <ImageBackground
          resizeMode={'cover'} // or cover
          style={{flex: 1, width: '100%'}}
          source={require('../../../img/fundo_casher_2.png')}>
          <TitleBar style={styles.titlebar} removeShadow={true}>
            <TouchableOpacity
              style={styles.button3}
              onPress={() => {
                this.backPageButton();
              }}>
              <Icon
                style={styles.icon}
                fromFontFamily={'Material Icons'}
                name={'keyboard_backspace'}
              />
            </TouchableOpacity>
          </TitleBar>
          <ScrollView style={styles.scroll}>
            <View style={styles.view3}>
              <View style={styles.view5}>
                <ImageUpload
                  style={styles.imageupload}
                  value={this.state.cadastro.foto_large}
                  onChange={value => {
                    this.state.cadastro.foto_large = value;
                    this.setState({cadastro: this.state.cadastro});
                  }}
                  path={'casher/perfil'}
                />
              </View>
            </View>
            <View style={styles.view4}>
              <TextInput
                style={styles.textinput5}
                value={this.state.cadastro.nome}
                onChange={value => {
                  this.state.cadastro.nome = value;
                  this.setState({cadastro: this.state.cadastro});
                }}
                keyboardType={'default'}
                label={'Nome'}
              />
              <TextInput
                style={styles.textinput6}
                value={this.state.cadastro.email}
                onChange={value => {
                  this.state.cadastro.email = value;
                  this.setState({cadastro: this.state.cadastro});
                }}
                keyboardType={'email-address'}
                label={'Email'}
              />

              <TextInput
                style={styles.textinput5}
                value={this.state.cadastro.telefone}
                onChange={value => {
                  this.state.cadastro.telefone = Util.maskFormate(
                    value,
                    'phone',
                  );
                  this.setState({cadastro: this.state.cadastro});
                }}
                keyboardType={Platform.OS != 'web' ? 'numeric' : 'default'}
                label={'Telefone'}
              />

              <TextInput
                style={styles.textinput8}
                value={this.state.cadastro.senha}
                onChange={value => {
                  this.state.cadastro.senha = value;
                  this.setState({cadastro: this.state.cadastro});
                }}
                keyboardType={'default'}
                label={'Senha'}
                secureTextEntry={true}
              />

              <TextInput
                style={styles.textinput8}
                value={this.state.cadastro.confirmar_senha}
                onChange={value => {
                  this.state.cadastro.confirmar_senha = value;
                  this.setState({cadastro: this.state.cadastro});
                }}
                keyboardType={'default'}
                label={'Confirmar Senha'}
                secureTextEntry={true}
              />
            </View>
            <View style={styles.view6}>
              <TouchableOpacity
                style={styles.button211}
                onPress={() => {
                  this.salvar();
                }}
                disabled={this.state.load}
                elevation={2}>
                {!this.state.load ? (
                  <Text style={styles.text41}>{'Cadastrar-se'}</Text>
                ) : null}
                {this.state.load ? <Progress style={styles.progress} /> : null}
              </TouchableOpacity>
            </View>
          </ScrollView>
        </ImageBackground>
      </View>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(242,242,242,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  progress: {
    width: 35,
    height: 35,
  },
  titlebar: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignSelf: 'stretch',
    height: 50,
  },
  button: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  icon: {
    color: 'white',
    fontSize: 25,
  },
  scroll: {
    alignSelf: 'stretch',
    flex: 1,
    flexDirection: 'column',
  },
  view3: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  view5: {
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 196,
    height: 196,
    borderRadius: 98,
  },
  imageupload: {
    width: 190,
    height: 190,
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    borderRadius: 95,
    backgroundColor: 'rgba(230,230,230,1)',
  },
  view4: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: 20,
  },
  textinput5: {
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  textinput6: {
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  textinput8: {
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  view6: {
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 30,
  },
  button3: {
    alignSelf: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
    flexDirection: 'column',
    width: 50,
  },
  button211: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    height: 44,
    flexDirection: 'column',
    borderRadius: 22,
    backgroundColor: 'rgba(58,121,172,1)',
  },
  text41: {
    textAlign: 'center',
    color: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    fontWeight: '600',
  },
});
