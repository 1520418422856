import {Cloud, Util} from '../infra';
import md5 from 'md5';
// var token_user = Util.makeToken({ id: 3 });

export function loginFacebook(token, callback) {
  Cloud.get('login/facebook', {token}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function loginGoogle(user, callback) {
  Cloud.get('login/google', user, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function loginEmail({email, senha}, callback) {
  Cloud.get('login', {email, senha: md5(senha)}, (res, error) => {
    if (callback) callback(res, error);
  });
}

export function fb(callback) {
  const FBSDK = require('react-native-fbsdk');
  const {LoginManager, AccessToken} = FBSDK;
  LoginManager.logInWithPermissions(['public_profile', 'email']).then(
    result => {
      AccessToken.getCurrentAccessToken().then(accessToken => {
        console.log(accessToken.accessToken, '5263');
        callback(accessToken.accessToken);
      });
    },
    error => {
      console.log(error, '666');
      callback(error);
    },
  );
}
