import React, {Component} from 'react';
import * as TransacoesWorker from '../../worker/transacao';
import * as Divida from '../../worker/divida';
import {Content, Icon, ListView, TitleBar, Progress} from 'react-native-1app';
import TodoSwipeList from '../../components/TodoSwipeList';
import {showMessage} from 'react-native-1app/lib/FlashMessage';
import * as Actions from '../../redux/actions';
import moment from 'moment-timezone';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Text,
  BackHandler,
  ScrollView,
} from 'react-native';
import styleGlobal from '../../styleGlobal';
import {Util} from '../../infra';
export default class Transacoes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: false,
      transacoes: [],
      divida: this.propsNav().divida,
      total: '0,00',
      pago: '0,00',
    };
    this.backPageButtonHandler = this.backPageButtonHandler.bind(this);
  }

  propsNav() {
    var nav = this.props.navigation;
    if (nav && nav.state && nav.state.params) {
      return this.props.navigation.state.params;
    } else {
      return {};
    }
  }

  backPageButton() {
    if (this.props.navigation.state.params.onGoBack)
      this.props.navigation.state.params.onGoBack(); //func

    this.props.navigation.goBack();
  }

  backPageButtonHandler() {
    if (this.props.navigation.state.params.onGoBack)
      this.props.navigation.state.params.onGoBack(); //func
  }

  getColor(flag, tipo) {
    if (tipo == 'pendente') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    } else {
      return {
        color: 'rgba(48,129,219,1)',
      };
    }
  }

  componentDidMount() {
    this.unsubscribe = this.props.screenProps.store.subscribe(() => {
      var store = this.props.screenProps.store.getState();
      if (store.item != this.state.item) this.setState({item: store.item});
    });
    if (this.propsNav().id_divida) {
      this.loadTransacoes(this.propsNav().id_divida);
    }
    this.subs = [
      this.props.navigation.addListener('didFocus', payload => {
        this.loadTransacoes(this.propsNav().id_divida);
      }),
    ];
    BackHandler.addEventListener(
      'hardwareBackPress',
      this.backPageButtonHandler,
    );
  }

  loadTransacoes(id) {
    this.setState({load: true});
    TransacoesWorker.listar({id_divida: id}, (data, error) => {
      this.setState({
        transacoes: data.lista,
        pago: data.pago,
        total: data.total,
        divida: data.divida,
        load: false,
      });
    });
  }
  deletar(item) {
    this.setState({load: true});
    TransacoesWorker.remover(item, (data, error) => {
      this.setState({load: false});
      if (error) {
        showMessage({
          message: 'Erro ao deletar',
          description: error.msg,
          type: 'warning',
          icon: {icon: 'warning', position: 'left'},
          duration: 2000,
        });
      } else if (data.id) {
        showMessage({
          message: 'Transferência removida!',
          type: 'success',
          duration: 2000,
        });
        this.loadTransacoes(this.propsNav().id_divida);
      }
    });
  }
  componentWillUnmount() {
    BackHandler.removeEventListener(
      'hardwareBackPress',
      this.backPageButtonHandler,
    );
    if (this.unsubscribe) this.unsubscribe();
    if (this.subs) this.subs.forEach(sub => sub.remove());
  }

  render() {
    return (
      <View style={styles.content}>
        <TitleBar style={[styleGlobal.titlebar, Actions.getTema('titlebar')]}>
          <TouchableOpacity
            style={styles.button1}
            onPress={() => {
              this.backPageButton();
            }}>
            <Icon
              style={[styles.icon, Actions.getTema('iconstitlebar')]}
              fromFontFamily={'Material Design Icons'}
              name={'arrow-left'}
            />
            <Text style={[styles.text2, Actions.getTema('iconstitlebar')]}>
              {'Transações'}
            </Text>
          </TouchableOpacity>
          <View style={styles.view} />

          {this.state.load ? <Progress style={styleGlobal.progress} /> : null}
        </TitleBar>
        <View
          keyboardShouldPersistTaps={true}
          style={[styles.scroll, Actions.getTema('scroll')]}>
          <View style={styles.view50}>
            <TodoSwipeList
              style={[styles.listview, Actions.getTema('scroll')]}
              ListHeaderComponent={() => {
                return (
                  <Text
                    style={[styles.instrucao, Actions.getTema('translabel')]}>
                    {'Para remover uma transação, deslize ela para a esquerda'}
                  </Text>
                );
              }}
              renderItem={({item, index}) => {
                return (
                  <Celllistview
                    item={item}
                    screenProps={this.props.screenProps}
                    activity={this}
                    navigation={this.props.navigation}
                    rowID={index}
                  />
                );
              }}
              data={this.state.transacoes}
              keyExtractor={(item, index) => index}
              rightSubView={(item, index) => {
                return item.flag_principal ||
                  !Divida.testeDividaEdicao(this.state.divida) ? null : (
                  <TouchableOpacity
                    style={styles.button2}
                    onPress={() => {
                      this.deletar(item);
                    }}>
                    <Icon
                      style={[styles.icon2, Actions.getTema('iconstitlebar')]}
                      fromFontFamily={'Material Design Icons'}
                      name={'delete'}
                    />
                  </TouchableOpacity>
                );
              }}
              leftSubView={(item, index) => {
                return <View />;
              }}
            />
            {/* <ListView
              style={styles.listview}
              dataSource={this.state.transacoes}
              renderRow={(rowData, sectionID, rowID) => {
                return (
                  <Celllistview
                    item={rowData}
                    screenProps={this.props.screenProps}
                    activity={this}
                    navigation={this.props.navigation}
                    rowID={rowID}
                  />
                );
              }}
            /> */}
          </View>
        </View>

        <View style={[styles.footer, Actions.getTema('titlebar')]}>
          <View style={styles.view11}>
            {this.state.divida.flag_status != 'P' ? (
              <View style={styles.view99up}>
                <View style={styles.view99}>
                  <Text style={[styles.text82, Actions.getTema('labelfields')]}>
                    {'Pago:'}
                  </Text>
                  <Text
                    style={[
                      styles.text92,
                      {
                        ...this.getColor(
                          this.propsNav().divida.flag_tipo,
                          'pago',
                        ),
                      },
                    ]}>
                    {'R$ ' + this.state.pago}
                  </Text>
                </View>
                <View style={styles.view99}>
                  <Text style={[styles.text82, Actions.getTema('labelfields')]}>
                    {'Pendente:'}
                  </Text>
                  <Text
                    style={[
                      styles.text92,
                      {
                        ...this.getColor(
                          this.propsNav().divida.flag_tipo,
                          'pendente',
                        ),
                      },
                    ]}>
                    {'R$ ' + this.state.total}
                  </Text>
                </View>
              </View>
            ) : null}

            {this.state.divida.flag_status == 'P' ? (
              <View style={styles.view99}>
                <Text style={[styles.text82, Actions.getTema('labelfields')]}>
                  {'Quitado'}
                </Text>
              </View>
            ) : null}
          </View>
          <View style={styles.view8}>
            {/* <View style={styles.view14}> */}

            <TouchableOpacity
              style={[
                styles.viewnot3,
                {
                  backgroundColor:
                    this.state.divida.flag_status != 'P' &&
                    this.state.divida.flag_status != 'C' &&
                    Divida.testeDividaEdicao(this.state.divida)
                      ? Actions.getTema('addtrans_btn')
                      : ' rgba(172,172,172,1)',
                },
              ]}
              disabled={!Divida.testeDividaEdicao(this.state.divida)}
              onPress={() => {
                this.props.navigation.navigate('NovaTransacao', {
                  activity: this,
                  oldState: this.state,
                  // onGoBack: () => { this.loadTransacoes(this.propsNav().id_divida); },
                  id_divida: this.propsNav().id_divida,
                  divida: this.state.divida,
                  totais: {pago: this.state.pago, total: this.state.total},
                });
              }}>
              <Icon
                style={[
                  styles.icon3,
                  {
                    color: Divida.testeDividaEdicao(this.state.divida)
                      ? Actions.getTema('addtrans_icone')
                      : 'rgba(255,255,255,1)',
                  },
                ]}
                fromFontFamily={'Material Design Icons'}
                name={'plus'}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    );
  }
}

class Celllistview extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  getColor(flag) {
    if (flag == 'S') {
      return {
        color: 'rgba(255,0,0,1)',
      };
    } else if (flag == 'E') {
      return {
        color: 'rgba(48,129,219,1)',
      };
    }
  }

  render() {
    return (
      <TouchableOpacity
        style={[
          styles.Cell,
          {...Util.elevation()},
          Actions.getTema('titlebar'),
        ]}
        screenProps={this.props.screenProps}
        navigation={this.props.navigation}
        activity={this}
        disabled={
          this.props.item.flag_principal ||
          !Divida.testeDividaEdicao(this.props.activity.state.divida)
        }
        onPress={() => {
          if (
            this.props.item.flag_principal != true &&
            Divida.testeDividaEdicao(this.props.activity.state.divida)
          ) {
            this.props.navigation.navigate('NovaTransacao', {
              activity: this.props.activity,
              oldState: this.props.activity.state,
              // onGoBack: () => { this.loadTransacoes(this.propsNav().id_divida); },
              id_divida: this.props.activity.propsNav().id_divida,
              divida: this.props.activity.state.divida,
              id_transacao: this.props.item.id,
              totais: {
                pago: this.props.activity.state.pago,
                total: this.props.activity.state.total,
              },
            });
          }
        }}>
        <View style={styles.view9}>
          <Text style={[styles.text8, Actions.getTema('lbl_trans')]}>
            {moment(this.props.item.data_transacao).format(
              'DD/MM/YYYY HH:mm:ss',
            )}
          </Text>
          <Text
            style={[
              styles.text9,
              {...this.getColor(this.props.item.flag_tipo)},
            ]}>
            {this.props.item.valor_formatado}
          </Text>
          <Icon
            style={[
              styles.icon61,
              {...this.getColor(this.props.item.flag_tipo)},
            ]}
            fromFontFamily={'Material Design Icons'}
            name={this.props.item.flag_tipo == 'S' ? 'arrow-down' : 'arrow-up'}
          />
        </View>
        {this.props.item.descricao ? (
          <View style={styles.view9}>
            <Text style={[styles.text8, Actions.getTema('lbl_trans')]}>
              {this.props.item.descricao}
            </Text>
          </View>
        ) : null}
      </TouchableOpacity>
    );
  }
}

var styles = StyleSheet.create({
  content: {
    backgroundColor: 'rgba(255,255,255,1)',
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  button1: {
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    color: 'rgba(44,126,218,1)',
    height: 50,
    paddingLeft: 10,
    paddingRight: 20,
    flex: 1,
  },
  view11: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flex: 1,
  },
  icon: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
    marginRight: 5,
  },
  text2: {
    textAlign: 'left',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'auto',
    fontWeight: 'normal',
  },
  view: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  button2: {
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: 50,
  },
  icon2: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },

  icon3: {
    // color: "rgba(48,129,219,1)",
    color: 'rgba(255,255,255,1)',
    fontSize: 45,
  },
  viewnot3: {
    alignSelf: 'auto',
    flexDirection: 'column',
    // alignItems: "flex-start",
    // justifyContent: "flex-start",
    flexWrap: 'nowrap',
    minWidth: 20,
    minHeight: 20,
    backgroundColor: 'rgba(48,129,219,1)',
    borderRadius: 50,
    maxWidth: 50,
    maxHeight: 50,
  },
  scroll: {
    alignSelf: 'stretch',
    flexDirection: 'column',
    flex: 1,
  },
  view50: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  listview: {
    alignSelf: 'stretch',
  },
  Cell: {
    flexDirection: 'column',
    alignSelf: 'stretch',
    flex: 1,
    padding: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: 'rgba(255,255,255,1)',
    borderRadius: 8,
    margin: 5,
  },
  view9: {
    alignSelf: 'stretch',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  text8: {
    textAlign: 'left',
    alignSelf: 'center',
    fontWeight: 'bold',
    marginLeft: 10,
    flex: 1,
  },
  text9: {
    textAlign: 'right',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    
    marginRight: 5,
  },
  icon61: {
    color: 'rgba(48,129,219,1)',
    fontSize: 25,
  },
  footer: {
    alignSelf: 'stretch',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  view99: {
    alignSelf: 'auto',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    padding: 2,
  },
  view99up: {
    alignSelf: 'auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: 2,
  },
  view8: {
    
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 7,
  },
  text82: {
    textAlign: 'right',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    marginLeft: 10,
    fontSize: 16,
    marginRight: 5,
  },
  instrucao: {
    textAlign: 'left',
    color: 'rgba(0,0,0,1)',
    alignSelf: 'center',
    // paddingTop:10
    marginTop: 10,
    marginBottom: 10,
    fontSize: 12,
  },
  text92: {
    textAlign: 'right',
    color: 'rgba(48,129,219,1)',
    alignSelf: 'center',
    fontWeight: 'bold',
    marginRight: 5,
  },
});
